import React from 'react'
import StyledSystem from 'styled-system'
import { Button as BaseButton, ButtonProps } from '@chakra-ui/core'
import { themeUtils } from '../../themeUtils'
import { darken } from 'polished'

export interface CustomButtonProps extends ButtonProps {
  flexShrink?: StyledSystem.FlexboxProps['flexShrink']
}

const Button: React.FC<CustomButtonProps> = ({
  variant = 'solid',
  backgroundColor = themeUtils.colors.shuttleGrey,
  boxShadow = {
    base: '0 8px 10px rgba(69, 218, 122, 0.2)',
    lg: 'none',
  },
  paddingX = '1rem',
  paddingY = '0',
  iconSpacing = '1rem',
  fontWeight = 'medium',
  fontSize = '1.4rem',
  borderRadius = '0.5rem',
  height = '4rem',
  loadingText = 'Loading',
  _hover,
  ...rest
}) => {
  const LinkVariant = variant === 'link'
  const OutlinedVariant = variant === 'outline'
  const hoverProps = _hover
    ? _hover
    : {
        backgroundColor: LinkVariant
          ? 'inherit'
          : OutlinedVariant
          ? darken(0.1, `${rest.color}`)
          : darken(0.1, `${backgroundColor}`),
        textDecoration: LinkVariant ? 'underline' : 'inherit',
        color: LinkVariant ? 'inherit' : themeUtils.colors.white,
      }

  return (
    <BaseButton
      loadingText={loadingText}
      backgroundColor={
        LinkVariant || OutlinedVariant ? 'inherit' : backgroundColor
      }
      _hover={hoverProps}
      boxShadow={LinkVariant ? 'none' : boxShadow}
      variant={variant}
      borderRadius={borderRadius}
      color={
        LinkVariant || OutlinedVariant ? 'inherit' : themeUtils.colors.white
      }
      borderColor={OutlinedVariant ? rest.color : 'inherit'}
      border={OutlinedVariant ? `2px solid ${rest.color}` : 'inherit'}
      fontSize={fontSize}
      fontWeight={fontWeight}
      padding={`${paddingY} ${paddingX}`}
      height={height}
      iconSpacing={iconSpacing}
      {...rest}
    />
  )
}

export { Button }
