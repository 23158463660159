import React from 'react'
import { Modal, ModalBody, Text, ModalCloseButton } from '@chakra-ui/core'
import { useUser } from '../../../context/user-context'
import { themeUtils } from '../../../themeUtils'
import { LinkButton, ModalOverlay, ModalContent } from '../../Shared'
import { EmailIcon } from '../../Icons'
import { useTranslation } from 'react-i18next'

interface OrderContactModalProps {
  isOpen: boolean
  onClose?: () => void
}
const { red } = themeUtils.colors

export const OrderContactModal: React.FC<OrderContactModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation(['orderCreateButton'])
  const user = useUser()
  const customerSalesRep = user?.customer.regionalCustomerCareRep
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent padding="2.5rem" borderRadius="1rem" maxWidth="40rem">
        <ModalCloseButton data-cy="modal-close-btn" />
        <ModalBody padding="2rem 0" textAlign="center">
          <Text color={red}>
            <b>
              {t('orderContactModal.bodyLabel', { ns: ['orderCreateButton'] })}
            </b>
          </Text>
          <Text marginY="1rem">
            <b>{customerSalesRep?.name}</b>
          </Text>

          <LinkButton
            wordBreak="break-all"
            padding="1.5rem"
            href={`mailto:${customerSalesRep?.email}`}
          >
            <EmailIcon /> {customerSalesRep?.email}
          </LinkButton>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
