import React from 'react'
import {
  FormHelperText as BaseFormHelperText,
  BoxProps,
} from '@chakra-ui/core'

export const FormHelperText: React.FC<BoxProps> = ({
  fontSize = '1.2rem',
  ...props
}) => (
  <BaseFormHelperText fontSize={fontSize} {...props} />
)
