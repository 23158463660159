import { useContext } from 'react'
import {
  VialSearchContext,
  VialMapType,
  ActivityVialType,
} from '../context/VialSearchContext'
import { DesiredActivityAtTreatment, PortalVialSearchResultType, VialSearchResultOptionType } from '../components/Orders/OrderCreate/OrderFormDataTypes'

export const useVialSearch = () => {
  const { state, setState } = useContext(VialSearchContext)

  const addVial = (dosage: number) => {
    setState(prevState => {
      const selectedVials = prevState.selectedVials
      const selectedCalibrationDate = state.selectedCalibrationDate
      const existingSelections = new Map(selectedVials)
      const vialOrder = existingSelections.get(dosage)
      const updatedVial = new Map<string, number>()

      if (vialOrder) {
        const existingVial = vialOrder.get(selectedCalibrationDate)

        if (existingVial) {
          vialOrder.set(selectedCalibrationDate, existingVial + 1)
        } else {
          vialOrder.set(selectedCalibrationDate, 1)
        }

        existingSelections.set(dosage, vialOrder)
        return { ...prevState, selectedVials: existingSelections }
      }

      updatedVial.set(selectedCalibrationDate, 1)
      existingSelections.set(dosage, updatedVial)

      return { ...prevState, selectedVials: existingSelections }
    })
  }

  const removeVial = (
    dosage: number,
    calibrationDate: string = state.selectedCalibrationDate
  ) => {
    setState(prevState => {
      const selectedVials = prevState.selectedVials

      const existingSelections = new Map(selectedVials)
      const vialOrder = existingSelections.get(dosage)

      if (vialOrder) {
        const existingVial = vialOrder.get(calibrationDate)

        if (existingVial) {
          const updatedQuantity = existingVial - 1

          if (updatedQuantity > 0) {
            vialOrder.set(calibrationDate, existingVial - 1)
            existingSelections.set(dosage, vialOrder)
          } else {
            vialOrder.delete(calibrationDate)
            existingSelections.delete(dosage)
          }

          return { ...prevState, selectedVials: existingSelections }
        }
      }

      return prevState
    })
  }

  const clearSelectedVials = () => {
    setState(prevState => {
      return { ...prevState, selectedVials: new Map() }
    })
  }

  const setDefaultSelectedVials = (vials: VialMapType) => {
    setState(prevState => {
      return { ...prevState, selectedVials: new Map(vials) }
    })
  }

  const setSelectedCalibrationDate = (date: string) => {
    setState(prevState => {
      return { ...prevState, selectedCalibrationDate: date }
    })
  }

  const addActivityTreatmentVial = (
    vialNumber: string,
    vialActivityValue: number,
    vial: VialSearchResultOptionType
  ) => {
    setState(prevState => {
      const selectedVials = prevState.selectedActivityWiseVials
      const existingSelections = new Map(selectedVials)
      const vialOrder = existingSelections.get(vialNumber)
      const updatedVial = new Map<number, VialSearchResultOptionType>()

      if (vialOrder) {
        const existingVial = vialOrder.get(vialActivityValue)

        if (existingVial) {
          vialOrder.set(vialActivityValue, vial)
        } else {
          vialOrder.set(vialActivityValue, vial)
        }

        existingSelections.set(vialNumber, vialOrder)
        return { ...prevState, selectedActivityWiseVials: existingSelections }
      }

      updatedVial.set(vialActivityValue, vial)
      existingSelections.set(vialNumber, updatedVial)

      return { ...prevState, selectedActivityWiseVials: existingSelections }
    })
  }

  const clearSelectedActivityVials = () => {
    setState(prevState => {
      return {
        ...prevState,
        selectedActivityWiseVials: new Map<string, ActivityVialType>(),
      }
    })
  }

  const getAllSelectedVialOption = (): VialSearchResultOptionType[] => {
    return Array.from(state.selectedActivityWiseVials.values())
      .flatMap(activityMap => [...activityMap.values()]);
  };

  const checkIfDoseIsAvailable = (
    checkVial: VialSearchResultOptionType,
    optionalSelectedVials?: VialSearchResultOptionType[] 
  ): boolean => {
    const selectedVials = optionalSelectedVials || getAllSelectedVialOption()

    if (!checkVial.surplus) {
      return true
    }

    const filteredVials = selectedVials.filter(
      s =>
        s.dosage === checkVial.dosage &&
        s.region_code === checkVial.region_code &&
        s.calibration_week === checkVial.calibration_week &&
        s.deviation === s.deviation
    )
    if (filteredVials.length) {
      if (checkVial.available > filteredVials.length) {
        return true
      }
    } else if (checkVial.available) {
      return true
    }

    return false
  }

  const checkIfDoseAlreadySelected = (
    vialNumber: string,
    vialActivityValue: number,
    vial: VialSearchResultOptionType
  ): boolean => {
    const selectedVials = state.selectedActivityWiseVials
    const existingSelections = new Map(selectedVials)
    const vialOrder = existingSelections.get(vialNumber)

    if (vialOrder) {
      const existingVial = vialOrder.get(vialActivityValue)
      if (existingVial) {
        return existingVial.dosage === vial.dosage
      }
    }

    return false
  }

  const reverifySelectedActivityVials = (
    desiredActivityAtTreatmentSet: DesiredActivityAtTreatment[] | undefined,
    vialSearchResult: PortalVialSearchResultType | null | undefined
  ) => {
    if (!desiredActivityAtTreatmentSet || !vialSearchResult) {
      clearSelectedActivityVials()
      return
    }

    const selectedVials = state.selectedActivityWiseVials
    if (selectedVials.size) {
      const existingSelections = new Map(selectedVials)

      const UpdateActivitySelection = new Map<string, ActivityVialType>()

      desiredActivityAtTreatmentSet.forEach((desiredActivity, index) => {
        const vialTextKey = `vial${index + 1}`
        const vialOrder = existingSelections.get(vialTextKey)
        const updatedVial = new Map<number, VialSearchResultOptionType>()

        const { activity } = desiredActivity
        if (activity) {
          const key = Number.isInteger(activity)
            ? activity.toFixed(1)
            : activity.toString()

          const existingVial = vialOrder?.get(parseFloat(key))
          const vial = vialSearchResult[key]?.find(
            r => r.dosage === existingVial?.dosage
          )
          if (
            vial &&
            checkIfDoseIsAvailable(
              vial,
              Array.from(
                UpdateActivitySelection.values()
              ).flatMap(activityMap => [...activityMap.values()])
            )
          ) {
            updatedVial.set(parseFloat(key), vial)
            UpdateActivitySelection.set(vialTextKey, updatedVial)
          }
        }
      })

      setState(prevState => {
        return {
          ...prevState,
          selectedActivityWiseVials: UpdateActivitySelection,
        }
      })
    }
  }

  return {
    addVial,
    removeVial,
    setSelectedCalibrationDate,
    clearSelectedVials,
    setDefaultSelectedVials,
    selectedVials: state.selectedVials,
    selectedCalibrationDate: state.selectedCalibrationDate,
    addActivityTreatmentVial,
    clearSelectedActivityVials,
    selectedActivityVials: state.selectedActivityWiseVials,
    checkIfDoseIsAvailable,
    checkIfDoseAlreadySelected,
    reverifySelectedActivityVials,
    getAllSelectedVialOption,
  }
}
