import React from 'react'
import { IconProps } from '../../types'
import styled from '@emotion/styled'
import { themeUtils } from '../../themeUtils'

type Props = IconProps & React.SVGAttributes<SVGElement>

const Svg = styled.svg`
  flex-shrink: 0;
  backface-visibility: hidden;
  &:not(:root) {
    overflow: hidden;
  }
`

const ExclamationMarkIcon: React.FC<Props> = ({
  fill = themeUtils.colors.red,
  width = 70,
  height = 70,
  ...rest
}) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 70 70"
      {...rest}
    >
      <g>
        <g>
          <path
            d="M37.23439,39.19344c-0.67566,2.38819 -2.80442,2.42921 -3.51401,0c-0.81701,-2.80135 -3.72038,-13.43536 -3.72038,-20.34205c0,-9.1132 11,-9.15715 11,0c-0.00283,6.94771 -3.06168,17.85717 -3.76561,20.34205zM35.5,58c-2.67152,0 -4.5,-2.17771 -4.5,-5c0,-2.90361 1.90538,-5 4.5,-5c2.74272,0 4.5,2.09639 4.5,5c0,2.8253 -1.75728,5 -4.5,5zM35,0c-19.32875,0 -35,15.67125 -35,35c0,19.32875 15.67125,35 35,35c19.32875,0 35,-15.67125 35,-35c0,-19.32875 -15.67125,-35 -35,-35z"
            fill={fill}
          ></path>
        </g>
      </g>
    </Svg>
  )
}

export { ExclamationMarkIcon }
