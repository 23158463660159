import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { themeUtils } from '../../../themeUtils'
import '../../../SharedStyles.css'
import { NPISearch_npiSearch_results } from '../../../schema/queries/__generated__/NPISearch'

const TableCell = styled.td`
  max-width: 300px;
  padding: 2.5rem 0.5rem;

  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;

  &:first-of-type {
    text-transform: uppercase;
  }

  @media print {
    font-size: 1rem;
  }
`

const TableRow = styled.tr`
  border-top: 1px solid ${themeUtils.colors.borderGrey};
  &:hover {
    background-color: ${themeUtils.colors.lightGrey};
  }
`

interface Props {
  result: NPISearch_npiSearch_results
  onSelectNPI: (result: NPISearch_npiSearch_results) => void
}

export const NPIRow: React.FC<Props> = ({ result, onSelectNPI }) => {
  const { number, basic, addresses } = result

  const addressValue = useMemo(() => {
    let address = addresses && addresses.filter(a => a.addressPurpose === "LOCATION")[0]
    if(address) {
      const {address1, city, state, postalCode} = address
      return `${address1}, ${city}, ${state} - ${postalCode}`
    }
    return ""
  }, [addresses])

  return (
    <>
      <TableRow
        data-cy="npi-table-row"
        onClick={() => onSelectNPI(result)}
        style={{ cursor: 'pointer' }}
      >
        <TableCell>
          {basic?.firstName && (
            <p>{basic.firstName}</p>
          )}
          {basic?.middleName && (
            <p>{basic.middleName}</p>
          )}
          {basic?.lastName && (
            <p>{basic.lastName}</p>
          )}
        </TableCell>
        <TableCell>{addressValue}</TableCell>
        <TableCell>{number}</TableCell>
      </TableRow>
    </>
  )
}
