import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  SetOrderFilters,
  SetOrderFiltersVariables,
} from '../../schema/mutations/__generated__/SetOrderFilters'
import { ORDER_FILTERS_QUERY } from '../../schema/queries'
import { GetOrderFilters } from '../../schema/queries/__generated__/GetOrderFilters'
import { SET_ORDER_FILTERS_MUTATION } from '../../schema/mutations'
import { Pagination } from '../Shared'

interface Props {
  totalPages: number
  windowSize?: number
}

export const OrdersPagination: React.FC<Props> = ({
  totalPages,
  windowSize,
}) => {
  const { data } = useQuery<GetOrderFilters>(ORDER_FILTERS_QUERY)

  const [setFiltersMutation] = useMutation<
    SetOrderFilters,
    SetOrderFiltersVariables
  >(SET_ORDER_FILTERS_MUTATION)

  const changePage = (page: number): void => {
    if (!data) return

    setFiltersMutation({
      variables: {
        input: {
          ...data.orderFilters,
          page,
        },
      },
    })
  }

  const currentPage = data ? data.orderFilters.page : 1

  return (
    <Pagination
      ariaLabel="Order Pagination"
      onClick={changePage}
      currentPage={currentPage}
      totalPages={totalPages}
      windowSize={windowSize}
    />
  )
}
