import React, { Dispatch, SetStateAction } from 'react'
import { Box, Text, FlexProps } from '@chakra-ui/core'
import { AccessoryIcon } from '../../Icons'
import { ProductContainer } from '../TreatmentsWidget'
import { AdminSetType, OrderFormData } from './OrderFormDataTypes'
import { RemoveOrderItem } from './RemoveOrderItem'
import { useTranslation } from 'react-i18next'

interface Props extends FlexProps {
  accessory: AdminSetType
  accessories: Array<AdminSetType>
  index: number
  setAccessoryData?: Dispatch<SetStateAction<OrderFormData>>
  removeable?: boolean
}

export const Accessory: React.FC<Props> = ({
  accessory,
  accessories,
  setAccessoryData,
  removeable = false,
  index,
  ...restProps
}) => {
  const { t } = useTranslation(['treatment'])
  const removeAccessory = (indexToRemove: number): void => {
    if (!removeable || !setAccessoryData) return

    const updatedAdminSets = accessories.reduce((array, accessory, idx) => {
      if (idx !== indexToRemove) array.push(accessory)
      return array
    }, Array<AdminSetType>())

    setAccessoryData((prevData: OrderFormData) => {
      return { ...prevData, adminSets: updatedAdminSets }
    })
  }

  return (
    <ProductContainer {...restProps}>
      <Box width="3.4rem" height="3rem">
        <AccessoryIcon />{' '}
      </Box>
      <Text marginLeft="1.5rem">{t('accessory.tubingSet', { ns: ['treatment'] })}</Text>
      <Box display="flex" flex="1" justifyContent="flex-end" alignSelf="center">
        <Text
          border="1px solid rgba(255, 255, 255, 0.3)"
          boxShadow="0px 10px 15px rgba(0, 57, 104, 0.1)"
          borderRadius="0.5rem"
          padding="0.5rem 1rem"
        >
          {accessory.quantity}x
        </Text>
      </Box>
      {removeable && <RemoveOrderItem onClick={() => removeAccessory(index)} />}
    </ProductContainer>
  )
}
