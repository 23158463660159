import React from 'react'
import { Option } from '.'
import { FormControl } from '@chakra-ui/core'
import { FormLabel, FormErrorMessage, FormHelperText } from '../../Forms'
import Select, {
  components,
  StylesConfig,
  ThemeConfig,
  DropdownIndicatorProps,
} from 'react-select'
import { themeUtils } from '../../../themeUtils'
import classnames from 'classnames'
import { ThemeVersion } from '../../../types'
import styled from '@emotion/styled'

interface Props {
  [key: string]: any
  value?: Option | null
  inputValue?: string
  options: Option[]
  onChange?: (option: Option) => void
  label?: string
  hint?: string
  error?: string
  placeholder?: string
  disabled?: boolean
  loading?: boolean
  clearable?: boolean
  isSearchable?: boolean
  isRequired?: boolean
  onInputChange?: (newValue: string) => void
  noOptionsMessage?: (obj: { inputValue: string }) => string | null
  innerRef?: any
  version?: ThemeVersion
  groupClassName?: string
  classNamePrefix?: string
}

const DropdownIndicator = (props: DropdownIndicatorProps<Option>) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        style={{ height: '0.65em' }}
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAmUlEQVQoU2NkwAasMhoY/jM4MByf4YAuzTgsNFhmTGBgZFrAcGzaBbh/sHnaKsuA4f/feEYGy4wFDAz//RkYmR3hmtA1QBTvZ2Bg3AgJJXRNyBqQFDMcn5GACFZkTQz/AsDxwMhUADMZpBhkNmo8wDQxMB5kYGDQZ2D4LwByBkwxpga48xjioQGwEFkxdg0ITR8Yjs8oQE8JAI50VX3QNFWDAAAAAElFTkSuQmCC"
      />
    </components.DropdownIndicator>
  )
}

const customTheme: ThemeConfig = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: themeUtils.colors.geyserGrey,
    primary: themeUtils.colors.midnightBlue,
    primary50: themeUtils.colors.geyserGrey,
    neutral20: themeUtils.colors.geyserGrey,
  },
})

export const CustomSelect: React.FC<Props> = ({
  value,
  inputValue,
  onChange,
  label,
  hint,
  error,
  placeholder,
  options,
  disabled,
  loading,
  clearable,
  isSearchable,
  isRequired,
  noOptionsMessage,
  onInputChange,
  innerRef,
  version,
  groupClassName,
  classNamePrefix,
  ...props
}) => {
  const colourStyles: StylesConfig<Option> = {
    control: (styles, { isFocused, isDisabled }) => {
      return {
        ...styles,
        borderWidth: '1px',
        height: `${version === ThemeVersion.V2 ? '47px' : '6rem'}`,
        width: '100%',
        borderRadius: `${version === ThemeVersion.V2 ? '5px' : '1rem'}`,
        borderColor: `${themeUtils.colors.geyserGrey}`,
        fontSize: '1.4rem',
        padding: '0 0.06rem 0 1rem',
        boxShadow: isFocused
          ? `0 0 0 1px ${themeUtils.colors.geyserGrey}`
          : 'unset',
        backgroundColor: themeUtils.colors.white,
        opacity: isDisabled ? 0.4 : 'unset',
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        '&:hover': {
          borderWidth: '1px',
        },
      }
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: '1.4rem',
        fontWeight: data.isLink ? 'bold' : styles.fontWeight,
        color: data.isLink
          ? themeUtils.colors.green
          : isSelected
          ? themeUtils.colors.white
          : themeUtils.colors.midnightBlue,
        cursor: data.isLink ? 'pointer' : 'default',
  
        ':hover': {
          backgroundColor: data.isLink ? themeUtils.colors.white : undefined,
        },
  
        ':active': {
          ...styles[':active'],
        },
      }
    },
    input: styles => ({
      ...styles,
      ':active': {
        ...styles[':active'],
        borderColor: themeUtils.colors.geyserGrey,
        borderWidth: '1px',
      },
    }),
    placeholder: styles => ({
      ...styles,
      color: themeUtils.colors.slateGrey,
    }),
    singleValue: styles => ({ ...styles, color: themeUtils.colors.midnightBlue }),
    indicatorsContainer: styles => ({
      ...styles,
      padding: '1px',
    }),
  }

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={!!error}
      margin={version === ThemeVersion.V2 ? '0' : '0 0 1rem 0'}
      className={groupClassName}
      width="100%"
    >
      {!!label && <FormLabel>{label}</FormLabel>}
      <Select
        styles={colourStyles}
        theme={customTheme}
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        placeholder={placeholder}
        isSearchable={isSearchable}
        options={options}
        isClearable={clearable}
        value={value}
        inputValue={inputValue}
        onChange={onChange ? option => onChange(option as Option) : undefined}
        isDisabled={disabled}
        isLoading={loading}
        onInputChange={onInputChange}
        noOptionsMessage={noOptionsMessage}
        classNamePrefix={classNamePrefix ?? 'react-select'}
        className={classnames('react-select-container', {
          'has-error': !!error,
        })}
        ref={innerRef}
        {...props}
      />
      {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
      {!!hint && <FormHelperText>{hint}</FormHelperText>}
    </FormControl>
  )
}
