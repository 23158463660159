import React from 'react'
import { useUser } from '../../context/user-context'
import { OrderShow_order } from "../../schema/queries/__generated__/OrderShow";

interface Props {
  order?: OrderShow_order
}

export const CustomerName: React.FC<Props> = ({order}) => {
  const user = useUser()
  if (!user) return null
  const customerName = order ? order.customerName : user.customer.name

  return <>{customerName}</>
}
