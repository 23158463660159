import React from 'react'
import { OrderShow_order } from '../../../schema/queries/__generated__/OrderShow'
import { themeUtils } from '../../../themeUtils'
import {
  OrderCreditTypeEnum,
  OrderFinanceStatusEnum,
} from '../../../__generated__/globalTypes'
import { Heading, ParagraphText } from '../../Shared'
import { BillingItem } from './BillingItem'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'

interface Props {
  order: OrderShow_order
}

const { slateGrey } = themeUtils.colors

const getInvoiceStatusText = (
  isPending = false,
  isInvoiced = false
): string | undefined => {
  if (isPending) return i18n.t('financeInfo.pending', { ns: ['orderShowScreen'] })
  if (isInvoiced) return i18n.t('financeInfo.invoiced', { ns: ['orderShowScreen'] })
}

const getCreditStatusText = ({
  isCredited = false,
  isCreditRequested = false,
  isRebillInvoice = false,
}): string | undefined => {
  if (isCredited && isRebillInvoice) return i18n.t('financeInfo.invoiced', { ns: ['orderShowScreen'] })
  if (isCredited) return i18n.t('financeInfo.credited', { ns: ['orderShowScreen'] })
  if (isCreditRequested) return i18n.t('financeInfo.pending', { ns: ['orderShowScreen'] })
}

export const FinanceInformation: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation(['orderShowScreen'])
  const {
    invoiceNumber,
    creditType,
    sapSalesOrderNumber,
    sapCreditMemoNumber,
    financeStatus,
  } = order
  const showSalesOrderNumber = ![
    OrderFinanceStatusEnum.SENT_TO_FINANCE,
    OrderFinanceStatusEnum.PENDING_PO,
  ].some(status => financeStatus.includes(status))
  const isPending = [
    OrderFinanceStatusEnum.SENT_TO_FINANCE,
    OrderFinanceStatusEnum.PENDING_PO,
    OrderFinanceStatusEnum.ACCEPTED_BY_FINANCE,
    OrderFinanceStatusEnum.REJECTED_BY_FINANCE,
    OrderFinanceStatusEnum.ACCEPTED_BY_FINANCE_WITH_WARNINGS,
  ].includes(financeStatus)
  const isCredited = financeStatus === OrderFinanceStatusEnum.CREDITED
  const isCreditRequested =
    isCredited ||
    financeStatus === OrderFinanceStatusEnum.CREDIT_REQUESTED ||
    financeStatus === OrderFinanceStatusEnum.CREDIT_APPROVED ||
    financeStatus === OrderFinanceStatusEnum.CREDIT_REJECTED_BY_FINANCE
  const isInvoiced =
    isCreditRequested || financeStatus === OrderFinanceStatusEnum.INVOICED
  const isRebill =
    isCreditRequested && creditType === OrderCreditTypeEnum.CREDIT_AND_REBILL
  const showCreditDetails = isCredited || isCreditRequested
  console.log(
    'order status',
    JSON.stringify(
      {
        status: order.status,
        financeStatus,
        creditType,
      },
      null,
      4
    )
  )
  return (
    <>
      {showCreditDetails && (
        <>
          {isRebill && (
            <>
              <Heading as="h2" color={slateGrey} fontWeight="normal">
                {t('financeInfo.invoice', { ns: ['orderShowScreen'] })}
              </Heading>
              <BillingItem heading={t('financeInfo.status', { ns: ['orderShowScreen'] })}>
                <ParagraphText color={slateGrey}>
                  {getCreditStatusText({
                    isCredited,
                    isCreditRequested,
                    isRebillInvoice: true,
                  })}
                </ParagraphText>
              </BillingItem>
            </>
          )}
          <Heading as="h2" color={slateGrey} fontWeight="normal">
            {t('financeInfo.credit', { ns: ['orderShowScreen'] })}
          </Heading>
          <BillingItem heading={t('financeInfo.status', { ns: ['orderShowScreen'] })}>
            <ParagraphText color={slateGrey}>
              {getCreditStatusText({ isCredited, isCreditRequested })}
            </ParagraphText>
          </BillingItem>
          {!!sapCreditMemoNumber && (
            <BillingItem heading={t('financeInfo.memoNum', { ns: ['orderShowScreen'] })}>
              <ParagraphText color={slateGrey}>
                {sapCreditMemoNumber}
              </ParagraphText>
            </BillingItem>
          )}
        </>
      )}
      <Heading as="h2" color={slateGrey} fontWeight="normal">
        {t('financeInfo.invoice', { ns: ['orderShowScreen'] })}
      </Heading>
      <BillingItem heading={t('financeInfo.status', { ns: ['orderShowScreen'] })}>
        <ParagraphText color={slateGrey}>
          {getInvoiceStatusText(isPending, isInvoiced)}
        </ParagraphText>
      </BillingItem>
      {invoiceNumber ? (
        <BillingItem heading={t('financeInfo.invoiceNo', { ns: ['orderShowScreen'] })}>
          <ParagraphText color={slateGrey}>{invoiceNumber}</ParagraphText>
        </BillingItem>
      ) : null}
      {showSalesOrderNumber && sapSalesOrderNumber ? (
        <BillingItem heading={t('financeInfo.bscNo', { ns: ['orderShowScreen'] })}>
          <ParagraphText color={slateGrey}>{sapSalesOrderNumber}</ParagraphText>
        </BillingItem>
      ) : null}
    </>
  )
}
