import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { Flex, FlexProps } from '@chakra-ui/core'
import { OrderCreateWidget } from '../../components/Orders/OrderCreate'
import { OrderData } from '../../types'
import { VialSearchProvider } from '../../context/VialSearchContext'
import { OrderFormHeader } from '../../components/Orders'
import { OrderFormProvider } from '../../context/OrderFormContext'
import styled from '@emotion/styled'

const Container = styled(Flex)<FlexProps>`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

type Props = RouteComponentProps<{ orderData: OrderData }>

export const OrderCreateScreen: React.FC<Props> = props => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const orderData = props?.location?.state?.orderData
  return (
    <OrderFormProvider>
      <Container>
        <OrderFormHeader displayBackButton={!orderData} />
        <VialSearchProvider>
          <OrderCreateWidget preSelectedOrderData={orderData} />
        </VialSearchProvider>
      </Container>
    </OrderFormProvider>
  )
}
