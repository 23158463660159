import React, { ReactNode } from 'react'
import {
  Modal as BaseModal,
  ModalHeader,
  ModalCloseButton,
  Text,
} from '@chakra-ui/core'
import { ModalOverlay, ModalContent } from '.'
import { themeUtils } from '../../../themeUtils'

interface ModalProps {
  isOpen?: boolean
  onOpen?: () => void
  onClose?: () => void
  onToggle?: () => void
  headingText: string
  showCloseButton?: boolean
  closeOnOverlayClick?: boolean
  icon: ReactNode,
  size?: string
}
const { midnightBlue } = themeUtils.colors

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onOpen,
  onClose,
  icon,
  headingText,
  showCloseButton = false,
  closeOnOverlayClick = false,
  children,
  size = "xl",
  ...rest
}) => {
  return (
    <>
      <BaseModal
        closeOnOverlayClick={closeOnOverlayClick}
        isCentered
        size={size}
        onClose={onClose}
        isOpen={isOpen}
        {...rest}
      >
        <ModalOverlay />
        <ModalContent>
          {showCloseButton && <ModalCloseButton />}
          <ModalHeader
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            {icon}
            <Text
              color={midnightBlue}
              fontSize="1.8rem"
              fontWeight="medium"
              marginTop="0.5rem"
              marginBottom="0.5rem"
            >
              {headingText}
            </Text>
          </ModalHeader>
          {children}
        </ModalContent>
      </BaseModal>
    </>
  )
}
