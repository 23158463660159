import gql from 'graphql-tag'

export const ORDER_FILTERS_QUERY = gql`
  query GetOrderFilters {
    orderFilters @client {
      state
      page
      sort {
        column
        direction
      }
      limit
      search
      status
      customerId
    }
  }
`
