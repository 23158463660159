import React from 'react'
import { LoadingIndicator, Alert } from '../../Shared'
import { ApolloError } from 'apollo-client'
import { NPISearch_npiSearch_results } from '../../../schema/queries/__generated__/NPISearch'
import { NPIRow } from '.'
import { useTranslation } from 'react-i18next'

const BlankRow: React.FC = ({ children }) => (
  <tr>
    <td colSpan={3} style={{ textAlign: 'center' }}>
      {children}
    </td>
  </tr>
)

interface Props {
  results:  NPISearch_npiSearch_results[] | null | undefined
  loading: boolean
  error?: ApolloError
  onSelectNPI: (result: NPISearch_npiSearch_results) => void
}

export const TableContent: React.FC<Props> = ({ results, loading, error, onSelectNPI }) => {
  const { t } = useTranslation(['npiWidget'])
  if (error) {
    return (
      <BlankRow>
        <Alert status="error">{error.message}</Alert>
      </BlankRow>
    )
  }

  if (loading) {
    return (
      <BlankRow>
        <LoadingIndicator />
      </BlankRow>
    )
  }

  if (!results || results.length === 0)
    return (
      <BlankRow>
        {t('table.noResult', {
          ns: ['npiWidget'],
        })}
      </BlankRow>
    )

  return (
    <>
      {results.map((obj, i) => {
        return (
          <NPIRow result={obj} key={obj.number} onSelectNPI={onSelectNPI} />
        )
      })}
    </>
  )
}
