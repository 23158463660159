import React from 'react'
import {
  Modal,
  ModalBody
} from '@chakra-ui/core'
import { ModalContent, ModalOverlay, Heading, Button, ParagraphText } from './'
import { LARGE, X_LARGE, mqNames, themeUtils } from '../../themeUtils'
import { CheckCircleIcon } from '../Icons'
import styled from '@emotion/styled'

const ModalContentContainer = styled(ModalContent)`
  max-width: 30.5rem;

  ${mqNames(LARGE)} {
    max-width: 38.5rem;
  }

  ${mqNames(X_LARGE)} {
    max-width: 48.5rem;
  }
`

interface Props {
  isOpen: boolean
  title: string,
  description?: string | null,
  btnLabel: string,
  onClick: () => void
}

const { green, midnightBlue, slateGrey } = themeUtils.colors


export const SuccessModal: React.FC<Props> = ({ isOpen, title, description, btnLabel, onClick}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClick}>
      <ModalOverlay />
      <ModalContentContainer>
        <ModalBody display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <CheckCircleIcon strokeFill={green}/>
          <Heading color={midnightBlue} as="h2" margin={"0"}>
            {title}
          </Heading>
          {description && (
            <ParagraphText marginBottom={"2rem"} color={slateGrey}>{description}</ParagraphText>
          )}
          <Button backgroundColor={green} isFullWidth onClick={onClick}>
            {btnLabel}
          </Button>
        </ModalBody>
      </ModalContentContainer>
    </Modal>
  )
}