import React from 'react'
import { Alert, AlertProps, AlertIcon } from '@chakra-ui/core'
import { themeUtils } from '../../themeUtils'

const successGreen = themeUtils.colors.green

const SuccessMessage: React.FC<AlertProps> = ({ children, ...rest }) => (
  <Alert
    color={themeUtils.colors.white}
    backgroundColor={successGreen}
    borderWidth={{ base: '0', lg: '0.1rem' }}
    borderRadius="1.3rem"
    borderColor={successGreen}
    boxShadow="0 10px 15px rgba(141, 162, 179, 0.1)"
    fontSize="1.4rem"
    padding="0.5rem 2rem"
    marginBottom="1rem"
    status="success"
    {...rest}
  >
    <>
      <AlertIcon />
      {children}
    </>
  </Alert>
)

export { SuccessMessage }
