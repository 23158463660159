import React from 'react'
import { Link } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { themeUtils } from '../../../themeUtils'

interface Props {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  active?: boolean
  isDisabled?: boolean
}

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 1rem;
  min-width: 2rem;
  text-align: center;
  background-color: ${(props: Props) =>
    props.active ? themeUtils.colors.midnightBlue : 'transparent'};
  color: ${(props: Props) =>
    props.active ? themeUtils.colors.white : themeUtils.colors.midnightBlue};
  text-decoration: none;
  text-transform: none;
  position: relative;

  &:hover {
    background-color: ${(props: Props) =>
      props.active
        ? themeUtils.colors.midnightBlue
        : themeUtils.colors.geyserGrey};
    color: ${(props: Props) =>
      props.active ? themeUtils.colors.white : themeUtils.colors.midnightBlue};
    text-decoration: none;
  }

  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: ${themeUtils.colors.geyserGrey};
  }
`

const PaginationLink: React.FC<Props> = props => {
  return <StyledLink onClick={props.onClick} {...props} />
}

export { PaginationLink }
