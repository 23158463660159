import React, { useMemo, useEffect } from 'react'
import { CustomSelect, Option } from '../../Shared'
import { availableTreatmentTimes } from '../../lib'
import { ThemeVersion } from '../../../types'
import i18n from '../../../i18n'

interface Props {
  treatmentDate: Date | null
  treatmentTime: string
  changeTreatmentTime?: (option: Option | null) => void
  isMnxEligibleOrder: () => boolean
  isReadOnly?: boolean
  showShippingError?: boolean
  label?: string
  placeholder?: string
}

export const TreatmentTime: React.FC<Props> = ({
  treatmentDate,
  treatmentTime,
  changeTreatmentTime,
  isMnxEligibleOrder,
  isReadOnly,
  showShippingError,
  label = i18n.t('activityFocused.treatmentTime.defaultLabel', {
    ns: ['orderCreate'],
  }),
  placeholder = i18n.t('activityFocused.treatmentTime.defaultPlaceholder', {
    ns: ['orderCreate'],
  }),
}) => {
  const availableTreatmentTimesOptions = useMemo(() => {
    const options: Option[] = availableTreatmentTimes(
      treatmentDate,
      isMnxEligibleOrder()
    ).map(({ label, value }) => ({
      value: value,
      label: label,
    }))

    return options
  }, [treatmentDate, isMnxEligibleOrder()])

  const treatmentTimeValue = useMemo(() => {
    const value: Option | undefined = availableTreatmentTimesOptions.find(
      o => o.value === treatmentTime
    )
    return value ?? null
  }, [treatmentTime])

  useEffect(() => {
    if (
      !availableTreatmentTimesOptions.some(
        option => option.value === treatmentTimeValue?.value
      ) &&
      changeTreatmentTime
    )
      changeTreatmentTime(null)
  }, [treatmentDate])

  return (
    <CustomSelect
      label={label}
      placeholder={placeholder}
      value={treatmentTimeValue ?? null}
      onChange={
        changeTreatmentTime
          ? option => changeTreatmentTime(option as Option)
          : undefined
      }
      options={availableTreatmentTimesOptions}
      isSearchable={false}
      id="treatmentTime"
      version={ThemeVersion.V2}
      disabled={!treatmentDate || isReadOnly}
      isInvalid={showShippingError}
      classNamePrefix="treatment-time-select"
    />
  )
}
