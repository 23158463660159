import React, { forwardRef } from 'react'
import {
  Input as BaseInput,
  InputProps as BaseInputProps,
} from '@chakra-ui/core'
import { themeUtils } from '../../themeUtils'
const borderGrey = themeUtils.colors.geyserGrey

type Ref = HTMLInputElement
export interface InputProps extends BaseInputProps {
  isEmpty?: boolean
}

const Input = forwardRef<Ref, InputProps>(
  (
    {
      focusBorderColor = borderGrey,
      errorBorderColor = themeUtils.colors.red,
      height = '6rem',
      borderWidth = '0.1rem',
      boxShadow = { base: '0 10px 15px rgba(141, 162, 179, 0.1)', lg: 'none' },
      borderRadius = '1rem',
      margin = '0 0 1rem 0',
      padding = '2rem',
      paddingLeft = '2rem',
      paddingRight = '2rem',
      fontSize = '1.4rem',
      color = themeUtils.colors.midnightBlue,
      ...rest
    },
    ref
  ) => {
    return (
      <BaseInput
        focusBorderColor={focusBorderColor}
        ref={ref}
        color={color}
        fontSize={fontSize}
        height={height}
        borderWidth={borderWidth}
        borderRadius={borderRadius}
        borderColor={borderGrey}
        boxShadow={boxShadow}
        padding={padding}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        margin={margin}
        _invalid={{
          borderWidth: '0.2rem',
          borderColor: errorBorderColor,
          boxShadow,
        }}
        _placeholder={{
          color: themeUtils.colors.slateGrey,
        }}
        {...rest}
      />
    )
  }
)

Input.displayName = 'CustomFormInput'
export { Input }
