import React, { useMemo } from 'react'
import {
  AdminSetType,
  OrderFormData,
  VialSearchResultOptionType,
} from '../OrderCreate/OrderFormDataTypes'
import { NestDataObject } from 'react-hook-form'
import {
  AddTubingSets,
  OrderFormClearErrorType,
  OrderFormErrorType,
  TotalEstimatedActivity,
} from '..'
import { MEDIUM, mqNames, themeUtils } from '../../../themeUtils'
import styled from '@emotion/styled'
import { Box, Divider, Flex, useDisclosure } from '@chakra-ui/core'
import { Button, FormHeader } from '../../Shared'
import { PencilIcon } from '../../Icons'
import { lighten } from 'polished'
import { useOrderForm, useVialSearch } from '../../../hooks'
import { OrderFormStepType } from '../../../context/OrderFormContext'
import { TreatmentVialOption } from '../TreatmentVialOptions'
import { getDefaultAddressId } from '../OrderCreate'
import { OrderCreateButton } from '../OrderCreate/OrderCreateButton'
import { withScreenStartTimeCheck } from '../../lib'
import { dateOnly } from '../../../utils'
import { isMnxEligibleAddress } from '../OrderCreate'
import { useUser } from '../../../context/user-context'
import { CheckDeliveryCharges } from '../../../schema/queries/__generated__/CheckDeliveryCharges'
import { GetCustomer } from '../../../schema/queries/__generated__/GetCustomer'
import { ShowExpeditedChargeMsg } from '../../Shared/ShowExpeditedChargeMsg'
import { useTranslation } from 'react-i18next'

const { white, midnightBlue, green } = themeUtils.colors

const ParentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: 1px solid rgba(0, 57, 104, 0.1);
  ${mqNames(MEDIUM)} {
    border-top: unset;
  }
`

const Container = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: fit-content;
  padding: 15px;
  border-radius: 10px;
  background: ${white};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
`

const BoxContainer1 = styled(Box)`
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 57, 104, 0.2);
  width: 100%;

  ${mqNames(MEDIUM)} {
    justify-content: space-between;
    border-bottom: unset;
  }
`

const Heading = styled(FormHeader)<{ forMobile?: boolean }>`
  display: ${props => (props.forMobile ? 'block' : 'none')};
  ${mqNames(MEDIUM)} {
    display: ${props => (props.forMobile ? 'none' : 'block')};
  }
`

const EditButtonStyle = styled(Button)`
  color: ${midnightBlue};
  padding-bottom: 0.5rem;
  border: none;

  ${mqNames(MEDIUM)} {
    padding-bottom: unset;
  }

  &:hover {
    color: ${lighten(0.2, midnightBlue)};
  }
`

interface FormattedResult {
  key: string
  value: Array<VialSearchResultOptionType>
}

interface Props {
  formData: OrderFormData
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
  onChangeCheckBoxValue: (val: boolean) => void
  createAccessoryOnlyOrder: boolean
  changeTreatmentDate: (date: Date | null) => void
  minOrderTreatmentDate(isMnxEligible: boolean): Date
  isMnxEligibleOrder: () => boolean
  isMnxTreatmentDate: () => boolean
  errors: NestDataObject<OrderFormData>
  setError: OrderFormErrorType
  clearError: OrderFormClearErrorType
  isReadOnly?: boolean
  screenOpenedAt: Date
  deliveryChargeData: CheckDeliveryCharges | undefined
  customerData: GetCustomer | undefined
}

export const FinalisingOrder: React.FC<Props> = ({
  formData,
  setFormData,
  isMnxTreatmentDate,
  createAccessoryOnlyOrder,
  screenOpenedAt,
  deliveryChargeData,
  customerData,
}) => {
  const { t } = useTranslation(['orderCreate'])
  const { setFormStep } = useOrderForm()
  const { selectedActivityVials } = useVialSearch()
  const {
    desiredActivityAtTreatmentSet,
    treatmentDate,
    treatmentTime,
    adminSets,
    addressId,
    totalActivityAtTreatment,
    activityFormShippingTimeline,
    activityFormShowShippingError,
  } = formData
  const { isOpen, onOpen, onClose } = useDisclosure()
  const user = useUser()

  const handleEditClick = (): void => {
    // reset patient reference, treating physician, tubing sets, PO number, delivery address fields
    setFormData((prevFormData: OrderFormData) => {
      return {
        ...prevFormData,
        patientRef: '',
        poNumber: prevFormData.poNumber ?? '',
        treatingPhysicianId: '',
        treatingPhysicianName: '',
        adminSets: Array<AdminSetType>(),
        addressId: getDefaultAddressId(user),
      }
    })
    setFormStep(OrderFormStepType.STEP_2)
  }

  const formattedVialSearchResult = useMemo(() => {
    if (!desiredActivityAtTreatmentSet || !selectedActivityVials.size) {
      return [] // Return an empty array if either of the dependencies is falsy
    }

    const formattedResult: FormattedResult[] = []

    desiredActivityAtTreatmentSet.forEach((desiredActivity, index) => {
      const { activity } = desiredActivity
      if (activity) {
        const key = Number.isInteger(activity)
          ? activity.toFixed(1)
          : activity.toString()
        const value = selectedActivityVials
          .get(`vial${index + 1}`)
          ?.get(parseFloat(key))

        formattedResult.push({
          key,
          value: value ? [value] : [],
        })
      }
    })

    return formattedResult
  }, [desiredActivityAtTreatmentSet, selectedActivityVials])

  const treatmentDateOnly = treatmentDate && dateOnly(treatmentDate)

  const orderCreateButtonDisabled = (): boolean => {
    if (treatmentDateOnly && treatmentTime) {
      const adminSetsPresent = adminSets.some((adminSet)=> adminSet.confirmed)

      return !((createAccessoryOnlyOrder && adminSetsPresent) || selectedActivityVials.size > 0)
    } else {
      return true
    }
  }

  const isMnxEligibleOrder = () => {
    return !createAccessoryOnlyOrder && isMnxEligibleAddress(addressId, user)
  }

  const allDoses: VialSearchResultOptionType[] = formattedVialSearchResult.map((object) => { return object.value }).flat()

  return (
    <ParentContainer>
      <Heading forMobile={true}>{t('activityFocused.finalising.header')}</Heading>
      <Container>
        <BoxContainer1>
          <Heading>{t('activityFocused.finalising.header')}</Heading>
          <EditButtonStyle
            _focus={undefined}
            variant="link"
            onClick={handleEditClick}
            id='edit-treatment-extra-details-btn'
            data-cy="edit-treatment-extra-details-btn"
          >
            <PencilIcon title={t('activityFocused.editIconTitle')} />
          </EditButtonStyle>
        </BoxContainer1>
        {formattedVialSearchResult.map((object, index) => {
          const key = `${object.key}-${index}`
          return (
            <TreatmentVialOption
              key={key}
              vialNumber={index}
              vialActivityValue={object.key}
              options={object.value}
            />
          )
        })}
        <ShowExpeditedChargeMsg
          mnxRushOrder={isMnxTreatmentDate()}
          showShippingError={activityFormShowShippingError}
          shippingTimeline={activityFormShippingTimeline}
          treatmentDate={treatmentDate}
          treatmentTime={treatmentTime}
          portalVials={allDoses}
        />
        <TotalEstimatedActivity totalActivityAtTreatment={totalActivityAtTreatment} />
        <Divider
          height={'1px'}
          width={'100%'}
          border={1}
          background={'rgba(0, 57, 104, 0.25)'}
          margin={'0'}
        />
        <AddTubingSets
          formData={formData}
          setFormData={setFormData}
          isMnxTreatmentDate={isMnxTreatmentDate}
        />
        <OrderCreateButton
          mnxRushOrder={isMnxTreatmentDate()}
          disabled={orderCreateButtonDisabled()}
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={withScreenStartTimeCheck(
            screenOpenedAt,
            isMnxEligibleOrder(),
            treatmentDate
          )}
          onSubmit={() =>
            withScreenStartTimeCheck(
              screenOpenedAt,
              isMnxEligibleOrder(),
              treatmentDate
            )(onOpen)
          }
          formData={formData}
          deliveryChargeData={deliveryChargeData?.checkDeliveryCharges}
          customer={customerData?.customer}
          createAccessoryOnlyOrder={createAccessoryOnlyOrder}
        />
      </Container>
    </ParentContainer>
  )
}
