import React from 'react'
import styled from '@emotion/styled'
import { List } from '@chakra-ui/core'
import { themeUtils } from '../../../themeUtils'

const StyledUl = styled(List)`
  display: flex;
  border: 1px solid ${themeUtils.colors.geyserGrey};
  padding-left: 0;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-weight: 500;

  li:first-of-type a {
    border-radius: 0.4rem 0 0 0.4rem;
  }


  li:last-child a {
    border-radius: 0 0.4rem 0.4rem 0;

    &:before {
      display: none;
    }
  }
`

const PaginationContainer: React.FC = props => {
  return <StyledUl {...props} />
}

export { PaginationContainer }
