import { Box, FormControl, Icon, InputProps, Popover, PopoverArrow, PopoverContent, PopoverTrigger } from '@chakra-ui/core'
import React, { ReactNode } from 'react'
import { FormLabel, Input, FormErrorMessage, FormHelperText } from '../../Forms'
import styled from '@emotion/styled'
import { themeUtils } from '../../../themeUtils'

const { geyserGrey, lightGray, darkBlue, white } = themeUtils.colors

interface Props extends InputProps {
  [key: string]: any
  label?: ReactNode
  smallLabel?: boolean
  error?: string
  hint?: string
  isRequired?: boolean
  hidden?: boolean
}

const CustomInput: React.FC<Props> = ({
  label,
  id,
  type = 'text',
  error,
  hint,
  invalid = false,
  isRequired,
  hidden,
  ...props
}) => (
  <FormControl
    isRequired={isRequired}
    isInvalid={!!error}
    margin={'0 0 1rem 0'}
    hidden={hidden}
  >
    {!!label && <FormLabel htmlFor={id}>{label}</FormLabel>}
    <Input
      maxHeight={'4rem'}
      marginBottom={0}
      id={id}
      type={type}
      isInvalid={invalid || !!error}
      {...props}
    />
    {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
    {!!hint && <FormHelperText>{hint}</FormHelperText>}
  </FormControl>
)

const InputStyle = styled(Input)`
  padding: 15px 20px;
  margin: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 5px;
  border: 1px solid ${geyserGrey};
  box-shadow: unset;

  height: 47px;

  &:hover {
    border-color: ${lightGray};
  }
`

interface PropsV2 extends InputProps {
  [key: string]: any
  label?: ReactNode
  smallLabel?: boolean
  error?: string
  hint?: string
  popoverHint?: JSX.Element | null
  isRequired?: boolean
  hidden?: boolean
}

const CustomInputV2: React.FC<PropsV2> = ({
  label,
  id,
  type = 'text',
  error,
  hint,
  popoverHint,
  invalid = false,
  isRequired,
  hidden,
  ...props
}) => (
  <FormControl isRequired={isRequired} isInvalid={!!error} hidden={hidden}>
    <Box>
      {!!label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      {!!popoverHint && (
        <Popover trigger="hover">
          <PopoverTrigger>
            <Icon name="info" />
          </PopoverTrigger>
          <PopoverContent
            zIndex={4}
            padding="0.5rem"
            background={darkBlue}
            color={white}
            textAlign="center"
            fontSize="1.4rem"
            borderRadius="1rem"
          >
            <PopoverArrow />
            {popoverHint}
          </PopoverContent>
        </Popover>
      )}
    </Box>
    <InputStyle id={id} type={type} isInvalid={invalid || !!error} {...props} />
    {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
    {!!hint && <FormHelperText>{hint}</FormHelperText>}
  </FormControl>
)

export { CustomInput as Input, CustomInputV2 as InputV2 }
