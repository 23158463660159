import React from 'react'
import { IconProps } from '../../types'

const DoneIcon: React.FC<IconProps> = ({
  fill = '#fff',
  width = 20,
  height = 20,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
    >
      <path
        fill={fill}
        d="M14.021 8.432l-3.888 3.894a1.501 1.501 0 0 1-2.124 0l-1.767-1.77A1 1 0 1 1 7.656 9.14l.706.707a1.003 1.003 0 0 0 1.418 0l2.827-2.832a1.002 1.002 0 0 1 1.414 1.416zM20 18a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2zM18 3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1z"
      />
    </svg>
  )
}

export { DoneIcon }
