import { useMutation, useQuery } from '@apollo/react-hooks'
import styled from '@emotion/styled'
import { ApolloError } from 'apollo-client'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMedia } from 'use-media'
import { ResponsiveTableHeading, SortableHeader, StandardHeading } from '.'
import { SortDirectionEnum } from '../../../__generated__/globalTypes'
import { SET_ORDER_FILTERS_MUTATION } from '../../../schema/mutations'
import {
  SetOrderFilters,
  SetOrderFiltersVariables,
} from '../../../schema/mutations/__generated__/SetOrderFilters'
import { ORDER_FILTERS_QUERY } from '../../../schema/queries'
import {
  GetOrderFilters,
  GetOrderFilters_orderFilters,
} from '../../../schema/queries/__generated__/GetOrderFilters'
import { OrdersIndex_orders_items } from '../../../schema/queries/__generated__/OrdersIndex'
import { breakpoints, MEDIUM } from '../../../themeUtils'
import { ResponsiveTableContainer } from '../../Shared/ResponsiveTableContainer'
import { TableContent } from './TableContent'

interface TableHeadingProps {
  text?: string
  shortText?: string
}

const Table = styled.table`
  width: 100%;
  font-size: 1.4rem;
`

const TableHeader = styled.thead`
  text-align: left;
`

interface Props {
  orders: OrdersIndex_orders_items[]
  isDistributor: boolean
  loading: boolean
  error?: ApolloError
}

export const OrdersTable: React.FC<Props> = props => {
  const { t } = useTranslation(['orderScreen'])
  const { data } = useQuery<GetOrderFilters>(ORDER_FILTERS_QUERY)

  const [setFiltersMutation] = useMutation<
    SetOrderFilters,
    SetOrderFiltersVariables
  >(SET_ORDER_FILTERS_MUTATION)

  const setFilters = (newFilters: GetOrderFilters_orderFilters) => {
    setFiltersMutation({
      variables: {
        input: newFilters,
      },
    })
  }

  const setSort = (nextColumn: string) => {
    if (!data) return

    const {
      sort: { column, direction },
    } = data.orderFilters
    let nextDirection

    if (column === nextColumn && direction === SortDirectionEnum.ASC) {
      nextDirection = SortDirectionEnum.DESC
    } else {
      nextDirection = SortDirectionEnum.ASC
    }

    setFilters({
      ...data.orderFilters,
      sort: {
        column: nextColumn,
        direction: nextDirection,
      },
    })
  }

  const isMediumScreen = useMedia({ minWidth: breakpoints[MEDIUM] })

  if (!data) return null

  return (
    <ResponsiveTableContainer showDefault={!props.isDistributor}>
      <TableHeader>
        <tr>
          {props.isDistributor && (
            <StandardHeading>
              {t('orderTable.customerName', { ns: ['orderScreen'] })}
            </StandardHeading>
          )}
          <StandardHeading>
            {isMediumScreen
              ? t('orderTable.patientReference', { ns: ['orderScreen'] })
              : t('orderTable.patientRef', { ns: ['orderScreen'] })}
          </StandardHeading>
          {props.isDistributor && (
            <StandardHeading>
              {t('orderTable.shipDate', { ns: ['orderScreen'] })}
            </StandardHeading>
          )}
          <SortableHeader
            title={
              isMediumScreen
                ? t('orderTable.treatmentDate', { ns: ['orderScreen'] })
                : t('orderTable.treatDate', { ns: ['orderScreen'] })
            }
            column="treatment_date"
            onToggle={setSort}
            sort={data.orderFilters.sort}
          />
          <ResponsiveTableHeading>
            {t('orderTable.doses', { ns: ['orderScreen'] })}
          </ResponsiveTableHeading>
          <ResponsiveTableHeading>
            {t('orderTable.orderID', { ns: ['orderScreen'] })}
          </ResponsiveTableHeading>
          <StandardHeading className="hide-for-screen">
            {t('orderTable.shipDate', { ns: ['orderScreen'] })}
          </StandardHeading>
          <StandardHeading>
            {t('orderTable.status', { ns: ['orderScreen'] })}
          </StandardHeading>
          <ResponsiveTableHeading />
        </tr>
      </TableHeader>

      <tbody>
        <TableContent {...props} />
      </tbody>
    </ResponsiveTableContainer>
  )
}
