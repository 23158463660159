import styled from '@emotion/styled'
import React from 'react'
import { MEDIUM, breakpoints, mqNames } from '../../themeUtils'
import {
  BackButton,
  CustomerName,
  FormHeader,
  Heading,
  SubText,
} from '../Shared'
import { Steps, SwitchBetweenOrderForm } from './NewOrderForm'
import { Box, Divider, Flex, FlexProps } from '@chakra-ui/core'
import { useOrderForm } from '../../hooks'
import { useMedia } from 'use-media'
import { OrderFormTypeEnum } from '../../__generated__/globalTypes'
import { useTranslation } from 'react-i18next'

interface Props {
  displayBackButton?: boolean
}

const Container = styled.span`
  display: flex;
  flex-direction: column-reverse;

  ${mqNames(MEDIUM)} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const SubContainer1 = styled(Flex)<FlexProps>`
  align-items: center;
`

const SubContainer2 = styled(Flex)<FlexProps>`
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  gap: 40px;

  ${mqNames(MEDIUM)} {
    margin-top: unset;
  }
`

const MediumContainer = styled(Flex)<FlexProps>`
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-top: 1px solid rgba(0, 57, 104, 0.1);
`

export const OrderFormHeader: React.FC<Props> = ({
  displayBackButton = true,
}) => {
  const { t } = useTranslation(['orderCreate'])
  const { formType, formHeading, hideSwitchFormLink } = useOrderForm()

  const isMedium = useMedia({ maxWidth: breakpoints[MEDIUM] })

  return (
    <>
      <Container>
        <SubContainer1>
          {displayBackButton && <BackButton />}
          <Box marginLeft="2rem">
            <Heading as="h1" marginBottom="0">
              {t('header', { ns: ['orderCreate'] })}
            </Heading>
            <SubText>
              <CustomerName />
            </SubText>
          </Box>
        </SubContainer1>
        <SubContainer2>
          <SwitchBetweenOrderForm />
          {!isMedium &&
            formType === OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED && (
              <>
                {!hideSwitchFormLink && (
                  <Flex>
                    <Divider
                      width={'2px'}
                      height={'58px'}
                      border={1}
                      borderColor={'unset'}
                      orientation="vertical"
                      margin={'unset'}
                    />
                  </Flex>
                )}
                <Steps />
              </>
            )}
        </SubContainer2>
      </Container>
      {isMedium && (
        <MediumContainer>
          <FormHeader>{formHeading}</FormHeader>
          {formType === OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED && (
            <Steps displayLabel={false} />
          )}
        </MediumContainer>
      )}
    </>
  )
}
