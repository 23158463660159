import { Box, Flex, Icon } from '@chakra-ui/core'
import React, { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GetCountryAlerts } from '../../schema/queries/__generated__/GetCountryAlerts'
import { useUser } from "../../context/user-context";
import { GET_COUNTRY_ALERTS_QUERY } from '../../schema/queries/CountryAlertsQueries'
import { useFeatureFlag } from '../../context/FeatureFlagsContext'
import { themeUtils } from '../../themeUtils'
import { getTimeZones } from '@vvo/tzdb'
import moment from "moment";
import { Trans, useTranslation } from 'react-i18next'


const { darkBlue, white } = themeUtils.colors

const timeZonesWithUtc = getTimeZones({ includeUtc: true })

export const DashboardAlertMessage: React.FC<{setHasAlert: React.Dispatch<React.SetStateAction<boolean>>}> = ({ setHasAlert }) => {
  const { t } = useTranslation(['dashboardScreen'])
  
  const user = useUser()
  const TreatmentFocusedFormFeatureFlag = useFeatureFlag(
    'activity_at_treatment_focused'
  )

  const { data } = useQuery<GetCountryAlerts>(GET_COUNTRY_ALERTS_QUERY, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (
      TreatmentFocusedFormFeatureFlag &&
      (data?.countryAlerts?.defaultMessageEnabled ||
        data?.countryAlerts?.alertMessage)
    ) {
      setHasAlert(true)
    }
  }, [TreatmentFocusedFormFeatureFlag, data])

  if (!TreatmentFocusedFormFeatureFlag) return null

  if (
    !data ||
    !data.countryAlerts ||
    (!data.countryAlerts.defaultMessageEnabled &&
      !data.countryAlerts.alertMessage)
  )
    return null

  const {
    name,
    cutOffTime,
    timezone,
    amTreatmentWindow,
    defaultMessageEnabled,
    alertMessage,
  } = data.countryAlerts

  let defaultMessage: JSX.Element | null = null

  if (defaultMessageEnabled && user?.preferredShippingAddress.timezone) {
    let timezoneLabel = timeZonesWithUtc.find(
      t => t.name === user.preferredShippingAddress.timezone
    )?.alternativeName

    const getLocalizedTime = (
      time: string,
      displayTimezoneLabel: boolean = true
    ) => {
      if (user.preferredShippingAddress.timezone) {
        const localTime = moment
          .tz(new Date(), timezone)
          .set('hour', parseInt(time.split(':')[0]))
          .set('minute', parseInt(time.split(':')[1]))
          .clone()
          .tz(user.preferredShippingAddress.timezone)

        return displayTimezoneLabel
          ? `${localTime.format('HH:mm')} ${timezoneLabel ?? timezone}`
          : `${localTime.format('HH:mm')}`
      }
    }

    const siteCutOffTime = getLocalizedTime(cutOffTime)

    defaultMessage = (
      <p>
        <Trans
          i18nKey="alertMessage.messageText"
          ns="dashboardScreen"
          values={{ name, siteCutOffTime, amTreatmentWindow }}
          components={{ br: <br /> }}
        />
      </p>
    )
  }

  return (
    <Box
      gridArea="alerts"
      backgroundColor={white}
      padding="2.5rem"
      boxShadow={'rgba(141, 162, 179, 0.1) 0px 10px 15px'}
      borderRadius="1rem"
      border={`${darkBlue} solid 2px`}
    >
      <Flex>
        <Icon name="info" color={darkBlue} size="30px" />
        <span style={{ paddingLeft: '10px', color: 'rgb(0, 57, 104)' }}>
          <b>{t('alertMessage.notice', { ns: ['dashboardScreen'] })}</b>
        </span>
      </Flex>
      <Box paddingLeft={'4rem'} color={'#000'}>
        {defaultMessage ?? null}
        {alertMessage ?? null}
      </Box>
    </Box>
  )
}
