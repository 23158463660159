import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { Text, Spinner } from '@chakra-ui/core'
import { Toggle, ToggleButton } from '../../Shared'
import { CALIBRATION_DATES_QUERY } from '../../../schema/queries'
import {
  CalibrationDates,
  CalibrationDates_calibrationDates_calibrationWeeks,
  CalibrationDatesVariables,
} from '../../../schema/queries/__generated__/CalibrationDates'
import { DAY_MONTH_FORMAT, DATE_ISO_FORMAT } from '../../../constants'
import { formatDate, Notification } from '../../../utils'
import { themeUtils } from '../../../themeUtils'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'

type CalDateWeekType = Array<{
  calibrationWeek: string
  calibrationDate?: string
}>
let buttonValues: CalDateWeekType = []

interface Props {
  treatmentDate: Date | undefined
  selectedValue: string
  name?: string
  onClick: (calibrationWeek: string) => void
  onInit?: (calibrationDates: string[]) => void
}

const getCalDateValues = (
  calibrationWeekData: CalibrationDates_calibrationDates_calibrationWeeks[]
): CalDateWeekType => {
  return calibrationWeekData.map(
    (result: CalibrationDates_calibrationDates_calibrationWeeks) => {
      return {
        calibrationWeek: `${i18n.t('calibrationWeekToggle.week', {
          ns: ['dashboardScreen'],
        })} ${result.calibrationWeek}`,
        calibrationDate: `${result.calibrationDate}`,
      }
    }
  )
}

export const CalibrationWeekToggle: React.FC<Props> = ({
  treatmentDate,
  selectedValue,
  onClick,
  name,
  onInit
}) => {
  const { t } = useTranslation(['dashboardScreen'])
  const [getCalibrationDates, { data, loading, error }] = useLazyQuery<
    CalibrationDates,
    CalibrationDatesVariables
  >(CALIBRATION_DATES_QUERY, { fetchPolicy: 'network-only', onCompleted: (data) => onInit && onInit(data.calibrationDates.calibrationDates) })

  const formattedTreatmentDate =
    treatmentDate && formatDate(treatmentDate, DATE_ISO_FORMAT)

  useEffect(() => {
    if (formattedTreatmentDate) {
      getCalibrationDates({
        variables: {
          treatmentDate: formattedTreatmentDate,
        }
      })
    }
  }, [formattedTreatmentDate, getCalibrationDates])

  if (error) {
    Notification.error(error.message)
  }

  buttonValues = [
    {
      calibrationWeek: `${t('calibrationWeekToggle.week', {
        ns: ['dashboardScreen'],
      })} 1`,
    },
    {
      calibrationWeek: `${t('calibrationWeekToggle.week', {
        ns: ['dashboardScreen'],
      })} 2`,
    },
  ]

  if (formattedTreatmentDate && data) {
    const dates = getCalDateValues(data.calibrationDates.calibrationWeeks)
    buttonValues = dates
  }
  const disabled = !formattedTreatmentDate || !data

  const renderToggleButtons = () => {
    return buttonValues.map((value, index) => {
      const firstItem = index === 0
      const calibrationDate = value.calibrationDate || null
      const calibrationWeek = value.calibrationWeek
      const isActive = calibrationDate === selectedValue
      const buttonValue = calibrationDate
        ? `${calibrationWeek} - ${formatDate(
            calibrationDate,
            DAY_MONTH_FORMAT
          )}`
        : calibrationWeek
      const onSelectWeek = (): void => {
        if (calibrationDate) onClick(calibrationDate)
      }

      return (
        <ToggleButton
          name={name}
          key={buttonValue}
          isActive={isActive}
          padRight={firstItem}
          onClick={onSelectWeek}
          aria-label={buttonValue}
          isDisabled={disabled}
          inactiveShadow
          data-cy={`calibration-week-${index+1}-btn`}
        >
          {buttonValue}
        </ToggleButton>
      )
    })
  }

  return (
    <>
      <Text
        id="CalibrationWeek"
        marginBottom="1rem"
        color={themeUtils.colors.midnightBlue}
      >
        {t('calibrationWeekToggle.text', { ns: ['dashboardScreen'] })}
      </Text>
      <Toggle aria-labelledby="CalibrationWeek" width="100%">
        {loading ? <Spinner /> : renderToggleButtons()}
      </Toggle>
    </>
  )
}
