import React from 'react'
import { Link as BaseLink, LinkProps as BaseLinkProps } from '@chakra-ui/core'
import { darken, lighten } from 'polished'
import styled from '@emotion/styled'
import { themeUtils, mqNames, SMALL } from '../../themeUtils'

interface IconProp {
  iconRight?: boolean
}

type LinkProps = IconProp & BaseLinkProps

const Link = styled(BaseLink)`
  background-color: ${(props: LinkProps) =>
    props.backgroundColor
      ? props.backgroundColor
      : themeUtils.colors.borderGrey};
  box-shadow: 0 10px 15px rgba(141, 162, 179, 0.1);
  border-radius: 1rem;
  color: ${themeUtils.colors.shuttleGrey};
  font-weight: 400;
  ${mqNames(SMALL)} {
    font-size: 1.4rem;
  }
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props: LinkProps) =>
    props.padding ? props.padding : '0.5rem 1.5rem'};

  svg {
    margin: ${(props: LinkProps) =>
      props.iconRight ? '0 0 0 1.5rem' : '0 1.5rem 0 0'};
  }

  &:hover {
    background-color: ${(props: LinkProps) =>
      props.backgroundColor
        ? darken(0.1, props.backgroundColor as string)
        : darken(0.1, themeUtils.colors.borderGrey)};
    color: ${(props: LinkProps) =>
      props.backgroundColor
        ? lighten(0.1, props.color as string)
        : lighten(0.1, themeUtils.colors.shuttleGrey)};
  }
`

const LinkButton: React.FC<LinkProps> = props => (
  <Link _hover={{ textDecoration: 'none' }} {...props} />
)

export { LinkButton }
