import React, { useState } from 'react'
import styled from '@emotion/styled'
import { RouteComponentProps } from '@reach/router'
import { Box } from '@chakra-ui/core'
import { Heading, CustomerName, SubText, Container } from '../components/Shared'
import { DashboardStatistics, VialSearchWidget } from '../components/Dashboard'
import { VialSearchProvider } from '../context/VialSearchContext'
import { CustomerCareWidget } from '../components/Orders'
import { MEDIUM, LARGE, mqNames } from '../themeUtils'
import { DashboardAlertMessage } from '../components/Dashboard/DashboardAlertMessage'
import { useTranslation } from 'react-i18next'

export const DashboardContainer = styled.div<{ hasAlert: boolean }>`
  display: grid;
  grid-gap: 20px;

  ${({ hasAlert }) =>
    hasAlert
      ? `
      grid-template-areas:
        'header'
        'alerts'
        'stats'
        'vial-finder'
        'contacts';
        `
      : `
      grid-template-areas:
        'header'
        'stats'
        'vial-finder'
        'contacts';
      `};

  ${mqNames(MEDIUM)} {
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: auto;

    ${({ hasAlert }) =>
      hasAlert
        ? `
      grid-template-areas:
        'header header'
        'alerts alerts'
        'stats contacts'
        'vial-finder vial-finder';
        `
        : `
      grid-template-areas:
        'header header'
        'stats contacts'
        'vial-finder vial-finder';
      `};
  }

  ${mqNames(LARGE)} {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 0fr 0fr 1fr;
    grid-template-areas:
      'header header'
      'alerts vial-finder'
      'stats vial-finder'
      'contacts vial-finder';

    ${({ hasAlert }) =>
      hasAlert
        ? `
      grid-template-areas:
        'header header'
        'alerts vial-finder'
        'stats vial-finder'
        'contacts vial-finder';
        `
        : `
      grid-template-areas:
        'header header'
        'stats vial-finder'
        'contacts vial-finder';
      `};
  }
`

export const Dashboard: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation(['dashboardScreen'])
  const [hasAlert, setHasAlert] = useState(false)
  return (
    <DashboardContainer hasAlert={hasAlert}>
      <Box gridArea="header">
        <Heading as="h1" marginBottom="0" paddingTop="2rem">
          {t('header.main', { ns: ['dashboardScreen'] })}
        </Heading>
        <SubText>
          <CustomerName />
        </SubText>
      </Box>
      <DashboardAlertMessage setHasAlert={setHasAlert} />
      <Box gridArea="stats">
        <DashboardStatistics />
      </Box>
      <Container
        heading={t('customerCare.heading', { ns: ['dashboardScreen'] })}
        gridArea="contacts"
        position="relative"
        paddingTop="0.5rem"
      >
        <CustomerCareWidget />
      </Container>
      <Box gridArea="vial-finder">
        <VialSearchProvider>
          <VialSearchWidget />
        </VialSearchProvider>
      </Box>
    </DashboardContainer>
  )
}
