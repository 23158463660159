import gql from 'graphql-tag'

export const VIAL_SEARCH_QUERY = gql`
  query VialSearch(
    $orderDate: String
    $orderTime: String
    $calibrationDate: Date!
    $treatmentDate: Date!
    $treatmentTime: String!
    $optimumShipDate: Date!
    $mnxRushOrder: Boolean
  ) {
    vialSearch(
      orderDate: $orderDate
      orderTime: $orderTime
      calibrationDate: $calibrationDate
      treatmentDate: $treatmentDate
      treatmentTime: $treatmentTime
      optimumShipDate: $optimumShipDate
      mnxRushOrder: $mnxRushOrder
    ) {
      surplus
      vials {
        available
        dosage
        surplus
        # productId
      }
    }
  }
`
