import { OrderFormTypeEnum } from "./__generated__/globalTypes"
import { DesiredActivityAtTreatment } from "./components/Orders/OrderCreate/OrderFormDataTypes"

export interface IconProps {
  fill?: string
  width?: string | number
  height?: string | number
  title?: string
}

export type Breakpoints =
  | string[]
  | { sm: string; md: string; lg: string; xl: string }

export type DateType = number | Date

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

// Order types
// TODO move these out of there to dedicated files
export type Vial = Map<string, number>
export type VialMap = Map<number, Vial>

export interface OrderData {
  orderDate: Date
  orderTime: string
  calibrationDate: string
  treatmentDate: Date
  treatmentTime: string
  vials: VialMap
  noOfVials?: number
  desiredActivityAtTreatmentSet?: Array<DesiredActivityAtTreatment>,
  formType: OrderFormTypeEnum
  surplusData: { [key: string]: boolean }
  optimumShipDatesByCalDate: { [key: string]: Date }
}

// react-nice-dates types
// TODO move these out of there to dedicated files
export enum FocusType {
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  None = 'None',
}
export enum Origin {
  Top = 'Top',
  Bottom = 'Bottom',
}

export enum LocalStorageKeys {
  CONFIRM_USER_DETAILS_POPUP = 'CONFIRM_USER_DETAILS_POPUP'
}

export enum ConfirmPopupValues {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
} 

export enum ThemeVersion {
  V1 = "V1",
  V2 = "V2",
} 