import React from 'react'
import { FlexProps } from '@chakra-ui/core'
import { SHORT_MONTH_FORMAT } from '../../../constants'
import { calcDecay, formatDate } from '../../../utils'
import { DoseText, ProductContainer, TreatmentText } from '../TreatmentsWidget'
import { useVialSearch } from '../../../hooks'
import { RemoveOrderItem } from './RemoveOrderItem'
import { timeInTimeZone } from '../../lib'
import { parseISO } from 'date-fns'
import { useUser } from '../../../context/user-context'
import { useTranslation } from 'react-i18next'

interface Props extends FlexProps {
  dosage: number
  quantity: number
  calibrationDate: string
  treatmentDate: string
  treatmentTime: string
  week: string
  removeable?: boolean
  treatmentActivity?: number
  keyProp?: string
}

export const Treatment: React.FC<Props> = ({
  dosage = 0.0,
  quantity,
  calibrationDate,
  treatmentDate,
  treatmentTime,
  week,
  removeable = false,
  treatmentActivity,
  keyProp,
  ...restProps
}) => {
  const { t } = useTranslation(['treatment'])
  const splitTreatmentTime = treatmentTime.split(':')
  const user = useUser()
  const siteTimezone = user?.preferredShippingAddress.timezone || ''
  const treatmentDateTime = timeInTimeZone(
    parseISO(treatmentDate),
    Number(splitTreatmentTime[0]),
    Number(splitTreatmentTime[1]),
    0,
    siteTimezone
  )
  const { removeVial } = useVialSearch()

  const removeDose = () => {
    removeable && removeVial(dosage, calibrationDate)
  }
  const weekText = !!week
    ? `${t('week', {
        ns: ['treatment'],
      })} ${week}`
    : null
  return (
    <ProductContainer {...restProps}>
      <DoseText>
        {dosage.toFixed(1)}{' '}
        <small>
          {t('unit', {
            ns: ['treatment'],
          })}
        </small>
      </DoseText>
      <TreatmentText>
        <p data-cy={`${keyProp}-treatment-activity`}>
          <b>
            {treatmentActivity
              ? treatmentActivity
              : calcDecay(
                  dosage,
                  calibrationDate,
                  treatmentDate,
                  treatmentTime,
                  treatmentDateTime
                )}{' '}
            {t('unit', {
              ns: ['treatment'],
            })}
          </b>{' '}
          {t('atTreatment', {
            ns: ['treatment'],
          })}
        </p>
        <p data-cy={`${keyProp}-week-${week}-label`}>
          <small>
            {[weekText, formatDate(calibrationDate, SHORT_MONTH_FORMAT)]
              .filter(Boolean)
              .join(' - ')}
          </small>
        </p>
      </TreatmentText>
      {removeable && (
        <RemoveOrderItem
          onClick={removeDose}
          data-cy={`${keyProp}-remove-order-item`}
        />
      )}
    </ProductContainer>
  )
}
