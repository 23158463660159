import React from 'react'
import { Box, BoxProps } from '@chakra-ui/core'
import { themeUtils } from '../../../themeUtils'

export const Toggle: React.FC<BoxProps> = ({ display = 'flex', ...rest }) => {
  return (
    <Box
      alignItems="stretch"
      backgroundColor={themeUtils.colors.white}
      border={{ base: `1px solid ${themeUtils.colors.geyserGrey}` }}
      borderRadius="0.5rem"
      boxShadow={{ base: 'none' }}
      minHeight="4rem"
      padding={{ base: '1.5rem' }}
      display={display}
      flex={{ base: 1, md: 'initial' }}
      justifyContent="center"
      {...rest}
    />
  )
}
