import React from 'react'
import styled from '@emotion/styled'
import { MEDIUM, mqNames } from '../../../themeUtils'
import { useUser } from '../../../context/user-context'
import { CogIcon } from '../../Icons'

const Container = styled.span`
  display: flex;
`

const UserInitialContainer = styled.span`
  display: inline-block;
  margin-right: 0.5rem;

  ${mqNames(MEDIUM)} {
    display: none;
  }
`

const UserNameContainer = styled.span`
  display: none;
  margin-right: 0.5rem;

  ${mqNames(MEDIUM)} {
    display: inline-block;
  }
`

const UserInitial: React.FC<{ text: string }> = ({ text }) => (
  <UserInitialContainer>{text.charAt(0).toUpperCase()}</UserInitialContainer>
)

const UserName: React.FC = () => {
  const user = useUser()
  if (!user) return null

  return (
    <Container>
      <UserInitial text={user.firstName} />
      <UserInitial text={user.lastName} />
      <UserNameContainer>
        {user.firstName} {user.lastName}
      </UserNameContainer>
      <CogIcon />
    </Container>
  )
}

export { UserName }
