import React from 'react'
import { Flex } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { Container, InlineErrorMessage } from '../Shared'
import { StatisticDial } from './StatisticDial'
import { themeUtils } from '../../themeUtils'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { DASHBOARD_QUERY, ORDER_FILTERS_QUERY } from '../../schema/queries'
import { DashboardQuery } from '../../schema/queries/__generated__/DashboardQuery'
import { navigate } from '@reach/router'
import { ORDERS_INDEX_PATH } from '../../routes'
import { GetOrderFilters } from '../../schema/queries/__generated__/GetOrderFilters'
import {
  SetOrderFilters,
  SetOrderFiltersVariables,
} from '../../schema/mutations/__generated__/SetOrderFilters'
import { SET_ORDER_FILTERS_MUTATION } from '../../schema/mutations'
import { OrderPortalStatusEnum } from '../../__generated__/globalTypes'
import { useTranslation } from 'react-i18next'

const CancellationRequests = styled.span`
  color: ${themeUtils.colors.red};
  cursor: pointer;
  display: block;
  font-size: 1.4rem;
  margin-top: 30px;
  text-align: center;
`

const ErrorMessage: React.FC<{ error: string }> = ({ error }) => (
  <Container>
    <InlineErrorMessage>{error}</InlineErrorMessage>
  </Container>
)

export const DashboardStatistics: React.FC = () => {
  const { t } = useTranslation(['dashboardScreen'])
  const { data, loading, error } = useQuery<DashboardQuery>(DASHBOARD_QUERY, {
    fetchPolicy: 'network-only',
  })
  const { data: filtersData } = useQuery<GetOrderFilters>(ORDER_FILTERS_QUERY)

  const [setFiltersMutation] = useMutation<
    SetOrderFilters,
    SetOrderFiltersVariables
  >(SET_ORDER_FILTERS_MUTATION)

  if (error) return <ErrorMessage error={error.message} />

  if (loading) return <Container>{t('statistics.loadingText', { ns: ['dashboardScreen'] })}</Container>
  if (!data) return <ErrorMessage error={t('statistics.errorText', { ns: ['dashboardScreen'] })} />

  const heading = t('statistics.heading', { ns: ['dashboardScreen'] })

  const submitted = data.submitted.totalItems
  const confirmed = data.confirmed.totalItems
  const shipped = data.shipped.totalItems
  const cancelling = data.cancelling.totalItems

  const openOrders = async (status: OrderPortalStatusEnum): Promise<void> => {
    if (filtersData) {
      await setFiltersMutation({
        variables: {
          input: {
            ...filtersData.orderFilters,
            status,
          },
        },
      })
    }
    navigate(ORDERS_INDEX_PATH)
  }

  const openSubmitted = (): Promise<void> =>
    openOrders(OrderPortalStatusEnum.SUBMITTED)

  const openConfirmed = (): Promise<void> =>
    openOrders(OrderPortalStatusEnum.CONFIRMED)

  const openShipped = (): Promise<void> =>
    openOrders(OrderPortalStatusEnum.SHIPPED)

  const openCancelRequested = (): Promise<void> =>
    openOrders(OrderPortalStatusEnum.CANCEL_REQUESTED)

  if (!submitted && !confirmed && !shipped && !cancelling) {
    return (
      <Container heading={heading}>
        <p>{t('statistics.noneOpen', { ns: ['dashboardScreen'] })}</p>
      </Container>
    )
  }

  return (
    <Container
      heading={heading}
      wrapperProps={{ position: 'relative', height: '100%' }}
    >
      <Flex direction="row" justifyContent="space-between">
        <StatisticDial
          count={submitted}
          label={t('statisticDial.submitted', { ns: ['dashboardScreen'] })}
          color={themeUtils.colors.yellow}
          onClick={openSubmitted}
        />
        <StatisticDial
          count={confirmed}
          label={t('statisticDial.confirmed', { ns: ['dashboardScreen'] })}
          color={themeUtils.colors.green}
          onClick={openConfirmed}
        />
        <StatisticDial
          count={shipped}
          label={t('statisticDial.shipped', { ns: ['dashboardScreen'] })}
          color={themeUtils.colors.turquoise}
          onClick={openShipped}
        />
      </Flex>
      <CancellationRequests onClick={openCancelRequested}>
        {cancelling} {t('cancellationRequests.text', { ns: ['dashboardScreen'] })}
      </CancellationRequests>
    </Container>
  )
}
