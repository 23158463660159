import React from 'react'
import { IconProps } from '../../types'

export const PlusIcon: React.FC<IconProps> = ({
  fill = '#fff',
  width = 20,
  height = 20,
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      {...rest}
    >
      <g>
        <g>
          <path
            fill={fill}
            d="M20 10a2.5 2.5 0 0 1-2.5 2.5h-5v5a2.5 2.5 0 0 1-5 0v-5h-5a2.5 2.5 0 0 1 0-5h5v-5a2.5 2.5 0 0 1 5 0v5h5A2.5 2.5 0 0 1 20 10"
          />
        </g>
      </g>
    </svg>
  )
}
