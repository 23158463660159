import React from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { useQuery } from '@apollo/react-hooks'
import { useMedia } from 'use-media'
import { Flex } from '@chakra-ui/core'
import { ORDER_FILTERS_QUERY, ORDERS_INDEX_QUERY } from '../../schema/queries'
import {
  OrdersIndex,
  OrdersIndex_orders_items,
  OrdersIndexVariables,
} from '../../schema/queries/__generated__/OrdersIndex'
import { GetOrderFilters } from '../../schema/queries/__generated__/GetOrderFilters'
import {
  CustomerSelect, DistributorOrderFiltersContainer,
  OrderFiltersContainer,
  OrderSearch,
  OrdersPagination,
  OrdersTable,
  OrderStatusSelect,
  StatusToggle,
} from '../../components/Orders'
import { Container, CustomerName, Heading, ResponsiveButton, SubText } from '../../components/Shared'
import { useUser } from '../../context/user-context'
import { DownloadSquareIcon, PlusIcon, PlusSquareIcon } from '../../components/Icons'
import { breakpoints, SMALL } from '../../themeUtils'
import { ORDERS_CREATE_PATH } from '../../routes'
import '../../SharedStyles.css'
import { useTranslation } from 'react-i18next'
import { isDistributor } from '../../components/lib'
import { CustomerBusinessTypeEnum } from "../../__generated__/globalTypes";

export const OrdersIndexScreen: React.FC<RouteComponentProps> = () => {
  const user = useUser()
  const { t } = useTranslation(['orderScreen'])
  const buttonText = t('buttons.create', { ns: ['orderScreen'] })

  const { data: filtersData } = useQuery<GetOrderFilters>(ORDER_FILTERS_QUERY)
  const { data, loading, error } = useQuery<OrdersIndex, OrdersIndexVariables>(
    ORDERS_INDEX_QUERY,
    {
      variables: {
        state: filtersData ? filtersData.orderFilters.state : undefined,
        limit: filtersData ? filtersData.orderFilters.limit : undefined,
        sort: filtersData
          ? {
              column: filtersData.orderFilters.sort.column,
              direction: filtersData.orderFilters.sort.direction,
            }
          : undefined,
        page: filtersData ? filtersData.orderFilters.page : undefined,
        search: filtersData ? filtersData.orderFilters.search : undefined,
        status: filtersData?.orderFilters.status,
        customerId: filtersData?.orderFilters.customerId,
      },
      fetchPolicy: 'network-only',
    }
  )

  const openNewOrder = (): Promise<void> => navigate(ORDERS_CREATE_PATH)

  const isSmallScreen = useMedia({ minWidth: breakpoints[SMALL] })

  let orders: OrdersIndex_orders_items[] = []
  let totalPages = 0

  // NB: when loading, data will be {}
  if (!loading && data) {
    orders = data.orders.items
    totalPages = data.orders.totalPages
  }

  function printOrdersScreen(): void {
    window.print()
  }

  return (
    <Flex flexDirection="column">
      <Flex
        justifyContent="space-between"
        paddingY="2rem"
        className="hide-for-print"
      >
        <div>
          <Heading as="h1" marginBottom="0">
            {t('header.main', { ns: ['orderScreen'] })}
          </Heading>
          <SubText>
            <CustomerName />
          </SubText>
        </div>
        <Flex alignItems="center">
          <ResponsiveButton
            leftIcon={DownloadSquareIcon}
            responsiveIcon={DownloadSquareIcon}
            ariaLabel={t('buttons.generate', { ns: ['orderScreen'] })}
            mr={4}
            onClick={printOrdersScreen}
          >
            {t('buttons.generate', { ns: ['orderScreen'] })}
          </ResponsiveButton>
          <ResponsiveButton
            leftIcon={PlusSquareIcon}
            responsiveIcon={PlusIcon}
            ariaLabel={buttonText}
            onClick={openNewOrder}>
              {buttonText}
            </ResponsiveButton>
        </Flex>
      </Flex>
      <Container>
        { user?.businessType != CustomerBusinessTypeEnum.Distributor ?
          <OrderFiltersContainer className="hide-for-print">
            <StatusToggle />
            <OrderSearch />
            <OrderStatusSelect />
          </OrderFiltersContainer>
          :
          <DistributorOrderFiltersContainer className="hide-for-print">
            <StatusToggle />
            <OrderSearch />
            <CustomerSelect />
            <OrderStatusSelect />
          </DistributorOrderFiltersContainer>
        }
        <OrdersTable loading={loading} orders={orders} error={error} isDistributor={isDistributor(user)}/>
        {isSmallScreen ? (
          <OrdersPagination totalPages={totalPages} />
        ) : (
          <OrdersPagination totalPages={totalPages} windowSize={1} />
        )}
      </Container>
    </Flex>
  )
}
