import gql from 'graphql-tag'

export const FULL_PORTAL_USER_FRAGMENT = gql`
  fragment FullPortalUserFragment on PortalUser {
    id
    firstName
    lastName
    email
    profession
    otherProfession
    npi
    hasUpdatedDetails
    teamMember {
      id
      profession
      otherProfession
      npi
    }
  }
`