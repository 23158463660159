import React, { useEffect, useMemo } from 'react'
import {
  OrderFormData,
  VialSearchResultOptionType,
} from '../OrderCreate/OrderFormDataTypes'
import { NestDataObject } from 'react-hook-form'
import {
  OrderFormClearErrorType,
  OrderFormErrorType,
  TotalEstimatedActivity,
} from '..'
import styled from '@emotion/styled'
import { Flex, FlexProps } from '@chakra-ui/core'
import { MEDIUM, mqNames, themeUtils } from '../../../themeUtils'
import { Button, FormHeader } from '../../Shared'
import { TreatmentVialOption } from './TreatmentVialOption'
import { useOrderForm, useVialSearch } from '../../../hooks'
import { OrderFormStepType } from '../../../context/OrderFormContext'
import { parseISO } from 'date-fns'
import { calculateShippingTimeline, countryConfiguration } from '../../lib'
import { dateOnly } from '../../../utils'
import { ShowExpeditedChargeMsg } from '../../Shared/ShowExpeditedChargeMsg'
import { useTranslation } from 'react-i18next'

interface Props {
  formData: OrderFormData
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
  onChangeCheckBoxValue: (val: boolean) => void
  createAccessoryOnlyOrder: boolean
  changeTreatmentDate: (date: Date | null) => void
  minOrderTreatmentDate(isMnxEligible: boolean): Date
  isMnxEligibleOrder: () => boolean
  isMnxTreatmentDate: () => boolean
  errors: NestDataObject<OrderFormData>
  setError: OrderFormErrorType
  clearError: OrderFormClearErrorType
  isReadOnly?: boolean
}

interface FormattedResult {
  key: string
  value: Array<VialSearchResultOptionType>
}

type CalculatedShipData = {
  calibrationDate: string
  shippingTimeline: number
}

const { white, green } = themeUtils.colors

const Container = styled(Flex)<FlexProps>`
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: fit-content;
  border-top: 1px solid rgba(0, 57, 104, 0.1);

  ${mqNames(MEDIUM)} {
    padding: 15px;
    border-radius: 10px;
    background: ${white};
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    border-top: unset;
  }
`

const SubContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  gap: 10px;

  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);

  padding: 20px;

  ${mqNames(MEDIUM)} {
    padding: 0;
    border-radius: unset;
    background: unset;
    box-shadow: unset;
  }
`

export const TreatmentVialOptions: React.FC<Props> = ({
  formData,
  setFormData,
  isMnxTreatmentDate,
}) => {
  const { t } = useTranslation(['orderCreate'])
  const { setFormStep } = useOrderForm()
  const { selectedActivityVials, getAllSelectedVialOption } = useVialSearch()
  const {
    desiredActivityAtTreatmentSet,
    vialSearchResult,
    noOfVials,
    totalActivityAtTreatment,
    treatmentDate,
    treatmentTime,
    activityFormShippingTimeline,
    activityFormShowShippingError,
  } = formData
  const treatmentDateOnly = treatmentDate && dateOnly(treatmentDate)

  if (vialSearchResult) {
    // Sorting the arrays based on deviation
    Object.keys(vialSearchResult).forEach(key => {
      vialSearchResult[key].sort(
        (a, b) => Math.abs(a.deviation) - Math.abs(b.deviation)
      )
    })
  }

  const onSubmit = (): void => {
    setFormStep(OrderFormStepType.STEP_3)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    updateShippingData()
  }, [selectedActivityVials])

  const updateShippingData = () => {
    const selectedVials = getAllSelectedVialOption()
    if (treatmentDateOnly && treatmentTime) {
      if (selectedVials.length) {
        const calculatedShipdataArray: CalculatedShipData[] = []

        selectedVials.forEach(vial => {
          if (
            !calculatedShipdataArray.some(
              c => c.calibrationDate === vial.calibration_date
            )
          ) {
            const shippingTimeline = calculateShippingTimeline(
              treatmentDateOnly,
              treatmentTime,
              parseISO(vial.optimum_ship_date),
              countryConfiguration?.amTreatmentWindow,
            )

            calculatedShipdataArray.push({
              calibrationDate: vial.calibration_date,
              shippingTimeline: shippingTimeline,
            })
          }
        })

        const minShippingTimelineObject =
          calculatedShipdataArray.length === 1
            ? calculatedShipdataArray[0]
            : calculatedShipdataArray.reduce(
                (minObject, currentObject) =>
                  typeof currentObject.shippingTimeline === 'number' &&
                  (minObject === null ||
                    currentObject.shippingTimeline < minObject.shippingTimeline)
                    ? currentObject
                    : minObject,
                null as CalculatedShipData | null
              )

        setFormData((prevFormData: OrderFormData) => ({
          ...prevFormData,
          activityFormShippingTimeline:
            minShippingTimelineObject?.shippingTimeline,
          activityFormShowShippingError: minShippingTimelineObject
            ? minShippingTimelineObject.shippingTimeline <= 3
            : false,
        }))
      } else {
        setFormData((prevFormData: OrderFormData) => {
          return {
            ...prevFormData,
            activityFormShippingTimeline: undefined,
            activityFormShowShippingError: false,
          }
        })
      }
    }
  }

  const formattedVialSearchResult = useMemo(() => {
    if (!desiredActivityAtTreatmentSet || !vialSearchResult) {
      return [] // Return an empty array if either of the dependencies is falsy
    }

    const formattedResult: FormattedResult[] = []

    desiredActivityAtTreatmentSet.forEach(desiredActivity => {
      const { activity } = desiredActivity
      if (activity) {
        const key = Number.isInteger(activity)
          ? activity.toFixed(1)
          : activity.toString()

        formattedResult.push({
          key,
          value: vialSearchResult[key] || [],
        })
      }
    })

    return formattedResult
  }, [desiredActivityAtTreatmentSet, vialSearchResult])

  const allDoses: VialSearchResultOptionType[] = formattedVialSearchResult.map((object) => { return object.value }).flat()

  return (
    <Container>
      <FormHeader>{t('activityFocused.vialOptions.heading')}</FormHeader>
      <SubContainer>
        {formattedVialSearchResult.map((object, index) => {
          return (
            <TreatmentVialOption
              key={object.key}
              vialNumber={index}
              vialActivityValue={object.key}
              options={object.value}
            />
          )
        })}
        <ShowExpeditedChargeMsg
          mnxRushOrder={isMnxTreatmentDate()}
          showShippingError={activityFormShowShippingError}
          shippingTimeline={activityFormShippingTimeline}
          treatmentDate={treatmentDate}
          treatmentTime={treatmentTime}
          portalVials={allDoses}
        />
        <TotalEstimatedActivity
          totalActivityAtTreatment={totalActivityAtTreatment}
        />
        <Button
          id='confirm-selection-btn'
          data-cy='confirm-selection-btn'
          backgroundColor={green}
          isFullWidth
          isDisabled={noOfVials !== selectedActivityVials.size}
          onClick={onSubmit}
        >
          {t('activityFocused.vialOptions.confirm')}
        </Button>
      </SubContainer>
    </Container>
  )
}
