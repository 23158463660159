import React from "react";
import { Icons } from "@chakra-ui/core/dist/theme/icons";
import { InlineErrorMessage } from "./InlineErrorMessage";
import {CheckDeliveryCharges_checkDeliveryCharges} from "../../schema/queries/__generated__/CheckDeliveryCharges";

interface showDeliveryChargesProps {
	deliveryCharge: CheckDeliveryCharges_checkDeliveryCharges | undefined
}

export const ShowDeliveryChargesMsg: React.FC<showDeliveryChargesProps> = ({
  deliveryCharge
}) => {
	const inlineErrorMessageStyleProps = {
    color:'#003968', 
    icon:'none' as Icons ,
    iconRequired: false,
    backgroundColor:'#EBEFF3',
    padding:'8px 10px',
    borderRadius:'8px',
    textAlign: 'center'
  }

	return(
    <InlineErrorMessage {...inlineErrorMessageStyleProps}>
      {deliveryCharge?.label} - ${deliveryCharge?.price}
    </InlineErrorMessage>
  )

  return null;
}
