import React, { forwardRef } from 'react'
import { ModalContent as BaseModalContent, BoxProps } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { animations } from 'react-animation'
import 'react-animation/dist/keyframes.css'

type Ref = React.RefObject<HTMLElement>
interface IModalContent {
  onClick?: React.KeyboardEventHandler<HTMLElement>
  zIndex?: BoxProps['zIndex']
  children: React.ReactNode
}

type ModalContentProps = IModalContent & BoxProps

const StyledModalContent = styled(BaseModalContent)`
  animation: ${animations.bounceIn};
`

export const ModalContent = forwardRef<Ref, ModalContentProps>(
  (
    {
      borderRadius = '1rem',
      boxShadow = '0 6px 10px rgba(0, 0, 0, 0.1)',
      padding = '2.5rem',
      ...props
    },
    ref
  ) => {
    return (
      <StyledModalContent
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        padding={padding}
        ref={ref}
        {...props}
      />
    )
  }
)
