import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import {
  FEDEX_REGEX,
  FEDEX_LINK,
  MNX_REGEX,
  MNX_LINK,
} from '../../../constants'
import { ParagraphText } from '../../Shared'
import { CarrierLink } from './CarrierLink'
import { themeUtils } from '../../../themeUtils'

interface Props {
  carrier: string
  waybillNumber: string | null
}

interface carrierLinkProps {
  carrier: string
  link: string
}

const { midnightBlue, slateGrey } = themeUtils.colors
const HighlightText = styled.span`
  font-weight: 500;
  color: ${midnightBlue};
`

const carriers = (
  carrier: string,
  waybillNumber: string | null
): Array<carrierLinkProps> => {
  const splitCarriers = carrier.split(',')
  // trim whitespace from carrier and get uniq carriers
  const uniqCarriers = splitCarriers.reduce((carriers, carrier) => {
    const carrierName = carrier.trim()
    if (carriers.indexOf(carrierName) < 0) carriers.push(carrierName)
    return carriers
  }, Array<string>())

  return uniqCarriers.map(carrier => {
    const carrierText = carrier.trim()
    const waybill = waybillNumber?.trim()
    let link = ''

    // if there is a waybill and only 1
    // generate appropriate links
    if (waybill?.split(',').length === 1) {
      if (FEDEX_REGEX.test(carrier)) {
        link = `${FEDEX_LINK}${waybill}`
      }
      if (MNX_REGEX.test(carrier)) {
        link = `${MNX_LINK}${waybill}`
      }
    }

    return { carrier: carrierText, link: link }
  })
}

export const CarrierText: React.FC<Props> = ({ carrier, waybillNumber }) => {
  return (
    <ParagraphText color={slateGrey}>
      <HighlightText>Carrier:</HighlightText>{' '}
      {carriers(carrier, waybillNumber).map((carrierItem, index) => {
        const { carrier, link } = carrierItem
        const separator = index !== 0 ? ',' : null

        return (
          <Fragment key={carrier}>
            {separator}{' '}
            {link ? <CarrierLink to={link}>{carrier}</CarrierLink> : carrier}
          </Fragment>
        )
      })}
    </ParagraphText>
  )
}
