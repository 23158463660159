import React from "react";
import { Icons } from "@chakra-ui/core/dist/theme/icons";
import { VialSearch_vialSearch_vials } from "../../schema/queries/__generated__/VialSearch";
import { InlineErrorMessage } from "./InlineErrorMessage";
import { shippingErrorMessage } from "../lib";
import { VialSearchResultOptionType } from "../Orders/OrderCreate/OrderFormDataTypes";
import { useTranslation } from 'react-i18next'

interface showExpeditedChargeMsgProps {
  mnxRushOrder: boolean
  treatmentDate?: Date | null | undefined
  treatmentTime?: string | undefined
  selectedCalibrationDate?: string | undefined
  vials?: VialSearch_vialSearch_vials[] | undefined
  portalVials?: VialSearchResultOptionType[] | undefined
  showShippingError?: boolean | undefined
  shippingTimeline?: number | undefined
  amTreatmentUnavailable?: boolean | undefined
}

export const ShowExpeditedChargeMsg: React.FC<showExpeditedChargeMsgProps> = ({
  mnxRushOrder,
  treatmentDate,
  treatmentTime,
  selectedCalibrationDate,
  vials,
  portalVials,
  showShippingError,
  shippingTimeline,
  amTreatmentUnavailable,
}) => {
  const { t } = useTranslation(['showExpeditedChargeMsg'])
  let isDosesAvailable: VialSearch_vialSearch_vials | VialSearchResultOptionType | undefined;
  if(vials)
    isDosesAvailable = vials.find((vial) => vial.available && vial.available > 0)

  if(portalVials)
    isDosesAvailable = portalVials.find((vial) => vial.available && vial.available > 0)

  const inlineErrorMessageStyleProps = {
    color:'#003968', 
    icon:'info' as Icons ,
    backgroundColor:'#EBEFF3',
    padding:'10px',
    borderRadius:'8px'
  }
    if (amTreatmentUnavailable) {
      return (
        <InlineErrorMessage {...inlineErrorMessageStyleProps}>
          {t('amTreatmentUnavailable', { ns: ['showExpeditedChargeMsg'] })}
        </InlineErrorMessage>
      )
    } else if(
      mnxRushOrder &&
      treatmentDate &&
      treatmentTime &&
      (portalVials || selectedCalibrationDate)
    ) {
      if(isDosesAvailable)
        return (
          <InlineErrorMessage
            keyProp="dose-available-alert"
            {...inlineErrorMessageStyleProps}
          >
            {t('mnxRushOrder.dosesAvailable', {
              ns: ['showExpeditedChargeMsg'],
            })}
          </InlineErrorMessage>
        )
      else if(!isDosesAvailable)
        return (
          <InlineErrorMessage
            keyProp="no-dose-available-alert"
            {...inlineErrorMessageStyleProps}
          >
            {t('mnxRushOrder.dosesUnavailable', {
              ns: ['showExpeditedChargeMsg'],
            })}
          </InlineErrorMessage>
        )
      return <></>
    } else if(!mnxRushOrder && showShippingError)
      return (
        <InlineErrorMessage
          keyProp="shipping-timeline-alert"
          {...inlineErrorMessageStyleProps}
        >
          {shippingErrorMessage(shippingTimeline)}
        </InlineErrorMessage>
      )
    return <></>
  }