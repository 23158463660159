import React from 'react'
import { darken } from 'polished'
import { themeUtils } from '../../../themeUtils'
import { Link, LinkProps } from '../../Shared'

const { slateGrey } = themeUtils.colors

export const CarrierLink: React.FC<LinkProps> = ({ to, children }) => {
  return (
    <Link
      color={slateGrey}
      textDecoration="underline"
      _hover={{ color: darken(0.1, slateGrey) }}
      target="_blank"
      rel="noreferrer"
      isExternal
      to={to}
    >
      {children}
    </Link>
  )
}
