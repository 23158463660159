import React from 'react'
import styled from '@emotion/styled'

const StyledSpan = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
`

interface Props {
  id?: string
}

const ScreenReaderOnlyText: React.FC<Props> = ({ id, ...rest }) => (
  <StyledSpan id={id} {...rest} />
)

export { ScreenReaderOnlyText }
