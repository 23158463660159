import React from 'react'
import {
  DesiredActivityAtTreatment,
  OrderFormData,
} from '../../Orders/OrderCreate/OrderFormDataTypes'
import { NestDataObject } from 'react-hook-form'
import { OrderFormClearErrorType, OrderFormErrorType } from '../../Orders'
import styled from '@emotion/styled'
import { Flex, Box } from '@chakra-ui/core'
import { Button } from '../Button'
import { FormLabel } from '../../Forms'
import { themeUtils } from '../../../themeUtils'
import { useTranslation } from 'react-i18next'

const { midnightBlue, white, geyserGrey } = themeUtils.colors

const PlusMinusContainer = styled(Flex)`
  gap: 10px;
`

const ButtonComponent = styled(Button)`
  align-items: center;
  padding: 15px 20px;
  align-items: flex-start;
  gap: 10px;

  border-radius: 5px;
  border: 1px solid ${geyserGrey};

  width: 47px;
  height: 47px;
`

const BoxComponent = styled(Box)<{ isReadOnly?: boolean }>`
  display: flex;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  border-radius: 5px;
  border: 1px solid ${geyserGrey};

  height: 47px;
  opacity: ${props => (props.isReadOnly ? '0.4' : 'unset')};
`

const ValueStyle = styled.label`
  color: ${midnightBlue};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

type Props = {
  noOfVials: number
  desiredActivityAtTreatmentSet: DesiredActivityAtTreatment[] | undefined
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
  errors: NestDataObject<OrderFormData>
  setError: OrderFormErrorType
  isReadOnly?: boolean
  isDisabled?: boolean
}

export const NoOfVialsField: React.FC<Props> = ({
  noOfVials,
  desiredActivityAtTreatmentSet,
  setFormData,
  isDisabled,
  isReadOnly,
}) => {
  const { t } = useTranslation(['orderCreate'])
  const onChangeNoOfVials = (action: string): void => {
    let value: number = noOfVials ?? 0
    let desiredActivitySet: DesiredActivityAtTreatment[] =
      desiredActivityAtTreatmentSet ?? []

    if (action === 'addition' && value < 6) {
      value += 1
      desiredActivitySet.push({
        activity: undefined,
      })
    } else if (action === 'subtraction' && value > 0) {
      value -= 1
      desiredActivitySet.pop()
    }

    setFormData((prevFormData: OrderFormData) => {
      return {
        ...prevFormData,
        noOfVials: value,
        desiredActivityAtTreatmentSet: desiredActivitySet,
      }
    })
  }
  return (
    <>
      <FormLabel>{t('activityFocused.noOfVials.label')}</FormLabel>
      <PlusMinusContainer>
        <ButtonComponent
          id="noOfVials-substraction-btn"
          data-cy="noOfVials-substraction-btn"
          color={midnightBlue}
          backgroundColor={white}
          alignItems={'center'}
          onClick={() => onChangeNoOfVials('subtraction')}
          isDisabled={noOfVials === 0 || isDisabled || isReadOnly}
        >
          -
        </ButtonComponent>
        <BoxComponent isReadOnly={isReadOnly}>
          <ValueStyle data-cy="noOfVials-label">{noOfVials}</ValueStyle>
        </BoxComponent>
        <ButtonComponent
          id="noOfVials-addition-btn"
          data-cy="noOfVials-addition-btn"
          color={midnightBlue}
          backgroundColor={white}
          alignItems={'center'}
          onClick={() => onChangeNoOfVials('addition')}
          isDisabled={noOfVials === 6 || isDisabled || isReadOnly}
        >
          +
        </ButtonComponent>
      </PlusMinusContainer>
    </>
  )
}
