import { RouteComponentProps } from '@reach/router'
import React, { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { FEATURE_FLAGS_QUERY } from '../schema/queries'
import {
  FeatureFlags,
  FeatureFlags_featureFlags,
} from '../schema/queries/__generated__/FeatureFlags'
import { Notification } from '../utils'
import { Spinner } from '../components/Shared/Spinner'

interface FeatureFlagsContext {
  featureFlags: FeatureFlags_featureFlags[]
}

const FeatureFlagsContext = React.createContext<FeatureFlagsContext>({
  featureFlags: [],
})

export const FeatureFlagsProvider: React.FC<RouteComponentProps> = props => {
  const { data, loading, error, refetch } = useQuery<FeatureFlags>(
    FEATURE_FLAGS_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (error) Notification.error(error.message)
  }, [error])

  // refetch the feature flags on route change
  // to check for updates
  useEffect(() => {
    refetch()
  }, [props.location?.key])

  if (loading) return <Spinner />

  return (
    <FeatureFlagsContext.Provider
      value={{
        featureFlags: data?.featureFlags ?? [],
      }}
      {...props}
    />
  )
}

export const useFeatureFlag = (title: string): boolean => {
  const featureFlagContext = React.useContext(FeatureFlagsContext)

  if (featureFlagContext === null) {
    throw new Error('You must wrap your components in a FeatureFlagsProvider.')
  }

  return (
    featureFlagContext.featureFlags.find(
      featureFlag => featureFlag.title === title
    )?.enabled || false
  )
}
