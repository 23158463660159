import { FormControl, InputGroup, InputRightAddon } from '@chakra-ui/core'
import React from 'react'
import { FormLabel, NumberInput } from '../../Forms'
import { OrderFormData } from './OrderFormDataTypes'
import { useTranslation } from 'react-i18next'

interface Props {
  formData: OrderFormData
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
}

export const TargetActivityAtTreatmentField: React.FC<Props> = ({
  formData,
  setFormData,
}) => {
  const { t } = useTranslation(['orderCreate'])
  const { treatmentDate, treatmentTime, targetActivityAtTreatment } = formData
  const changeTargetActivity = (event: {
    currentTarget: { value: string }
  }): void => {
    const { value } = event.currentTarget
    const twoDecimalsRegex = /^\d{1,}(\.\d{0,2})?$/

    if (value === '' || value.match(twoDecimalsRegex)) {
      setFormData((prevFormData: OrderFormData) => {
        return { ...prevFormData, targetActivityAtTreatment: parseFloat(value) }
      })
    }
  }

  return (
    <FormControl position="relative">
      <FormLabel htmlFor="targetActivityAtTreatment">
        {t('orderCreateWidget.activityAtTreatment.label')}
      </FormLabel>
      <InputGroup>
        <NumberInput
          id="targetActivityAtTreatment"
          data-cy="target-activity-at-treatment-input"
          inputMode="decimal"
          type="number"
          step="0.01"
          min="0.00"
          pattern="[0-9]*"
          placeholder={t('orderCreateWidget.activityAtTreatment.placeholder')}
          isDisabled={!treatmentDate || !treatmentTime}
          value={
            targetActivityAtTreatment
              ? targetActivityAtTreatment
              : String(targetActivityAtTreatment)
          }
          onChange={changeTargetActivity}
          borderWidth="0.1rem"
          borderRadius="0.5rem 0 0 0.5rem"
          height="4.7rem"
        />
        <InputRightAddon
          height="4.7rem"
          fontSize="1.2rem"
          borderRadius="0 0.5rem 0.5rem 0"
        >
          {t('orderCreateWidget.activityAtTreatment.unit')}
        </InputRightAddon>
      </InputGroup>
    </FormControl>
  )
}
