import React from 'react'
import { Picture } from 'react-responsive-picture'
import { IconProps } from '../../types'
import styled from '@emotion/styled'
import BSCLogo from '../../images/bsc-logo.png'

const LogoWrapContainer = styled.div<IconProps>`
  height: ${props => props?.height ?? '100%'};

  img {
    height: 100%;
  }
`

export const BTGLogo: React.FC<IconProps> = props => {
  return (
    <LogoWrapContainer {...props}>
      {<Picture src={`${BSCLogo}`} />}
    </LogoWrapContainer>
  )
}
