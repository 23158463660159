import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import React, { useState, ReactNode } from 'react'
import { RouteComponentProps, Link } from '@reach/router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { parse } from 'query-string'
import { CONFIRM_ACCOUNT_MUTATION } from '../../schema/mutations'
import {
  ConfirmPortalAccount,
  ConfirmPortalAccountVariables,
} from '../../schema/mutations/__generated__/ConfirmPortalAccount'
import {
  PortalUserConfirmationStatus,
  PortalUserConfirmationStatusVariables,
} from '../../schema/queries/__generated__/PortalUserConfirmationStatus'
import { CHECK_CONFIRMATION_STATUS } from '../../schema/queries'
import {
  Alert,
  LoginContainer,
  Heading,
  LoadingIndicator,
} from '../../components/Shared'
import { FormControl, FormHelperText, AlertIcon, Flex } from '@chakra-ui/core'
import { ErrorMessage, Input, SubmitButton } from '../../components/Forms'
import { DoneIcon } from '../../components/Icons'
import { LOGIN_PATH, RESEND_CONFIRMATION_PATH } from '../../routes'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../i18n'

const confirmationSchema = () => yup.object().shape({
  password: yup
    .string()
    .required(i18n.t('passwordInput.required', { ns: ['confirmConfirmationScreen'] }))
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)./, {
      message:
        i18n.t('passwordInput.matchesComplexity', { ns: ['confirmConfirmationScreen'] }),
      excludeEmptyString: true,
    }),
  passwordConfirmation: yup
    .string()
    .required(i18n.t('passwordConfirmationInput.required', { ns: ['confirmConfirmationScreen'] }))
    .oneOf([yup.ref('password'), null], i18n.t('passwordConfirmationInput.matching', { ns: ['confirmConfirmationScreen'] }))
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)./, {
      message:
        i18n.t('passwordConfirmationInput.matchesComplexity', { ns: ['confirmConfirmationScreen'] }),
      excludeEmptyString: true,
    }),
})

interface FormData {
  password: string
  passwordConfirmation: string
  submissionError: string
}

const alertElement = (
  status: 'error' | 'success' | 'warning' | 'info',
  msg: string | React.ReactElement = ''
): React.ReactElement => (
  <Alert status={status} maxWidth="37.5rem">
    <AlertIcon />
    <p>{msg}</p>
  </Alert>
)

const successMessage = (
  <>
    <Trans
      i18nKey="successMessage.successText"
      ns="confirmConfirmationScreen"
      components={{ a: <Link to={LOGIN_PATH} /> }}
    />
  </>
)

export const ConfirmConfirmation: React.FC<RouteComponentProps> = ({
  location,
}) => {
  const { t } = useTranslation(['confirmConfirmationScreen'])
  const [tokenError, setTokenError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [confirmAccount] = useMutation<
    ConfirmPortalAccount,
    ConfirmPortalAccountVariables
  >(CONFIRM_ACCOUNT_MUTATION)
  const {
    register,
    handleSubmit,
    setError,
    clearError,
    errors,
    reset,
    formState: { isSubmitting, isValid },
  } = useForm<FormData>({
    mode: 'onChange',
    validationSchema: confirmationSchema(),
  })

  let token = ''
  if (location && location.search) {
    token = parse(location.search).confirmation_token as string
  }

  const setSubmissonError = (errorMsg: string): void =>
    setError('submissionError', 'submission', errorMsg)

  const showErrorMessage = (): ReactNode => {
    if (errors.submissionError) {
      return errors.submissionError.message
    }

    return (
      <>
        {errors.password && errors.password.message} {errors.password && <br />}
        {errors.passwordConfirmation && errors.passwordConfirmation.message}
      </>
    )
  }

  if (token === '') {
    if (!tokenError) {
      setSubmissonError(t('submission.blankError', { ns: ['confirmConfirmationScreen'] }))
      setTokenError(true)
    }
  }

  const { loading, data } = useQuery<
    PortalUserConfirmationStatus,
    PortalUserConfirmationStatusVariables
  >(CHECK_CONFIRMATION_STATUS, {
    variables: { token },
    fetchPolicy: 'network-only',
  })

  if (loading) {
    return <LoadingIndicator />
  }

  const confirmedAccount = data?.portalUserConfirmationStatus?.confirmed

  const onSubmit = handleSubmit(
    async ({ password, passwordConfirmation }): Promise<void> => {
      try {
        clearError('submissionError')
        const { data } = await confirmAccount({
          variables: { input: { token, password, passwordConfirmation } },
        })
        if (!data) return

        if (data.confirmPortalAccount.__typename === 'ConfirmationSuccess') {
          reset()
          setSuccess(true)
        } else {
          const { errors } = data.confirmPortalAccount
          if (errors.length < 1) return

          const error = errors[0]
          const msg = `${error.path && error.path[2]} ${error.message}`
          setSubmissonError(msg)
        }
      } catch (error) {
        setSubmissonError(t('submission.error', { ns: ['confirmConfirmationScreen'] }))
      }
    }
  )
  const hasError =
    !!errors.password ||
    !!errors.passwordConfirmation ||
    !!errors.submissionError

  const renderAlert = () => {
    if (token === '') {
      return alertElement(
        'info',
        <>
          <Trans
            i18nKey="infoFragment.infoText"
            ns="confirmConfirmationScreen"
            components={{ a: <Link to={RESEND_CONFIRMATION_PATH} /> }}
          />
        </>
      )
    }

    if (confirmedAccount) {
      return alertElement(
        'error',
        <>
          <Trans
            i18nKey="alertElement.errorText"
            ns="confirmConfirmationScreen"
            components={{ a: <Link to={LOGIN_PATH} /> }}
          />
        </>
      )
    }

    if (success) {
      return alertElement('success', successMessage)
    }

    return alertElement(
      'info',
      t('alertElement.infoText', { ns: ['confirmConfirmationScreen'] })
    )
  }

  return (
    <Flex direction="column">
      <Heading>{t('header.main', { ns: ['confirmConfirmationScreen'] })}</Heading>
      {renderAlert()}
      <LoginContainer mt={{ base: '2rem', lg: '3rem' }}>
        <FormControl
          isInvalid={hasError}
          display="flex"
          justifyContent={{ base: 'flex-start', lg: 'center' }}
        >
          <ErrorMessage mt={{ lg: '-3.5rem' }}>
            {showErrorMessage()}
          </ErrorMessage>
        </FormControl>
        <form
          id="ConfirmAccount"
          autoComplete="off"
          acceptCharset="UTF-8"
          noValidate
          onSubmit={onSubmit}
        >
          <FormControl>
            <Input
              autoFocus
              variant="outline"
              type="password"
              placeholder={t('passwordInput.placeholder', { ns: ['confirmConfirmationScreen'] })}
              id="password"
              data-cy="password"
              name="password"
              isDisabled={token === '' || success || !!confirmedAccount}
              ref={register({
                required: true,
              })}
            />
            <FormHelperText
              fontSize="1.1rem"
              px="1rem"
              mt="-0.5rem"
              id="password-helper-text"
            >
              {t('passwordInput.helperText', { ns: ['confirmConfirmationScreen'] })}
            </FormHelperText>
          </FormControl>
          <FormControl mt="1rem">
            <Input
              variant="outline"
              type="password"
              placeholder={t('passwordConfirmationInput.placeholder', { ns: ['confirmConfirmationScreen'] })}
              id="passwordConfirmation"
              data-cy="passwordConfirmation"
              name="passwordConfirmation"
              isDisabled={token === '' || success || !!confirmedAccount}
              ref={register({
                required: true,
              })}
            />
          </FormControl>
          <SubmitButton
            isLoading={isSubmitting}
            isDisabled={
              token === '' || success || !!confirmedAccount || !isValid
            }
            isFullWidth
            loadingText={t('submission.loadingText', { ns: ['confirmConfirmationScreen'] })}
            rightIcon={DoneIcon}
            justifyContent={isSubmitting ? 'center' : 'space-between'}
          >
            {t('submission.buttonText', { ns: ['confirmConfirmationScreen'] })}
          </SubmitButton>
        </form>
      </LoginContainer>
    </Flex>
  )
}
