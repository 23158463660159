import { getISODay, addDays, format, set } from 'date-fns'
import { formatDate } from '.'
import { DATE_ISO_FORMAT, LONG_MONTH_FORMAT } from '../constants'

export const dateOnly = (
  dateTime: Date,
  formatString: string = DATE_ISO_FORMAT
): string => {
  return formatDate(dateTime, formatString)
}

export const getClosestDayOfLastWeek = (
  dayOfWeek: 'Mon' | 'Tue' | 'Wed' | 'Thur' | 'Fri' | 'Sat' | 'Sun',
  fromDate: Date = new Date()
): Date => {
  // follow the getISODay format (7 for Sunday, 1 for Monday)
  const dayOfWeekMap = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thur: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  }

  // -7 means last week
  // dayOfWeekMap[dayOfWeek] get the ISODay for the desired dayOfWeek
  // e.g. If today is Sunday, getISODay(fromDate) will returns 7
  // if the day we want to find is Thursday(4), apart from subtracting one week(-7),
  // we also need to account for the days between Sunday(7) and Thursday(4)
  // Hence we need to also subtract (getISODay(fromDate) - dayOfWeekMap[dayOfWeek])
  const offsetDays = -7 - (getISODay(fromDate) - dayOfWeekMap[dayOfWeek])

  return addDays(fromDate, offsetDays)
}

export const formattedTime = (time: Date, timeFormat = 'h:mm a'): string =>
  format(time, timeFormat)

export const formattedDate = (
  dateOfStatus: string | number | Date | null,
  format: string = LONG_MONTH_FORMAT
): string | 0 | null => dateOfStatus && formatDate(dateOfStatus, format)

export const newDate = (date: Date): Date =>
  set(date, { hours: 12, minutes: 0, seconds: 0 })
