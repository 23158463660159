import React from 'react'
import { IconProps } from '../../types'
import styled from '@emotion/styled'
import { themeUtils } from '../../themeUtils'

interface SVGProps {
  strokeFill?: string
}

type Props = IconProps & React.SVGAttributes<SVGElement> & SVGProps

const Svg = styled.svg`
  flex-shrink: 0;
  backface-visibility: hidden;
  &:not(:root) {
    overflow: hidden;
  }
`

export const CheckCircleIcon: React.FC<Props> = ({
  fill = themeUtils.colors.white,
  strokeFill = themeUtils.colors.red,
  width = 70,
  height = 70,
  ...rest
}) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 70 70"
      {...rest}
    >
      <g>
        <g>
          <path
            fill={fill}
            d="M35 70c19.33 0 35-15.67 35-35S54.33 0 35 0 0 15.67 0 35s15.67 35 35 35z"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke={strokeFill}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="50"
            strokeWidth="3"
            d="M20 35.457v0L29.706 45.5v0L50 24.5v0"
          />
        </g>
      </g>
    </Svg>
  )
}
