import { Notification } from './Notification'

export const handleErrors = (errors: any[]) => {
  errors.forEach(error => {
    const message = error.path
      ? `${error.path[1]} ${error.message}`
      : error.message
    Notification.error(message)
  })
}
