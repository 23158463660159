import React from 'react'
import { ModalBody, Stack } from '@chakra-ui/core'
import { themeUtils } from '../../../themeUtils'
import { Modal, Button, ParagraphText } from '../../Shared'
import { ExclamationMarkIcon, CheckSquareIcon } from '../../Icons'

interface Props {
  isOpen: boolean
  onOpen?: () => void
  onClose: () => void
  onSubmit: () => void
  loading: boolean
  buttonText: string
  headingText: string
  description?: string
}

export const RequestRemovalModal: React.FC<Props> = ({
  buttonText,
  headingText,
  onSubmit,
  loading,
  isOpen,
  onOpen,
  onClose,
  description,
  ...props
}) => {

  return (
    <>
      <Modal
        headingText={headingText}
        icon={<ExclamationMarkIcon />}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        {...props}
      >
        <ModalBody paddingX="0">
          {description && (
            <ParagraphText
              marginBottom={'2rem'}
              color={themeUtils.colors.slateGrey}
              textAlign={'center'}
            >
              {description}
            </ParagraphText>
          )}
        </ModalBody>
        <Stack isInline justifyContent="flex-start">
          <Button width="calc(100% / 3 * 1)" variant="link" onClick={onClose}>
            Cancel
          </Button>
          <Button
            width="calc(100% / 3 * 2)"
            rightIcon={CheckSquareIcon}
            onClick={onSubmit}
            backgroundColor={themeUtils.colors.red}
            isLoading={loading}
            loadingText="Requesting..."
          >
            {buttonText}
          </Button>
        </Stack>
      </Modal>
    </>
  )
}
