import styled from '@emotion/styled'
import { MEDIUM, mqNames, themeUtils } from '../../../themeUtils'

export const StandardHeading = styled.th`
  color: ${themeUtils.colors.midnightBlue};
  font-size: 1.8rem;
  font-weight: 400;
  padding: 2.5rem 0;

  @media print {
    font-size: 1.5rem;
  }
`

export const ResponsiveTableHeading = styled(StandardHeading)`
  @media screen {
    display: none;

    ${mqNames(MEDIUM)} {
      display: table-cell;
    }
  }
`
