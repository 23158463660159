import React, { Fragment } from 'react'
import { OrderShow_order_address } from '../../schema/queries/__generated__/OrderShow'
import { GetMyPortalUser_me_shippingAddresses } from '../../context/__generated__/GetMyPortalUser'

interface Props {
  address:
    | OrderShow_order_address
    | GetMyPortalUser_me_shippingAddresses
    | undefined
}

export const MultiLineAddress: React.FC<Props> = ({ address }) => {
  if (!address) return null

  const { line1, line2, line3, city, state, zip, country } = address
  const filteredAddressAttr = [
    line1,
    line2,
    line3,
    city,
    state,
    zip,
    country,
  ].filter(Boolean)

  return (
    <>
      {filteredAddressAttr.map((text, index) => (
        <Fragment key={index}>
          {!!index && <br />}
          {text}
        </Fragment>
      ))}
    </>
  )
}
