import gql from 'graphql-tag'

export const CALIBRATION_DATES_QUERY = gql`
  query CalibrationDates($treatmentDate: Date!) {
    calibrationDates(
      treatmentDate: $treatmentDate
    ) {
      calibrationDates
      calibrationWeeks {
        calibrationDate
        calibrationWeek
      }
    }
  }
`
