import { parseISO, differenceInHours } from 'date-fns'
import { round } from './rounding'
import { timeInTimeZone } from '../components/lib'

// Activity at treatment
// Activity at cal date/2^(elapsed absolute time in hours/64.1)
// Absolute time is based on the calibration date (always Sunday 12.00pm)
// and the planned treatment date and time
// Round final number to 2 decimal place
export const calcDecay = (
  dosage: number,
  calibrationDate: string,
  treatmentDate: string,
  treatmentTime: string,
  treatmentDateTime?: Date
): number => {
  const calDate = timeInTimeZone(parseISO(calibrationDate), 12, 0, 0, 'America/Toronto') // 12:00 EST
  const selectedTreatmentDateTime = treatmentDateTime || parseISO(`${treatmentDate} ${treatmentTime}`)
  const elapsedAbsoluteTime = differenceInHours(selectedTreatmentDateTime, calDate)
  const decayRate = elapsedAbsoluteTime / 64.1
  const decayCalc = dosage / Math.pow(2, decayRate)

  return round(decayCalc, 2)
}
