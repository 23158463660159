import React from 'react'
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/core'
import { RouteComponentProps, navigate, useLocation } from '@reach/router'
import { useAuth } from '../../../context/auth-context'
import { UserName } from '.'
import { themeUtils } from '../../../themeUtils'
import styled from '@emotion/styled'
import { MY_ACCOUNT_INDEX_PATH } from '../../../routes'
import { useTranslation } from 'react-i18next'
import { CustomerBusinessTypeEnum } from "../../../__generated__/globalTypes";

const ProfileMenu: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation(['navBar'])
  const location = useLocation()
  const authContext = useAuth()
  if (!authContext) return null
  const { user, logout } = authContext
  if (!user) return null

  const Container = styled.div`
    @media print {
      display: none;
    }
  `

  const openAccountPage = () => {
    if (location.pathname !== MY_ACCOUNT_INDEX_PATH) {
      navigate(MY_ACCOUNT_INDEX_PATH)
    }
  }

  return (
    <Container>
      <Menu>
        <MenuButton>
          <UserName />
        </MenuButton>
        <MenuList color={themeUtils.colors.slateGrey}>
          { user?.businessType != CustomerBusinessTypeEnum.Distributor && <MenuItem onClick={openAccountPage}>{t('profile.myAccount', { ns: ['navBar'] })}</MenuItem>}
          <MenuItem onClick={logout}>{t('profile.signOut', { ns: ['navBar'] })}</MenuItem>
        </MenuList>
      </Menu>
    </Container>
  )
}

export { ProfileMenu }
