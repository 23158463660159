import React, { MouseEvent } from 'react'
import styled from '@emotion/styled'
import {
  SortInput,
  SortDirectionEnum,
} from '../../../__generated__/globalTypes'
import { ArrowIcon } from '../../Icons/ArrowIcon'
import { StandardHeading, ResponsiveTableHeading } from '.'

interface Props {
  column: string
  sort: SortInput
  title: string
  onToggle: (column: string) => void
  headerType?: 'standard' | 'responsive'
}

const HeaderType: { [key: string]: any } = {
  standard: StandardHeading,
  responsive: ResponsiveTableHeading,
}

const headerTag = (type: string): any => HeaderType[type] || StandardHeading

export const SortableTitle = styled.a`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
`

export const SortableHeader: React.FC<Props> = ({
  column,
  sort,
  title,
  onToggle,
  headerType = 'standard',
}) => {
  const clickHandler = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault()
    onToggle(column)
  }

  const TagName = headerTag(headerType)
  return (
    <TagName>
      <SortableTitle href="javascript;" onClick={clickHandler}>
        {title}
        {column === sort.column && (
          <>
            {' '}
            {sort.direction === SortDirectionEnum.ASC ? (
              <ArrowIcon fill="#333" title="Up arrow icon" rotate={270} />
            ) : (
              <ArrowIcon fill="#333" title="Down arrow icon" rotate={90} />
            )}
          </>
        )}
      </SortableTitle>
    </TagName>
  )
}
