import React from 'react'
import { IconProps } from '../../types'

const OrdersIcon: React.FC<IconProps> = ({
  fill = '#fff',
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={fill}
    >
      <g>
        <g>
          <path d="M3.2 0h9.6v1.6H3.2zM1.6 3.2h12.8v1.6H1.6zM4 9.6h8v1.6H4zM0 6.4h16V16H0zm1.6 8h12.8V8H1.6z" />
        </g>
      </g>
    </svg>
  )
}

export { OrdersIcon }
