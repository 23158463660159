import React, { useEffect, useMemo, useState } from 'react'
import {
  Grid,
  GridProps,
  useDisclosure,
} from '@chakra-ui/core'

import styled from '@emotion/styled'
import { Button, Container, DetailText } from '../../Shared'
import { useUser } from '../../../context/user-context'
import { MEDIUM, X_LARGE, mqNames, themeUtils } from '../../../themeUtils'
import { getDefaultOrderFormOptions, PortalUserForm } from './PortalUserForm'
import { PencilIcon } from '../../Icons'
import { TeamMemberProfessionEnum } from '../../../__generated__/globalTypes'
import { GetProfessionValue } from '../TeamMember'
import { lighten } from 'polished'
import { isUSCustomer } from '../../lib'
import { useTranslation } from 'react-i18next'

const { midnightBlue, red } = themeUtils.colors

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-areas: 'content-1';
`

const BasicDetailsGrid = styled(Grid)<GridProps>`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  ${mqNames(MEDIUM)} {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  ${mqNames(X_LARGE)} {
    grid-template-columns: 1fr 1fr 3fr;
    grid-template-rows: 1fr 1fr;
  }
`

const Container1Grid = styled(Container)`
  display: grid;
  grid-area: content-1;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
`

export const AccountBasicDetailsWidget: React.FC = () => {
  const { t } = useTranslation(['myAccount'])
  const {
    isOpen: isPortalUserFormOpen,
    onOpen: onPortalUserFormOpen,
    onClose: onPortalUserFormClose,
  } = useDisclosure()
  const user = useUser()
  const [dimension, updateDimension] = useState({ width: window.innerWidth })
  const [leftMarginForEditIcon, setLeftMarginForEditIcon] = useState(
    dimension.width < 720 ? '90%' : '98%'
  )
  const DEFAULT_ORDER_FORM_OPTIONS = getDefaultOrderFormOptions()

  const professionValue = useMemo(() => {
    if (user?.profession) {
      return GetProfessionValue(user.profession, user?.otherProfession)
    }
    return ''
  }, [user?.profession, user?.otherProfession])

  useEffect(() => {
    window.addEventListener('resize', () => {
      updateDimension({
        ...dimension,
        width: window.innerWidth,
      })
      let leftMargin = window.innerWidth < 720 ? '90%' : '98%'
      setLeftMarginForEditIcon(leftMargin)
    })
  }, [window.innerWidth, window])

  if (!user) return null
  const { email, firstName, lastName, npi, defaultOrderForm } = user
  const displayNPI =
    isUSCustomer(user) &&
    user.profession === TeamMemberProfessionEnum.treating_physician

  const defaultOrderFormValue = DEFAULT_ORDER_FORM_OPTIONS.find(
    t => t.value === defaultOrderForm
  )?.label

  return (
    <GridWrapper>
      <Container1Grid heading={t('basicDetails.header', { ns: ['myAccount'] })}>
        <Button
          color={midnightBlue}
          _hover={{
            color: lighten(0.2, midnightBlue),
          }}
          _focus={undefined}
          backgroundColor={'transparent'}
          height={'unset'}
          variant="link"
          paddingLeft={0}
          paddingRight={0}
          padding={0}
          style={{ marginLeft: leftMarginForEditIcon }}
          onClick={onPortalUserFormOpen}
        >
          <PencilIcon
            title={t('basicDetails.editIconText', { ns: ['myAccount'] })}
          />
        </Button>
        <BasicDetailsGrid>
          <DetailText
            title={t('basicDetails.firstName.label', { ns: ['myAccount'] })}
          >
            {firstName}
          </DetailText>
          <DetailText
            title={t('basicDetails.lastName.label', { ns: ['myAccount'] })}
          >
            {lastName}
          </DetailText>
          <DetailText
            title={t('basicDetails.email.label', { ns: ['myAccount'] })}
          >
            {email}
          </DetailText>
          <DetailText
            title={t('basicDetails.profession.label', { ns: ['myAccount'] })}
          >
            {professionValue}
          </DetailText>
          {displayNPI && (
            <DetailText
              title={t('basicDetails.npiNumber.label', { ns: ['myAccount'] })}
              {...(!npi ? { color: red } : {})}
            >
              {npi || t('basicDetails.npiNumber.error', { ns: ['myAccount'] })}
            </DetailText>
          )}
          <DetailText
            title={t('basicDetails.defaultOrderForm.label', {
              ns: ['myAccount'],
            })}
          >
            {defaultOrderFormValue}
          </DetailText>
        </BasicDetailsGrid>
      </Container1Grid>
      {isPortalUserFormOpen && (
        <PortalUserForm
          isOpen={isPortalUserFormOpen}
          onClose={onPortalUserFormClose}
          headingTitle={t('basicDetails.formHeading', {
            ns: ['myAccount'],
          })}
        />
      )}
    </GridWrapper>
  )
}
