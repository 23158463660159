import React from 'react'
import { Text, BoxProps } from '@chakra-ui/core'
import { themeUtils } from '../../themeUtils'

const { midnightBlue } = themeUtils.colors

interface ParagraphTextProps extends BoxProps {
  variant?: 'standard' | 'title'
}

export const ParagraphText: React.FC<ParagraphTextProps> = ({
  variant = 'standard',
  children,
  color = midnightBlue,
  ...props
}) => {
  const isTitle = variant === 'title'
  return (
    <Text
      color={color}
      fontSize="1.5rem"
      fontWeight={isTitle ? 'medium' : 'normal'}
      {...props}
    >
      {children}
    </Text>
  )
}
