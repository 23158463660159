// ACCOUNT
export const LOGIN_PATH = '/'
const ACCOUNT_PATH = '/account'
export const RESEND_CONFIRMATION_PATH = `${ACCOUNT_PATH}/confirmation/new`
export const CONFIRMATION_PATH = `${ACCOUNT_PATH}/confirmation`
export const RESET_PASSWORD_PATH = `${ACCOUNT_PATH}/password/new`
export const NEW_PASSWORD_PATH = `${ACCOUNT_PATH}/password/edit`
export const RESEND_UNLOCK_PATH = `${ACCOUNT_PATH}/unlock/new`
export const UNLOCK_ACCOUNT_PATH = `${ACCOUNT_PATH}/unlock`

// ORDERS
export const ORDERS_CREATE_PATH = '/orders/create'
export const ORDERS_INDEX_PATH = '/orders'
export const ORDER_SHOW_PATH = `${ORDERS_INDEX_PATH}/:orderId`

// MY ACCOUNT
export const MY_ACCOUNT_INDEX_PATH = ACCOUNT_PATH
