import styled from '@emotion/styled'
import { MEDIUM, LARGE, mqNames } from '../../themeUtils'

export const OrderFiltersContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
    'toggle'
    'status-select'
    'search';

  ${mqNames(MEDIUM)} {
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      'toggle status-select'
      'search search';
  }

  ${mqNames(LARGE)} {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    grid-template-areas: 'toggle search status-select';
  }
`
export const DistributorOrderFiltersContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
    'toggle'
    'status-select'
    'customer-select'
    'search';

  ${mqNames(MEDIUM)} {
    grid-template-columns: 1.5fr 1fr 1.5fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      'toggle customer-select status-select'
      'search search search';
  }

  ${mqNames(LARGE)} {
    grid-template-columns: 2fr 4fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'toggle search customer-select status-select';
  }
`
