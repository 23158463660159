import React from 'react'
import { UpdateDetailsModal } from './'
import { useUser } from '../../../context/user-context'
import { useFeatureFlag } from '../../../context/FeatureFlagsContext'
import { ConfirmPopupValues, LocalStorageKeys } from '../../../types'
import { useDisclosure } from '@chakra-ui/core'
import { SuccessModal } from '../../Shared'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { isDistributor } from '../../lib'

interface Props {
  isHardTriggerModalOpen?: boolean
  onHardTriggerModalClose?: () => void
}

export const ConfirmUserDetails: React.FC<Props> = ({
  isHardTriggerModalOpen,
  onHardTriggerModalClose,
}) => {
  const { t } = useTranslation(['myAccount'])
  const treatingPhysicianFeatureFlag = useFeatureFlag('use_treating_physician')
  const user = useUser()

  const {
    isOpen: isSuccessModalOpen,
    onOpen: onSuccessModalOpen,
    onClose: onSuccessModalClose,
  } = useDisclosure()

  if (!treatingPhysicianFeatureFlag || !user || isDistributor(user)) return null

  const { loginCount, hasUpdatedDetails } = user

  let PopupValue: string | null = localStorage.getItem(
    LocalStorageKeys.CONFIRM_USER_DETAILS_POPUP
  )

  const onCloseSuccessModal = (): void => {
    onSuccessModalClose()
    navigate(`/`)
  }

  const onMainClose = (): void => {
    if (onHardTriggerModalClose) onHardTriggerModalClose()
  }

  if (isSuccessModalOpen) {
    return (
      <SuccessModal
        isOpen={isSuccessModalOpen}
        title={t('successModal.title', { ns: ['myAccount'] })}
        description={t('successModal.description', { ns: ['myAccount'] })}
        btnLabel={t('successModal.btnLabel', { ns: ['myAccount'] })}
        onClick={onCloseSuccessModal}
      />
    )
  }

  if (
    !hasUpdatedDetails &&
    ((loginCount <= 3 && PopupValue === ConfirmPopupValues.ENABLED) ||
      isHardTriggerModalOpen)
  ) {
    // display popup
    return (
      <UpdateDetailsModal
        onSuccess={onSuccessModalOpen}
        onMainClose={onMainClose}
      />
    )
  }

  return null
}
