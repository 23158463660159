import React from 'react'
import { CustomSelect, Option } from '../../Shared'
import { ThemeVersion } from '../../../types'
import { TreatmentIntentEnum } from '../../../__generated__/globalTypes'
import i18n from '../../../i18n'
import { useUser } from '../../../context/user-context'
import { isROWCustomer } from '../../lib'

interface Props {
  patientIntent: TreatmentIntentEnum | undefined | null
  changePatientIntent: (option: Option | null) => void
  label?: string
  placeholder?: string
  hint?: string
}

export const getTreatmentIntentOptions = () => ([
  {
    value: TreatmentIntentEnum.HCC,
    label: i18n.t('orderCreateWidget.patientIntentField.options.HCC', {
      ns: ['orderCreate'],
    }),
  },
  {
    value: TreatmentIntentEnum.MCRC,
    label: i18n.t('orderCreateWidget.patientIntentField.options.MCRC', {
      ns: ['orderCreate'],
    }),
  },
  {
    value: TreatmentIntentEnum.OTHER,
    label: i18n.t('orderCreateWidget.patientIntentField.options.OTHER', {
      ns: ['orderCreate'],
    }),
  },
])

export const PatientIntent: React.FC<Props> = ({
  patientIntent,
  changePatientIntent,
  label = i18n.t('orderCreateWidget.patientIntentField.label', {
    ns: ['orderCreate'],
  }),
  placeholder = i18n.t('orderCreateWidget.patientIntentField.placeholder', {
    ns: ['orderCreate'],
  }),
  hint = i18n.t('orderCreateWidget.patientIntentField.hint', {
    ns: ['orderCreate'],
  }),
}) => {
  const user = useUser()
  if (!isROWCustomer(user)) return null

  const patientIntentValue = getTreatmentIntentOptions().find(
    o => o.value == patientIntent
  )

  return (
    <CustomSelect
      label={label}
      placeholder={placeholder}
      hint={hint}
      value={patientIntentValue}
      onChange={option => changePatientIntent(option as Option)}
      options={getTreatmentIntentOptions()}
      isSearchable={false}
      clearable={true}
      id="treatmentIntent"
      version={ThemeVersion.V2}
      classNamePrefix="treatment-intent-select"
    />
  )
}
