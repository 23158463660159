import React from 'react'
import { IconProps } from '../../types'

interface CustomProps {
  rotate?: number
}
type ArrowProps = IconProps & CustomProps

const ArrowIcon: React.FC<ArrowProps> = ({
  fill = '#fff',
  width = 12,
  height = 13,
  rotate = 0,
  title,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 13"
      role="img"
      aria-labelledby="title"
      transform={`rotate(${rotate})`}
    >
      <title id="title">{title}</title>
      <g>
        <g transform="rotate(-360 1264 341.5)">
          <path
            fill={fill}
            d="M5.239 1.244l.79-.806L11.967 6.5l-5.938 6.062-.79-.806 4.647-4.744H.058v-1.14h9.715z"
          />
        </g>
      </g>
    </svg>
  )
}

export { ArrowIcon }
