import React from 'react'
import { FormHelperText } from '@chakra-ui/core'
import { Input, InputProps } from '../Forms'
import styled from '@emotion/styled'
import { themeUtils } from '../../themeUtils'

interface Props extends InputProps {
  errorMessage?: string
}

const CustomNumberInput = styled(Input)`
  /* Chakra ui reset override */
  &&& {
    -moz-appearance: number-input !important;
    -webkit-appearance: number-input !important;

    &:invalid,
    &:invalid:hover,
    &:invalid:focus {
      border-color: red;
    }

    &:invalid + p {
      display: block;
    }
  }
`

export const NumberInput: React.FC<Props> = ({
  errorMessage = 'must contain only numbers',
  ...inputProps
}) => {
  return (
    <>
      <CustomNumberInput {...inputProps} />
      <FormHelperText
        fontSize="1.2rem"
        color={themeUtils.colors.red}
        display="none"
        position="absolute"
        top="6rem"
      >
        {errorMessage}
      </FormHelperText>
    </>
  )
}
