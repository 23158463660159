import {
  Flex,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Modal,
  ModalHeader,
  ModalCloseButton,
  Box,
  useDisclosure,
} from '@chakra-ui/core'
import React from 'react'
import { Button, Heading, ModalContent, ModalOverlay } from '../../Shared'
import { useUser } from '../../../context/user-context'
import {
  LARGE,
  MEDIUM,
  X_LARGE,
  mqNames,
  themeUtils,
} from '../../../themeUtils'
import styled from '@emotion/styled'
import { FormLabel } from '../../Forms'
import { PlusSquareIcon } from '../../Icons'
import { copyToClipboard } from '../../../utils/commonUtils'
import { useTranslation } from 'react-i18next'

const PopoverContentContainer = styled(PopoverContent)`
  height: 48rem;
  padding: 1.5rem;
  font-size: 1.4rem;
  z-index: 1401;
  border-radius: 1rem;
  max-width: unset;

  position: absolute !important;
  top: 0px !important;
  left: unset !important;
  will-change: unset !important;
  transform: unset !important;

  margin-top: unset !important;
  margin-left: unset;
  left: 0px !important;

  ${mqNames(MEDIUM)} {
    margin-left: 27.5rem;
    left: unset !important;
  }

  ${mqNames(LARGE)} {
    height: 44rem;
    margin-left: 35.5rem;
    left: unset !important;
  }

  ${mqNames(X_LARGE)} {
    height: 42rem;
    margin-left: 45.5rem;
    left: unset !important;
  }
`

const BodyContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

interface Props {
  isPopoverOpen: boolean
  onPopoverClose: () => void
}

const { blue, green } = themeUtils.colors

export const InviteTeamMemberPopover: React.FC<Props> = ({
  isPopoverOpen,
  onPopoverClose,
}) => {
  const { t } = useTranslation(['teamMemberSection'])

  return (
    <Popover
      isOpen={isPopoverOpen}
      onClose={onPopoverClose}
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Flex>
          <Button hidden>
            {t('invite.btnLabel', { ns: ['teamMemberSection'] })}
          </Button>
        </Flex>
      </PopoverTrigger>
      {isPopoverOpen && (
        <PopoverContentContainer>
          <PopoverHeader fontWeight="semibold">
            <Heading color={blue} as="h2" marginBottom="0">
              {t('invite.header', { ns: ['teamMemberSection'] })}
            </Heading>
          </PopoverHeader>
          <PopoverCloseButton size={'md'} />
          <InviteTeamMemberBody />
        </PopoverContentContainer>
      )}
    </Popover>
  )
}

export const InviteTeamMemberButton: React.FC = () => {
  const { t } = useTranslation(['teamMemberSection'])
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button
        backgroundColor={green}
        boxShadow={{
          base: 'none',
          sm: '0 10px 15px rgba(69, 218, 122, 0.2);',
        }}
        leftIcon={PlusSquareIcon}
        iconSpacing={'1rem'}
        onClick={onOpen}
      >
        {t('invite.btnLabel', { ns: ['teamMemberSection'] })}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => onClose()}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent
          maxWidth="48.5rem"
          justifyContent="center"
          height={'48rem'}
        >
          <ModalHeader padding={'0 0 0 0.5rem'}>
            <Heading color={blue} as="h2" marginBottom="0">
              {t('invite.header', { ns: ['teamMemberSection'] })}
            </Heading>
          </ModalHeader>
          <ModalCloseButton _focus={undefined} />
          <InviteTeamMemberBody />
        </ModalContent>
      </Modal>
    </>
  )
}

export const InviteTeamMemberBody: React.FC = () => {
  const { t } = useTranslation(['teamMemberSection'])
  const user = useUser()
  const registrationUrl = user?.countryConfiguration.portalRegistrationLink

  const onClickToShare = () => {
    const recipientEmail = ''
    const subject = t('invite.share.subject', { ns: ['teamMemberSection'] })
    const body = `${t('invite.share.body', {
      ns: ['teamMemberSection'],
    })}
    ${registrationUrl}\n\n
    ${t('invite.share.bodyEnd', {
      ns: ['teamMemberSection'],
    })}
    `
    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`

    // Open the default email app
    window.location.href = mailtoLink
  }

  const onClickToCopy = () => {
    registrationUrl &&
      copyToClipboard(
        registrationUrl,
        t('invite.copy.successMsg', { ns: ['teamMemberSection'] })
      )
  }

  return (
    <BodyContainer borderBottom={0}>
      <FormLabel padding={'0.75rem'} flexGrow={1}>
        {t('invite.bodyText', { ns: ['teamMemberSection'] })}
      </FormLabel>
      <Button backgroundColor={blue} isFullWidth onClick={onClickToShare}>
        {t('invite.share.btnLabel', { ns: ['teamMemberSection'] })}
      </Button>
      <Button
        isFullWidth
        variant="outline"
        color={blue}
        marginTop={'1rem'}
        onClick={onClickToCopy}
      >
        {t('invite.copy.btnLabel', { ns: ['teamMemberSection'] })}
      </Button>
    </BodyContainer>
  )
}
