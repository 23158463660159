import React, { useState } from 'react'
import { OrderShow_order } from '../../../schema/queries/__generated__/OrderShow'
import { Button, Option } from '../../Shared'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_TREATMENT_INTENT } from '../../../schema/mutations'
import { handleErrors, Notification } from '../../../utils'
import { PencilIcon } from '../../Icons'
import { themeUtils } from '../../../themeUtils'
import { Box, Flex, FlexProps, PseudoBox, useDisclosure } from '@chakra-ui/core'
import { lighten } from 'polished'
import { useTranslation } from 'react-i18next'
import { PatientIntent, getTreatmentIntentOptions } from '../NewOrderForm'
import { UpdateTreatmentIntent, UpdateTreatmentIntentVariables } from '../../../schema/mutations/__generated__/UpdateTreatmentIntent'

interface Props {
  order: OrderShow_order
  refetch: Function
  canEdit: Boolean | undefined
  wrapperProps: FlexProps
}

const buttonStyles = {
  marginLeft: '1rem',
  height: 'auto',
  width: 'auto',
  minWidth: '6rem',
}

const { midnightBlue } = themeUtils.colors

export const PatientIntentForm: React.FC<Props> = ({
  order,
  refetch,
  canEdit=false,
  wrapperProps,
}) => {
  const { t } = useTranslation(['orderShowScreen'])
  const [patientIntentValue, setPatientIntentValue] = useState(order.treatmentIntent)

  const [updatePatientIntent] = useMutation<
    UpdateTreatmentIntent,
    UpdateTreatmentIntentVariables
  >(UPDATE_TREATMENT_INTENT)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const resetField = () => {
    onClose()
    setPatientIntentValue(order.treatmentIntent ?? null)
  }

  const onUpdatePatientIntent = async (): Promise<void> => {
    if (!isOpen) return

    const { data, errors } = await updatePatientIntent({
      variables: {
        input: {
          id: order?.id,
          treatmentIntent: patientIntentValue
        },
      },
    })
    onClose()

    const successMessage = t('patientIntent.updated', {
      ns: ['orderShowScreen'],
    })
    refetch()

    if (errors) {
      errors.forEach(error => Notification.error(error.message))
    } else {
      if (data && data.updateTreatmentIntent.__typename === 'Error') {
        handleErrors(data.updateTreatmentIntent.errors)
      } else {
        refetch()
        Notification.success(successMessage)
      }
    }
  }

  const changePatientIntent = (option: Option | null): void => {
    setPatientIntentValue(option?.value ?? null)
  }

  const orderPatientIntentValue = getTreatmentIntentOptions().find(
    o => o.value == order.treatmentIntent
  )?.label

  return (
    <>
      <Flex {...wrapperProps}>
        {isOpen ? (
          <>
            <PatientIntent
              changePatientIntent={changePatientIntent}
              patientIntent={patientIntentValue}
              label=""
              hint=""
            />
            <Flex>
              <Button
                {...buttonStyles}
                maxHeight="60px"
                onClick={() => onUpdatePatientIntent()}
              >
                {t('patientIntent.save', { ns: ['orderShowScreen'] })}
              </Button>

              <Button
                {...buttonStyles}
                backgroundColor={themeUtils.colors.red}
                maxHeight="60px"
                onClick={() => resetField()}
              >
                {t('patientIntent.cancel', { ns: ['orderShowScreen'] })}
              </Button>
            </Flex>
          </>
        ) : (
          <>
            <Box as="span" cursor={canEdit? "pointer" : ""} onClick={canEdit ? () => onOpen() : () =>{}}>
              {orderPatientIntentValue ||
                t('patientIntent.awaiting', { ns: ['orderShowScreen'] })}
            </Box>
            {!isOpen && canEdit && (
              <PseudoBox
                cursor="pointer"
                color={midnightBlue}
                _hover={{
                  color: lighten(0.2, midnightBlue),
                }}
                onClick={() => onOpen()}
              >
                <PencilIcon
                  title={t('patientIntent.edit', {
                    ns: ['orderShowScreen'],
                  })}
                />
              </PseudoBox>
            )}
          </>
        )}
      </Flex>
    </>
  )
}
