import React from 'react'
import { SHORT_MONTH_FORMAT } from '../../../constants'
import { calcDecay, formatDate } from '../../../utils'
import { OrderShow_order_treatments } from '../../../schema/queries/__generated__/OrderShow'
import { ProductContainer } from './ProductContainer'
import { DoseText } from './DoseText'
import { TreatmentText } from './TreatmentText'
import { timeInTimeZone } from '../../lib'
import { parseISO } from 'date-fns'
import { useUser } from '../../../context/user-context'
import { useTranslation } from 'react-i18next'

interface Props {
  treatment: OrderShow_order_treatments
  treatmentDate: string
  treatmentTime: string
}

export const Treatment: React.FC<Props> = ({
  treatment,
  treatmentDate,
  treatmentTime,
}) => {
  const { t } = useTranslation(['treatment'])
  const splitTreatmentTime = treatmentTime.split(':')
  const user = useUser()
  const siteTimezone = user?.preferredShippingAddress.timezone || ''
  const treatmentDateTime = timeInTimeZone(parseISO(treatmentDate), Number(splitTreatmentTime[0]), Number(splitTreatmentTime[1]), 0, siteTimezone)
  const dosage = treatment.product.dosage || 0.0
  const { calibrationDate, calibrationWeek } = treatment
  const weekText = !!calibrationWeek ? `${t('week', {
    ns: ['treatment'],
  })} ${calibrationWeek}` : null

  return (
    <ProductContainer calibrationWeek={calibrationWeek}>
      <DoseText>
        {dosage.toFixed(1)}{' '}
        <small>
          {t('unit', {
            ns: ['treatment'],
          })}
        </small>
      </DoseText>
      <TreatmentText>
        <p>
          <b>
            {calcDecay(
              dosage,
              calibrationDate,
              treatmentDate,
              treatmentTime,
              treatmentDateTime
            )}{' '}
            {t('unit', {
              ns: ['treatment'],
            })}
          </b>{' '}
          {t('atTreatment', {
            ns: ['treatment'],
          })}
        </p>
        <p>
          <small>
            {[weekText, formatDate(calibrationDate, SHORT_MONTH_FORMAT)]
              .filter(Boolean)
              .join(' - ')}
          </small>
        </p>
      </TreatmentText>
    </ProductContainer>
  )
}
