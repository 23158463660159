import gql from 'graphql-tag'

export const NPI_SEARCH_QUERY = gql`
  query NPISearch(
    $firstName: String
    $lastName: String
    $number: String
    $taxonomyDescription: String
  ) {
    npiSearch(
      firstName: $firstName
      lastName: $lastName
      number: $number
      taxonomyDescription: $taxonomyDescription
    ) {
      resultCount
      results {
        number
        addresses {
          countryCode
          countryName
          addressPurpose
          addressType
          address1
          city
          state
          postalCode
        }
        basic {
          firstName
          middleName
          lastName
        }
      }
    }
  }
`
