import React, { useMemo } from 'react'
import { Box, Flex, Text } from '@chakra-ui/core'
import { VialSearch_vialSearch_vials } from '../../../../schema/queries/__generated__/VialSearch'
import { themeUtils } from '../../../../themeUtils'
import { VialStepper } from './VialStepper'
import { useVialSearch } from '../../../../hooks'
import { BoxFill } from '../../../Shared'
import { useTranslation } from 'react-i18next'

interface VialProps {
  vial: VialSearch_vialSearch_vials
  index: number
}

const shuttleGrey = themeUtils.colors.shuttleGrey
const borderGrey = themeUtils.colors.borderGrey
const blue = themeUtils.colors.midnightBlue
const white = themeUtils.colors.white
const limedSpruce = themeUtils.colors.limedSpruce
const ufoGreenLight = themeUtils.colors.ufoGreenLight
const ufoGreen = themeUtils.colors.ufoGreen

export const Vial: React.FC<VialProps> = ({ vial, index }) => {
  const { t } = useTranslation(['orderCreate'])
  const vialDosage = vial.dosage
  const vialsAvailable = vial.available
  const fromSurplus = vial.surplus
  const { selectedVials, selectedCalibrationDate } = useVialSearch()

  const selectedQuantity = useMemo(() => {
    if (selectedVials && selectedVials.size) {
      const vialsForCal = selectedVials.get(vialDosage)
      if (vialsForCal) {
        return vialsForCal.get(selectedCalibrationDate) || 0
      }
    }

    return 0
  }, [selectedVials, selectedCalibrationDate, vialDosage])

  const hasSelection = selectedQuantity > 0
  const vialsAvailableCount = Math.max(
    0,
    (vialsAvailable || 0) - selectedQuantity
  )
  return (
    <Flex
      backgroundColor={hasSelection ? ufoGreenLight : borderGrey}
      border={hasSelection ? `2px solid ${ufoGreen}` : undefined}
      borderRadius="1rem"
      padding="0 1.5rem"
      width="100%"
      height="6rem"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      key={vialDosage}
      transition="background-color 250ms linear"
      data-cy={`vial-search-result-sub-container-${index + 1}`}
    >
      <Box data-cy={`vial-available-${index + 1}`}>
        <Text
          color={fromSurplus ? (vialsAvailable ? blue : shuttleGrey) : blue}
        >
          {vialDosage.toFixed(1)} GBq
        </Text>
        {fromSurplus && (
          <Text color={shuttleGrey}>
            {vialsAvailableCount} {t('orderCreateWidget.vialOptions.available')}
          </Text>
        )}
      </Box>
      <VialStepper
        vial={vial}
        selectedQuantity={selectedQuantity}
        index={index}
      />
      {hasSelection && (
        <Box
          position="absolute"
          top="0.3rem"
          right="0.3rem"
          fontSize="1rem"
          color={white}
          data-cy={`selected-quantity-container-${index + 1}`}
        >
          <BoxFill
            circular
            backgroundColor={limedSpruce}
            boxShadow="0px 4px 6px rgba(92, 108, 122, 0.304606)"
            width="1.6rem"
            height="1.6rem"
            data-cy={`selected-quantity-sub-container-${index + 1}`}
          >
            {selectedQuantity}
          </BoxFill>
        </Box>
      )}
    </Flex>
  )
}
