import { useContext } from 'react'
import {
  OrderFormContext,
  getOrderFormHeading,
  OrderFormStepType,
} from '../context/OrderFormContext'
import { OrderFormTypeEnum } from '../__generated__/globalTypes'

export const useOrderForm = () => {
  const { state, setState } = useContext(OrderFormContext)

  const setDefaultFormType = (type?: OrderFormTypeEnum) => {
    setState(prevState => {
      return { ...prevState, type: type ?? OrderFormTypeEnum.VIAL_SELECTOR }
    })
  }

  const setFormType = (type: OrderFormTypeEnum) => {
    setState(prevState => {
      return { ...prevState, prevSource: prevState.type, type: type }
    })
  }

  const setHideSwitchFormValue = (value: boolean) => {
    setState(prevState => {
      return { ...prevState, hideSwitchFormLink: value }
    })
  }

  const setFormStep = (step: OrderFormStepType) => {
    if (
      step === OrderFormStepType.STEP_1 ||
      step === OrderFormStepType.STEP_2
    ) {
      setState(prevState => {
        return {
          ...prevState,
          step: step,
          formHeading: getOrderFormHeading().VIAL_SEARCH,
          hideSwitchFormLink: false,
        }
      })
    } else if (step === OrderFormStepType.STEP_3) {
      setState(prevState => {
        return {
          ...prevState,
          step: step,
          formHeading: getOrderFormHeading().TREATMENT_DETAILS,
          hideSwitchFormLink: true,
        }
      })
    } else {
      setState(prevState => {
        return {
          ...prevState,
          step: step,
        }
      })
    }
  }

  return {
    setDefaultFormType,
    setFormType,
    setFormStep,
    formType: state.type,
    step: state.step,
    formHeading: state.formHeading,
    prevFormSource: state.prevSource,
    setHideSwitchFormValue,
    hideSwitchFormLink: state.hideSwitchFormLink,
  }
}