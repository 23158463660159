import React, { createContext, useState, Dispatch, SetStateAction } from 'react'
import { getDefaultOrderFormType } from '../components/lib'
import { useUser } from './user-context'
import { OrderFormTypeEnum } from '../__generated__/globalTypes'
import i18n from '../i18n'

export enum OrderFormStepType {
  STEP_1 = 'STEP_1',
  STEP_2 = 'STEP_2',
  STEP_3 = 'STEP_3',
}

export const getOrderFormHeading = () => ({
  VIAL_SEARCH: i18n.t('formHeading.vialSearch', { ns: ['orderCreate'] }),
  TREATMENT_DETAILS: i18n.t('formHeading.treatmentDetails', { ns: ['orderCreate'] }),
})

interface OrderFormContextState {
  type: OrderFormTypeEnum
  step: OrderFormStepType
  formHeading: string
  prevSource: OrderFormTypeEnum | undefined
  hideSwitchFormLink: boolean
}

export interface OrderFormContextType {
  state: OrderFormContextState
  setState: Dispatch<SetStateAction<OrderFormContextState>>
}

export const OrderFormContext = createContext<OrderFormContextType>({
  state: {
    type: OrderFormTypeEnum.VIAL_SELECTOR,
    step: OrderFormStepType.STEP_1,
    formHeading: getOrderFormHeading().VIAL_SEARCH,
    prevSource: undefined,
    hideSwitchFormLink: false,
  },
  setState: () => ({}),
})

export const OrderFormProvider: React.FC = ({ children }) => {
  const user = useUser()
  const [state, setState] = useState<OrderFormContextState>({
    type: getDefaultOrderFormType(user),
    step: OrderFormStepType.STEP_1,
    formHeading: getOrderFormHeading().VIAL_SEARCH,
    prevSource: undefined,
    hideSwitchFormLink: false,
  })

  return (
    <OrderFormContext.Provider value={{ state, setState }}>
      {children}
    </OrderFormContext.Provider>
  )
}
