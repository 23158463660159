import React, {useEffect, useState} from 'react'
import styled from '@emotion/styled'
import {
  ResponsiveTableHeading,
  StandardHeading,
  TableContent,
} from '.'
import { Container } from '../../Shared'
import { useQuery } from 'react-apollo'
import { GET_TEAM_MEMBERS } from '../../../schema/queries/TeamMembersQueries'
import { GetTeamMembers } from '../../../schema/queries/__generated__/GetTeamMembers'
import { useUser } from '../../../context/user-context'
import { isUSCustomer } from '../../lib'
import { isROWCustomer } from '../../lib'
import { useTranslation } from 'react-i18next'

const Table = styled.table`
  width: 100%;
  font-size: 1.5rem;
`

const TableHeader = styled.thead`
  text-align: left;
`

const Container1Grid = styled(Container)`
  display: grid;
  grid-area: content-1;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  height: '100%';
  position: 'relative';
`

export const TeamMembersTable: React.FC = () => {
  const { t } = useTranslation(['teamMemberSection'])
  const { data, loading, error } = useQuery<GetTeamMembers>(GET_TEAM_MEMBERS)
  const [dimension, updateDimension] = useState({width: window.innerWidth});
  const [isSmallScreen, setIsSmallScreen] = useState(dimension.width < 720);
  const user = useUser()

  useEffect(() => {
    window.addEventListener("resize", () => {
      updateDimension({
        ...dimension,
        width: window.innerWidth,
      });
      setIsSmallScreen(window.innerWidth < 720)
    })
  },[window.innerWidth, window]);

  return (
    <Container1Grid
      heading={t('tableSectionHeader', { ns: ['teamMemberSection'] })}
      paddingTop={0}
    >
      <Table>
        <TableHeader>
          <tr>
            <StandardHeading>
              {t('table.header.name', { ns: ['teamMemberSection'] })}
            </StandardHeading>
            {!isROWCustomer(user) && (
              <ResponsiveTableHeading>
                {t('table.header.email', { ns: ['teamMemberSection'] })}
              </ResponsiveTableHeading>
            )}
            <StandardHeading>
              {t('table.header.profession', { ns: ['teamMemberSection'] })}
            </StandardHeading>
            {isUSCustomer(user) && (
              <ResponsiveTableHeading>
                {t('table.header.npiNumber', { ns: ['teamMemberSection'] })}
              </ResponsiveTableHeading>
            )}
            <StandardHeading>
              {t('table.header.isPortalUser', { ns: ['teamMemberSection'] })}
            </StandardHeading>
            {isSmallScreen ? (
              <StandardHeading style={{ textAlign: 'right' }}>
                {t('table.header.actions', { ns: ['teamMemberSection'] })}
              </StandardHeading>
            ) : (
              <StandardHeading
                style={{ textAlign: 'right', paddingRight: '10rem' }}
              >
                {t('table.header.actions', { ns: ['teamMemberSection'] })}
              </StandardHeading>
            )}
            <ResponsiveTableHeading />
          </tr>
        </TableHeader>

        <tbody>
          <TableContent
            teamMembers={data?.teamMembers}
            loading={loading}
            error={error}
          />
        </tbody>
      </Table>
    </Container1Grid>
  )
}
