import React, { FormEvent } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { ModalBody, FormControl, InputProps, Stack } from '@chakra-ui/core'
import {
  GetReasons,
  GetReasonsVariables,
} from '../../schema/queries/__generated__/GetReasons'
import { GET_REASONS_QUERY } from '../../schema/queries/ReasonQueries'
import { ReasonTypeEnum } from '../../__generated__/globalTypes'
import { VOID_STATUSES } from '../../constants'
import { themeUtils } from '../../themeUtils'
import { Modal, ScreenReaderOnlyText, Button } from '../Shared'
import { Select } from '../Forms'
import { ExclamationMarkIcon, CheckSquareIcon } from '../Icons'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onOpen?: () => void
  onClose: () => void
  onToggle?: () => void
  onReasonSelect: (e: FormEvent<HTMLInputElement>) => void
  onSubmit: () => void
  loading: boolean
  selectedReason: string | number | undefined
  selectProps?: InputProps
  orderStatus: string
}

export const RequestCancellationModal: React.FC<Props> = ({
  orderStatus,
  selectProps,
  selectedReason,
  onReasonSelect,
  onSubmit,
  loading,
  isOpen,
  onOpen,
  onClose,
  ...props
}) => {
  const { t } = useTranslation(['orderOverviewScreen'])
  const reasonType = VOID_STATUSES.includes(orderStatus)
    ? ReasonTypeEnum.VOID
    : ReasonTypeEnum.CANCELLATION
  const { data } = useQuery<GetReasons, GetReasonsVariables>(
    GET_REASONS_QUERY,
    {
      variables: { type: reasonType, limit: 100 },
      fetchPolicy: 'cache-first',
    }
  )

  return (
    <>
      <Modal
        headingText={t('cancelModal.heading', { ns: ['orderOverviewScreen'] })}
        icon={<ExclamationMarkIcon />}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        {...props}
      >
        <ModalBody paddingX="0">
          <FormControl>
            <ScreenReaderOnlyText id="SelectLabelText">
            {t('cancelModal.reason', { ns: ['orderOverviewScreen'] })}
            </ScreenReaderOnlyText>
            <Select
              aria-labelledby="SelectLabelText"
              onChange={onReasonSelect}
              {...selectProps}
              value={selectedReason}
              isEmpty={selectedReason === ''}
            >
              <option value="">{t('cancelModal.selectPlaceholder', { ns: ['orderOverviewScreen'] })}</option>
              {data &&
                data.reasons.items.map(reason => (
                  <option key={reason.id} value={reason.id}>
                    {reason.customerFacingDescription}
                  </option>
                ))}
            </Select>
          </FormControl>
        </ModalBody>
        <Stack isInline justifyContent="flex-start">
          <Button width="calc(100% / 3 * 1)" variant="link" onClick={onClose}>
            {t('cancelModal.backButtonText', { ns: ['orderOverviewScreen'] })}
          </Button>
          <Button
            width="calc(100% / 3 * 2)"
            rightIcon={CheckSquareIcon}
            onClick={onSubmit}
            backgroundColor={themeUtils.colors.red}
            isDisabled={!selectedReason}
            isLoading={loading}
            loadingText={t('cancelModal.loadingText', { ns: ['orderOverviewScreen'] })}
          >
            {t('cancelModal.submitText', { ns: ['orderOverviewScreen'] })}
          </Button>
        </Stack>
      </Modal>
    </>
  )
}
