import React from 'react'
import { useUser } from './context/user-context'
import { GlobalLayout } from '../src/components/Layout'
import { AppSpinner } from './components/AppSpinner'

const loadAuthenticatedApp = (): Promise<
  typeof import('./authenticated-app')
> => import('./authenticated-app')
const AuthenticatedApp = React.lazy(loadAuthenticatedApp)
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

export const App: React.FC = () => {
  const user = useUser()
  // pre-load the authenticated side in the background while the user's
  // filling out the login form.
  React.useEffect(() => {
    loadAuthenticatedApp()
  }, [])
  return (
    <React.Suspense fallback={<AppSpinner />}>
      <GlobalLayout>
        {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </GlobalLayout>
    </React.Suspense>
  )
}
