import React from 'react'
import moment from 'moment'
import 'moment-timezone'
import Clock from 'react-live-clock'

import styled from '@emotion/styled'
import { breakpoints, LARGE, mqNames, themeUtils } from '../../../themeUtils'

import { FooterNavBar } from '.'
import { BTGLogo } from '../../Icons'
import useMedia from 'use-media'
import 'moment/locale/fr';
import i18n from '../../../i18n'

const FooterElement = styled.footer`
  background-color: ${themeUtils.colors.grey};
  color: #000000;
  height: auto;
  bottom: 0;
  width: 100%;

  ${mqNames(LARGE)} {
    height: 6rem;
  }
`

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 3rem;
  justify-content: space-between;
  max-width: ${themeUtils.widths.max};
  margin: 0 auto;

  a {
    height: 50%;
  }

  ${mqNames(LARGE)} {
    flex-direction: row;
  }
`

const ClockContainer = styled.div`
  font-size: 1.3rem;
  margin-top: 2rem;
  padding-bottom: 2rem;

  ${mqNames(LARGE)} {
    margin-top: 0;
    padding-bottom: 0;
  }
`

const zone_name = moment.tz.guess()
const getLocal = () => (i18n.language === 'fr' ? 'fr' : 'en')

const Footer: React.FC = () => {
  const isLargeScreen = useMedia({ minWidth: breakpoints[LARGE] })

  return (
    <FooterElement>
      <FooterContainer>
        <FooterNavBar />
        <BTGLogo height={isLargeScreen ? '75%' : '50px'} />
        <ClockContainer>
          <Clock
            format={'ddd MMM DD YYYY H:mm:ss z Z'}
            ticking={true}
            timezone={zone_name}
            locale={getLocal()}
          />{' '}
          ({zone_name})
        </ClockContainer>
      </FooterContainer>
    </FooterElement>
  )
}

export { Footer }