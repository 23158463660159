import styled from '@emotion/styled'

export const TreatmentText = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  padding-left: 1.5rem;

  p {
    margin: 0;

    b {
      font-weight: 500;
    }

    small {
      font-size: 1.2rem;
    }
  }
`
