import React from 'react'
import { darken } from 'polished'
import { themeUtils } from '../../../themeUtils'
import { CrossIcon } from '../../Icons'
import { Button, CustomButtonProps } from '../../Shared'

type _CustomButtonProps = Omit<CustomButtonProps, 'children'>

const { red, white } = themeUtils.colors

export const RemoveOrderItem: React.FC<_CustomButtonProps> = ({ ...props }) => (
  <Button
    backgroundColor={white}
    boxShadow="0px 4px 6px rgba(92, 108, 122, 0.304606)"
    _hover={{ backgroundColor: darken(0.1, white) }}
    _disabled={{ background: 'rgba(92, 108, 122, 0.2)' }}
    transition="background-color 250ms linear"
    height="1.8rem"
    width="1.8rem"
    minWidth="inherit"
    borderRadius="9999px"
    paddingX={0}
    paddingY={0}
    position="absolute"
    left="calc(100% - 2.3rem)"
    top="5px"
    {...props}
  >
    <CrossIcon fill={red} />
  </Button>
)
