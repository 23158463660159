import React from 'react'
import { Box, BoxProps } from "@chakra-ui/core";

export const Scrollable: React.FC<BoxProps> = ({ maxHeight, children }) => {
  return (
    <Box
      overflowY="auto"
      maxHeight={maxHeight}
      padding="4"
      marginBottom={"1rem"}
    >
      {children}
    </Box>
  );
};
