import React from 'react'
import { FormErrorMessage, BoxProps } from '@chakra-ui/core'
import { themeUtils } from '../../themeUtils'

const errorRed = themeUtils.colors.red

const ErrorMessage: React.FC<BoxProps> = props => (
  <FormErrorMessage
    color={themeUtils.colors.white}
    backgroundColor={errorRed}
    borderWidth={{ base: '0', lg: '0.1rem' }}
    borderRadius="1.3rem"
    borderColor={errorRed}
    boxShadow="0 10px 15px rgba(141, 162, 179, 0.1)"
    fontSize="1.4rem"
    padding="0.5rem 2rem"
    marginBottom="1rem"
    {...props}
  />
)

export { ErrorMessage }
