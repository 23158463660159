import { ApolloError } from 'apollo-client'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { OrdersIndex_orders_items } from '../../../schema/queries/__generated__/OrdersIndex'
import { Alert, LoadingIndicator } from '../../Shared'
import { OrderGroupedRow } from './OrderGroupedRow'
import { OrderRow } from './OrderRow'

const BlankRow: React.FC = ({ children }) => (
  <tr>
    <td colSpan={5} style={{ textAlign: 'center' }}>
      {children}
    </td>
  </tr>
)

interface Props {
  orders: OrdersIndex_orders_items[]
  isDistributor: boolean
  loading: boolean
  error?: ApolloError
}

export const TableContent: React.FC<Props> = ({
  orders,
  isDistributor,
  loading,
  error,
}) => {
  const { t } = useTranslation(['orderScreen'])
  if (error) {
    return (
      <BlankRow>
        <Alert status="error">{error.message}</Alert>
      </BlankRow>
    )
  }

  if (loading) {
    return (
      <BlankRow>
        <LoadingIndicator />
      </BlankRow>
    )
  }

  if (!orders || orders.length === 0)
    return (
      <BlankRow>{t('orderTable.noneFound', { ns: ['orderScreen'] })}</BlankRow>
    )

  const results = orders.reduce((result, order) => {
    const key = `${order.patientRef}-${
      order.treatmentDate
    }-${order.treatmentTime.replace(':', '')}-${order.customerId}`

    const existingOrders = result.get(key)

    if (existingOrders) return result.set(key, [...existingOrders, order])

    return result.set(key, [order])
  }, new Map<string, OrdersIndex_orders_items[]>())

  return (
    <>
      {Array.from(results.values()).map(orders => {
        if (orders.length === 1) {
          return (
            <OrderRow
              key={orders[0].orderNumber}
              order={orders[0]}
              isDistributor={isDistributor}
            />
          )
        }

        const OrderNumbers = orders
          .map((order: OrdersIndex_orders_items) => order.orderNumber)
          .join('-')

        return (
          <OrderGroupedRow
            key={OrderNumbers}
            orders={orders}
            isDistributor={isDistributor}
          />
        )
      })}
    </>
  )
}
