import gql from 'graphql-tag'

export const DASHBOARD_QUERY = gql`
  query DashboardQuery {
    submitted: orders(state: OPEN, status: SUBMITTED) {
      totalItems
    }
    confirmed: orders(state: OPEN, status: CONFIRMED) {
      totalItems
    }
    shipped: orders(state: OPEN, status: SHIPPED) {
      totalItems
    }
    cancelling: orders(state: OPEN, status: CANCEL_REQUESTED) {
      totalItems
    }
  }
`
