import React from 'react'
import {
  DesiredActivityAtTreatment,
  OrderFormData,
} from '../../Orders/OrderCreate/OrderFormDataTypes'
import { NestDataObject } from 'react-hook-form'
import { OrderFormClearErrorType, OrderFormErrorType } from '../../Orders'
import { NoOfVialsField } from './NoOfVialsField'
import { DesiredActivityFields } from './DesiredActivityFields'

interface Props {
  [key: string]: any
  noOfVials: number
  error?: string
  hint?: string
  isRequired?: boolean
  isDisabled?: boolean
  hidden?: boolean
  desiredActivityAtTreatmentSet: DesiredActivityAtTreatment[] | undefined
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
  errors: NestDataObject<OrderFormData>
  setError: OrderFormErrorType
  clearError: OrderFormClearErrorType
  isReadOnly?: boolean
}

export const NoOfVialsWidget: React.FC<Props> = ({
  noOfVials,
  desiredActivityAtTreatmentSet,
  setFormData,
  isDisabled,
  errors,
  setError,
  clearError,
  isReadOnly,
}) => {
  return (
    <>
      <NoOfVialsField
        noOfVials={noOfVials}
        desiredActivityAtTreatmentSet={desiredActivityAtTreatmentSet}
        setFormData={setFormData}
        errors={errors}
        setError={setError}
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
      />
      <DesiredActivityFields
        noOfVials={noOfVials}
        desiredActivityAtTreatmentSet={desiredActivityAtTreatmentSet}
        setFormData={setFormData}
        errors={errors}
        isReadOnly={isReadOnly}
      />
    </>
  )
}
