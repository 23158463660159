import gql from 'graphql-tag'

export const IP_INFO_QUERY = gql`
  query IPInfo {
    ipInfo {
      registrationLink
      locale
    }
  }
`
