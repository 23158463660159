import styled from '@emotion/styled'
import React from 'react'
import useMedia from 'use-media'
import { breakpoints, MEDIUM } from '../../themeUtils'

const Table = styled.table`
  width: 100%;
  font-size: 1.4rem;
`

const ResponsiveTable = styled.table`
  width: 200%;
  font-size: 1.4rem;
`

const ResponsiveDiv = styled.div`
  overflow-x: scroll;
`

interface Props {
  showDefault: boolean
  children: React.ReactNode
}

export const ResponsiveTableContainer: React.FC<Props> = ({ children, showDefault }) => {
  const isMediumScreen = useMedia({ minWidth: breakpoints[MEDIUM] })
  return isMediumScreen || showDefault ? (
    <Table>{children}</Table>
  ) : (
    <ResponsiveDiv>
      <ResponsiveTable>{children}</ResponsiveTable>
    </ResponsiveDiv>
  )
}
