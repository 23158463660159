import React from 'react'
import { Flex, FlexProps } from '@chakra-ui/core'

export const CenteredWrapper: React.FC<FlexProps> = ({
  children,
  width = '100%',
  padding = '0',
  ...props
}) => {
  return (
    <Flex
      width={width}
      padding={padding}
      direction="column"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      {children}
    </Flex>
  )
}
