import React from 'react'
import { Button, CustomButtonProps } from '../../Shared'
import { MEDIUM, mqNames, themeUtils } from '../../../themeUtils'
import styled from '@emotion/styled'
import { useOrderForm } from '../../../hooks'
import { useFeatureFlag } from '../../../context/FeatureFlagsContext'
import { OrderFormTypeEnum } from '../../../__generated__/globalTypes'
import { useTranslation } from 'react-i18next'

interface ButtonComponentProps extends CustomButtonProps {
  formType: OrderFormTypeEnum
}

const { darkBlue } = themeUtils.colors

const focusStyles = `
  border-radius: 4px;
  border: 1px solid rgba(0, 57, 104, 0.15);
  background: rgba(0, 57, 104, 0.1);
  text-decoration: none;
`

const unfocusedStyles = `
  border-radius: unset;
  border: unset;
  background: unset;
  text-decoration: underline;
`

const ButtonComponent = styled(Button)<ButtonComponentProps>`
  color: ${darkBlue};
  font-size: 16px;
  font-weight: 500;
  padding: 2rem;
  text-align: center;

  &:hover {
    color: ${darkBlue};
  }

  ${focusStyles}

  &:focus-within {
    ${focusStyles}
  }

  ${mqNames(MEDIUM)} {
    ${unfocusedStyles}

    &:focus-within {
      ${unfocusedStyles}
    }

    padding-right: ${props =>
      props.formType === OrderFormTypeEnum.VIAL_SELECTOR ? '2rem' : 'unset'};
  }
`

export const SwitchBetweenOrderForm: React.FC = () => {
  const { t } = useTranslation(['orderCreate'])
  const { formType, setFormType, hideSwitchFormLink } = useOrderForm()
  const TreatmentFocusedFormFeatureFlag = useFeatureFlag(
    'activity_at_treatment_focused'
  )

  const label =
    formType === OrderFormTypeEnum.VIAL_SELECTOR
      ? t('switchToActivity', { ns: ['orderCreate'] })
      : t('switchToVial', { ns: ['orderCreate'] })

  const onChangeForm = () => {
    setFormType(
      formType === OrderFormTypeEnum.VIAL_SELECTOR
        ? OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED
        : OrderFormTypeEnum.VIAL_SELECTOR
    )
  }

  if (hideSwitchFormLink || !TreatmentFocusedFormFeatureFlag) {
    return null
  }

  return (
    <>
      <ButtonComponent
        formType={formType}
        variant="link"
        _focus={undefined}
        onClick={onChangeForm}
        data-cy='switch-order-from-link-btn'
      >
        {label}
      </ButtonComponent>
    </>
  )
}
