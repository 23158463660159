import React from 'react'
import {
  FormErrorMessage as BaseFormErrorMessage,
  BoxProps,
} from '@chakra-ui/core'

export const FormErrorMessage: React.FC<BoxProps> = ({
  fontSize = '1.2rem',
  ...props
}) => (
  <BaseFormErrorMessage fontSize={fontSize} {...props} />
)
