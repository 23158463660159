import gql from 'graphql-tag'
import {FULL_PORTAL_USER_FRAGMENT} from "../fragments/PortalUserFragment";

export const UPDATE_PORTAL_USER_MUTATION = gql`
  mutation UpdatePortalUser($input: UpdatePortalUserInput!) {
    updatePortalUser(input: $input) {
      __typename
      ... on PortalUser {
        ...FullPortalUserFragment
      }
      ... on Error {
        errors {
          message
          path
          type
        }
      }
    }
  }
  ${FULL_PORTAL_USER_FRAGMENT}
`
