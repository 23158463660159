import { VialSearchResultOptionType } from "../components/Orders/OrderCreate/OrderFormDataTypes"
import { 
  VialSearch, 
  VialSearch_vialSearch_vials 
} from "../schema/queries/__generated__/VialSearch"

export const getVialsIfParamsDefined = (
  treatmentDate: Date | null | undefined,
  treatmentTime: string | undefined,
  selectedCalibrationDate: string | undefined,
  vialData: VialSearch | undefined
): VialSearch_vialSearch_vials[] | undefined => {
  return (treatmentDate &&
    treatmentTime &&
    selectedCalibrationDate &&
    vialData?.vialSearch?.vials) ||
  undefined
}

export const getVialsIfActivityTreatmentParamsDefined = (
  treatmentDate: Date | null | undefined,
  treatmentTime: string | undefined,
  vialData: VialSearchResultOptionType[] | undefined
): VialSearchResultOptionType[] | undefined => {
  return (treatmentDate &&
    treatmentTime &&
    vialData) ||
  undefined
}