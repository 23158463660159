import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { OrderShow_order } from '../../../schema/queries/__generated__/OrderShow'
import { Button, InputV2 } from '../../Shared'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_PO_NUMBER } from '../../../schema/mutations'
import {
  UpdatePoNumber,
  UpdatePoNumberVariables,
} from '../../../schema/mutations/__generated__/UpdatePoNumber'
import { handleErrors, Notification } from '../../../utils'
import { OrderStatusEnum } from '../../../__generated__/globalTypes'
import { PencilIcon } from '../../Icons'
import { themeUtils } from '../../../themeUtils'
import { Box, Flex, FlexProps, PseudoBox } from '@chakra-ui/core'
import { lighten } from 'polished'
import { useTranslation } from 'react-i18next'

interface Props {
  order: OrderShow_order
  refetch: Function
  canEdit: Boolean | undefined
  wrapperProps: FlexProps
}

const buttonStyles = {
  marginLeft: '1rem',
  height: 'auto',
  width: 'auto',
  minWidth: '6rem',
}

const { midnightBlue } = themeUtils.colors

export const PoNumberInlineForm: React.FC<Props> = ({
  order,
  refetch,
  canEdit=false,
  wrapperProps,
}) => {
  const { t } = useTranslation(['orderShowScreen'])
  const prevPropValueRef = useRef<OrderShow_order | null>(order);
  const [poNumberForState, setPoNumberForState] = useState(
    ([OrderStatusEnum.SHIPPED].includes(order.status)
      ? order.poNumberAfterShipped
        ? order.poNumberAfterShipped
        : order.poNumber
      : order.poNumber) || ''
  )

  useEffect(() => {
    if (
      prevPropValueRef.current !== null &&
      prevPropValueRef.current?.poNumber !== order.poNumber
    ) {
      setPoNumberForState(
        ([OrderStatusEnum.SHIPPED].includes(order.status)
          ? order.poNumberAfterShipped
            ? order.poNumberAfterShipped
            : order.poNumber
          : order.poNumber) || ''
      )
      prevPropValueRef.current = order
    }
  }, [order])

  const [editMode, setEditMode] = useState(false)
  const [updatePoNumber] = useMutation<UpdatePoNumber, UpdatePoNumberVariables>(
    UPDATE_PO_NUMBER
  )

  const resetField = () => {
    setEditMode(false)
    setPoNumberForState(order.poNumber || '')
  }

  const onUpdatePoNumber = async (value: string): Promise<void> => {
    if (!editMode) return

    const { data, errors } = await updatePoNumber({
      variables: {
        input: {
          id: order?.id,
          poNumber: value,
        },
      },
    })
    setEditMode(false)

    const successMessage = t('poNumForm.updated', { ns: ['orderShowScreen'] })
    refetch()

    if (errors) {
      errors.forEach(error => Notification.error(error.message))
    } else {
      if (data && data.updatePoNumber.__typename === 'Error') {
        handleErrors(data.updatePoNumber.errors)
      } else {
        refetch()
        Notification.success(successMessage)
      }
    }
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>): void =>
    setPoNumberForState(event.target.value)

  return (
    <Flex {...wrapperProps}>
      {editMode ? (
        <>
          <InputV2
            variant="outline"
            margin="0"
            type="text"
            placeholder={t('poNumForm.poInputPlaceholder', { ns: ['orderShowScreen'] })}
            id="po_number"
            data-cy="po_number"
            name="po_number"
            value={poNumberForState}
            onChange={onChange}
          />

          <Flex>
            <Button
              {...buttonStyles}
              onClick={() => onUpdatePoNumber(poNumberForState || '')}
            >
              {t('poNumForm.save', { ns: ['orderShowScreen'] })}
            </Button>

            <Button
              {...buttonStyles}
              backgroundColor={themeUtils.colors.red}
              onClick={() => resetField()}
            >
              {t('poNumForm.cancel', { ns: ['orderShowScreen'] })}
            </Button>
          </Flex>
        </>
      ) : (
        <>
          <Box as="span" cursor={canEdit? "pointer" : ""} onClick={canEdit ? () => setEditMode(true) : () =>{}}>
            {poNumberForState || t('poNumForm.missing', { ns: ['orderShowScreen'] })}
          </Box>
          <PseudoBox
            cursor="pointer"
            color={midnightBlue}
            _hover={{
              color: lighten(0.2, midnightBlue),
            }}
            onClick={() => setEditMode(!editMode)}
          >
            {!editMode && canEdit && <PencilIcon title={t('poNumForm.edit', { ns: ['orderShowScreen'] })} />}
          </PseudoBox>
        </>
      )}
    </Flex>
  )
}
