import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Box,
  Flex,
} from '@chakra-ui/core'
import {
  Button,
  WidgetHeader,
  ParagraphText,
  ModalOverlay,
  ModalContent,
  InlineErrorMessage,
} from '../../Shared'
import { CheckSquareIcon } from '../../Icons'
import { themeUtils } from '../../../themeUtils'
import { OrderFormData } from './OrderFormDataTypes'
import { Treatments, TreatmentsV2 } from './Treatments'
import { dateOnly } from '../../../utils'
import { CalibrationDates } from '../../../schema/queries/__generated__/CalibrationDates'
import { VialMapType } from '../../../context/VialSearchContext'
import { Accessories } from './Accessories'
import { ShippingWidget } from '../ShippingWidget'
import { ActivityAtTreatment } from './ActivityAtTreatment'
import { ShowExpeditedChargeMsg } from '../../Shared/ShowExpeditedChargeMsg'
import { ShowDeliveryChargesMsg } from '../../Shared/ShowDeliveryChargesMsg'
import { useUser } from '../../../context/user-context'
import { isUSCustomer } from '../../lib'
import { GetCustomer_customer } from '../../../schema/queries/__generated__/GetCustomer'
import { CheckDeliveryCharges_checkDeliveryCharges } from '../../../schema/queries/__generated__/CheckDeliveryCharges'
import { Icons } from '@chakra-ui/core/dist/theme/icons'
import { useOrderForm, useVialSearch } from '../../../hooks'
import { OrderFormTypeEnum } from '../../../__generated__/globalTypes'
import { Trans, useTranslation } from 'react-i18next'

const inlineErrorMessageStyleProps = {
  color: '#003968',
  icon: 'info' as Icons,
  backgroundColor: '#EBEFF3',
  padding: '8px 10px',
  borderRadius: '8px',
}

interface OrderConfirmationModalProps {
  isOpen: boolean
  submitting: boolean
  orderData: OrderFormData
  deliveryChargeData: CheckDeliveryCharges_checkDeliveryCharges | undefined
  calibrationWeekData: CalibrationDates | undefined
  customer: GetCustomer_customer | undefined
  selectedVials?: VialMapType
  mnxRushOrder: boolean
  createAccessoryOnlyOrder: boolean
  onOpen?: () => void
  onClose?: () => void
  onToggle?: () => void
  onConfirm: () => void
}
const { slateGrey, green, red } = themeUtils.colors

export const OrderConfirmationModal: React.FC<OrderConfirmationModalProps> = ({
  isOpen,
  submitting,
  onClose,
  onConfirm,
  orderData,
  deliveryChargeData,
  calibrationWeekData,
  selectedVials,
  mnxRushOrder,
  customer,
  createAccessoryOnlyOrder,
}) => {
  const { t } = useTranslation(['orderConfirmationModal', 'treatment'])
  const user = useUser()
  const { selectedActivityVials } = useVialSearch()
  const { formType } = useOrderForm()
  const treatmentDateOnly = !!orderData?.treatmentDate
    ? dateOnly(orderData.treatmentDate)
    : ''

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        maxWidth="62.5rem"
        maxHeight={{ base: '60rem', md: '72rem' }}
      >
        <ModalHeader padding="0" fontWeight="inherit" fontSize="inherit">
          <WidgetHeader color={slateGrey}>
            {t('header.main', { ns: ['orderConfirmationModal'] })}
          </WidgetHeader>
        </ModalHeader>
        <ModalBody borderTop="1px solid #EFEFEF" padding="2rem 0">
          <Flex flexDirection={{ base: 'column', md: 'row' }}>
            <Box flex="1">
              <ShippingWidget
                order={orderData}
                createAccessoryOnlyOrder={createAccessoryOnlyOrder}
              />
            </Box>
            <Box flex="2" overflow="auto" paddingLeft={{ base: 0, md: '2rem' }}>
              <WidgetHeader marginBottom="0rem">{t('header', { ns: ['treatment'] })}</WidgetHeader>
              {formType === OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED ? (
                <TreatmentsV2
                  treatmentDate={treatmentDateOnly}
                  treatmentTime={orderData?.treatmentTime}
                  singleRow
                />
              ) : (
                <Treatments
                  calibrationWeekData={calibrationWeekData}
                  selectedVials={selectedVials}
                  treatmentDate={treatmentDateOnly}
                  treatmentTime={orderData?.treatmentTime}
                  singleRow
                />
              )}
              <ActivityAtTreatment
                selectedVials={
                  formType === OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED
                    ? selectedActivityVials
                    : selectedVials
                }
                targetActivity={orderData?.targetActivityAtTreatment}
                totalActivity={orderData?.totalActivityAtTreatment}
              />
              {createAccessoryOnlyOrder && (
                <InlineErrorMessage {...inlineErrorMessageStyleProps}>
                  {t('accessory.noTreatments', { ns: ['treatment'] })}
                </InlineErrorMessage>
              )}
              <Accessories
                accessories={orderData?.adminSets}
                singleRow={true}
                marginTop="1rem"
              />
              {!!orderData?.targetActivityAtTreatment && (
                <ParagraphText marginY="1rem">
                  <Trans
                    i18nKey="activityAtTreatment.targetActivityWithVar"
                    ns="treatment"
                    values={{
                      targetActivityAtTreatment:
                        orderData.targetActivityAtTreatment,
                    }}
                    components={{ b: <b /> }}
                  />
                </ParagraphText>
              )}
              <ShowExpeditedChargeMsg
                mnxRushOrder={mnxRushOrder}
                showShippingError={
                  formType === OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED
                    ? orderData.activityFormShowShippingError
                    : orderData?.showShippingError
                }
                shippingTimeline={
                  formType === OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED
                    ? orderData.activityFormShippingTimeline
                    : orderData?.shippingTimeline
                }
              />
              {isUSCustomer(user) &&
                customer &&
                deliveryChargeData &&
                deliveryChargeData.applicableForCharges &&
                deliveryChargeData.key && (
                  <ShowDeliveryChargesMsg deliveryCharge={deliveryChargeData} />
                )}
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter borderTop="1px solid #EFEFEF" paddingTop="2rem">
          <Button
            variant="link"
            onClick={onClose}
            height="6rem"
            width="12rem"
            marginRight="1rem"
            color={red}
            data-cy="cancel-btn"
          >
            {t('button.cancelLabel', { ns: ['orderConfirmationModal'] })}
          </Button>
          <Button
            backgroundColor={green}
            marginTop="0"
            height="6rem"
            width="18rem"
            paddingX="2rem"
            paddingY="2rem"
            justifyContent="space-between"
            rightIcon={CheckSquareIcon}
            onClick={onConfirm}
            isLoading={submitting}
            loadingText={t('button.confirmLoading', {
              ns: ['orderConfirmationModal'],
            })}
            data-cy="confirm-btn"
          >
            {t('button.confirmLabel', { ns: ['orderConfirmationModal'] })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
