import React, { useState } from 'react'
import { useDisclosure } from '@chakra-ui/core'
import { useMedia } from 'use-media'
import { useMutation } from '@apollo/react-hooks'
import { differenceInHours } from 'date-fns'
import { toDate } from 'date-fns-tz'
import { CANCEL_ORDER_MUTATION } from '../../schema/mutations/OrderMutations'
import {
  CancelOrder,
  CancelOrderVariables,
} from '../../schema/mutations/__generated__/CancelOrder'
import { OrderShow_order } from '../../schema/queries/__generated__/OrderShow'
import { VOIDABLE_STATUSES } from '../../constants'
import { themeUtils } from '../../themeUtils'
import { Notification, handleErrors } from '../../utils'
import { Button } from '../Shared'
import { RequestCancellationModal } from '.'
import { LateCancellationModal } from '.'
import { CrossSquareIcon } from '../Icons'
import { useTranslation } from 'react-i18next'

interface Props {
  order: OrderShow_order
  refetch: Function
}


export const RequestCancellationButton: React.FC<Props> = props => {
  const { t } = useTranslation(['orderOverviewScreen'])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isXSmall = useMedia({ maxWidth: '375px' })
  const [selectedReason, setReason] = useState('')
  const orderNumber = props.order.orderNumber
  const orderStatus = props.order.status

  const CancelButtonText = t('cancelButton.text', { ns: ['orderOverviewScreen'] })

  const [cancelOrder, { loading }] = useMutation<
    CancelOrder,
    CancelOrderVariables
  >(CANCEL_ORDER_MUTATION, {
    variables: {
      input: {
        id: orderNumber,
        cancellationReasonId: selectedReason || '',
      },
    },
  })

  const onReasonSelect = (event: React.FormEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget
    setReason(value)
  }

  const onRequestCancellation = async (): Promise<void> => {
    const { data, errors } = await cancelOrder()
    const voidable = VOIDABLE_STATUSES.includes(props.order.status)
    const successMessage = voidable
      ? t('cancelMessages.successText', { ns: ['orderOverviewScreen'] })
      : t('cancelMessages.requested', { ns: ['orderOverviewScreen'] })
    if (errors) {
      errors.forEach(error => Notification.error(error.message))
    } else {
      if (data && data.cancelOrder.__typename === 'Error') {
        handleErrors(data.cancelOrder.errors)
      } else {
        props.refetch()
        Notification.success(successMessage)
      }
    }
  }

  const timeZone = 'America/New_York'
  const now = new Date()
  const shipDate = props.order.shipDate

  const zonedShipDate = toDate(shipDate + 'T12:00', { timeZone })

  // const canCancel = isBefore(now, zonedShipDate)
  const orderIsConfirmed = !!props.order.acceptedAt

  const diff = differenceInHours(zonedShipDate, now)
  const shipDateWithin72Hours = diff < 72

  const showRequestCancellationModal = !(
    orderIsConfirmed && shipDateWithin72Hours
  )

  // show late cancellation modal when:
  // - Order is CONFIRMED
  // - Ship date < 72 hours

  return (
    <>
      <Button
        backgroundColor={themeUtils.colors.red}
        leftIcon={CrossSquareIcon}
        isFullWidth={isXSmall}
        marginBottom={isXSmall ? '1rem' : '0'}
        // add back in one we have download button
        // marginRight={isXSmall ? '0' : '1rem'}
        onClick={onOpen}
      >
        {CancelButtonText}
      </Button>
      {showRequestCancellationModal ? (
        <RequestCancellationModal
          orderStatus={orderStatus}
          isOpen={isOpen}
          onClose={onClose}
          onReasonSelect={onReasonSelect}
          selectedReason={selectedReason}
          onSubmit={onRequestCancellation}
          loading={loading}
        />
      ) : (
        <LateCancellationModal
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={onRequestCancellation}
        />
      )}
    </>
  )
}
