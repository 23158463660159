/** @jsx jsx */
import React, { useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { RouteComponentProps, Link } from '@reach/router'
import { useMutation } from '@apollo/react-hooks'
import { RESET_PASSWORD_MUTATION } from '../../schema/mutations'
import {
  ResetPortalPassword,
  ResetPortalPasswordVariables,
} from '../../schema/mutations/__generated__/ResetPortalPassword'
import { FormControl, AlertIcon, Flex } from '@chakra-ui/core'
import { css, jsx } from '@emotion/core'
import { Alert, LoginContainer, Heading } from '../../components/Shared'
import { ErrorMessage, Input, SubmitButton } from '../../components/Forms'
import { DoneIcon } from '../../components/Icons'
import { LOGIN_PATH } from '../../routes'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'

type FormData = {
  email: string
  submissionError: string
}

const resendSchema = () => yup.object().shape({
  email: yup
    .string()
    .email(i18n.t('emailInput.validationError', { ns: ['resetPasswordScreen'] }))
    .required(i18n.t('emailInput.required', { ns: ['resetPasswordScreen'] })),
})

export const ResetPasswordScreen: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation(['resetPasswordScreen'])
  const {
    register,
    handleSubmit,
    setError,
    clearError,
    errors,
    reset,
    formState: { isSubmitting, isValid },
  } = useForm<FormData>({
    mode: 'onChange',
    validationSchema: resendSchema(),
  })
  const [success, setSuccess] = useState(false)
  const [resetPortalPassword] = useMutation<
    ResetPortalPassword,
    ResetPortalPasswordVariables
  >(RESET_PASSWORD_MUTATION)

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      clearError('submissionError')
      await resetPortalPassword({ variables: { input: { email } } })
      reset()
      setSuccess(true)
    } catch (error) {
      setError(
        'submissionError',
        'submission',
        t('submission.error', { ns: ['resetPasswordScreen'] })
      )
    }
  })

  const hasError = !!errors.email || !!errors.submissionError

  const showErrorMessage = () => {
    if (errors.submissionError) {
      return errors.submissionError.message
    }

    return errors.email && errors.email.message
  }

  return (
    <Flex direction="column">
      <Heading>{t('header.main', { ns: ['resetPasswordScreen'] })}</Heading>
      {success && (
        <Alert status="success" maxWidth="37.5rem">
          <AlertIcon />
          <p>
            {t('successAlert.successText', { ns: ['resetPasswordScreen'] })}
          </p>
        </Alert>
      )}
      <LoginContainer mt={{ base: '2rem', lg: '3rem' }}>
        <FormControl
          isInvalid={hasError}
          display="flex"
          justifyContent={{ base: 'flex-start', lg: 'center' }}
        >
          <ErrorMessage mt={{ lg: '-3.5rem' }}>
            {showErrorMessage()}
          </ErrorMessage>
        </FormControl>
        <form
          id="confirmAccountReset"
          autoComplete="off"
          acceptCharset="UTF-8"
          noValidate
          onSubmit={onSubmit}
        >
          <FormControl>
            <Input
              autoFocus
              variant="outline"
              type="email"
              placeholder={t('emailInput.placeholder', { ns: ['resetPasswordScreen'] })}
              id="email"
              data-cy="email"
              name="email"
              isDisabled={success}
              ref={register({
                required: true,
              })}
            />
          </FormControl>
          <Link
            css={css`
              font-size: 1.4rem;
              padding-right: 2rem;
              float: right;
            `}
            to={LOGIN_PATH}
          >
            {t('loginLink.linkText', { ns: ['resetPasswordScreen'] })}
          </Link>
          <SubmitButton
            isLoading={isSubmitting}
            isDisabled={!isValid}
            isFullWidth
            loadingText={t('submission.loadingText', { ns: ['resetPasswordScreen'] })}
            rightIcon={DoneIcon}
            justifyContent={isSubmitting ? 'center' : 'space-between'}
          >
            {t('submission.buttonText', { ns: ['resetPasswordScreen'] })}
          </SubmitButton>
        </form>
      </LoginContainer>
    </Flex>
  )
}
