import React from 'react'
import { IconProps } from '../../types'
import styled from '@emotion/styled'

type Props = IconProps & React.SVGAttributes<SVGElement>

const Svg = styled.svg`
  flex-shrink: 0;
  backface-visibility: hidden;
  &:not(:root) {
    overflow: hidden;
  }
`

const CrossSquareIcon: React.FC<Props> = ({
  fill = '#fff',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      {...rest}
    >
      <g>
        <g>
          <path
            d="M0,1.6c0,-0.8832 0.716,-1.6 1.6,-1.6h12.8c0.8832,0 1.6,0.7168 1.6,1.6v12.8c0,0.884 -0.7168,1.6 -1.6,1.6h-12.8c-0.884,0 -1.6,-0.716 -1.6,-1.6v-12.8M6.86863,8l-1.69706,-1.69706l1.13137,-1.13137l1.69706,1.69706l1.69706,-1.69706l1.13137,1.13137l-1.69706,1.69706l1.69706,1.69706l-1.13137,1.13137l-1.69706,-1.69706l-1.69706,1.69706l-1.13137,-1.13137zM13.6,14.4c0.4416,0 0.8,-0.3576 0.8,-0.8v-11.2c0,-0.4416 -0.3584,-0.8 -0.8,-0.8h-11.2c-0.4416,0 -0.8,0.3584 -0.8,0.8v11.2c0,0.4424 0.3584,0.8 0.8,0.8h11.2"
            fill={fill}
          ></path>
        </g>
      </g>
    </Svg>
  )
}

export { CrossSquareIcon }
