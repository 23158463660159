import gql from 'graphql-tag'

export const GET_COUNTRY_ALERTS_QUERY = gql`
  query GetCountryAlerts {
    countryAlerts {
      name
      isEnabled
      cutOffTime
      amTreatmentWindow
      defaultMessageEnabled
      timezone
      alertMessage
    }
  }
`
