import { Nav } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { ENVIRONMENT_QUERY } from "../../../schema/queries";

const EnvHeader: React.FC = () => {
  const [name, setName] = useState('')
  const [color, setColor] = useState('')
  const bannerBackgroundColors = {
    'snd': '#FFAA00',
    'staging': '#5AB85B'
  }
  const envNames = {
    'snd': 'SND',
    'staging': 'Staging'
  }

  const response = useQuery(ENVIRONMENT_QUERY);

  useEffect(() => {
    if(response.data) {
      let envName:string = response.data.environmentName
      // @ts-ignore
      setName(envNames[envName])
      // @ts-ignore
      setColor(bannerBackgroundColors[envName])
    }
  }, [response])
  return (
    <div>
      { name &&
        <Nav style={{
          backgroundColor: '#fff',
          display: 'inline-block',
          textAlign: 'center',
          padding: '0px',
          width: '100%',
          zIndex: 0
        }}>
          <h1 style={{ color: '#fff', backgroundColor: `${color}`, margin: '0px', height: '4rem' }}>
            { name }
          </h1>
        </Nav>
      }
    </div>
  )
}

export { EnvHeader }
