import React from 'react'
import { ButtonProps, IconButton } from '@chakra-ui/core'
import { Icons } from '@chakra-ui/core/dist/theme/icons'
import { useMedia } from 'use-media'
import { Button } from '.'
import { breakpoints, SMALL, themeUtils } from '../../themeUtils'
import { darken } from 'polished'

interface CustomProps {
  ariaLabel: string
  breakpoint?: string
  responsiveIcon: Icons | React.ComponentType
}

type Props = ButtonProps & CustomProps

const ResponsiveButton: React.FC<Props> = ({
  backgroundColor = themeUtils.colors.green,
  ariaLabel,
  boxShadow = {
    base: 'none',
    sm: '0 10px 15px rgba(69, 218, 122, 0.2);',
  },
  display = 'flex',
  responsiveIcon,
  leftIcon,
  rightIcon,
  iconSpacing = '1rem',
  breakpoint = breakpoints[SMALL],
  ...rest
}) => {
  const isSmall = useMedia({ minWidth: breakpoint })

  return (
    <>
      {isSmall && (
        <Button
          display={display}
          backgroundColor={backgroundColor}
          boxShadow={boxShadow}
          aria-label={ariaLabel}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          iconSpacing={iconSpacing}
          {...rest}
        />
      )}
      {!isSmall && (
        <IconButton
          aria-label={ariaLabel}
          backgroundColor={backgroundColor}
          _hover={{ backgroundColor: darken(0.1, `${backgroundColor}`) }}
          color={themeUtils.colors.white}
          display={display}
          icon={responsiveIcon}
          height="3.8rem"
          width="3.8rem"
          isRound
          {...rest}
        />
      )}
    </>
  )
}

export { ResponsiveButton }
