import React, { Dispatch, SetStateAction } from 'react'
import { Grid, GridProps } from '@chakra-ui/core'
import { AdminSetType, OrderFormData } from './OrderFormDataTypes'
import { Accessory } from './Accessory'
import { WidgetHeader } from '../../Shared'
import { useTranslation } from 'react-i18next'

interface Props extends GridProps {
  accessories: Array<AdminSetType>
  setFormData?: Dispatch<SetStateAction<OrderFormData>>
  singleRow?: boolean
  removeable?: boolean
}

export const Accessories: React.FC<Props> = ({
  accessories,
  setFormData,
  singleRow = false,
  removeable = false,
  ...props
}) => {
  const { t } = useTranslation(['treatment'])
  if (!accessories.length) return null

  const confirmedSets = accessories.filter(accessory => accessory.confirmed)

  const gridTemplateColumns = singleRow
    ? '1fr'
    : { base: '1fr', md: '1fr 1fr', lg: '1fr' }

  return (
    <Grid
      width="100%"
      gridGap="1rem"
      gridTemplateColumns={gridTemplateColumns}
      marginBottom="1rem"
      marginTop="2rem"
      {...props}
    >
      {confirmedSets.length > 0 && (
        <WidgetHeader marginBottom="0rem">
          {t('accessory.header', { ns: ['treatment'] })}
        </WidgetHeader>
      )}
      {confirmedSets.map((adminSet, index) => {
        return (
          <Accessory
            key={adminSet.lineItemNumber}
            setAccessoryData={setFormData}
            accessory={adminSet}
            accessories={accessories}
            index={index}
            removeable={removeable}
            maxWidth="100%"
          />
        )
      })}
    </Grid>
  )
}
