import React, { forwardRef, ReactChild } from 'react'
import { Box, BoxProps } from '@chakra-ui/core'

type Props = BoxProps & ReactChild

const Heading = forwardRef((props: Props, ref) => {
  const { children } = props
  return (
    <Box ref={ref} as="h1" {...props}>
      {children}
    </Box>
  )
})

Heading.displayName = 'Heading'

export { Heading }
