import React, { useState } from 'react'
import { Step1 } from './Step1'
import { OrderFormData } from '../OrderCreate/OrderFormDataTypes'
import { ManualFieldError, MultipleFieldErrors, useForm } from 'react-hook-form'
import { Step2, Step3 } from '..'
import { ccToday } from '../../lib'
import { CheckDeliveryCharges } from '../../../schema/queries/__generated__/CheckDeliveryCharges'
import { GetCustomer } from '../../../schema/queries/__generated__/GetCustomer'
import { Option } from '../../Shared'

export interface OrderFormErrorType {
  (name: string, type: string, message?: string | undefined): void
  (
    name: string | ManualFieldError<OrderFormData>[],
    type: string | MultipleFieldErrors,
    message?: string | undefined
  ): void
}

export interface OrderFormClearErrorType {
  (): void
  (name: string): void
  (names: string[]): void
  (name?: string | string[] | undefined): void
}

interface Props {
  formData: OrderFormData
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
  onChangeCheckBoxValue: (val: boolean) => void
  createAccessoryOnlyOrder: boolean
  changeTreatmentDate: (date: Date | null) => void
  minOrderTreatmentDate(isMnxEligible: boolean): Date
  isMnxEligibleOrder: () => boolean
  isMnxTreatmentDate: () => boolean
  deliveryChargeData: CheckDeliveryCharges | undefined
  customerData: GetCustomer | undefined
}

export const OrderNewWidget: React.FC<Props> = ({ ...props }) => {
  const [screenOpenedAt] = useState(new Date())
  const { errors, clearError, setError } = useForm<OrderFormData>()

  return (
    <>
      <Step1
        errors={errors}
        setError={setError}
        clearError={clearError}
        {...props}
      />
      <Step2
        errors={errors}
        setError={setError}
        clearError={clearError}
        {...props}
      />
      <Step3
        errors={errors}
        setError={setError}
        clearError={clearError}
        screenOpenedAt={screenOpenedAt}
        {...props}
      />
    </>
  )
}
