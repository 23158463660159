import {
  CustomerBusinessTypeEnum,
  RegionCodeEnum,
} from '../../__generated__/globalTypes'
import { GetMyPortalUser_me } from '../../context/__generated__/GetMyPortalUser'

export const isCustomerInRegion = (
  user: GetMyPortalUser_me | null,
  regionCode: RegionCodeEnum
): boolean => {
  return user?.customer.region.code === regionCode
}

export const isUSCustomer = (user: GetMyPortalUser_me | null): boolean =>
  isCustomerInRegion(user, RegionCodeEnum.US)
export const isROWCustomer = (user: GetMyPortalUser_me | null): boolean =>
  isCustomerInRegion(user, RegionCodeEnum.ROW)
export const isCACustomer = (user: GetMyPortalUser_me | null): boolean =>
  isCustomerInRegion(user, RegionCodeEnum.CA)

export const isDistributor = (user: GetMyPortalUser_me | null): boolean => {
  return user?.businessType === CustomerBusinessTypeEnum.Distributor
}
