/** @jsx jsx */
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { RouteComponentProps, Link } from '@reach/router'
import { useAuth } from '../../context/auth-context'
import { css, jsx } from '@emotion/core'
import { FormControl, ControlBox, Flex, Box, Text } from '@chakra-ui/core'
import {
  LoginContainer,
  Heading,
  VisuallyHidden,
} from '../../components/Shared'
import { themeUtils } from '../../themeUtils'
import { RESET_PASSWORD_PATH } from '../../routes'
import { ErrorMessage, Input, SubmitButton } from '../../components/Forms'
import { LoginCheckIcon, DoneIcon } from '../../components/Icons'
import { ConfirmPopupValues, LocalStorageKeys } from '../../types'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'

type FormData = {
  email: string
  password: string
  rememberMe: boolean
  submissionError: string
}

const loginSchema = () => yup.object().shape({
  email: yup
    .string()
    .email(i18n.t('emailInput.validationError', { ns: ['loginScreen'] }))
    .required(i18n.t('emailInput.requiredError', { ns: ['loginScreen'] })),
  password: yup
    .string()
    .required(i18n.t('passwordInput.requiredError', { ns: ['loginScreen'] })),
})

export const LoginScreen: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation(['common', 'loginScreen'])
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearError,
    formState: { isSubmitting, isValid },
  } = useForm<FormData>({
    mode: 'onChange',
    validationSchema: loginSchema(),
  })

  const authContext = useAuth()
  if (!authContext) return null
  const { login } = authContext

  const setSubmissonError = (errorMsg: string): void =>
    setError('submissionError', 'submission', errorMsg)

  const submitLogin = handleSubmit(async ({ email, password }) => {
    try {
      const result = await login(email, password)
      clearError('submissionError')
      if (result === null) {
        setSubmissonError(t('error.noAccount', { ns: ['loginScreen'] }))
      } else if (
        result &&
        result.data.me &&
        result.data.me.loginCount <= 3 &&
        !result.data.me.hasUpdatedDetails
      ) {
        localStorage.setItem(
          LocalStorageKeys.CONFIRM_USER_DETAILS_POPUP,
          ConfirmPopupValues.ENABLED
        )
      }
    } catch (error) {
      setSubmissonError(t('error.apiFailure', { ns: ['loginScreen'] }))
    }
  })

  return (
    <Flex direction="column">
      <Heading>{t('header.main', { ns: ['loginScreen'] })}</Heading>
      <Text mb="1rem">{t('header.sub', { ns: ['loginScreen'] })}</Text>
      <LoginContainer mt={{ base: '2rem', lg: '3rem' }}>
        <FormControl
          isInvalid={
            !!errors.email || !!errors.password || !!errors.submissionError
          }
          display="flex"
          justifyContent={{ base: 'flex-start', lg: 'center' }}
        >
          <ErrorMessage mt={{ lg: '-3.5rem' }}>
            {errors.email
              ? errors.email.message
              : errors.password
              ? errors.password.message
              : errors.submissionError && errors.submissionError.message}
          </ErrorMessage>
        </FormControl>
        <form
          autoComplete="off"
          acceptCharset="UTF-8"
          data-cy="login-form"
          noValidate
          onSubmit={submitLogin}
        >
          <FormControl isInvalid={!!errors.submissionError}>
            <Input
              variant="outline"
              type="email"
              placeholder={t('emailInput.placeholder', { ns: ['loginScreen'] })}
              id="email"
              data-cy="email"
              name="email"
              ref={register({
                required: true,
              })}
            />
          </FormControl>
          <FormControl isInvalid={!!errors.submissionError}>
            <Input
              type="password"
              placeholder={t('passwordInput.placeholder', {
                ns: ['loginScreen'],
              })}
              id="password"
              name="password"
              data-cy="password"
              ref={register({
                required: true,
              })}
            />
          </FormControl>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <FormControl>
              <label>
                <VisuallyHidden
                  type="checkbox"
                  as="input"
                  name="rememberMe"
                  ref={register}
                />

                <ControlBox
                  aria-hidden="false"
                  size="4rem"
                  rounded="full"
                  backgroundColor={themeUtils.colors.borderGrey}
                  boxShadow={{
                    base: '0 8px 10px rgba(69, 218, 122, 0.2)',
                    lg: 'none',
                  }}
                  _child={{ opacity: 1 }}
                  _checked={{
                    bg: themeUtils.colors.green,
                    color: themeUtils.colors.white,
                    borderColor: themeUtils.colors.green,
                  }}
                  _focus={{
                    borderColor: themeUtils.colors.green,
                    boxShadow: 'outline',
                  }}
                  cursor="pointer"
                >
                  <LoginCheckIcon />
                </ControlBox>

                <Box
                  as="span"
                  verticalAlign="middle"
                  display="inline-block"
                  height="3rem"
                  ml="1.4rem"
                  color={themeUtils.colors.slateGrey}
                  fontSize="1.4rem"
                  cursor="pointer"
                >
                  {t('rememberMeCheckbox.label', { ns: ['loginScreen'] })}
                </Box>
              </label>
            </FormControl>
            <span
              css={css`
                border-width: 1px;
                height: 1.4rem;
              `}
            />
            <Link
              css={css`
                font-size: 1.4rem;
                padding-right: 2rem;
              `}
              to={RESET_PASSWORD_PATH}
            >
              {t('redirectLink.forgotPassword', { ns: ['common'] })}
            </Link>
          </div>
          <SubmitButton
            isLoading={isSubmitting}
            isDisabled={!isValid}
            isFullWidth
            loadingText={t('signinButton.locadingText', {
              ns: ['loginScreen'],
            })}
            rightIcon={DoneIcon}
            justifyContent={isSubmitting ? 'center' : 'space-between'}
          >
            {t('signinButton.label', { ns: ['loginScreen'] })}
          </SubmitButton>
        </form>
      </LoginContainer>
    </Flex>
  )
}
