import React from 'react'
import { Box, Stack, Text } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { LARGE, mqNames, themeUtils } from '../../../themeUtils'
import {
  OrderShow,
  OrderShow_order,
  OrderShow_order_accessories,
  OrderShow_order_treatments,
} from '../../../schema/queries/__generated__/OrderShow'
import { Treatment } from './Treatment'
import { ParagraphText } from '../../Shared'
import { Accessory } from './Accessory'
import { dateOnly } from '../../../utils'
import { totalActivityCalc } from '../../lib'
import { useUser } from '../../../context/user-context'
import { useTranslation } from 'react-i18next'

const { midnightBlue, slateGrey } = themeUtils.colors

type Props = OrderShow

const TreatmentStack = styled(Stack)`
  overflow: initial;

  ${mqNames(LARGE)} {
    max-height: 45.5rem;
    overflow: auto;
  }
`

const renderAccessories = (
  accessories: OrderShow_order_accessories[]
): JSX.Element[] =>
  accessories.map((accessory, index) => (
    <Accessory
      key={accessory.lineItemNumber}
      accessory={accessory}
      index={index}
    />
  ))

const renderTreatments = (
  treatments: OrderShow_order_treatments[],
  treatmentDate: string,
  treatmentTime: string
): JSX.Element[] =>
  treatments.map(treatment => (
    <Treatment
      key={treatment.lineItemNumber}
      treatment={treatment}
      treatmentDate={treatmentDate}
      treatmentTime={treatmentTime}
    />
  ))

const renderTreatmentItems = (order: OrderShow_order): JSX.Element[] => {
  const accessories = order.accessories
  const treatments = order.treatments
  const treatmentItems =
    treatments &&
    renderTreatments(treatments, order.treatmentDate, order.treatmentTime)
  const AccessoryItems = accessories && renderAccessories(accessories)
  // joined like this otherwise the Chakra UI
  // Stack component spacing logic breaks
  return treatmentItems.concat(AccessoryItems)
}

const TreatmentsWidget: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation(['treatment'])
  const user = useUser()
  if (!order) return null
  const siteTimezone = user?.preferredShippingAddress.timezone || ''
  const totalActivityAtTreatment = totalActivityCalc(
    dateOnly(order.treatmentDate),
    order.treatmentTime,
    order.treatments,
    siteTimezone
  )
  return (
    <Box>
      <TreatmentStack spacing={4} shouldWrapChildren>
        {renderTreatmentItems(order)}
      </TreatmentStack>
      {totalActivityAtTreatment > 0 && (
        <ParagraphText color={slateGrey} margin-bottom="1rem">
          {t('activityAtTreatment.totalEstimated', { ns: ['treatment'] })}{' '}
          <Box as="strong" color={midnightBlue}>
            {totalActivityAtTreatment}{' '}
            {t('unit', {
              ns: ['treatment'],
            })}
          </Box>
        </ParagraphText>
      )}
    </Box>
  )
}

export { TreatmentsWidget }
