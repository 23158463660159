import React from 'react'
import styled from '@emotion/styled'
import { themeUtils } from '../../../themeUtils'

const { slateGrey } = themeUtils.colors

const FieldLabelStyle = styled.label`
  align-self: stretch;
  color: ${slateGrey};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
`

export const FieldLabel: React.FC = ({ children }) => {
  return <FieldLabelStyle>{children}</FieldLabelStyle>
}
