import React from 'react'
import styled from '@emotion/styled'
import { Box, Divider, Flex } from '@chakra-ui/core'
import { MEDIUM, mqNames, themeUtils } from '../../../themeUtils'
import { VialOption } from './VialOption'
import { VialSearchResultOptionType } from '../OrderCreate/OrderFormDataTypes'
import { OrderFormStepType } from '../../../context/OrderFormContext'
import { NoAvailableVials } from './NoAvailableVials'
import { useOrderForm } from '../../../hooks'
import { useTranslation } from 'react-i18next'

const { midnightBlue } = themeUtils.colors

interface Props {
  vialNumber: number
  vialActivityValue: string
  options: VialSearchResultOptionType[]
}

const Container = styled(Box)<{ step: OrderFormStepType }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;

  ${mqNames(MEDIUM)} {
    border-radius: ${props => (props.step !== OrderFormStepType.STEP_3  ? '5px' : 'unset')};
    border: ${props => (props.step !== OrderFormStepType.STEP_3  ? '1px solid #d7dde1' : 'unset')};
    box-shadow: unset;
    padding: ${props => (props.step !== OrderFormStepType.STEP_3  ? '15px' : '0')};
  }
`
const Header = styled.label`
  color: ${midnightBlue};
  font-size: 16px;
  font-weight: 600;
`

const Scroll = styled(Flex)`
  flex-direction: column;
  gap: 10px;
  width: 100%;

  ${mqNames(MEDIUM)} {
    overflow-y: auto;
    max-height: 57rem;
  }
`

const DividerStyle = styled(Divider)`
  ${mqNames(MEDIUM)} {
    display: none;
  }
`

export const TreatmentVialOption: React.FC<Props> = ({
  vialNumber,
  vialActivityValue,
  options,
}) => {
  const { t } = useTranslation(['orderCreate'])
  const { step } = useOrderForm()
  const key = `${vialActivityValue}-${vialNumber}`
  return (
    <Container step={step} data-cy={`${key}-container`}>
      {vialNumber !== 0 && (
        <DividerStyle
          height={'1px'}
          width={'100%'}
          border={1}
          background={'rgba(0, 57, 104, 0.25)'}
          margin={'0'}
        />
      )}
      <Header>
        {t('activityFocused.vialOptions.optionHeader', {
          vialNumber: vialNumber + 1,
          vialActivityValue,
        })}
      </Header>
      <Scroll>
        {options && options.length > 0 ? (
          options.map((option, index) => {
            const key = `vial${vialNumber + 1}-${index}`
            return (
              <VialOption
                id={key}
                key={key}
                optionParentKey={`vial${vialNumber + 1}`}
                option={option}
                vialActivityValue={vialActivityValue}
              />
            )
          })
        ) : (
          <NoAvailableVials />
        )}
      </Scroll>
    </Container>
  )
}
