import React from 'react'
import { Box, BoxProps } from '@chakra-ui/core'
import { themeUtils } from '../../themeUtils'
import { ParagraphText } from './ParagraphText'

interface Props extends BoxProps {
  title: string
  color?: string
}

const { slateGrey } = themeUtils.colors

export const DetailText: React.FC<Props> = ({ title, children, color = slateGrey, width = {base: '50%', md: '100%'} }) => {
  return (
    <Box marginBottom="2rem" width={width}>
      {<ParagraphText variant="title">{title}</ParagraphText>}
      {children && <ParagraphText color={color}>{children}</ParagraphText>}
    </Box>
  )
}
