import React, { ReactElement } from 'react'
import { Grid } from '@chakra-ui/core'
import { VialMapType } from '../../../context/VialSearchContext'
import { themeUtils } from '../../../themeUtils'
import { Treatment } from './Treatment'
import { CalibrationDates } from '../../../schema/queries/__generated__/CalibrationDates'
import { useVialSearch } from '../../../hooks'

const { midnightBlue, moonstone } = themeUtils.colors

interface Props {
  calibrationWeekData: CalibrationDates | undefined
  selectedVials: VialMapType | undefined
  treatmentDate: string | undefined
  treatmentTime: string | undefined
  singleRow?: boolean
  removeable?: boolean
}

interface PropsV2 {
  treatmentDate: string | undefined
  treatmentTime: string | undefined
  singleRow?: boolean
  removeable?: boolean
}

interface WeekMap {
  [key: string]: {
    week: string
    backgroundColor: string
  }
}

export const Treatments: React.FC<Props> = ({
  calibrationWeekData,
  selectedVials,
  treatmentDate,
  treatmentTime,
  singleRow = false,
  removeable = false,
}) => {
  const calibrationWeeks =
    calibrationWeekData?.calibrationDates?.calibrationWeeks
  if (
    !treatmentDate ||
    !treatmentTime ||
    !selectedVials ||
    selectedVials.size === 0 ||
    !calibrationWeeks
  )
    return null

  const selectedDoses = Array.from(selectedVials.keys())
  const sortedDoses = selectedDoses.sort((a, b) => a - b)

  const weekObj: WeekMap = {}
  calibrationWeeks.forEach(result => {
    const week = result.calibrationWeek
    const backgroundColor = week === '1' ? midnightBlue : moonstone

    weekObj[result.calibrationDate] = {
      week,
      backgroundColor,
    }
  })

  const gridTemplateColumns = singleRow
    ? '1fr'
    : { base: '1fr', md: '1fr 1fr', lg: '1fr' }

  return (
    <Grid width="100%" gridGap="1rem" gridTemplateColumns={gridTemplateColumns} data-cy="selected-treatments-container">
      {sortedDoses.map((dosage: number) => {
        const vialData = selectedVials.get(dosage)

        if (vialData) {
          const treatmentList = Array.from(vialData.entries()).sort()

          return treatmentList.map(
            ([calibrationDate, quantity]: [string, number]): ReactElement[] => {
              return [...Array(quantity)].map((_item, index) => {
                const key = `${dosage}-${calibrationDate}-${index}`
                const weekData = weekObj[calibrationDate]

                return (
                  <Treatment
                    key={key}
                    keyProp={key}
                    dosage={dosage}
                    quantity={quantity}
                    calibrationDate={calibrationDate}
                    treatmentDate={treatmentDate}
                    treatmentTime={treatmentTime}
                    maxWidth="100%"
                    backgroundColor={weekData && weekData.backgroundColor}
                    week={weekData && weekData.week}
                    removeable={removeable}
                  />
                )
              })
            }
          )
        }
      })}
    </Grid>
  )
}


export const TreatmentsV2: React.FC<PropsV2> = ({
  treatmentDate,
  treatmentTime,
  singleRow = false,
  removeable = false,
}) => {
  const { getAllSelectedVialOption } = useVialSearch()

  if (
    !treatmentDate ||
    !treatmentTime
  )
    return null
  
  const selectedVials = getAllSelectedVialOption()
  const sortedDoses = selectedVials.slice().sort((a, b) => a.dosage - b.dosage);

  const gridTemplateColumns = singleRow
    ? '1fr'
    : { base: '1fr', md: '1fr 1fr', lg: '1fr' }

  return (
    <Grid width="100%" gridGap="1rem" gridTemplateColumns={gridTemplateColumns} data-cy="selected-treatments-container">
      {sortedDoses.map((vialData, index) => {
        const {dosage, calibration_date, calibration_week, treatment_activity} = vialData
        if (vialData) {
          const key = `${dosage}-${calibration_date}-${index}`

          return (
            <Treatment
              key={key}
              dosage={dosage}
              quantity={1}
              calibrationDate={calibration_date}
              treatmentDate={treatmentDate}
              treatmentTime={treatmentTime}
              treatmentActivity={treatment_activity}
              maxWidth="100%"
              backgroundColor={calibration_week === 1 ? midnightBlue : moonstone}
              week={calibration_week.toString()}
              removeable={removeable}
            />
          )
        }
      })}
    </Grid>
  )
}