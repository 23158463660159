import React from 'react'
import { Grid, Box, BoxProps, GridProps } from '@chakra-ui/core'
import { format, parse } from 'date-fns'
import styled from '@emotion/styled'
import { formatDate } from '../../../utils'
import { DAY_OF_WEEK_FORMAT } from '../../../constants'
import { mqNames, MEDIUM, SMALL } from '../../../themeUtils'
import { OrderShow_order } from '../../../schema/queries/__generated__/OrderShow'
import { DetailText } from '../../Shared'
import {TreatingPhysicianForm} from "./TreatingPhysicianForm";
import { useFeatureFlag } from "../../../context/FeatureFlagsContext";
import { useTranslation } from 'react-i18next'
import { PatientIntentForm } from './PatientIntentForm'
import { isROWCustomer } from '../../lib'
import { useUser } from '../../../context/user-context'
import { useAuth } from '../../../context/auth-context'
import { getTimeFormat } from '../../../i18n'

interface Props {
  order: OrderShow_order
  refetch: Function
}

const ShippingWrapper = styled(Box)<BoxProps>`
  max-width: 100%;

  p {
    font-size: 1.5rem;
  }
`
const OrderDetailsGrid = styled(Grid)<GridProps>`
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  ${mqNames(SMALL)} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`

export const OrderShowShippingWidget: React.FC<Props> = ({ order, refetch }) => {
  const { t } = useTranslation(['orderShowScreen'])
  const user = useUser()
  const auth = useAuth()
  const canEditOrders = auth?.user?.canEditOrders
  const treatingPhysicianFeatureFlag = useFeatureFlag('use_treating_physician')
  if (!order) return null
  const treatmentTime = parse(order.treatmentTime, 'HH:mm', new Date())
  const IsAccessoryOnlyOrder: boolean = !order.treatments.length

  return (
    <ShippingWrapper>
      <OrderDetailsGrid>
        <DetailText
          title={t('showShippingWidget.orderNo', { ns: ['orderShowScreen'] })}
        >
          {order.orderNumber}
        </DetailText>
        <DetailText
          title={t('showShippingWidget.patientRef', {
            ns: ['orderShowScreen'],
          })}
        >
          {order?.patientRef ||
            t('showShippingWidget.tbd', { ns: ['orderShowScreen'] })}
        </DetailText>
        <DetailText
          title={t('showShippingWidget.treatmentDate', {
            ns: ['orderShowScreen'],
          })}
        >
          {order?.treatmentDate &&
            formatDate(order.treatmentDate, DAY_OF_WEEK_FORMAT)}
        </DetailText>
        <DetailText
          title={t('showShippingWidget.treatmentTime', {
            ns: ['orderShowScreen'],
          })}
        >
          {format(treatmentTime, getTimeFormat())}
        </DetailText>
      </OrderDetailsGrid>
      {treatingPhysicianFeatureFlag && (
        <DetailText
          title={t('showShippingWidget.treatingPhys', {
            ns: ['orderShowScreen'],
          })}
          width={'100%'}
        >
          <TreatingPhysicianForm
            order={order}
            refetch={refetch}
            canEdit={canEditOrders}
            wrapperProps={{
              width: '100%',
              marginTop: { base: '1rem', sm: 0 },
              marginBottom: '1rem',
            }}
          ></TreatingPhysicianForm>
        </DetailText>
      )}
      {isROWCustomer(user) && !IsAccessoryOnlyOrder && (
        <DetailText
          title={t('showShippingWidget.patientIntent', {
            ns: ['orderShowScreen'],
          })}
          width={'100%'}
        >
          <PatientIntentForm
            order={order}
            refetch={refetch}
            canEdit={canEditOrders}
            wrapperProps={{
              width: '100%',
              marginTop: { base: '1rem', sm: 0 },
              marginBottom: '1rem',
            }}
          />
        </DetailText>
      )}
    </ShippingWrapper>
  )
}
