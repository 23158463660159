import React, { ReactNode } from 'react'
import { PaginationContainer, PaginationLink } from '.'
import { ScreenReaderOnlyText, ScreenReaderHiddenText } from '../'
import { Flex, ListItem } from '@chakra-ui/core'

interface Props {
  currentPage: number
  totalPages: number
  windowSize?: number
  ariaLabel: string
  onClick: (page: number) => void
}

const CustomPagination: React.FC<Props> = ({
  currentPage,
  totalPages,
  windowSize = 4,
  ariaLabel,
  onClick,
}) => {
  if (totalPages < 2) return null

  let firstPage: ReactNode
  let previousPage: ReactNode
  let leftEllipsis: ReactNode
  let nextPage: ReactNode
  let lastPage: ReactNode
  let rightEllipsis: ReactNode

  if (currentPage > 1) {
    firstPage = (
      <ListItem>
        <PaginationLink aria-label="First page" onClick={() => onClick(1)}>
          <ScreenReaderHiddenText>&laquo;</ScreenReaderHiddenText>
          <ScreenReaderOnlyText>First</ScreenReaderOnlyText>
        </PaginationLink>
      </ListItem>
    )
  }

  if (!(currentPage - 1 <= 0)) {
    previousPage = (
      <ListItem>
        <PaginationLink
          aria-label="Previous page"
          aria-disabled="false"
          aria-current="false"
          onClick={() => onClick(currentPage - 1)}
        >
          <ScreenReaderHiddenText>‹</ScreenReaderHiddenText>
          <ScreenReaderOnlyText>Prev</ScreenReaderOnlyText>
        </PaginationLink>
      </ListItem>
    )
  }

  const pageWindow = []
  let idx1 = currentPage - windowSize

  while (idx1 < currentPage) {
    if (idx1 >= 1) {
      pageWindow.push(idx1)
    }
    idx1++
  }

  pageWindow.push(currentPage)

  let idx2 = currentPage + 1
  while (idx2 <= currentPage + windowSize && idx2 <= totalPages) {
    pageWindow.push(idx2)
    idx2++
  }

  if (currentPage > windowSize + 1) {
    leftEllipsis = (
      <ListItem>
        <PaginationLink aria-current="false" isDisabled>
          &hellip;
        </PaginationLink>
      </ListItem>
    )
  }

  const currentWindow: ReactNode[] = []

  pageWindow.forEach(page => {
    const key = `page-${page}`

    if (currentPage === page) {
      currentWindow.push(
        <ListItem key={key}>
          <PaginationLink
            active
            aria-label={`Page ${page}, current page`}
            aria-current="true"
            aria-disabled="false"
          >
            {page}
          </PaginationLink>
        </ListItem>
      )
    } else {
      currentWindow.push(
        <ListItem key={key}>
          <PaginationLink
            key={`page-${page}`}
            onClick={() => onClick(page)}
            aria-label={`Page ${page}`}
            aria-current="false"
            aria-disabled="false"
          >
            {page}
          </PaginationLink>
        </ListItem>
      )
    }
  })

  if (currentPage + 1 <= totalPages) {
    nextPage = (
      <ListItem>
        <PaginationLink
          aria-label="Next page"
          aria-disabled="false"
          aria-current="false"
          onClick={() => onClick(currentPage + 1)}
        >
          <ScreenReaderHiddenText>›</ScreenReaderHiddenText>
          <ScreenReaderOnlyText>Next</ScreenReaderOnlyText>
        </PaginationLink>
      </ListItem>
    )
  }

  if (currentPage !== totalPages) {
    lastPage = (
      <ListItem>
        <PaginationLink
          aria-label="Last page"
          aria-current="false"
          aria-disabled="false"
          onClick={() => onClick(totalPages)}
        >
          <ScreenReaderHiddenText>&raquo;</ScreenReaderHiddenText>
          <ScreenReaderOnlyText>Last</ScreenReaderOnlyText>
        </PaginationLink>
      </ListItem>
    )
  }

  if (currentPage + windowSize < totalPages) {
    rightEllipsis = (
      <ListItem>
        <PaginationLink aria-current="false" isDisabled>
          &hellip;
        </PaginationLink>
      </ListItem>
    )
  }

  return (
    <Flex
      as="nav"
      backgroundColor="#ffffff"
      marginTop="2rem"
      role="navigation"
      aria-label={ariaLabel}
      justifyContent="center"
    >
      <PaginationContainer>
        {firstPage}
        {previousPage}
        {leftEllipsis}
        {currentWindow}
        {rightEllipsis}
        {nextPage}
        {lastPage}
      </PaginationContainer>
    </Flex>
  )
}

export { CustomPagination as Pagination }
