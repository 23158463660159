import React from 'react'
import styled from '@emotion/styled'
import { Link } from '../../components/Shared'
import {
  RESEND_CONFIRMATION_PATH,
  RESEND_UNLOCK_PATH,
  LOGIN_PATH,
} from '../../routes'
import { useTranslation } from 'react-i18next'

interface Props {
  confirmation?: boolean
  unlock?: boolean
  resendUnlock?: boolean
  registrationUrl?: string
}

const defaultProps = {
  confirmation: true,
  unlock: true,
  resendUnlock: true,
}

const AccountLinkContainer = styled.div`
  padding: 1rem 2rem;
  text-align: center;

  a {
    margin-bottom: 0.5rem;
    display: block;
  }
`

export const AccountLinks: React.FC<Props> = ({
  confirmation,
  unlock,
  resendUnlock,
  registrationUrl,
}) => {
  const { t } = useTranslation(['common'])

  return (
    <AccountLinkContainer>
      {unlock && (
        <Link to={LOGIN_PATH} marginBottom="0.5rem" display="block">
          {t('redirectLink.signIn', { ns: ['common'] })}
        </Link>
      )}

      {confirmation && (
        <>
          <Link to={RESEND_CONFIRMATION_PATH}>
            {t('redirectLink.resendAccountConfirmation', { ns: ['common'] })}
          </Link>
        </>
      )}

      {resendUnlock && (
        <>
          <Link to={RESEND_UNLOCK_PATH}>
            {t('redirectLink.resendUnlockIntructions', { ns: ['common'] })}
          </Link>
        </>
      )}

      <div className="registrationContainer">
        <span>{t('redirectLink.dontHaveAccount', { ns: ['common'] })}</span>
        <Link to={registrationUrl || ''} isExternal={true}>
          {t('redirectLink.registerNow', { ns: ['common'] })}
        </Link>
      </div>
    </AccountLinkContainer>
  )
}

AccountLinks.defaultProps = defaultProps
