import React from 'react'
import { IconProps } from '../../types'

export const CrossIcon: React.FC<IconProps> = ({
  fill = '#fff',
  width = 15,
  height = 15,
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 15 15"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0355 11.0355C10.5476 11.5234 9.75567 11.5234 9.26777 11.0355L7.5 9.26776L5.73223 11.0355C5.24433 11.5234 4.45237 11.5234 3.96447 11.0355C3.47656 10.5476 3.47656 9.75566 3.96447 9.26776L5.73223 7.49999L3.96447 5.73223C3.47656 5.24432 3.47656 4.45236 3.96447 3.96446C4.45237 3.47655 5.24433 3.47655 5.73223 3.96446L7.5 5.73223L9.26777 3.96446C9.75567 3.47655 10.5476 3.47655 11.0355 3.96446C11.5234 4.45236 11.5234 5.24432 11.0355 5.73223L9.26777 7.49999L11.0355 9.26776C11.5234 9.75566 11.5234 10.5476 11.0355 11.0355Z"
        fill={fill}
      />
    </svg>
  )
}
