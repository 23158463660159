import gql from 'graphql-tag'

export const ACCESSORY_FRAGMENT = gql`
  fragment Accessory on Accessory {
    id
    product {
      id
      itemNumber
      itemDescription
      dosage
      isDeliveryFreight
      price
    }
    quantity
    lineItemNumber
    freeReason
  }
`

export const TREATMENT_FRAGMENT = gql`
  fragment Treatment on Treatment {
    id
    product {
      id
      itemNumber
      itemDescription
      dosage
    }
    quantity
    calibrationDate
    calibrationWeek
    calibrationSheet
    lineItemNumber
  }
`
