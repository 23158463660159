import React from 'react'
import { round } from '../../../utils'
import {
  ActivityVialMapType,
  VialMapType,
} from '../../../context/VialSearchContext'
import { themeUtils } from '../../../themeUtils'
import { ParagraphText } from '../../Shared'
import {
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/core'
import { Trans, useTranslation } from 'react-i18next'

const { darkBlue, white } = themeUtils.colors

interface Props {
  selectedVials?: VialMapType | ActivityVialMapType
  targetActivity: number | undefined
  totalActivity: number
}

const { red } = themeUtils.colors

export const ActivityAtTreatment: React.FC<Props> = ({
  selectedVials,
  targetActivity,
  totalActivity,
}) => {
  const { t } = useTranslation(['treatment'])
  if (!selectedVials || selectedVials.size === 0) return null

  const targetValue =
    targetActivity && !isNaN(targetActivity) ? targetActivity : undefined
  const totalActivity2Dec = totalActivity.toFixed(2)
  if (!targetValue) {
    return (
      <ParagraphText marginY="1rem">
        <Trans
          i18nKey="activityAtTreatment.totalEstimatedWithVar"
          ns="treatment"
          values={{ totalActivity2Dec }}
          components={{ b: <b /> }}
        />
        <Popover trigger="hover">
          <PopoverTrigger>
            <Icon name="info" color="#013c71" />
          </PopoverTrigger>
          <PopoverContent
            zIndex={4}
            padding="1.5rem"
            background={darkBlue}
            color={white}
            textAlign="center"
            fontSize="1.4rem"
            borderRadius="1rem"
          >
            <PopoverBody>
              {t('activityAtTreatment.infoText', { ns: ['treatment'] })}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </ParagraphText>
    )
  }

  const difference = round(targetValue - totalActivity, 2)
  const activityChange = round((difference / targetValue) * 100, 2)
  // increase or decrease
  // If negative, it means there was an increase and not an decrease.
  const sign = Math.sign(activityChange)
  const absoluteNum = Math.abs(activityChange)

  // Calculated Activity at Treatment is 10% below the Target Activity at Treatment
  const belowTargetActivity = sign === 1 && absoluteNum >= 10
  // Calculated Activity at Treatment is 10% above the Target Activity at Treatment
  const aboveTargetActivity = sign === -1 && absoluteNum >= 10

  // total activity is 10% below the Target Activity at Treatment
  const activityError =
    targetValue !== totalActivity &&
    (aboveTargetActivity || belowTargetActivity)
  const activityErrorText = aboveTargetActivity
    ? t('activityAtTreatment.activityError.higher', { ns: ['treatment'] })
    : t('activityAtTreatment.activityError.lower', { ns: ['treatment'] })

  return (
    <>
      <ParagraphText marginY="1rem">
        {t('activityAtTreatment.totalEstimated', { ns: ['treatment'] })}{' '}
        {activityError ? (
          <b style={{ color: `${red}` }}>
            {totalActivity.toFixed(2)} {t('unit', { ns: ['treatment'] })}{' '}
          </b>
        ) : (
          <b>
            {totalActivity.toFixed(2)} {t('unit', { ns: ['treatment'] })}{' '}
          </b>
        )}
        <Popover trigger="hover">
          <PopoverTrigger>
            <Icon name="info" color="#013c71" />
          </PopoverTrigger>
          <PopoverContent
            zIndex={4}
            padding="1.5rem"
            background={darkBlue}
            color={white}
            textAlign="center"
            fontSize="1.4rem"
            borderRadius="1rem"
          >
            <PopoverBody>
              {t('activityAtTreatment.infoText', { ns: ['treatment'] })}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </ParagraphText>
      {activityError && (
        <ParagraphText color={red} marginBottom="1rem">
          <b>
            <Trans
              i18nKey="activityAtTreatment.activityError.error"
              ns="treatment"
              values={{ activityErrorText }}
            />
          </b>
        </ParagraphText>
      )}
    </>
  )
}
