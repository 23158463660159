import React, { useEffect, useState, ReactElement } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { parse } from 'query-string'
import { useMutation } from '@apollo/react-hooks'
import { UNLOCK_ACCOUNT_MUTATION } from '../../schema/mutations'
import {
  UnlockPortalAccount,
  UnlockPortalAccountVariables,
} from '../../schema/mutations/__generated__/UnlockPortalAccount'
import { LOGIN_PATH } from '../../routes'
import { CircularProgress, Flex } from '@chakra-ui/core'
import { AlertOverlay } from '../../components/Shared'
import { SUPPORT_EMAIL } from '../../constants'
import { themeUtils } from '../../themeUtils'
import { useTranslation } from 'react-i18next'

export const UnlockAccountScreen: React.FC<RouteComponentProps> = ({
  location,
}) => {
  const { t } = useTranslation(['unlockAccountScreen'])
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [unlockPortalAccount] = useMutation<
    UnlockPortalAccount,
    UnlockPortalAccountVariables
  >(UNLOCK_ACCOUNT_MUTATION)
  let token = ''

  if (location && location.search) {
    token = parse(location.search).unlock_token as string
  }

  const submit = async (): Promise<void> => {
    const { data } = await unlockPortalAccount({ variables: { token } })

    if (data && data.unlockPortalAccount) {
      setError(false)
      setSuccess(true)
    }

    setError(true)
  }

  const renderMessage = (): ReactElement => {
    if (success) {
      return (
        <AlertOverlay status="success" title={t('success.title', { ns: ['unlockAccountScreen'] })}>
          {t('success.description', { ns: ['unlockAccountScreen'] })}
        </AlertOverlay>
      )
    }

    if (error) {
      return (
        <AlertOverlay
          status="error"
          title={t('failure.title', { ns: ['unlockAccountScreen'] })}
        >
          {t('failure.description.desc1', { ns: ['unlockAccountScreen'] })}{' '}
          <br />
          {t('failure.description.desc2', { ns: ['unlockAccountScreen'] })}{' '}
          <a href={`mailto:${SUPPORT_EMAIL}`}>
            {t('failure.description.contactSupport', {
              ns: ['unlockAccountScreen'],
            })}
          </a>{' '}
          {t('failure.description.desc3', { ns: ['unlockAccountScreen'] })}
        </AlertOverlay>
      )
    }

    return (
      <Flex align="center" justifyContent="center" marginBottom="1rem">
        <CircularProgress isIndeterminate color={themeUtils.colors.green} />
      </Flex>
    )
  }

  useEffect(() => {
    if (token) {
      submit()
    } else {
      setError(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (success) {
        navigate(LOGIN_PATH)
      }
    }, 10000)

    return () => clearInterval(timer)
  }, [success])

  return renderMessage()
}
