import React from 'react'
import { Box, BoxProps, Flex, Icon, Text } from '@chakra-ui/core'
import { Icons } from '@chakra-ui/core/dist/theme/icons'
import { themeUtils } from '../../themeUtils'
import { ThemeVersion } from '../../types'
import styled from '@emotion/styled'

const { red, white, antiFlashWhite, midnightBlue } = themeUtils.colors

interface Props extends BoxProps {
  icon?: Icons
  solid?: boolean
  iconRequired?: boolean
  textAlign?: any
  version?: ThemeVersion
  keyProp?: string
}

export const InlineErrorMessage: React.FC<Props> = ({
  solid = false,
  iconRequired = true,
  textAlign = 'left',
  version,
  keyProp,
  ...props
}) => {
  if (version === ThemeVersion.V2) {
    return (
      <Container>
        {iconRequired && (
          <Icon
            aria-hidden
            name={props.icon || 'warning'}
            color={midnightBlue}
            width={'28px'}
            height={'28px'}
          />
        )}
        <TextStyle data-cy={keyProp}>{props.children}</TextStyle>
      </Container>
    )
  }

  return (
    <Flex
      color={solid ? white : red}
      backgroundColor={solid ? red : undefined}
      borderWidth={{ base: '0', lg: '0.1rem' }}
      borderRadius={solid ? '1.3rem' : 0}
      borderColor={solid ? red : undefined}
      border="none"
      boxShadow={solid ? '0 10px 15px rgba(141, 162, 179, 0.1)' : ''}
      padding={solid ? '0.5rem 2rem' : 0}
      marginY="1rem"
      {...props}
    >
      {iconRequired && (
        <Icon
          aria-hidden
          name={props.icon || 'warning'}
          mr="0.5em"
          mt="0.3rem"
        />
      )}
      <Text lineHeight="normal" textAlign={textAlign} data-cy={keyProp}>
        {props.children}
      </Text>
    </Flex>
  )
}

const Container = styled(Box)`
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 15px;

  border-radius: 10px;
  border: 1px solid ${midnightBlue};
  background: ${antiFlashWhite};
`

const TextStyle = styled.label`
  color: ${midnightBlue};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`
