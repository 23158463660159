import { Flex, Popover, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger } from '@chakra-ui/core'
import React from 'react'
import { Button, Heading } from '../../Shared'
import { LARGE, MEDIUM, X_LARGE, mqNames, themeUtils } from '../../../themeUtils'
import { PopoverBody } from 'reactstrap'
import { TeamMemberFormBody } from './TeamMemberForm'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

const PopoverContentContainer = styled(PopoverContent)`
  padding: 1.5rem;
  font-size: 1.4rem;
  z-index: 1401;
  border-radius: 1rem;
  max-width: unset;

  position: absolute !important;
  top: 0px !important;
  left: unset !important;
  will-change: unset !important;
  transform: unset !important;

  
  margin-left: unset;
  left: 0px !important;

  ${mqNames(MEDIUM)} {
    margin-left: 27.5rem;
    left: unset !important;
  }

  ${mqNames(LARGE)} {
    margin-left: 35.5rem;
    left: unset !important;
  }

  ${mqNames(X_LARGE)} {
    margin-left: 45.5rem;
    left: unset !important;
  }
`

interface Props {
  isPopoverOpen: boolean
  onPopoverClose: () => void
}

const {
  blue,
} = themeUtils.colors

export const TeamMemberPopover: React.FC<Props> = ({isPopoverOpen, onPopoverClose}) => {
  const { t } = useTranslation(['teamMemberSection'])

  const cancelButton = (
    <Button isFullWidth marginTop={'1rem'} onClick={onPopoverClose}>
      {t('add.cancelBtnLabel', { ns: ['teamMemberSection'] })}
    </Button>
  )

  return (
    <Popover
      isOpen={isPopoverOpen}
      onClose={onPopoverClose}
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Flex>
          <Button hidden>
            {t('add.btnLabel', { ns: ['teamMemberSection'] })}
          </Button>
        </Flex>
      </PopoverTrigger>
      {isPopoverOpen && (
        <PopoverContentContainer>
          <PopoverHeader fontWeight="semibold">
            <Heading color={blue} as="h2" marginBottom="0">
              {t('add.defaultHeadingTitle', { ns: ['teamMemberSection'] })}
            </Heading>
          </PopoverHeader>
          <PopoverCloseButton size={'md'} />
          <PopoverBody borderBottom={0}>
            <TeamMemberFormBody
              onClose={onPopoverClose}
              cancelBtn={cancelButton}
            />
          </PopoverBody>
        </PopoverContentContainer>
      )}
    </Popover>
  )
}
