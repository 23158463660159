import { STATUSES } from './statuses'
import { OrderStatusEnum } from '../__generated__/globalTypes'

export const CANCELLABLE_STATUSES = [
  STATUSES.DRAFT,
  STATUSES.NEW,
  OrderStatusEnum.ACCEPTED,
  OrderStatusEnum.BUILD_PENDING,
  OrderStatusEnum.BUILT,
  OrderStatusEnum.REJECTED,
  OrderStatusEnum.RESERVED,
]
export const VOIDABLE_STATUSES = [STATUSES.DRAFT, STATUSES.NEW]
export const VOID_STATUSES = [STATUSES.DRAFT, STATUSES.NEW, STATUSES.VOID, STATUSES.RESERVED]
export const CANCELLATION_REASON_STATUSES = [
  STATUSES.CANCELLING,
  STATUSES.CANCELLED,
  STATUSES.VOID,
]

export const AdminSetItemNumbers = ['K125914034', 'K125914041']

export const FEDEX_REGEX = RegExp('(federal express canada|fedex)', 'i')
export const MNX_REGEX = RegExp('(mnx)', 'i')
export const FEDEX_LINK = `https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=`
export const MNX_LINK = `https://mxweb.mnx.com/TrackOrder.aspx?TrackingNumber=`
