import React, { Dispatch, SetStateAction, useEffect } from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  Box,
  useDisclosure,
  Text,
} from '@chakra-ui/core'
import { darken } from 'polished'
import {
  Button,
  WidgetHeader,
  LinkButton,
  ModalOverlay,
  ModalContent,
} from '../../Shared'
import { themeUtils } from '../../../themeUtils'
import { AccessoryIcon, CheckSquareIcon, EmailIcon } from '../../Icons'
import { AccessoryFields } from './AccessoryFields'
import { AdminSetType, OrderFormData } from './OrderFormDataTypes'
import { Notification } from '../../../utils'
import { useUser } from '../../../context/user-context'
import { GetMyPortalUser_me_preferredShippingAddress_defaultAdminSet } from '../../../context/__generated__/GetMyPortalUser'
import { ThemeVersion } from '../../../types'
import { useTranslation } from 'react-i18next'

interface AddAccessoryButtonProps {
  mnxRushOrder: boolean
  adminSets: Array<AdminSetType>
  defaultAdminSet:
    | GetMyPortalUser_me_preferredShippingAddress_defaultAdminSet
    | undefined
  setAccessoryData: Dispatch<SetStateAction<OrderFormData>>
  createAccessoryOnlyOrder: boolean
}

const { green, red, slateGrey, midnightBlue } = themeUtils.colors

export const AddAccessoryButton: React.FC<AddAccessoryButtonProps> = ({
  adminSets,
  defaultAdminSet,
  setAccessoryData,
  mnxRushOrder,
  createAccessoryOnlyOrder
}) => {
  const { t } = useTranslation(['orderCreate'])
  const AccessoryText = t('orderCreateWidget.tubingSets.title', {
    ns: ['orderCreate'],
  })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const user = useUser()
  const customerSalesRep = user?.customer.regionalCustomerCareRep

  const onConfirm = () => {
    const newAdminSets = [...adminSets]

    adminSets.forEach((adminSet, idx) => {
      const hasQuantity = !!adminSet.quantity
      // @ts-ignore
      newAdminSets[idx]['hasError'] = !hasQuantity
      newAdminSets[idx]['confirmed'] = hasQuantity
    })

    const hasErrors = newAdminSets.some(adminSet => adminSet.hasError)
    setAccessoryData((prevData: OrderFormData) => {
      return { ...prevData, adminSets: newAdminSets }
    })
    !hasErrors && onClose()
  }

  useEffect(() => {
    if (!adminSets.length) return

    const hasErrors = adminSets.some(adminSet => adminSet.hasError)

    const errorMsg = t('orderCreateWidget.tubingSets.errorMsg', {
      ns: ['orderCreate'],
    })
    if (hasErrors) {
      Notification.error(errorMsg, {
        timeout: 5000,
        killer: true,
      })
    }
  }, [adminSets])

  return (
    <>
      {!createAccessoryOnlyOrder && (
        <Button
          variant="link"
          marginTop={{ base: '1rem', md: 0 }}
          textDecoration="underline"
          _hover={{
            color: darken(0.1, slateGrey),
          }}
          height="6rem"
          justifyContent="flex-start"
          onClick={onOpen}
          data-cy="add-tubing-set-link-btn"
        >
          {AccessoryText}
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth="40rem" justifyContent="center">
          <ModalBody
            padding="2rem 0"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <AccessoryIcon fill={midnightBlue} width="8rem" height="10rem" />
            <WidgetHeader marginY="2rem">{AccessoryText}</WidgetHeader>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              textAlign="center"
              width="100%"
              marginTop="2rem"
              marginBottom={defaultAdminSet ? 0 : '3rem'}
            >
              {defaultAdminSet && !mnxRushOrder ? (
                <AccessoryFields
                  defaultAdminSet={defaultAdminSet}
                  adminSets={adminSets}
                  setAccessoryData={setAccessoryData}
                  initializeWithAccessory={false}
                  version={ThemeVersion.V2}
                />
              ) : (
                <>
                  {mnxRushOrder ? (
                    <div>
                      <Text color={red}>
                        <b>
                          {t('orderCreateWidget.accessorySets', {
                            ns: ['orderCreate'],
                          })}
                        </b>
                      </Text>
                    </div>
                  ) : (
                    <div>
                      <Text color={red}>
                        <b>
                          {t('orderCreateWidget.sorryNo', {
                            ns: ['orderCreate'],
                          })}
                        </b>
                      </Text>
                      <Text marginY="1rem">
                        <b>{customerSalesRep?.name}</b>
                      </Text>
                      <LinkButton
                        wordBreak="break-all"
                        padding="1.5rem"
                        href={`mailto:${customerSalesRep?.email}`}
                      >
                        <EmailIcon /> {customerSalesRep?.email}
                      </LinkButton>
                    </div>
                  )}
                </>
              )}
            </Box>
          </ModalBody>
          <ModalFooter
            borderTop="1px solid #EFEFEF"
            paddingTop="2rem"
            alignSelf="center"
          >
            <Button
              variant="link"
              onClick={onClose}
              height="6rem"
              width="12rem"
              color={red}
              data-cy="cancel-btn"
            >
              {t('orderCreateWidget.tubingSets.cancel', {
                ns: ['orderCreate'],
              })}
            </Button>
            {!mnxRushOrder ? (
              <Button
                backgroundColor={green}
                marginTop="0"
                height="6rem"
                width="18rem"
                paddingX="2rem"
                paddingY="2rem"
                justifyContent="space-between"
                rightIcon={CheckSquareIcon}
                isDisabled={!adminSets.length}
                onClick={onConfirm}
                data-cy="confirm-btn"
              >
                {t('orderCreateWidget.tubingSets.confirm', {
                  ns: ['orderCreate'],
                })}
              </Button>
            ) : null}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
