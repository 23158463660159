import React from 'react'
import { MEDIUM, mqNames, themeUtils } from '../../../themeUtils'
import styled from '@emotion/styled'
import { Box, Flex } from '@chakra-ui/core'
import { OrderFormData } from '../OrderCreate/OrderFormDataTypes'
import { NestDataObject } from 'react-hook-form'
import {
  DeliveryDetails,
  OrderFormClearErrorType,
  OrderFormErrorType,
  PatientIntent,
  TreatingPhysician,
  TreatmentTime,
} from '..'
import { FormHeader, InputV2, Option } from '../../Shared'
import { useOrderForm } from '../../../hooks'
import { DatePickerWidget } from '../../Forms'
import { useUser } from '../../../context/user-context'
import { formatDate } from '../../../utils'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

const { white } = themeUtils.colors

const Container = styled(Flex)`
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  height: fit-content;

  border-radius: 10px;
  background: ${white};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
`

const BoxContainer = styled(Box)`
  display: grid;
  gap: 20px;
  width: 100%;

  ${mqNames(MEDIUM)} {
    width: 450px;
  }
`

const Heading = styled(FormHeader)`
  display: none;
  ${mqNames(MEDIUM)} {
    display: block;
  }
`

interface Props {
  formData: OrderFormData
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
  onChangeCheckBoxValue: (val: boolean) => void
  createAccessoryOnlyOrder: boolean
  changeTreatmentDate: (date: Date | null) => void
  minOrderTreatmentDate(isMnxEligible: boolean): Date
  isMnxEligibleOrder: () => boolean
  isMnxTreatmentDate: () => boolean
  errors: NestDataObject<OrderFormData>
  setError: OrderFormErrorType
  clearError: OrderFormClearErrorType
}

export const TreatmentExtraDetailsWidget: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation(['orderCreate'])
  const { formData, setFormData, isMnxEligibleOrder } = props
  const user = useUser()
  const { formHeading } = useOrderForm()
  const {
    treatmentDate,
    treatmentTime,
    patientRef,
    poNumber,
    validBlanketPO,
    treatmentIntent,
  } = formData

  const changeValue = (event: {
    currentTarget: { value: string; name: string }
  }): void => {
    const { value, name } = event.currentTarget

    setFormData((prevFormData: OrderFormData) => {
      return { ...prevFormData, [name]: value }
    })
  }

  const changePatientIntent = (option: Option | null): void => {
    setFormData((prevFormData: OrderFormData) => {
      return { ...prevFormData, treatmentIntent: option?.value }
    })
  }

  const poNumberPlaceholder = user?.customer.sapBillingDetail?.poRequired
    ? t('activityFocused.poNumber.placeholder')
    : t('activityFocused.poNumber..placeholderOpt')

  const jsxElement =
    validBlanketPO &&
    poNumber === user?.customer.purchaseOrder?.reference &&
    user?.customer.purchaseOrder?.endDate ? (
      <>
        <b>{t('orderCreateWidget.blanketPO')}</b>
        {formatDate(parseISO(user?.customer.purchaseOrder?.endDate))}
      </>
    ) : null

  return (
    <Container>
      <Heading>{formHeading}</Heading>
      <BoxContainer>
        <DatePickerWidget
          name="treatmentDate"
          label={t('activityFocused.treatmentDate.label')}
          id="treatment-date-input"
          placeholder={t('activityFocused.treatmentDate.placeholder')}
          date={!!treatmentDate ? treatmentDate : undefined}
          isDisabled={true}
        />
        <TreatmentTime
          treatmentDate={treatmentDate}
          treatmentTime={treatmentTime}
          isMnxEligibleOrder={isMnxEligibleOrder}
          isReadOnly={true}
        />
        <InputV2
          label={t('activityFocused.patientRef.label')}
          aria-describedby="patient-ref-helper-text"
          id="patientRef"
          data-cy="patient-reference-input"
          name="patientRef"
          placeholder={t('activityFocused.patientRef.placeholder')}
          hint={t('activityFocused.patientRef.hint')}
          onChange={changeValue}
          type="text"
          value={patientRef ?? ''}
        />
        <PatientIntent
          changePatientIntent={changePatientIntent}
          patientIntent={treatmentIntent}
        />
        <TreatingPhysician formData={formData} setFormData={setFormData} />
        <InputV2
          label={t('activityFocused.poNumber.label')}
          aria-describedby="po-number-helper-text"
          id="poNumber"
          data-cy="po-number-input"
          name="poNumber"
          placeholder={poNumberPlaceholder}
          onChange={changeValue}
          type="text"
          value={poNumber ?? ''}
          popoverHint={jsxElement}
        />
        <DeliveryDetails {...props} />
      </BoxContainer>
    </Container>
  )
}
