export const DATE_FORMAT = 'MMM do, yyyy'
export const DAY_OF_WEEK_FORMAT = 'E, MMM do, yyyy'
export const DAY_MONTH_FORMAT = 'do MMM'
export const LONG_MONTH_FORMAT = 'do MMMM yyyy'
export const SHORT_MONTH_FORMAT = 'do MMM yyyy'
export const DATE_ISO_FORMAT = 'yyyy-MM-dd'
export const DATETIME_FORMAT = 'HH:mm:ss MMM d, yyyy'
export const TIMESTAMP_FORMAT = 'E, MMM do, yyyy - HH:mm:ss z'
export const HOUR_AND_MIN = 'HH:mm'

//FRENCH SPECIFIC DATE FORMAT
export const DATE_FR_FORMAT = 'MMM dd, yyyy'
export const DAY_OF_WEEK_FR_FORMAT = 'E, dd MMM, yyyy'
export const DAY_MONTH_FR_FORMAT = 'dd MMM'
export const LONG_MONTH_FR_FORMAT = 'dd MMMM yyyy'
export const SHORT_MONTH_FR_FORMAT = 'dd MMM yyyy'
export const TIMESTAMP_FR_FORMAT = 'E, dd MMM, yyyy - HH:mm:ss z'
