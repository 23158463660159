import React from 'react'
import { OrdersIndex_orders_items } from '../../../schema/queries/__generated__/OrdersIndex'
import { OrderRow } from './OrderRow'
import { isDistributor } from '../../lib'

interface Props {
  orders: OrdersIndex_orders_items[]
  isDistributor: boolean
}

export const OrderGroupedRow: React.FC<Props> = ({ orders, isDistributor }) => (
  <>
    {orders.map((order: OrdersIndex_orders_items, index) => (
      <OrderRow key={order.orderNumber} order={order} isDistributor={isDistributor} firstItem={index === 0} />
    ))}
  </>
)
