import React from 'react'
import { Box } from '@chakra-ui/core'
import { CustomerCareContent } from './CustomerCareContent'

export const CustomerCareWidget: React.FC = () => {
  return (
    <Box>
      <CustomerCareContent topPad />
    </Box>
  )
}
