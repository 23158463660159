// English
import commonEnglish from './English/common.json'
import notFoundEnglish from './English/notFound.json'
import navBarEnglish from './English/navBar.json'
import dashboardScreenEnglish from './English/dashboardScreen.json'

import loginScreenEnglish from './English/Unauthenticated/loginScreen.json'
import confirmConfirmationScreenEnglish from './English/Unauthenticated/confirmConfirmationScreen.json'
import newPasswordScreenEnglish from './English/Unauthenticated/newPasswordScreen.json'
import resendConfirmationScreenEnglish from './English/Unauthenticated/resendConfirmationScreen.json'
import resendUnlockInstructionsScreenEnglish from './English/Unauthenticated/resendUnlockInstructionsScreen.json'
import resetPasswordScreenEnglish from './English/Unauthenticated/resetPasswordScreen.json'
import unlockAccountScreenEnglish from './English/Unauthenticated/unlockAccountScreen.json'

import orderCreateEnglish from './English/Orders/orderCreate.json'
import orderOverviewScreenEnglish from './English/Orders/orderOverviewScreen.json'
import orderScreenEnglish from './English/Orders/orderScreen.json'
import orderShowScreenEnglish from './English/Orders/orderShowScreen.json'
import orderConfirmationModalEnglish from './English/Orders/orderConfirmationModal.json'
import shippingErrorMessageEnglish from './English/Orders/shippingErrorMessage.json'
import showExpeditedChargeMsgEnglish from './English/Orders/showExpeditedChargeMsg.json'
import treatmentEnglish from './English/Orders/treatment.json'
import orderCreateButtonEnglish from './English/Orders/orderCreateButton.json'
import statusEnglish from './English/Orders/status.json'

import myAccountEnglish from './English/MyAccount/myAccount.json'
import npiWidgetEnglish from './English/MyAccount/npiWidget.json'
import teamMemberSectionEnglish from './English/MyAccount/teamMemberSection.json'

// French
import commonFrench from './French/common.json'
import notFoundFrench from './French/notFound.json'
import navBarFrench from './French/navBar.json'
import dashboardScreenFrench from './French/dashboardScreen.json'

import loginScreenFrench from './French/Unauthenticated/loginScreen.json'
import confirmConfirmationScreenFrench from './French/Unauthenticated/confirmConfirmationScreen.json'
import newPasswordScreenFrench from './French/Unauthenticated/newPasswordScreen.json'
import resendConfirmationScreenFrench from './French/Unauthenticated/resendConfirmationScreen.json'
import resendUnlockInstructionsScreenFrench from './French/Unauthenticated/resendUnlockInstructionsScreen.json'
import resetPasswordScreenFrench from './French/Unauthenticated/resetPasswordScreen.json'
import unlockAccountScreenFrench from './French/Unauthenticated/unlockAccountScreen.json'

import orderCreateFrench from './French/Orders/orderCreate.json'
import orderOverviewScreenFrench from './French/Orders/orderOverviewScreen.json'
import orderScreenFrench from './French/Orders/orderScreen.json'
import orderShowScreenFrench from './French/Orders/orderShowScreen.json'
import orderConfirmationModalFrench from './French/Orders/orderConfirmationModal.json'
import shippingErrorMessageFrench from './French/Orders/shippingErrorMessage.json'
import showExpeditedChargeMsgFrench from './French/Orders/showExpeditedChargeMsg.json'
import treatmentFrench from './French/Orders/treatment.json'
import orderCreateButtonFrench from './French/Orders/orderCreateButton.json'
import statusFrench from './French/Orders/status.json'

import myAccountFrench from './French/MyAccount/myAccount.json'
import npiWidgetFrench from './French/MyAccount/npiWidget.json'
import teamMemberSectionFrench from './French/MyAccount/teamMemberSection.json'

export const resources = {
  en: {
    common: commonEnglish,
    navBar: navBarEnglish,
    dashboardScreen: dashboardScreenEnglish,
    //UNAUTHENTICATED
    loginScreen: loginScreenEnglish,
    confirmConfirmationScreen: confirmConfirmationScreenEnglish,
    newPasswordScreen: newPasswordScreenEnglish,
    resendConfirmationScreen: resendConfirmationScreenEnglish,
    resendUnlockInstructionsScreen: resendUnlockInstructionsScreenEnglish,
    resetPasswordScreen: resetPasswordScreenEnglish,
    unlockAccountScreen: unlockAccountScreenEnglish,
    //ORDERS
    orderCreate: orderCreateEnglish,
    orderOverviewScreen: orderOverviewScreenEnglish,
    orderScreen: orderScreenEnglish,
    orderShowScreen: orderShowScreenEnglish,
    orderConfirmationModal: orderConfirmationModalEnglish,
    shippingErrorMessage: shippingErrorMessageEnglish,
    showExpeditedChargeMsg: showExpeditedChargeMsgEnglish,
    treatment: treatmentEnglish,
    orderCreateButton: orderCreateButtonEnglish,
    status: statusEnglish,
    //MY ACCOUNT
    myAccount: myAccountEnglish,
    npiWidget: npiWidgetEnglish,
    teamMemberSection: teamMemberSectionEnglish,
    //PAGE NOT FOUND
    notFound: notFoundEnglish,
  },
  fr: {
    common: commonFrench,
    navBar: navBarFrench,
    dashboardScreen: dashboardScreenFrench, // Pending
    //UNAUTHENTICATED
    loginScreen: loginScreenFrench,
    confirmConfirmationScreen: confirmConfirmationScreenFrench,
    newPasswordScreen: newPasswordScreenFrench,
    resendConfirmationScreen: resendConfirmationScreenFrench,
    resendUnlockInstructionsScreen: resendUnlockInstructionsScreenFrench,
    resetPasswordScreen: resetPasswordScreenFrench,
    unlockAccountScreen: unlockAccountScreenFrench,
    //ORDERS
    orderCreate: orderCreateFrench,
    orderOverviewScreen: orderOverviewScreenFrench,
    orderScreen: orderScreenFrench, // Pending
    orderShowScreen: orderShowScreenFrench, // Pending
    orderConfirmationModal: orderConfirmationModalFrench,
    shippingErrorMessage: shippingErrorMessageFrench,
    showExpeditedChargeMsg: showExpeditedChargeMsgFrench, // Pending
    treatment: treatmentFrench,
    orderCreateButton: orderCreateButtonFrench,
    status: statusFrench,
    //MY ACCOUNT
    myAccount: myAccountFrench, // Pending
    npiWidget: npiWidgetFrench,
    teamMemberSection: teamMemberSectionFrench,
    //PAGE NOT FOUND
    notFound: notFoundFrench,
  },
}
