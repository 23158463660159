import React, { ReactElement } from 'react'
import { themeUtils } from '../../themeUtils'
import { BoxFill, BoxFillProps } from '.'
import { ChevronIcon } from '../Icons'
import { Link } from '../../components/Shared'
import { ORDERS_INDEX_PATH } from '../../routes'

interface BackButtonProps extends BoxFillProps {
  link?: string
}

const { darkBlue, geyserGrey } = themeUtils.colors

const BackButton: React.FC<BackButtonProps> = ({
  children,
  link,
  width = '5rem',
  height = '5rem',
}): ReactElement => (
  <BoxFill
    width={width}
    height={height}
    circular
    backgroundColor="white"
    boxShadow={'0 8px 10px rgba(69, 218, 122, 0.2)'}
    _hover={{
      backgroundColor: geyserGrey,
    }}
    title="Back to orders"
  >
    <Link
      display="flex"
      width="inherit"
      height="inherit"
      justifyContent="center"
      alignItems="center"
      color={darkBlue}
      _hover={{
        color: darkBlue,
      }}
      to={link ?? ORDERS_INDEX_PATH}
    >
      {children ?? (
        <ChevronIcon
          title="Left arrow icon"
          width="35"
          height="35"
          fill="currentColor"
        />
      )}
    </Link>
  </BoxFill>
)

export { BackButton }
