import gql from 'graphql-tag'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'

import introspectionQueryResultData from './fragmentTypes.json'
import { ORDER_FILTERS_QUERY } from './queries'
import { OrderStateEnum, SortDirectionEnum } from '../__generated__/globalTypes'

import { SetOrderFiltersVariables } from './mutations/__generated__/SetOrderFilters'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  introspectionQueryResultData,
})

const typeDefs = gql`
  extend type Query {
    orderFilters: OrderFilters!
  }

  extend type Mutation {
    setOrderFilters(input: OrderFiltersInput!): Boolean
  }

  type OrderFilters {
    state: OrderStateEnum!
    page: Int!
    sort: SortInput!
    limit: Int!
    search: String!
    status: OrderPortalStatusEnum
    customerId: ID
  }

  input OrderFiltersInput {
    state: OrderStateEnum!
    page: Int!
    sort: SortInput!
    limit: Int!
    search: String!
    status: OrderPortalStatusEnum
    customerId: ID
  }
`

const resolvers = {
  Mutation: {
    setOrderFilters: (
      _root: {},
      variables: SetOrderFiltersVariables,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeQuery({
        query: ORDER_FILTERS_QUERY,
        data: {
          orderFilters: {
            __typename: 'OrderFilters',
            ...variables.input,
            sort: {
              __typename: 'SortInput',
              ...variables.input.sort,
            },
          },
        },
      })
      return true
    },
  },
}

const cache = new InMemoryCache({
  freezeResults: true,
  fragmentMatcher,
})

const initialCacheData = {
  orderFilters: {
    __typename: 'OrderFilters',
    search: '',
    state: OrderStateEnum.OPEN,
    sort: {
      __typename: 'SortInput',
      column: 'created_at',
      direction: SortDirectionEnum.DESC,
    },
    page: 1,
    limit: 25,
    status: null,
    customerId: '',
  },
}

// Initialise the cache with default values
cache.writeData({
  data: initialCacheData,
})

export { typeDefs, resolvers, cache, initialCacheData }
