import React from 'react'
import { IconProps } from '../../types'

const CheckIcon: React.FC<IconProps> = ({
  fill = 'none',
  width = 34,
  height = 24,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 34 24"
    >
      <path
        fill={fill}
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="50"
        strokeWidth="3"
        d="M2 12.457L11.706 22.5 32 1.5"
      />
    </svg>
  )
}

export { CheckIcon }
