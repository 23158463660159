import React from 'react'
import { IconProps } from '../../types'
import styled from '@emotion/styled'

type Props = IconProps & React.SVGAttributes<SVGElement>

const Svg = styled.svg`
  flex-shrink: 0;
  backface-visibility: hidden;
  &:not(:root) {
    overflow: hidden;
  }
`

const PlusSquareIcon: React.FC<Props> = ({
  fill = '#fff',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      {...rest}
    >
      <g>
        <g>
          <path
            fill={fill}
            d="M0 1.6A1.6 1.6 0 0 1 1.6 0h12.8A1.6 1.6 0 0 1 16 1.6v12.8a1.6 1.6 0 0 1-1.6 1.6H1.6A1.6 1.6 0 0 1 0 14.4V1.6m7.2 5.6V4.8h1.6v2.4h2.4v1.6H8.8v2.4H7.2V8.8H4.8V7.2zm6.4 7.2a.8.8 0 0 0 .8-.8V2.4a.8.8 0 0 0-.8-.8H2.4a.8.8 0 0 0-.8.8v11.2a.8.8 0 0 0 .8.8h11.2"
          />
        </g>
      </g>
    </Svg>
  )
}

export { PlusSquareIcon }
