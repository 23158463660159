import React from 'react'
import { PseudoBox, PseudoBoxProps } from '@chakra-ui/core'
import { themeUtils } from '../../themeUtils'

export interface BoxFillProps extends PseudoBoxProps {
  backgroundColor?: string
  boxShadow?: string
  circular?: boolean
  width?: string
  height?: string
  flexShrink?: number
}

const BoxFill: React.FC<BoxFillProps> = ({
  backgroundColor = themeUtils.colors.borderGrey,
  boxShadow = '0 8px 10px rgba(141, 162, 179, 0.1)',
  circular = false,
  width = '7rem',
  height = '7rem',
  flexShrink = 0,
  children,
  ...rest
}) => {
  return (
    <PseudoBox
      backgroundColor={backgroundColor}
      alignItems="center"
      justifyContent="center"
      width={width}
      height={height}
      borderRadius={circular ? '10rem' : 0}
      boxShadow={boxShadow}
      flexShrink={flexShrink}
      display="flex"
      {...rest}
    >
      {children}
    </PseudoBox>
  )
}

export { BoxFill }
