import { Box, Flex } from '@chakra-ui/core'
import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CarrierText, Status } from '.'
import { OrderStatusEnum } from '../../../__generated__/globalTypes'
import { DAY_OF_WEEK_FORMAT } from '../../../constants'
import { GetMyPortalUser_me } from '../../../context/__generated__/GetMyPortalUser'
import { OrderShow_order } from '../../../schema/queries/__generated__/OrderShow'
import { themeUtils } from '../../../themeUtils'
import { formatDate } from '../../../utils'
import { statusSubmittedText } from '../../../utils/commonUtils'
import { MultiLineAddress, ParagraphText } from '../../Shared'
import { isDistributor } from '../../lib'

interface Props {
  order: OrderShow_order
  user: GetMyPortalUser_me | null | undefined
}

const { midnightBlue, slateGrey, black } = themeUtils.colors
const HighlightText = styled.span`
  font-weight: 500;
  color: ${midnightBlue};
`

const BlackHighlightText = styled.span`
  font-weight: 500;
  color: ${black};
`

export const StatusWidget: React.FC<Props> = ({ order, user }) => {
  const { t } = useTranslation(['orderShowScreen'])
  if (!order) return null
  const {
    waybillNumber,
    carrier,
    status,
    actualShipDate,
    deliveryDate,
    dockDate,
    usingMnxReservedSurplusInventory,
    shippingLink,
    shipDate,
  } = order
  const submittedDate = order.createdAt
  const confirmedDate = order.acceptedAt || order.reservedAt
  const cancellationRequestedDate = order.cancellationRequestedAt
  const cancelledDate = order.cancelledAt
  const isShipped = status === OrderStatusEnum.SHIPPED && actualShipDate
  const toDisplayDeliveryDate = [
    OrderStatusEnum.ACCEPTED,
    OrderStatusEnum.BUILD_PENDING,
    OrderStatusEnum.BUILT,
  ]
  if (usingMnxReservedSurplusInventory) {
    toDisplayDeliveryDate.push(
      OrderStatusEnum.RESERVED,
      OrderStatusEnum.RELEASED
    )
  }
  const displayDeliveryDate =
    toDisplayDeliveryDate.includes(status) && deliveryDate

  const shippingLinkTag = (
    <a target="_blank" href={shippingLink || undefined} rel="noreferrer">
      {t('statusWidget.trackingLink', { ns: ['orderShowScreen'] })}
    </a>
  )

  const isCancelling =
    status === OrderStatusEnum.CANCELLING ||
    status === OrderStatusEnum.CANCELLED ||
    status === OrderStatusEnum.VOID
  const isCancelled =
    status === OrderStatusEnum.CANCELLED || status === OrderStatusEnum.VOID
  const cancellationReason = `${order.cancellationReason?.code} - ${order.cancellationReason?.customerFacingDescription}`

  return (
    <Flex direction="column" position="relative" height="100%">
      {isCancelled && (
        <Status
          status={t('statusWidget.cancelConfirmed', {
            ns: ['orderShowScreen'],
          })}
          alert={true}
          active={false}
          dateOfStatus={cancelledDate}
          name={order.cancelledByName as string}
          type={order.cancelledByType as string}
        >
          <ParagraphText color={slateGrey}>
            <b>{t('statusWidget.reason', { ns: ['orderShowScreen'] })}</b>{' '}
            {cancellationReason}
          </ParagraphText>
        </Status>
      )}

      {isCancelling && order.status !== OrderStatusEnum.VOID && (
        <Status
          status={t('statusWidget.requested', { ns: ['orderShowScreen'] })}
          alert={true}
          active={false}
          dateOfStatus={cancellationRequestedDate}
          name={order.cancellationRequestedByName as string}
          type={order.cancellationRequestedByType as string}
        >
          <ParagraphText color={slateGrey}>
            <b>{t('statusWidget.reason', { ns: ['orderShowScreen'] })}</b>{' '}
            {cancellationReason}
          </ParagraphText>
        </Status>
      )}

      {!isCancelling && (
        <Status
          status={t('statusWidget.shipped', { ns: ['orderShowScreen'] })}
          active={!!actualShipDate}
        >
          {(isShipped || displayDeliveryDate) && (
            <>
              <ParagraphText color={slateGrey}>
                {isShipped && formatDate(actualShipDate, DAY_OF_WEEK_FORMAT)}
                {displayDeliveryDate && (
                  <>
                    {!order.distributorId ? (
                      <>
                        <p>
                          {t('statusWidget.expectedDelivery', {
                            ns: ['orderShowScreen'],
                          })}{' '}
                          {formatDate(deliveryDate, DAY_OF_WEEK_FORMAT)}
                        </p>
                        {user &&
                        isDistributor(user) &&
                        [
                          OrderStatusEnum.ACCEPTED,
                          OrderStatusEnum.BUILD_PENDING,
                          OrderStatusEnum.BUILT,
                        ].includes(status) ? (
                          <p>
                            {t('statusWidget.expectedShip', {
                              ns: ['orderShowScreen'],
                            })}{' '}
                            {formatDate(shipDate, DAY_OF_WEEK_FORMAT)}
                          </p>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <p>
                          {t('statusWidget.expectedDelivery', {
                            ns: ['orderShowScreen'],
                          })}
                        </p>
                        <ul className="m-l-2rem">
                          <li>
                            {t('statusWidget.expectedDeliveryDate', {
                              ns: ['orderShowScreen'],
                            })}{' '}
                            {formatDate(deliveryDate, DAY_OF_WEEK_FORMAT)}
                          </li>
                          <li>
                            {t('statusWidget.expectedDockDate', {
                              ns: ['orderShowScreen'],
                            })}{' '}
                            {formatDate(dockDate, DAY_OF_WEEK_FORMAT)}
                          </li>

                          {user &&
                          isDistributor(user) &&
                          [
                            OrderStatusEnum.ACCEPTED,
                            OrderStatusEnum.BUILD_PENDING,
                            OrderStatusEnum.BUILT,
                          ].includes(status) ? (
                            <li>
                              {t('statusWidget.expectedShipDate', {
                                ns: ['orderShowScreen'],
                              })}{' '}
                              {formatDate(shipDate, DAY_OF_WEEK_FORMAT)}
                            </li>
                          ) : null}
                        </ul>
                      </>
                    )}
                  </>
                )}
              </ParagraphText>
              <ParagraphText color={slateGrey}>
                {(isShipped || displayDeliveryDate) && shippingLink && (
                  <>{shippingLinkTag}</>
                )}
              </ParagraphText>
            </>
          )}
          {isShipped && (
            <Box>
              {carrier && (
                <CarrierText carrier={carrier} waybillNumber={waybillNumber} />
              )}
              {waybillNumber && (
                <ParagraphText color={slateGrey}>
                  <HighlightText>
                    {t('statusWidget.waybill', { ns: ['orderShowScreen'] })}
                  </HighlightText>{' '}
                  {waybillNumber}
                </ParagraphText>
              )}
            </Box>
          )}
        </Status>
      )}

      {order.status !== OrderStatusEnum.VOID && (
        <Status
          status={t('statusWidget.confirmed', { ns: ['orderShowScreen'] })}
          dateOfStatus={confirmedDate}
          active={!!confirmedDate}
          name={order.confirmedByName as string}
          type={order.confirmedByType as string}
        />
      )}

      <Status
        status={statusSubmittedText(order.customerPortalOrder)}
        dateOfStatus={submittedDate}
        active={!!submittedDate}
        name={order.createdByName as string}
        type={order.createdByType as string}
      />

      <Box
        backgroundColor={themeUtils.colors.lightGrey}
        padding="2rem"
        borderRadius="1rem"
        marginTop="auto"
        fontSize="1.5rem"
      >
        <ParagraphText variant="title" marginBottom="2rem">
          {t('statusWidget.shippingAddress', { ns: ['orderShowScreen'] })}
        </ParagraphText>
        <ParagraphText color={slateGrey}>
          <MultiLineAddress address={order.address} />
        </ParagraphText>
      </Box>
    </Flex>
  )
}
