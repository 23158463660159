import React from 'react'
import styled from '@emotion/styled'
import { enUS, fr } from 'date-fns/locale'
import {
  DatePicker as NiceDatesPicker,
  DatePickerProps,
  InputProps as DateInputProps,
} from 'react-nice-dates'
import { themeUtils } from '../../themeUtils'
import { DAY_OF_WEEK_FORMAT } from '../../constants'
import { formatDate as formatDateUtil } from '../../utils'
import { Input, InputProps as CustomInputProps } from './Input'
import './datePicker.scss'
import { ThemeVersion } from '../../types'
import i18n, { getDateFormat } from '../../i18n'

interface Props extends Partial<DatePickerProps> {
  id?: string
  label?: string
  name?: string
  format?: string
  formatDate?: (date: Date) => string
  firstDayOfWeek?: number
  placeholder?: string
  version?: ThemeVersion
  isDisabled?: boolean
}

type InputProps = DateInputProps & {
  ref: React.RefObject<HTMLInputElement>
  readOnly: boolean
}

interface CustomDateInputProps extends CustomInputProps {
  version?: ThemeVersion
}

const DateInput = styled(Input)<CustomDateInputProps>`
  height: ${props => (props.version === ThemeVersion.V2 ? '47px' : '6rem')};
  width: 100%;
  border-width: 0.1rem;
  box-shadow: 0 10px 15px rgba(141, 162, 179, 0.1);
  border-radius: ${props =>
    props.version === ThemeVersion.V2 ? '5px' : '1rem'};
  margin: ${props => (props.version === ThemeVersion.V2 ? '0' : '0 0 1rem 0')};
  padding: 2rem;
  font-size: 1.4rem;
  border-width: 1px;
  background-color: #fff;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAmUlEQVQoU2NkwAasMhoY/jM4MByf4YAuzTgsNFhmTGBgZFrAcGzaBbh/sHnaKsuA4f/feEYGy4wFDAz//RkYmR3hmtA1QBTvZ2Bg3AgJJXRNyBqQFDMcn5GACFZkTQz/AsDxwMhUADMZpBhkNmo8wDQxMB5kYGDQZ2D4LwByBkwxpga48xjioQGwEFkxdg0ITR8Yjs8oQE8JAI50VX3QNFWDAAAAAElFTkSuQmCC'); /* Add custom arrow */
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  box-shadow: none;
  color: ${themeUtils.colors.midnightBlue};
  padding: 0 3rem 0 2rem;

  &:focus {
    border-color: ${themeUtils.colors.geyserGrey};
  }

  &::placeholder {
    color: ${themeUtils.colors.slateGrey};
    opacity: 1;
  }
`

const DayPickerWrapper = styled.div<{ version?: ThemeVersion }>`
  /* https://www.styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity */
  &&& {
    .nice-dates {
      position: relative;
    }
    .nice-dates-popover {
      margin: ${props => (props.version === ThemeVersion.V2 ? '0' : '-1rem 0')};
    }
  }
`

export const DatePicker: React.FC<Props> = ({
  formatDate = formatDateUtil,
  format = getDateFormat(DAY_OF_WEEK_FORMAT),
  placeholder = 'Select a date',
  date,
  onDateChange,
  modifiers,
  locale = i18n.language === 'fr' ? fr : enUS,
  version,
  isDisabled,
  ...restProps
}) => {
  // const odc = onDateChange || null
  const modifiersClassNames = {
    // TODO: uncomment the line below to implement dates highlighting
    // highlight: '-highlight'
  }
  return (
    <DayPickerWrapper version={version}>
      <NiceDatesPicker
        date={date}
        onDateChange={onDateChange}
        locale={locale}
        modifiers={modifiers}
        format={format}
        modifiersClassNames={modifiersClassNames}
        {...restProps}
      >
        {({ inputProps, focused }) => {
          const props = { ...inputProps, placeholder }
          return (
            <DateInput
              className={'input' + (focused ? ' -focused' : '')}
              version={version}
              isDisabled={isDisabled}
              data-cy={restProps.id}
              {...props}
            />
          )
        }}
      </NiceDatesPicker>
    </DayPickerWrapper>
  )
}
