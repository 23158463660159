import React, { useEffect } from 'react'
import { MissingInfoModal } from './MissingInfoModal'
import { useDisclosure } from '@chakra-ui/core'
import { OrderShow_order } from '../../../schema/queries/__generated__/OrderShow'
import { OrderMissingInfoFormData } from '../OrderCreate/OrderFormDataTypes'
import { useMutation } from 'react-apollo'
import {
  UpdateOrder,
  UpdateOrderVariables,
} from '../../../schema/mutations/__generated__/UpdateOrder'
import { UPDATE_ORDER_MUTATION } from '../../../schema/mutations'
import { Notification, handleErrors } from '../../../utils'
import { useUser } from '../../../context/user-context'
import { useFeatureFlag } from '../../../context/FeatureFlagsContext'
import { navigate, useLocation } from '@reach/router'
import { ORDER_SHOW_PATH } from '../../../routes'
import { isROWCustomer } from '../../lib'
import { useTranslation } from 'react-i18next'

interface Props {
  order: OrderShow_order
  refetch: Function
}

interface CustomizedState {
  showOrderMissinigInfoModal: boolean
}

export const MissingDetailsWidget: React.FC<Props> = ({ order, refetch }) => {
  const { t } = useTranslation(['orderShowScreen'])
  const user = useUser()
  const location = useLocation()
  const locationState = location.state as CustomizedState
  const treatingPhysicianFeatureFlag = useFeatureFlag('use_treating_physician')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [updateOrder, { loading: updateLoading }] = useMutation<
    UpdateOrder,
    UpdateOrderVariables
  >(UPDATE_ORDER_MUTATION)

  useEffect(() => {
    const IsAccessoryOnlyOrder: boolean = !order.treatments.length
    if (
      treatingPhysicianFeatureFlag &&
      locationState &&
      locationState.showOrderMissinigInfoModal &&
      !IsAccessoryOnlyOrder
    ) {
      const isMissingInfo =
        (!isROWCustomer(user) &&
          (!order.poNumber ||
            !order.patientRef ||
            !order.treatingPhysicianName)) ||
        (isROWCustomer(user) && (!order.poNumber || !order.patientRef))

      if (isMissingInfo) {
        onOpen()
      }
    }
  }, [])

  const onSubmit = async (
    formData: OrderMissingInfoFormData
  ): Promise<void> => {
    const { data, errors } = await updateOrder({
      variables: {
        input: {
          id: order?.id,
          poNumber: formData.poNumber,
          patientRef: formData.patientRef,
          treatingPhysicianId: formData.treatingPhysicianId,
          treatingPhysicianName: formData.treatingPhysicianName,
        },
      },
    })

    if (errors) {
      errors.forEach(error => Notification.error(error.message))
    } else {
      if (data && data.updateOrder.__typename === 'Error') {
        handleErrors(data.updateOrder.errors)
      } else {
        refetch()
        Notification.success(t('missingInfoModal.successMsg'))
        onModalClose()
      }
    }
  }

  const onModalClose = (): void => {
    onClose()
    // to reset the state of the location and will not open the popup again
    navigate(ORDER_SHOW_PATH.replace(':orderId', order.orderNumber))
  }

  return (
    <MissingInfoModal
      order={order}
      isOpen={isOpen}
      onClose={onModalClose}
      onSubmit={onSubmit}
      onOpen={onOpen}
      isLoading={updateLoading}
    />
  )
}
