import React from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import { useTranslation } from 'react-i18next'

const NotFound: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation(['notFound'])
  return (
    <div
      css={{
        height: '100%',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div>
        {t('noneHere', { ns: ['notFound'] })}{' '}
        <Link to="/">{t('linkText', { ns: ['notFound'] })}</Link>
      </div>
    </div>
  )
}

export { NotFound }
