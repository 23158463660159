import * as React from 'react'
import { Global } from '@emotion/core'
import { ThemeProvider, CSSReset } from '@chakra-ui/core'
import CustomTheme from '../../CustomTheme'
import GlobalStyles from '../../GlobalStyles'

export const GlobalLayout: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={CustomTheme}>
      <CSSReset />
      <Global styles={GlobalStyles} />
      {children}
    </ThemeProvider>
  )
}
