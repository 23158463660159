import React from 'react'
import { IconProps } from '../../types'

const EmailIcon: React.FC<IconProps> = ({
  fill = '#5c6c7a',
  width = 20,
  height = 16,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 16"
    >
      <path fill={fill} d="M0 .5h20v15H0zm18 13V5.453l-8 7.027-8-7.035V13.5zm0-11H2v.281l8 7.037 8-7.027z"/>
    </svg>
  )
}

export { EmailIcon }
