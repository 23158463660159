import React, { useMemo } from 'react'
import { GetTeamMembers_teamMembers } from '../../../schema/queries/__generated__/GetTeamMembers'
import { Box, Flex, Text } from '@chakra-ui/core'
import { themeUtils } from '../../../themeUtils'
import { TeamMemberProfessionEnum } from '../../../__generated__/globalTypes'
import { humanize, titleize } from 'underscore.string'
import { isROWCustomer } from '../../lib'
import { useUser } from '../../../context/user-context'
import { GetProfessionValue } from './TeamMemberRow'

interface Props {
  member: GetTeamMembers_teamMembers
}

const { blue, lightGrey } = themeUtils.colors

export const MemberCard: React.FC<Props> = ({member}) => {
  const user = useUser()
  const {
    firstName,
    lastName,
    email,
    profession,
    otherProfession
  } = member

  const professionValue = useMemo(() => {
    return GetProfessionValue(profession, otherProfession)
  }, [profession, otherProfession])

  return (
    <Box
      marginBottom={'1rem'}
      borderBottom="1px"
      borderRadius="md"
      borderColor={lightGrey}
    >
      <Flex>
        <Text color={blue} marginRight={'1rem'}>
          <b>{titleize(humanize(`${firstName} ${lastName}`))}</b>
        </Text>
        <Text>{professionValue}</Text>
      </Flex>
      {!isROWCustomer(user) && <Text>{email}</Text>}
    </Box>
  )
}