import { parseISO, differenceInBusinessDays, getDay } from 'date-fns'
import { minutesSinceMidnight } from '../orders'
import { newDate } from '../../../utils'
import i18n from '../../../i18n'

export const calculateShippingTimeline = (
  treatmentDate: string,
  treatmentTime: string,
  optimumShipDate: Date,
  amTreatmentWindow: string
): number => {
  const parsedTreatmentDate = newDate(parseISO(treatmentDate))
  const treatmentDay = getDay(parsedTreatmentDate)
  const optimumShipDay = getDay(optimumShipDate)
  const minsSinceMidnight = minutesSinceMidnight(treatmentDate, treatmentTime)
  const amTreatmentTime = amTreatmentWindow
  const amMinsSinceMidnight = minutesSinceMidnight(treatmentDate, amTreatmentTime)

  let shippingTimelineDiff = differenceInBusinessDays(
    parsedTreatmentDate,
    optimumShipDate
  )

  // thursday ship & monday treatment
  if (optimumShipDay === 4 && treatmentDay === 1) {
    shippingTimelineDiff = 2
  }

  // friday ship & monday, tuesday treatment
  if (optimumShipDay === 5 && [1, 2, 3].includes(treatmentDay)) {
    shippingTimelineDiff = treatmentDay
  }

  const shippingTimelineCalc =
    minsSinceMidnight >= amMinsSinceMidnight ? shippingTimelineDiff + 1 : shippingTimelineDiff
  const shippingTimeline = Math.max(0, shippingTimelineCalc)

  return shippingTimeline
}

export const shippingErrorMessage = (
  shippingTimeline: number | undefined,
  submitting = false
): string => {
  let msg = ''
  if (shippingTimeline || shippingTimeline === 0) {
    if (shippingTimeline <= 1) {
      msg = i18n.t('oneDay', { ns: ['shippingErrorMessage'] })

      if (submitting) {
        msg = i18n.t('oneDaySubmit', { ns: ['shippingErrorMessage'] })
      }
    }

    if (shippingTimeline === 2) {
      msg = i18n.t('twoDay', { ns: ['shippingErrorMessage'] })
    }

    if (shippingTimeline === 3) {
      msg = i18n.t('threeDay', { ns: ['shippingErrorMessage'] })
    }
  }

  return msg
}
