import React, { ReactNode } from 'react'
import { DatePickerProps } from 'react-nice-dates'
import { FormControl } from '@chakra-ui/core'
import { FormErrorMessage } from '../FormErrorMessage'
import { FormHelperText } from '../FormHelperText'
import { DatePicker } from '../DatePicker'
import { ThemeVersion } from '../../../types'
import { FormLabel } from '../FormLabel'

interface Props extends Partial<DatePickerProps> {
  [key: string]: any
  label?: ReactNode
  name: string
  placeholder?: string
  smallLabel?: boolean
  error?: string
  hint?: string
  isRequired?: boolean
  hidden?: boolean
  isDisabled?: boolean
}

export const DatePickerWidget: React.FC<Props> = ({
  label,
  placeholder,
  id,
  name,
  error,
  hint,
  invalid = false,
  isRequired,
  hidden,
  isDisabled,
  ...restProps
}) => {
  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={!!error}
      hidden={hidden}
      width={'100%'}
    >
      {!!label && <FormLabel>{label}</FormLabel>}
      <DatePicker
        id={id}
        placeholder={placeholder}
        name={name}
        version={ThemeVersion.V2}
        isDisabled={isDisabled}
        {...restProps}
      />
      {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
      {!!hint && <FormHelperText>{hint}</FormHelperText>}
    </FormControl>
  )
}
