import styled from '@emotion/styled'
import { Text } from '@chakra-ui/core'

export const DoseText = styled(Text)`
  font-size: 3rem;
  padding-right: 4.5rem;
  position: relative;
  height: 100%;
  width: 10rem;
  line-height: 30px;
  display: inline-flex;

  &::before {
    position: absolute;
    content: '';
    width: 0.1rem;
    height: 3rem;
    top: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.18);
  }

  small {
    font-size: 1.4rem;
    font-weight: 500;
    margin-left: 0.5rem;
  }
`
