import React from 'react'
import { IconProps } from '../../types'
import styled from '@emotion/styled'

type Props = IconProps & React.SVGAttributes<SVGElement>

const Svg = styled.svg`
  flex-shrink: 0;
  backface-visibility: hidden;
  &:not(:root) {
    overflow: hidden;
  }
`

const CancelRemovalRequestWhiteIcon: React.FC<Props> = ({
  fill = '#fff',
  width = 17,
  height = 16,
  ...rest
}) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      {...rest}
    >
      <g>
        <g>
          <path
            d="m4.912336,0.201795l11.204704,0a0.774608,0.871659 0 0 1 0.774608,0.871659l0,13.946536a0.774608,0.871659 0 0 1 -0.774608,0.871659l-11.204704,0a0.774608,0.871659 0 0 1 -0.644474,-0.387888l-4.130984,-6.973268a0.774608,0.871659 0 0 1 0,-0.967541l4.130984,-6.973268a0.774608,0.871659 0 0 1 0.644474,-0.387888zm0.414415,1.743317l-3.614321,6.10161l3.614321,6.10161l10.015681,0l0,-12.203219l-10.015681,0zm4.593425,4.869084l2.190591,-2.465922l1.09607,1.233397l-2.191366,2.46505l2.191366,2.46505l-1.09607,1.233397l-2.190591,-2.465922l-2.190591,2.465922l-1.09607,-1.233397l2.191366,-2.46505l-2.191366,-2.46505l1.09607,-1.233397l2.190591,2.465922z"
            fill={fill}
          ></path>
        </g>
      </g>
    </Svg>
  )
}

export { CancelRemovalRequestWhiteIcon }
