import React from 'react'
import { useUser } from '../../../context/user-context'
import { CustomerRep } from './CustomerRep'

interface Props {
  layout?: 'order' | 'dashboard'
  topPad?: boolean
}

export const CustomerCareContent: React.FC<Props> = ({ layout, topPad }) => {
  const user = useUser()
  const salesRep = user?.customer.salesRep
  const customerSalesRep = user?.customer.regionalCustomerCareRep
  const layoutType = layout === 'dashboard' ? 'column' : 'row'
  return (
    <>
      {!!salesRep && (
        <CustomerRep
          topPad={topPad}
          isSalesRep
          user={salesRep}
          layout={layoutType}
        />
      )}
      {!!customerSalesRep && (
        <CustomerRep
          initials="C S"
          topPad={topPad}
          user={customerSalesRep}
          layout={layoutType}
        />
      )}
    </>
  )
}
