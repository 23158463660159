import React from 'react'
import { OrderFormClearErrorType, OrderFormErrorType, TreatmentDetailsWidget } from './'
import { OrderFormData } from '../OrderCreate/OrderFormDataTypes'
import { NestDataObject } from 'react-hook-form'
import { useOrderForm } from '../../../hooks'
import { OrderFormStepType } from '../../../context/OrderFormContext'
import styled from '@emotion/styled'
import { MEDIUM, mqNames } from '../../../themeUtils'

interface Props {
  formData: OrderFormData
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
  onChangeCheckBoxValue: (val: boolean) => void
  createAccessoryOnlyOrder: boolean
  changeTreatmentDate: (date: Date | null) => void
  minOrderTreatmentDate(isMnxEligible: boolean): Date
  isMnxEligibleOrder: () => boolean
  isMnxTreatmentDate: () => boolean
  errors: NestDataObject<OrderFormData>
  setError: OrderFormErrorType
  clearError: OrderFormClearErrorType
}

const Container = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  ${mqNames(MEDIUM)} {
    grid-template-columns: 1.2fr 1fr;
    grid-template-rows: auto auto;
  }
`

export const Step1: React.FC<Props> = ({ ...props }) => {
  const { step } = useOrderForm()
  if (step !== OrderFormStepType.STEP_1) return null
  return (
    <Container>
      <TreatmentDetailsWidget {...props} />
    </Container>
  )
}
