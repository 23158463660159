import React from 'react'
import styled from '@emotion/styled'
import { themeUtils } from '../../../themeUtils'
import { useUser } from '../../../context/user-context'
import { useTranslation } from 'react-i18next'

const Nav = styled.nav`
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  li {
    min-width: 10rem;
  }

  a {
    text-decoration: none;
    color: ${themeUtils.colors.lightBlack};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6rem;

    font-weight: 700;
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 24px;
    line-height: 2.4rem;

    &:hover {
      color: ${themeUtils.colors.darkGrey};
    }
  }

  a.active {
    color: ${themeUtils.colors.darkGrey};
  }
`

const FooterNavBar: React.FC = () => {
  const { t } = useTranslation(['navBar'])
  const user = useUser()
  return (
    <Nav>
      <ul>
        <li>
          <a
            href={user?.countryConfiguration.privacyNoticeLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.privacyPolicy')}
          </a>
        </li>
        <li>
          <a
            href={user?.countryConfiguration.termsOfUseLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.termsOfUse')}
          </a>
        </li>
        <li>
          <a
            href={user?.countryConfiguration.copyrightNoticeLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.copyrightNotice')}
          </a>
        </li>
      </ul>
    </Nav>
  )
}

export { FooterNavBar }
