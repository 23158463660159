import Cookie from 'js-cookie'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { ApolloLink } from 'apollo-link'
import { CSRF_TOKEN } from '../constants'
import { typeDefs, resolvers, cache, initialCacheData } from './client-schema'
import i18n from '../i18n'

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_PORTAL_API_URL}/portal/graphql`,
  credentials: 'include',
})

const csrfLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-CSRF-Token': Cookie.get(CSRF_TOKEN),
      'X-Locale': i18n.language
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      )

      if (
        extensions &&
        extensions.code &&
        extensions.code === 'AUTHENTICATION_ERROR'
      ) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        client.resetStore()
      }
    })
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const afterwareLink = new ApolloLink((operation, forward) => {
  if (!forward) return null
  return forward(operation).map(response => {
    const context = operation.getContext()
    const {
      response: { headers },
    } = context

    if (headers) {
      const csrfToken = headers.get(CSRF_TOKEN)
      if (csrfToken) {
        Cookie.set(CSRF_TOKEN, csrfToken)
      }
    }

    return response
  })
})

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, csrfLink, afterwareLink, httpLink]),
  typeDefs,
  resolvers,
  cache,
  assumeImmutableResults: true,
})

client.onResetStore(async () => cache.writeData({ data: initialCacheData }))
