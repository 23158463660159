import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { Input, InputProps } from './Input'
import { themeUtils } from '../../themeUtils'

type Ref = HTMLInputElement

const CustomSelect = styled(Input)`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  border-width: 1px;
  background-color: #fff;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAmUlEQVQoU2NkwAasMhoY/jM4MByf4YAuzTgsNFhmTGBgZFrAcGzaBbh/sHnaKsuA4f/feEYGy4wFDAz//RkYmR3hmtA1QBTvZ2Bg3AgJJXRNyBqQFDMcn5GACFZkTQz/AsDxwMhUADMZpBhkNmo8wDQxMB5kYGDQZ2D4LwByBkwxpga48xjioQGwEFkxdg0ITR8Yjs8oQE8JAI50VX3QNFWDAAAAAElFTkSuQmCC'); /* Add custom arrow */
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  padding: 0 3rem 0 2rem;

  /* https://www.styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity */
  &&& {
    color: ${(props: InputProps) => {
      return props.isEmpty
        ? themeUtils.colors.slateGrey
        : themeUtils.colors.midnightBlue
    }};
  }

  /* Hide the default arrow in IE 10 and 11 */
  &::-ms-expand {
    display: none;
  }
`

export const Select = forwardRef<Ref, InputProps>((props, ref) => {
  return <CustomSelect as="select" ref={ref} {...props} />
})

Select.displayName = 'Select'
