import React from 'react'
import { IconProps } from '../../types'

export const PlusIconThin: React.FC<IconProps> = ({
  fill = '#fff',
  width = 14,
  height = 14,
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 6V8H8V14H6V8H0V6H6V0H8V6H14Z"
        fill={fill}
      />
    </svg>
  )
}
