import React from 'react'
import styled from '@emotion/styled'

const StyledSubText = styled.p`
  margin-bottom: 2rem;
`

const SubText: React.FC = props => <StyledSubText {...props} />

export { SubText }
