/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx, SerializedStyles } from '@emotion/core'
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/core'
import {
  breakpoints,
  LARGE,
  X_LARGE,
  themeUtils,
  mqNames,
} from '../../themeUtils'
import useMedia from 'use-media'
import styled from '@emotion/styled'

interface Props {
  value?: number
  thickness?: number
  color?: string
  label: string
  count: string | number
  onClick: () => void
}

const Count = styled.span`
  color: ${themeUtils.colors.blue};
  display: block;
  font-size: 40px;
  text-align: center;

  ${mqNames(LARGE)} {
    margin-bottom: 5px;
  }
`

const Label = styled.span`
  display: block;
  text-align: center;
  font-size: 1.4rem;
`

const Wrapper = styled.div`
  cursor: pointer;
  width: calc((100% - 40px) / 3);
`

const getProgressStyle = (colour?: string): SerializedStyles | undefined => {
  if (!colour?.startsWith('#')) return undefined

  return css`
    [data-progress-indicator] {
      color: ${colour};
      transition: all 0.2s ease-in-out;
    }
  `
}

export const StatisticDial: React.FC<Props> = ({
  label,
  count,
  value = 25,
  thickness = 0.06,
  color,
  onClick,
}) => {
  const isLargeScreen = useMedia({ minWidth: breakpoints[X_LARGE] })
  const isHexColour = color?.startsWith('#')

  const [hover, setHover] = useState(false)
  const toggle = (): void => setHover(!hover)

  return (
    <Wrapper onClick={onClick}>
      <CircularProgress
        value={hover ? 100 : value}
        thickness={thickness}
        color={isHexColour ? undefined : color}
        // NB: removing height forces responsiveness
        height={undefined}
        width="100%"
        css={getProgressStyle(color)}
        onMouseOver={toggle}
        onMouseOut={toggle}
      >
        <CircularProgressLabel>
          <Count>{count}</Count>
          {isLargeScreen && <Label>{label}</Label>}
        </CircularProgressLabel>
      </CircularProgress>
      {!isLargeScreen && <Label>{label}</Label>}
    </Wrapper>
  )
}
