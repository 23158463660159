import React from 'react'
import { IconProps } from '../../types'

interface CustomProps {
  rotate?: number
}
type ChevronProps = IconProps & CustomProps

const ChevronIcon: React.FC<ChevronProps> = ({
  fill = '#fff',
  width = 24,
  height = 24,
  rotate = 0,
  title,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      transform={`rotate(${rotate})`}
      viewBox="0 0 26 24"
      aria-labelledby="title"
      role="img"
      strokeWidth="1.5"
      stroke={fill}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <title id="title">{title}</title>
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <polyline points="15 6 9 12 15 18" />
    </svg>
  )
}

export { ChevronIcon }
