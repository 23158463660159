import facepaint from 'facepaint'
import BackgroundImage from './images/background.png'

export interface KeyAttrs {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export const SMALL = 'small'
export const MEDIUM = 'medium'
export const LARGE = 'large'
export const X_LARGE = 'xLarge'

export const themeUtils = {
  breakpoints: {
    [SMALL]: '480px',
    [MEDIUM]: '768px',
    [LARGE]: '992px',
    [X_LARGE]: '1200px',
  },
  colors: {
    blue: '#004e8f',
    darkBlue: '#013c71',
    darkBlueOpaque: 'rgba(18, 25, 35, 0.7)',
    darkGrey: '#b7babd',
    geyserGrey: '#d8dde2',
    regentGray: '#7d8b98',
    lightGray: '#cbd5e0',
    green: '#45da7a',
    borderGrey: '#ebeff3',
    grey: '#efeeee',
    lightGrey: '#f8f8f8',
    midnightBlue: '#003968',
    red: '#f58774',
    shuttleGrey: '#5c6c7a',
    slateGrey: '#768592',
    slateGreyLight: '#84929d',
    white: '#ffffff',
    lightBlack: '#3c3c3c',
    black: '#333333',
    yellow: '#ffd76b',
    turquoise: '#58dacd',
    fringyFlower: '#B4E2D1',
    limedSpruce: '#333D46',
    casper: '#b2c3d3',
    antiFlashWhite: '#F2F5F7',
    keppel: '#36B0A2',
    ufoGreen: 'rgba(69, 218, 122)',
    ufoGreenLight: 'rgba(69, 218, 122, 0.1)',
    moonstone: 'rgb(54, 164, 176)',
  },
  fonts: {
    body: '"Red Hat Display", sans-serif',
    headings: '"Red Hat Display", sans-serif',
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  fontSizes: {
    sm: '1.4rem',
    md: '1.8rem',
    lg: '3rem',
  },
  images: {
    backgroundImage: BackgroundImage,
  },
  widths: {
    max: '1920px',
  },
  padding: '3rem',
}

export const breakpoints = themeUtils.breakpoints

function prop<T, K extends keyof T>(obj: T, key: K): T[K] {
  return obj[key]
}

// similar to the example here https://emotion.sh/docs/media-queries#reusable-media-queries
// bu allows you to reference via names of breakpoints rather than index of the array item
// e.g ${mqNames('small')} rather than ${mq[0]}
export const mqNames = (n: string): string => {
  const bpArray: string[][] = Object.keys(breakpoints).map(key => [
    key,
    prop(breakpoints, key as keyof typeof breakpoints),
  ])

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (min-width: ${size})`]
    return acc
  }, Array<string>())

  return result
}

// example of using facepaint here https://emotion.sh/docs/media-queries#facepaint
export const mq = facepaint(
  Object.values(breakpoints).map(bp => `@media (min-width: ${bp})`)
)
