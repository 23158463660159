import React from 'react'
import { Flex } from '@chakra-ui/core'
import { OrderShow_order } from '../../../schema/queries/__generated__/OrderShow'
import { OrderFinanceStatusEnum } from '../../../__generated__/globalTypes'
import { PoNumberInlineForm } from '../ShippingWidget/PoNumberInlineForm'
import { BillingItem } from './BillingItem'
import { FinanceInformation } from './FinanceInformation'
import { useAuth } from '../../../context/auth-context'
import { useTranslation } from 'react-i18next'

interface BillingInformationProps {
  order: OrderShow_order
  refetch: Function
}

type Props = BillingInformationProps

const BillingInformationWidget: React.FC<Props> = ({ order, refetch }) => {
  const { t } = useTranslation(['orderShowScreen'])
  const auth = useAuth()
  if (!order) return null
  const { financeStatus } = order
  const hasFinanceStatus = financeStatus !== OrderFinanceStatusEnum.NONE

  return (
    <Flex direction="column">
      <BillingItem
        heading={t('poNumForm.heading', { ns: ['orderShowScreen'] })}
        direction={{ base: 'column', sm: 'row' }}
      >
        <PoNumberInlineForm
          order={order}
          refetch={refetch}
          canEdit={auth?.user?.canEditOrders}
          wrapperProps={{
            marginTop: { base: '1rem', sm: 0 },
            marginBottom: '1rem',
          }}
        ></PoNumberInlineForm>
      </BillingItem>
      {hasFinanceStatus ? <FinanceInformation order={order} /> : null}
    </Flex>
  )
}

export { BillingInformationWidget }
