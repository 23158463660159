import React from 'react'
import { IconProps } from '../../types'
import styled from '@emotion/styled'

type Props = IconProps & React.SVGAttributes<SVGElement>

const Svg = styled.svg`
  flex-shrink: 0;
  backface-visibility: hidden;
  &:not(:root) {
    overflow: hidden;
  }
`

const DownloadSquareIcon: React.FC<Props> = ({
  fill = '#fff',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      {...rest}
    >
      <g>
        <g>
          <path
            d="M0,1.6c0,-0.8832 0.716,-1.6 1.6,-1.6h12.8c0.8832,0 1.6,0.7168 1.6,1.6v12.8c0,0.884 -0.7168,1.6 -1.6,1.6h-12.8c-0.884,0 -1.6,-0.716 -1.6,-1.6v-12.8M13.6,14.4c0.4416,0 0.8,-0.3576 0.8,-0.8v-11.2c0,-0.4416 -0.3584,-0.8 -0.8,-0.8h-11.2c-0.4416,0 -0.8,0.3584 -0.8,0.8v11.2c0,0.4424 0.3584,0.8 0.8,0.8h11.2M11.372,9.7816l-2.2728,2.1488c-0.6168,0.5824 -1.5816,0.5824 -2.1984,0l-2.2728,-2.1488c-0.3216,-0.3032 -0.3352,-0.8096 -0.032,-1.1312c0.3032,-0.3208 0.8096,-0.3352 1.1312,-0.032l0.7976,0.7544c0.2552,0.2416 0.6752,0.0608 0.6752,-0.2904v-4.2824c0,-0.4416 0.3584,-0.8 0.8,-0.8c0.4416,0 0.8,0.3584 0.8,0.8v4.2824c0,0.3512 0.42,0.532 0.6744,0.2904l0.7984,-0.7544c0.3208,-0.3032 0.8272,-0.2888 1.1312,0.032c0.3032,0.3216 0.2888,0.828 -0.032,1.1312"
            fill={fill}
          ></path>
        </g>
      </g>
    </Svg>
  )
}

export { DownloadSquareIcon }
