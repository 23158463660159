import React from 'react'
import styled from '@emotion/styled'
import { themeUtils } from '../../../themeUtils'
import { Flex, FlexProps } from '@chakra-ui/core'

const { midnightBlue, geyserGrey, white } = themeUtils.colors

interface Props {
  id?: string
  label?: string
  defaultChecked?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  disabled?: boolean
}

const CheckboxContainer = styled(Flex)<FlexProps>`
  gap: 10px;

  .checkBox {
    width: 20px;
    height: 20px;
    flex-shrink: 0;

    border-radius: 5px;
    border: 1px solid ${geyserGrey};
    background: ${white};
  }
`

const LabelStyle = styled.label`
  color: ${midnightBlue};
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
`

export const Checkbox: React.FC<Props> = ({
  id,
  label,
  defaultChecked,
  onChange,
  disabled,
}) => {
  return (
    <CheckboxContainer>
      <input
        id={id}
        type="checkbox"
        defaultChecked={defaultChecked}
        onChange={onChange}
        disabled={disabled}
        data-cy={id}
      />
      {label && <LabelStyle>{label}</LabelStyle>}
    </CheckboxContainer>
  )
}
