import gql from 'graphql-tag'

export const COUNTRY_CONFIGURATION = gql`
  query CountryConfiguration {
    countryConfiguration {
      id
      name
      countryCode
      isEnabled
      cutOffTime
      amTreatmentWindow
      timezone
      minimumBufferDays
      amTreatmentOption
      alertMessage
      cutOffPassed
      cutOffTimeToday
    }
  }
`
