import React from 'react'
import { Alert as BaseAlert, AlertProps } from '@chakra-ui/core'

const Alert: React.FC<AlertProps> = props => (
  <BaseAlert
    lineHeight="2rem"
    borderRadius="1rem"
    padding="0.5rem 2rem"
    {...props}
  />
)

export { Alert }
