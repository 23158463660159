import React from 'react'
import { Flex } from '@chakra-ui/core'
import { TherasphereLogo } from './Icons/TherasphereLogo'
import { Spinner } from './Shared'

export const AppSpinner: React.FC = () => (
  <Flex
    direction="column"
    justifyContent="center"
    alignItems="center"
    height="100vh"
  >
    <TherasphereLogo
      fill="#003c68"
      width="100%"
      height="50px"
    ></TherasphereLogo>
    <Spinner />
  </Flex>
)
