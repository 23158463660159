import React from 'react'
import { Box, Text } from '@chakra-ui/core'
import { AccessoryIcon } from '../../Icons'
import { ProductContainer } from './ProductContainer'
import { OrderShow_order_accessories } from '../../../schema/queries/__generated__/OrderShow'
import { AdminSetItemNumbers } from '../../../constants'
import { DeliveryAccessoryContainer } from './DeliveryAccessoryContainer'
import { useUser } from '../../../context/user-context'
import { RegionCodeEnum } from '../../../__generated__/globalTypes'
import { isUSCustomer } from '../../lib'
import { useTranslation } from 'react-i18next'

interface Props {
  accessory: OrderShow_order_accessories
  index: number
}

export const Accessory: React.FC<Props> = ({ accessory, index }) => {
  const { t } = useTranslation(['treatment'])
  const user = useUser()
  let {
    itemNumber,
    itemDescription,
    isDeliveryFreight,
    price,
  } = accessory.product
  if (accessory.freeReason) {
    price = '0'
  }

  const accessoryDescription = AdminSetItemNumbers.includes(itemNumber)
    ? t('accessory.accessorySet', { ns: ['treatment'] })
    : itemDescription

  // @ts-ignore
  return (
    <div>
      {isDeliveryFreight ? (
        <>
          {isUSCustomer(user) && (
            <DeliveryAccessoryContainer
              backgroundColor={'#E5EBEF'}
              color={'rgba(0,57,104, 1)'}
            >
              <Box
                display="flex"
                flex="1"
                justifyContent="center"
                alignSelf="center"
              >
                <Text>
                  {accessoryDescription} - ${price}
                </Text>
              </Box>
            </DeliveryAccessoryContainer>
          )}
        </>
      ) : (
        <ProductContainer>
          <Box width="3.4rem" height="3rem">
            <AccessoryIcon />{' '}
          </Box>
          <Text marginLeft="1.5rem">{accessoryDescription}</Text>
          <Box
            display="flex"
            flex="1"
            justifyContent="flex-end"
            alignSelf="center"
          >
            <Text
              border="1px solid rgba(255, 255, 255, 0.3)"
              boxShadow="0px 10px 15px rgba(0, 57, 104, 0.1)"
              borderRadius="0.5rem"
              padding="0.5rem 1rem"
            >
              {accessory.quantity}x
            </Text>
          </Box>
        </ProductContainer>
      )}
    </div>
  )
}
