import React from 'react'
import { Flex, Text, FlexProps } from '@chakra-ui/core'
import styled from '@emotion/styled'
import {
  GetMyPortalUser_me_customer_salesRep,
  GetMyPortalUser_me_customer_regionalCustomerCareRep,
} from '../../../context/__generated__/GetMyPortalUser'
import { LinkButton, InitialAvatar } from '../../Shared'
import { mqNames, themeUtils, SMALL } from '../../../themeUtils'
import { useTranslation } from 'react-i18next'

type UserType =
  | GetMyPortalUser_me_customer_salesRep
  | GetMyPortalUser_me_customer_regionalCustomerCareRep

interface Props {
  topPad?: boolean
  initials?: string
  isSalesRep?: boolean
  user: UserType
  layout?: 'row' | 'column'
}

type StyleProps = Partial<Props> & FlexProps

const CustomerRepBox = styled(Flex)<StyleProps>`
  flex-direction: row;
  justify-content: space-between;

  ${mqNames(SMALL)} {
    flex-direction: column;
  }
`

const calcFontSize = (size: string): string => `calc(${size} / 2.5)`
const avatarSizes = { base: '8rem', md: '5rem', lg: '4rem' }

interface FlexStyles {
  [key: string]: FlexProps
}

const StyledLinkButton = styled(LinkButton)`
  flex: 1;
  min-width: 0;
  margin: 1rem 0 0 0;
  padding: 0 0.5rem;
  background-color: ${themeUtils.colors.casper};
  color: ${themeUtils.colors.white};
  border-radius: 0.5rem;
  align-self: flex-start;
`

export const CustomerRep: React.FC<Props> = ({
  topPad = false,
  initials,
  user,
  isSalesRep,
}) => {
  const { t } = useTranslation(['dashboardScreen'])
  return (
    <CustomerRepBox paddingTop={topPad ? '2rem' : 0}>
      <Flex alignContent="center">
        <InitialAvatar
          bg={themeUtils.colors.borderGrey}
          minWidth={{
            base: avatarSizes.base,
            md: avatarSizes.md,
            lg: avatarSizes.lg,
          }}
          width={{
            base: avatarSizes.base,
            md: avatarSizes.md,
            lg: avatarSizes.lg,
          }}
          height={{
            base: avatarSizes.base,
            md: avatarSizes.md,
            lg: avatarSizes.lg,
          }}
          fontSize={{
            base: calcFontSize(avatarSizes.base),
            md: calcFontSize(avatarSizes.md),
            lg: calcFontSize(avatarSizes.lg),
          }}
          marginRight={{ base: '3rem', md: '2rem', lg: '1.5rem' }}
          name={initials ? initials : user.name}
        />
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            display="block"
            width="100%"
            color={themeUtils.colors.midnightBlue}
            fontWeight="medium"
          >
            {user.name}
          </Text>
          <Text display="block" width="100%">
            {isSalesRep
              ? t('customerCare.ioConsultant', { ns: ['dashboardScreen'] })
              : t('customerCare.customerSupport', { ns: ['dashboardScreen'] })}
          </Text>
          <StyledLinkButton href={`mailto:${user.email}`} title={user.email}>
            {t('customerCare.email', { ns: ['dashboardScreen'] })}
          </StyledLinkButton>
        </Flex>
      </Flex>
    </CustomerRepBox>
  )
}
