import React from 'react'
import { Flex, Box, BoxProps } from '@chakra-ui/core'
import { format, parse } from 'date-fns'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { formatDate } from '../../../utils'
import { DAY_OF_WEEK_FORMAT } from '../../../constants'
import { mqNames, MEDIUM, themeUtils } from '../../../themeUtils'
import { OrderShow_order } from '../../../schema/queries/__generated__/OrderShow'
import { OrderFormData } from '../OrderCreate/OrderFormDataTypes'
import { MultiLineAddress, DetailText, WidgetHeader } from '../../Shared'
import { useFeatureFlag } from '../../../context/FeatureFlagsContext'
import { useTranslation } from 'react-i18next'
import { getTreatmentIntentOptions } from '../NewOrderForm'
import { useUser } from '../../../context/user-context'
import { isROWCustomer } from '../../lib'
import { getTimeFormat } from '../../../i18n'

interface Props {
  order: OrderShow_order | OrderFormData
  separator?: boolean
  createAccessoryOnlyOrder?: boolean
}

type StyleProps = Partial<Props> & BoxProps

const separatorPartial = (props: StyleProps) =>
  props.separator
    ? css`
        position: relative;
        &::before {
          position: absolute;
          content: '';
          width: 0.1rem;
          height: 100%;
          bottom: 0;
          right: 0;
          background: ${themeUtils.colors.borderGrey};
        }
      `
    : null

const ShippingWrapper = styled(Box)<StyleProps>`
  max-width: 100%;

  ${mqNames(MEDIUM)} {
    max-width: 35rem;
    ${separatorPartial};
  }

  p {
    font-size: 1.7rem;
  }

  .error {
    color: red;
  }
`

export const ShippingWidget: React.FC<Props> = ({
  order,
  separator = true,
  createAccessoryOnlyOrder,
}) => {
  const { t } = useTranslation(['orderConfirmationModal'])
  const user = useUser()
  const treatingPhysicianFeatureFlag = useFeatureFlag('use_treating_physician')
  if (!order) return null
  const treatmentTime = parse(order.treatmentTime, 'HH:mm', new Date())
  const patientIntent =
    getTreatmentIntentOptions().find(o => o.value == order?.treatmentIntent)
      ?.label ?? null
  return (
    <ShippingWrapper separator={separator}>
      <WidgetHeader>
        {t('shipping.header', { ns: ['orderConfirmationModal'] })}
      </WidgetHeader>
      <Flex flex="1" flexWrap="wrap">
        <DetailText
          title={t('shipping.patientReference.title', {
            ns: ['orderConfirmationModal'],
          })}
        >
          {order?.patientRef || (
            <span className="error">
              {t('shipping.patientReference.error', {
                ns: ['orderConfirmationModal'],
              })}
            </span>
          )}
        </DetailText>
        {isROWCustomer(user) && !createAccessoryOnlyOrder && (
          <DetailText
            title={t('shipping.patientIntent.title', {
              ns: ['orderConfirmationModal'],
            })}
          >
            {patientIntent || (
              <span className="error">
                {t('shipping.patientIntent.error', {
                  ns: ['orderConfirmationModal'],
                })}
              </span>
            )}
          </DetailText>
        )}
        {treatingPhysicianFeatureFlag && !createAccessoryOnlyOrder && (
          <DetailText
            title={t('shipping.treatingPhysician.title', {
              ns: ['orderConfirmationModal'],
            })}
          >
            {order?.treatingPhysicianName || (
              <span className="error">
                {t('shipping.treatingPhysician.error', {
                  ns: ['orderConfirmationModal'],
                })}
              </span>
            )}
          </DetailText>
        )}
        <DetailText
          title={t('shipping.poNumber.title', {
            ns: ['orderConfirmationModal'],
          })}
        >
          {order?.poNumber || (
            <span className="error">
              {t('shipping.poNumber.error', {
                ns: ['orderConfirmationModal'],
              })}
            </span>
          )}
        </DetailText>
        <DetailText
          title={t('shipping.treatmentDate.title', {
            ns: ['orderConfirmationModal'],
          })}
        >
          {order?.treatmentDate &&
            formatDate(order.treatmentDate, DAY_OF_WEEK_FORMAT)}
        </DetailText>
        <DetailText
          title={t('shipping.treatmentTime.title', {
            ns: ['orderConfirmationModal'],
          })}
        >
          {format(treatmentTime, getTimeFormat())}
        </DetailText>
        <DetailText
          title={t('shipping.shippingTo.title', {
            ns: ['orderConfirmationModal'],
          })}
        >
          <MultiLineAddress address={order.address} />
        </DetailText>
      </Flex>
    </ShippingWrapper>
  )
}
