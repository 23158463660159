import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { Box, Divider, Flex } from '@chakra-ui/core'
import { MEDIUM, mqNames, themeUtils } from '../../../themeUtils'
import { SHORT_MONTH_FORMAT } from '../../../constants'
import { formatDate } from '../../../utils'
import { VialSearchResultOptionType } from '../OrderCreate/OrderFormDataTypes'
import { useOrderForm, useVialSearch } from '../../../hooks'
import { darken } from 'polished'
import { OrderFormStepType } from '../../../context/OrderFormContext'
import { InlineErrorMessage } from '../../Shared'
import { Icons } from '@chakra-ui/core/dist/theme/icons'
import { ThemeVersion } from '../../../types'
import { useTranslation } from 'react-i18next'

const {
  antiFlashWhite,
  midnightBlue,
  shuttleGrey,
  white,
  moonstone,
  green,
} = themeUtils.colors

interface Props {
  id?: string
  optionParentKey: string
  option: VialSearchResultOptionType
  vialActivityValue: string
}

export const VialOption: React.FC<Props> = ({
  id,
  optionParentKey,
  option,
  vialActivityValue,
}) => {
  const { t } = useTranslation(['orderCreate'])
  const { step } = useOrderForm()
  const {
    deviation,
    dosage,
    treatment_activity,
    calibration_date,
    calibration_week,
  } = option
  let deviationLabel = `${deviation > 0 ? '+' : ''}${deviation}%`
  let deviationSubLabel =
    deviation > 0
      ? t('activityFocused.vialOptions.deviation.over')
      : t('activityFocused.vialOptions.deviation.under')

  const {
    selectedActivityVials,
    addActivityTreatmentVial,
    checkIfDoseIsAvailable,
    checkIfDoseAlreadySelected,
  } = useVialSearch()

  const selected = useMemo(() => {
    if (selectedActivityVials && selectedActivityVials.size) {
      const existingVialGroup = selectedActivityVials.get(optionParentKey)
      if (existingVialGroup) {
        const vialsForCal = existingVialGroup.get(parseFloat(vialActivityValue))
        if (vialsForCal) {
          return vialsForCal.dosage === dosage
        }
      }
    }
    return false
  }, [optionParentKey, selectedActivityVials, dosage])

  const updateDose = () => {
    if (
      !checkIfDoseAlreadySelected(
        optionParentKey,
        parseFloat(vialActivityValue),
        option
      ) &&
      checkIfDoseIsAvailable(option)
    ) {
      addActivityTreatmentVial(
        optionParentKey,
        parseFloat(vialActivityValue),
        option
      )
    }
  }

  const isAvailable = useMemo(() => {
    if (
      checkIfDoseAlreadySelected(
        optionParentKey,
        parseFloat(vialActivityValue),
        option
      )
    ) {
      return true
    }
    return checkIfDoseIsAvailable(option)
  }, [selectedActivityVials])

  return (
    <Container
      isDisabled={!isAvailable}
      step={step}
      selection={selected}
      onClick={isAvailable ? updateDose : undefined}
      id={id}
      data-cy={id}
    >
      <SubContainer1 isDisabled={!isAvailable} gridArea={'at-calibration'}>
        <Flex1>
          <HeadingLabel>{`${dosage} ${t(
            'activityFocused.vialOptions.unit'
          )}`}</HeadingLabel>
          <VialBadge>
            {t('activityFocused.vialOptions.atCalibration')}
          </VialBadge>
        </Flex1>
        <Flex2>
          <DoseAtTreatment data-cy={`${id}-treatment-activity`}>
            {`${treatment_activity} ${t(
              'activityFocused.vialOptions.atTreatment'
            )}`}
          </DoseAtTreatment>
          <DateBdage
            week={calibration_week}
            data-cy={`${id}-week-${calibration_week}-label`}
          >{`${t(
            'activityFocused.vialOptions.week'
          )} ${calibration_week},  ${formatDate(
            calibration_date,
            SHORT_MONTH_FORMAT
          )}`}</DateBdage>
        </Flex2>
      </SubContainer1>
      <Border gridArea={'border'} />
      <SubContainer2 isDisabled={!isAvailable} gridArea={'desired-activity'}>
        <HeadingLabel data-cy={`${id}-deviation-label`}>
          {deviationLabel}
        </HeadingLabel>
        <SubLabel data-cy={`${id}-deviation-sub-label`}>
          {deviationSubLabel}
        </SubLabel>
      </SubContainer2>
      {!isAvailable && (
        <SubContainer3>
          <InlineErrorMessage
            version={ThemeVersion.V2}
            icon={'info' as Icons}
            data-cy={`${id}-no-additinal-vial-available-error`}
          >
            {t('activityFocused.vialOptions.unavailable')}
          </InlineErrorMessage>
        </SubContainer3>
      )}
    </Container>
  )
}

const Container = styled(Box)<{
  selection: boolean
  step: OrderFormStepType
  isDisabled: boolean
}>`
  padding: 15px;
  border-radius: 5px;
  background: ${antiFlashWhite};
  width: 100%;

  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'at-calibration'
    'border'
    'desired-activity'
    'info-msg';

  ${mqNames(MEDIUM)} {
    grid-template-columns: 3fr 0fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      'at-calibration border desired-activity'
      'info-msg info-msg info-msg';
  }

  cursor: pointer;
  ${({ selection, step, isDisabled }) =>
    selection
      ? `
          border: ${
            step !== OrderFormStepType.STEP_3 ? `2px solid ${green}` : 'unset'
          };
          background: rgba(69, 218, 122, 0.10);
        `
      : isDisabled ? `` : `
          &:hover {
            background: ${darken(0.1, antiFlashWhite)} ;
          }
        `};

  ${({ isDisabled }) =>
    isDisabled
      ? `
      cursor: not-allowed;
      background: rgb(242, 245, 247, 0.4);
        `
      : ``};
`

const SubContainer1 = styled(Box)<{isDisabled: boolean}>`
  display: inherit;
  gap: 10px;
  ${({ isDisabled }) =>
    isDisabled
      ? `
      opacity: 0.4;
        `
      : ``};
`

const SubContainer2 = styled(Box)<{isDisabled: boolean}>`
  display: flex;
  gap: 20px;
  align-items: center;

  ${mqNames(MEDIUM)} {
    display: inherit;
    gap: 5px;
  }

  ${({ isDisabled }) =>
    isDisabled
      ? `
      opacity: 0.4;
        `
      : ``};
`

const SubContainer3 = styled(Box)`
  grid-area: info-msg;
  grid-column: 1 / span 3;
  cursor: initial;
`

const Flex1 = styled(Flex)`
  align-items: center;
  gap: 15px;
  justify-content: space-between;

  ${mqNames(MEDIUM)} {
    justify-content: start;
  }
`
const Flex2 = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;

  ${mqNames(MEDIUM)} {
    flex-direction: column;
  }
`

const Border = styled(Divider)`
  width: 100%;
  height: 1px;
  border: 1;
  background: rgba(0, 57, 104, 0.4);
  margin: 0;

  ${mqNames(MEDIUM)} {
    width: 1px;
    height: auto;
  }
`

const HeadingLabel = styled.label`
  color: ${midnightBlue};
  font-size: 20px;
  font-weight: 400;
`

const SubLabel = styled.label`
  color: ${shuttleGrey};
  font-size: 12px;
  font-weight: 500;
`

const DoseAtTreatment = styled.label`
  color: ${midnightBlue};
  font-size: 12px;
  font-weight: 500;
`

const VialBadge = styled.div`
  display: flex;
  padding: 3px 5px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 3px;
  border: 1px solid ${midnightBlue};

  color: ${midnightBlue};
  font-size: 12px;
  font-weight: 600;
`

const DateBdage = styled.div<{ week: number }>`
  display: flex;
  padding: 2px 5px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 3px;
  background: ${props => (props.week === 1 ? midnightBlue : moonstone)};

  color: ${white};
  font-size: 12px;
  font-weight: 600;
`
