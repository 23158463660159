import React from 'react'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  AlertProps,
  Box,
} from '@chakra-ui/core'
import { themeUtils } from '../../themeUtils'

const successGreen = themeUtils.colors.green
const errorRed = themeUtils.colors.red
interface AlertOverlayProps extends AlertProps {
  title: string
  status: 'error' | 'success' | 'warning' | 'info'
}

interface Status {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export const statuses: Status = {
  info: { icon: 'info', color: 'blue' },
  warning: { icon: 'warning-2', color: 'orange' },
  success: { icon: 'check-circle', color: successGreen },
  error: { icon: 'warning', color: errorRed },
}

const AlertOverlay: React.FC<AlertOverlayProps> = ({
  children,
  title,
  status,
  ...rest
}) => (
  <Alert
    status={status}
    backgroundColor={statuses[status] && statuses[status]['color']}
    variant="solid"
    flexDirection="column"
    justifyContent="center"
    textAlign="center"
    borderRadius="1rem"
    padding="3rem"
    {...rest}
  >
    <AlertIcon size="7rem" />
    <AlertTitle mt="2rem" mb="3rem" fontSize="1.8rem" fontWeight={'normal'}>
      {title}
    </AlertTitle>
    <Box
      as="span"
      border="1px solid rgba(255, 255, 255, 0.37)"
      width="10rem"
    ></Box>
    <AlertDescription mt="2rem" fontSize="1.8rem">
      {children}
    </AlertDescription>
  </Alert>
)

export { AlertOverlay }
