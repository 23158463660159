import React from 'react'
import { RouteComponentProps } from '@reach/router'
import styled from '@emotion/styled'
import { Header, Footer } from '.'
import { MEDIUM, mqNames, themeUtils } from '../../themeUtils'
import { FeatureFlagsProvider } from '../../context/FeatureFlagsContext'
import { ConfirmUserDetails } from '../MyAccount/BasicDetails'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
const defaultPadding = themeUtils.padding
const smallPadding = `calc(${themeUtils.padding} / 2)`

const Main = styled.main`
  width: 100vw;
  flex: 1;
  padding: 0 ${smallPadding} ${smallPadding} ${smallPadding};

  ${mqNames(MEDIUM)} {
    padding: 0 ${defaultPadding} ${defaultPadding} ${defaultPadding};
  }
`

const AuthenticatedLayout: React.FC<RouteComponentProps> = ({
  children,
  location,
}) => {
  return (
    <FeatureFlagsProvider location={location}>
      <Container>
        <Header />
        <Main className="container">
          <section>{children}</section>
          <ConfirmUserDetails />
        </Main>
        <Footer />
      </Container>
    </FeatureFlagsProvider>
  )
}

export { AuthenticatedLayout }
