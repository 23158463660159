import React from 'react'
import { IconProps } from '../../types'

const DashboardIcon: React.FC<IconProps> = ({
  fill = '#fff',
  width = 17,
  height = 16,
}) => {
  return (
    <svg
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 16"
    >
      <g>
        <g>
          <path d="M5.264 0C6.59 0 7.7 1.082 7.7 2.407v2.4C7.7 6.133 6.59 7.2 5.264 7.2h-2.4C1.538 7.2.5 6.133.5 4.807v-2.4C.5 1.082 1.538 0 2.864 0zM6.1 2.407c0-.44-.394-.807-.836-.807h-2.4c-.44 0-.764.366-.764.807v2.4c0 .441.323.793.764.793h2.4c.442 0 .836-.352.836-.793zM14.064 0C15.39 0 16.5 1.082 16.5 2.407v2.4c0 1.326-1.11 2.393-2.436 2.393h-2.4C10.338 7.2 9.3 6.133 9.3 4.807v-2.4C9.3 1.082 10.338 0 11.664 0zm.836 2.407c0-.44-.394-.807-.836-.807h-2.4c-.44 0-.764.366-.764.807v2.4c0 .441.323.793.764.793h2.4c.442 0 .836-.352.836-.793zM5.264 8.8C6.59 8.8 7.7 9.882 7.7 11.207v2.4C7.7 14.933 6.59 16 5.264 16h-2.4C1.538 16 .5 14.933.5 13.607v-2.4C.5 9.882 1.538 8.8 2.864 8.8zm.836 2.407c0-.44-.394-.807-.836-.807h-2.4c-.44 0-.764.366-.764.807v2.4c0 .441.323.793.764.793h2.4c.442 0 .836-.352.836-.793zM14.064 8.8c1.326 0 2.436 1.082 2.436 2.407v2.4c0 1.326-1.11 2.393-2.436 2.393h-2.4C10.338 16 9.3 14.933 9.3 13.607v-2.4c0-1.325 1.038-2.407 2.364-2.407zm.836 2.407c0-.44-.394-.807-.836-.807h-2.4c-.44 0-.764.366-.764.807v2.4c0 .441.323.793.764.793h2.4c.442 0 .836-.352.836-.793z" />
        </g>
      </g>
    </svg>
  )
}

export { DashboardIcon }
