import React, { useMemo, useState } from 'react'
import {
  Button,
  CustomSelect,
  DetailText,
  Heading,
  ModalContent,
  ModalOverlay,
  Option,
} from '../../Shared'
import { ModalHeader } from 'reactstrap'
import { MEDIUM, mqNames, themeUtils } from '../../../themeUtils'
import { CheckSquareIcon } from '../../Icons'
import { OrderShow_order } from '../../../schema/queries/__generated__/OrderShow'
import {
  Box,
  ModalBody,
  Text,
  Modal,
  ModalCloseButton,
  FormControl,
  useDisclosure,
  Divider,
} from '@chakra-ui/core'
import { FormHelperText, Input } from '../../Forms'
import {
  TeamMemberForm,
  TeamMemberSoucreEnum,
} from '../../MyAccount/TeamMember'
import { OrderMissingInfoFormData } from '../OrderCreate/OrderFormDataTypes'
import { useQuery } from 'react-apollo'
import { GET_CUSTOMER_QUERY } from '../../../schema/queries/CustomerQueries'
import { GetCustomer } from '../../../schema/queries/__generated__/GetCustomer'
import { useUser } from '../../../context/user-context'
import styled from '@emotion/styled'
import { OrderStatusEnum } from '../../../__generated__/globalTypes'
import { CreatePortalTeamMember_createPortalTeamMember_TeamMember } from '../../../schema/mutations/__generated__/CreatePortalTeamMember'
import { isROWCustomer } from '../../lib'
import { useTranslation } from 'react-i18next'

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
`

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: '100%';
  position: 'relative';

  ${mqNames(MEDIUM)} {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
  }
`

interface Props {
  order: OrderShow_order
  isOpen: boolean
  onOpen?: () => void
  onClose: () => void
  onSubmit: (formData: OrderMissingInfoFormData) => void
  isLoading?: boolean
}
const { green, blue, red } = themeUtils.colors

export const MissingInfoModal: React.FC<Props> = ({
  isOpen,
  onOpen,
  onClose,
  onSubmit,
  order,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation(['orderShowScreen'])
  const user = useUser()
  const { data: customerData } = useQuery<GetCustomer>(GET_CUSTOMER_QUERY)

  const {
    isOpen: isTPOpen,
    onOpen: onTPOpen,
    onClose: onTPClose,
  } = useDisclosure()

  const orderPONumber: string | null = useMemo(() => {
    return (
      ([OrderStatusEnum.SHIPPED].includes(order.status)
        ? order.poNumberAfterShipped
          ? order.poNumberAfterShipped
          : order.poNumber
        : order.poNumber) || ''
    )
  }, [order])

  const IsAccessoryOnlyOrder: boolean = useMemo(() => {
    return !order.treatments.length
  }, [order])

  const [formData, setFormData] = useState<OrderMissingInfoFormData>({
    patientRef: (order && order.patientRef) || '',
    poNumber: (order && orderPONumber) || '',
    treatingPhysicianId: (order && order.treatingPhysicianId) || '',
    treatingPhysicianName: (order && order.treatingPhysicianName) || '',
  })

  const poNumberPlaceholder = useMemo(() => {
    if (user?.customer.sapBillingDetail?.poRequired) {
      return t('missingInfoModal.poRequired', { ns: ['orderShowScreen'] })
    }
    return t('missingInfoModal.poNumOpt', { ns: ['orderShowScreen'] })
  }, [user?.customer.sapBillingDetail?.poRequired])

  const changeValue = (event: {
    currentTarget: { value: string; name: string }
  }): void => {
    const { value, name } = event.currentTarget

    setFormData((prevFormData: OrderMissingInfoFormData) => {
      return { ...prevFormData, [name]: value }
    })
  }

  const onTreatingPhysicianChange = (option: Option | null): void => {
    if (option?.value === 'add_member') {
      // Open add team member modal
      onTPOpen()
    } else {
      setFormData((prevFormData: OrderMissingInfoFormData) => {
        return {
          ...prevFormData,
          treatingPhysicianId: option?.value ?? undefined,
          treatingPhysicianName: option?.label ?? undefined,
        }
      })
    }
  }

  const handleTPModalClose = (createdUser?: CreatePortalTeamMember_createPortalTeamMember_TeamMember): void => {
    onTPClose()
    if (createdUser) {
      setFormData((prevFormData: OrderMissingInfoFormData) => {
        return {
          ...prevFormData,
          treatingPhysicianId: createdUser.id,
          treatingPhysicianName: createdUser.name,
        }
      })
    }
  }

  const treatingPhysicianOptions = useMemo(() => {
    const options: Option[] = []
    if (customerData?.customer.treatingPhysicians) {
      customerData?.customer.treatingPhysicians.map(t => {
        return options.push({
          value: t.id,
          label: t.name,
        })
      })
    }
    options.push({
      value: 'add_member',
      label: t('missingInfoModal.addPhys', { ns: ['orderShowScreen'] }),
      isLink: true,
    })
    return options
  }, [customerData?.customer.treatingPhysicians])

  const treatingPhysicianOptionVal = useMemo(() => {
    const value: Option | undefined = treatingPhysicianOptions.find(
      o => o.value === formData.treatingPhysicianId
    )
    return value ?? null
  }, [
    customerData?.customer.treatingPhysicians,
    formData.treatingPhysicianId,
    formData.treatingPhysicianName,
  ])

  const displayTPField =
    !IsAccessoryOnlyOrder &&
    !order.treatingPhysicianName &&
    !isROWCustomer(user)

  return (
    <>
      <TeamMemberForm
        isOpen={isTPOpen}
        onClose={handleTPModalClose}
        headingTitle={t('missingInfoModal.addPhys', { ns: ['orderShowScreen'] })}
        submitBtnLabel={t('missingInfoModal.addPhys', { ns: ['orderShowScreen'] })}
        source={TeamMemberSoucreEnum.ADD_TREATING_PHYSICIAN}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay display={isTPOpen ? "none": undefined} />
        <ModalContent maxWidth="48.5rem" justifyContent="center" display={isTPOpen ? "none": undefined}>
          <ModalHeader padding="0">
            <Heading color={blue} as="h2" marginBottom="0">
              {t('missingInfoModal.missingInfo', { ns: ['orderShowScreen'] })}
            </Heading>
          </ModalHeader>
          <ModalCloseButton _focus={undefined} data-cy="modal-close-btn"/>

          <ModalBody borderTop="1px solid #EFEFEF" padding="2rem 0 0 0">
            <GridWrapper>
              {!order.patientRef && (
                <ContainerGrid>
                  <DetailText title={t('missingInfoModal.patientRef', { ns: ['orderShowScreen'] })}>
                    {!formData.patientRef && (
                      <Text color={red}>{t('missingInfoModal.notSupplied', { ns: ['orderShowScreen'] })}</Text>
                    )}
                  </DetailText>
                  <FormControl>
                    <Input
                      aria-describedby="patient-ref-helper-text"
                      id="patientRef"
                      data-cy="patient-reference-input"
                      name="patientRef"
                      placeholder={t('missingInfoModal.patientRefOpt', { ns: ['orderShowScreen'] })}
                      borderWidth="0.1rem"
                      margin={0}
                      onChange={changeValue}
                    />
                    <FormHelperText
                      fontSize="1.2rem"
                      marginBottom="1rem"
                      id="patient-ref-helper-text"
                    >
                      {t('missingInfoModal.dontInclude', { ns: ['orderShowScreen'] })}
                    </FormHelperText>
                  </FormControl>
                </ContainerGrid>
              )}
              {displayTPField && (
                <ContainerGrid>
                  <DetailText title={t('missingInfoModal.treatingPhys', { ns: ['orderShowScreen'] })}>
                    {!formData.treatingPhysicianName && (
                      <Text color={red}>{t('missingInfoModal.awaiting', { ns: ['orderShowScreen'] })}</Text>
                    )}
                  </DetailText>
                  <CustomSelect
                    placeholder={t('missingInfoModal.pleaseSelect', { ns: ['orderShowScreen'] })}
                    value={treatingPhysicianOptionVal}
                    onChange={option =>
                      onTreatingPhysicianChange(option as Option)
                    }
                    options={treatingPhysicianOptions}
                    isSearchable={false}
                    clearable={true}
                    data-cy="treating-physician-select"
                    classNamePrefix='treating-physician-select'
                  />
                </ContainerGrid>
              )}
              {!orderPONumber && (
                <ContainerGrid>
                  <DetailText title={t('missingInfoModal.poNum', { ns: ['orderShowScreen'] })}>
                    {!formData.poNumber && (
                      <Text color={red}>{t('missingInfoModal.missingPo', { ns: ['orderShowScreen'] })}</Text>
                    )}
                  </DetailText>
                  <FormControl>
                    <Input
                      id="poNumber"
                      data-cy="po-number-input"
                      name="poNumber"
                      placeholder={poNumberPlaceholder}
                      borderWidth="0.1rem"
                      onChange={changeValue}
                      value={formData.poNumber}
                    />
                  </FormControl>
                </ContainerGrid>
              )}
            </GridWrapper>
            <Divider
              marginTop={'1.5rem'}
              marginBottom={'1.5rem'}
              border={'1'}
            />
            <Box
              display={'flex'}
              justifyContent={'end'}
              padding={'0 1rem'}
            >
              <Button
                backgroundColor={green}
                rightIcon={CheckSquareIcon}
                loadingText={t('missingInfoModal.submitting', { ns: ['orderShowScreen'] })}
                onClick={e => onSubmit(formData)}
                isLoading={isLoading}
                data-cy="submit-btn"
              >
                {t('missingInfoModal.confirm', { ns: ['orderShowScreen'] })}
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
