import React from 'react'
import { BoxProps } from '@chakra-ui/core'
import { Heading } from '../Shared'
import { themeUtils } from '../../themeUtils'

export const WidgetHeader: React.FC<BoxProps> = ({
  children,
  marginTop = '2.5rem',
  marginBottom = '3.5rem',
  marginRight = '3.5rem',
  ...rest
}) => (
  <Heading
    as="h2"
    color={themeUtils.colors.slateGrey}
    fontWeight="normal"
    marginBottom={marginBottom}
    display="inline-flex"
    marginRight={marginRight}
    {...rest}
  >
    {children}
  </Heading>
)

export const FormHeader: React.FC<BoxProps> = ({
  children,
  ...rest
}) => (
  <Heading
    as="h2"
    color={themeUtils.colors.darkBlue}
    fontWeight="normal"
    {...rest}
  >
    {children}
  </Heading>
)
