import React from 'react'
import { IconProps } from '../../types'

export const PlusCircleIcon: React.FC<IconProps> = ({
  fill = '#fff',
  width = 21,
  height = 20,
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 20"
      {...rest}
    >
      <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M14.9192 10C14.9192 10.552 14.4698 11 13.9161 11H11.9099V13C11.9099 13.552 11.4605 14 10.9068 14C10.3531 14 9.90368 13.552 9.90368 13V11H7.89747C7.34376 11 6.89437 10.552 6.89437 10C6.89437 9.448 7.34376 9 7.89747 9H9.90368V7C9.90368 6.448 10.3531 6 10.9068 6C11.4605 6 11.9099 6.448 11.9099 7V9H13.9161C14.4698 9 14.9192 9.448 14.9192 10ZM10.9068 18C6.48209 18 2.88194 14.411 2.88194 10C2.88194 5.589 6.48209 2 10.9068 2C15.3315 2 18.9316 5.589 18.9316 10C18.9316 14.411 15.3315 18 10.9068 18ZM10.9068 0C5.36664 0 0.875732 4.477 0.875732 10C0.875732 15.523 5.36664 20 10.9068 20C16.4469 20 20.9378 15.523 20.9378 10C20.9378 4.477 16.4469 0 10.9068 0Z"/>
    </svg>
  )
}
