import React, { ChangeEvent, useMemo } from 'react'
import { Select } from '../Forms'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { ORDER_FILTERS_QUERY } from '../../schema/queries'
import { GetOrderFilters } from '../../schema/queries/__generated__/GetOrderFilters'
import { SET_ORDER_FILTERS_MUTATION } from '../../schema/mutations'
import {
  SetOrderFilters,
  SetOrderFiltersVariables,
} from '../../schema/mutations/__generated__/SetOrderFilters'
import { Flex } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { CustomSelect, Option } from '../Shared'
import { CUSTOMERS_QUERY } from "../../schema/queries/CustomerQueries";
import { Customers } from "../../schema/queries/__generated__/Customers";

const humanise = (string: string): string => {
  const humanised = string
    .toLowerCase()
    .split('_')
    .join(' ')

  return (
    humanised.substr(0, 1).toUpperCase() + humanised.substr(1).toLowerCase()
  )
}

export const CustomerSelect: React.FC = () => {
  const { t } = useTranslation(['orderScreen'])
  const { data } = useQuery<GetOrderFilters>(ORDER_FILTERS_QUERY)

  const customerId = data?.orderFilters.customerId || ''

  const { data: customersData } = useQuery<Customers>(CUSTOMERS_QUERY, {
    fetchPolicy: 'cache-first',
  })

  const [setFiltersMutation] = useMutation<
    SetOrderFilters,
    SetOrderFiltersVariables
  >(SET_ORDER_FILTERS_MUTATION)

  const customerOptions = (): Option[] => {
    const options = customersData?.customers?.map((customer) => {
      return  {
        value: customer.id,
        label: customer.name
      }
    })
    options?.unshift({value: '', label: t('customerDropdown.all', { ns: ['orderScreen'] })})
    return options || []
  }

  const onChange = (option: Option | null): void => {
    if (!data?.orderFilters) return

    const newCustomerId = (customersData?.customers?.find(customer => customer.id == option?.value)) ? option?.value : ''

    setFiltersMutation({
      variables: {
        input: {
          ...data.orderFilters,
          customerId: newCustomerId,
        },
      },
    })
  }

  const customerOptionVal = useMemo(() => {
    const value: Option | undefined = customerOptions().find(
      o => o.value === customerId
    )
    return value ?? null
  }, [
    customerOptions(),
    customerId
  ])


  return (
    <Flex gridArea="customer-select">
      <CustomSelect
        value={customerOptionVal}
        isEmpty={customerId === ''}
        onChange={option => onChange(option as Option)}
        // onChange={onChange}
        marginBottom={0}
        isSearchable={true}
        options={customerOptions()}
      >
      </CustomSelect>
    </Flex>
  )
}
