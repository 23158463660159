import { format, parseISO } from 'date-fns'
import { DATE_FORMAT } from '../constants'
import { enUS, fr } from 'date-fns/locale'
import i18n, { getDateFormat } from '../i18n'

export const formatDate = (
  date: string | number | Date,
  formatString: string = DATE_FORMAT
): string => {
  const options = {
    locale: i18n.language === 'fr' ? fr : enUS,
  }
  formatString = getDateFormat(formatString)
  if (typeof date === 'string')
    return format(parseISO(date), formatString, options)

  return format(date, formatString, options)
}
