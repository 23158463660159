import React, { ChangeEvent } from 'react'
import { Option } from '.'
import { FormControl } from '@chakra-ui/core'
import {
  FormLabel,
  Select,
  FormErrorMessage,
  FormHelperText,
} from '../../Forms'
import { useTranslation } from 'react-i18next'

interface Props {
  [key: string]: any
  name?: string | undefined
  value?: string | number | readonly string[] | undefined
  inputValue?: string
  options: Option[]
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  label?: string
  hint?: string
  error?: string
  placeholder?: string
  disabled?: boolean
  innerRef?: any
  isRequired?: boolean
  hidden?: boolean
}

export const SingleSelect: React.FC<Props> = ({
  name,
  value,
  onChange,
  label,
  hint,
  error,
  placeholder,
  options,
  disabled,
  innerRef,
  isRequired,
  hidden,
  ...props
}) => {
  const { t } = useTranslation(['common'])

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={!!error}
      margin={'0 0 1rem 0'}
      hidden={hidden}
    >
      {!!label && <FormLabel>{label}</FormLabel>}
      <Select
        name={name}
        isEmpty={!value}
        placeholder={placeholder}
        isDisabled={disabled}
        value={value ?? ''}
        onChange={onChange}
        isInvalid={!!error}
        ref={innerRef}
        maxHeight={'4rem'}
        marginBottom={0}
        {...props}
      >
        <option value="" disabled>
          {placeholder ??
            t('singleSelect.defaultPlaceholder', {
              label: label?.toLowerCase(),
              ns: ['common'],
            })}
        </option>
        {options.map(option => {
          const { value, label } = option
          return (
            <option key={label} value={value}>
              {label}
            </option>
          )
        })}
      </Select>
      {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
      {!!hint && <FormHelperText>{hint}</FormHelperText>}
    </FormControl>
  )
}
