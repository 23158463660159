import React, { useMemo } from 'react'
import { OrderFormData } from '../OrderCreate/OrderFormDataTypes'
import { CustomSelect, Option } from '../../Shared'
import { useUser } from '../../../context/user-context'
import { ThemeVersion } from '../../../types'
import { addressLabel } from '../../lib'
import { useTranslation } from 'react-i18next'

interface Props {
  formData: OrderFormData
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
}

export const ShippingAddress: React.FC<Props> = ({ formData, setFormData }) => {
  const { t } = useTranslation(['orderCreate'])
  const user = useUser()
  const { addressId } = formData

  const onChange = (option: Option): void => {
    setFormData((prevFormData: OrderFormData) => {
      return {
        ...prevFormData,
        addressId: option.value,
      }
    })
  }

  const Options = useMemo(() => {
    const options: Option[] = []
    if (user?.shippingAddresses) {
      user.shippingAddresses.map(address => {
        const label = addressLabel(address)
        return options.push({
          value: address.id,
          label: label,
        })
      })
    }
    return options
  }, [user?.shippingAddresses])

  const Value = useMemo(() => {
    const value: Option | undefined = Options.find(o => o.value === addressId)
    return value ?? null
  }, [user?.shippingAddresses, addressId])

  return (
    <CustomSelect
      label={t('orderCreateWidget.shippingAddField.label', {
        ns: ['orderCreate'],
      })}
      placeholder={t('orderCreateWidget.shippingAddField.placeholder', {
        ns: ['orderCreate'],
      })}
      value={Value}
      onChange={onChange}
      options={Options}
      version={ThemeVersion.V2}
      classNamePrefix="shipping-address-select"
      disabled={!user?.shippingAddresses}
    />
  )
}
