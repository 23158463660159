import styled from '@emotion/styled'
import React from 'react'
import { themeUtils } from '../../../themeUtils'
import { TableContent } from './'
import { NPISearch_npiSearch, NPISearch_npiSearch_results } from '../../../schema/queries/__generated__/NPISearch'
import { ApolloError } from 'apollo-client'
import { useTranslation } from 'react-i18next'

const TableContainer = styled.div`
  max-height: 30rem;
  overflow-y: auto;   
`

const Table = styled.table`
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 1rem 0;

  table-layout: fixed;
`

const TableHeader = styled.thead`
  text-align: left;

  background-color: ${themeUtils.colors.white};
  position: sticky;
  top: 0;
  z-index: 1;
`

const StandardHeading = styled.th`
  color: ${themeUtils.colors.midnightBlue};
  padding: 1rem 0;

  @media print {
    font-size: 1.5rem;
  }
`
const TableBody = styled.tbody`
  max-height: 14rem;
`

interface Props {
  data: NPISearch_npiSearch |undefined
  loading: boolean
  error?: ApolloError
  onSelectNPI: (result: NPISearch_npiSearch_results) => void
}

export const NPISearchResultTable: React.FC<Props> = ({ data, loading, error, onSelectNPI}) => {
  const { t } = useTranslation(['npiWidget'])
  return (
    <>
      <TableContainer>
        <Table>
          <TableHeader>
            <tr>
              <StandardHeading>
                {t('table.header.name', {
                  ns: ['npiWidget'],
                })}
              </StandardHeading>
              <StandardHeading>
                {t('table.header.address', {
                  ns: ['npiWidget'],
                })}
              </StandardHeading>
              <StandardHeading>
                {t('table.header.npiNumber', {
                  ns: ['npiWidget'],
                })}
              </StandardHeading>
            </tr>
          </TableHeader>

          <TableBody>
            <TableContent
              results={data?.results}
              loading={loading}
              error={error}
              onSelectNPI={onSelectNPI}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
