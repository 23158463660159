import React, { ReactElement } from 'react'
import { ModalBody, Stack, Box, Heading, Text } from '@chakra-ui/core'
import { themeUtils } from '../../themeUtils'
import { Modal, Button } from '../Shared'
import { ExclamationMarkIcon, CheckSquareIcon } from '../Icons'
import { useTranslation } from 'react-i18next'
import { isROWCustomer } from '../lib'
import { useUser } from '../../context/user-context'

interface Props {
  isOpen: boolean
  onOpen?: () => void
  onClose: () => void
  onSubmit: () => void
}

const Number = ({
  title,
  desc,
}: {
  title: string
  desc: ReactElement[]
}): ReactElement => {
  return (
    <Box p={5} shadow="md" borderWidth="1px">
      <Heading fontSize="xl">{title}</Heading>
      <Text mt={4}>{desc}</Text>
    </Box>
  )
}

export const LateCancellationModal: React.FC<Props> = ({
  isOpen,
  onOpen,
  onClose,
  ...props
}) => {
  const { t } = useTranslation(['orderOverviewScreen'])
  const user = useUser()
  const numbers = isROWCustomer(user)
    ? {
        Austria: '+43 720 880 791',
        Belgium: '+32 2 374 09 54',
        France: '+33 170 615 491',
        Germany: '+49 21138789845',
        Ireland: '+353 15134196',
        Italy: '+39 0694803473',
        Netherlands: '+31 202410935',
        Portugal: '+351 308802418',
        Spain: '+34 911239029',
        Switzerland: '+41 315280673',
        UK: '+44 2033184706',
      }
    : { 'US & Canada': '1-866-363-3330' }

  return (
    <>
      <Modal
        headingText={t('lateCancelModal.heading', {
          ns: ['orderOverviewScreen'],
        })}
        icon={<ExclamationMarkIcon />}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        size="3xl"
        {...props}
      >
        <ModalBody paddingX="8" paddingY="8">
          <Stack spacing={8}>
            <Box>
              <Text>
                {t('lateCancelModal.unavailable', {
                  ns: ['orderOverviewScreen'],
                })}
              </Text>
            </Box>

            <Box>
              <Number
                title=""
                desc={Object.entries(numbers).map(n => (
                  <Stack key={n[1]} isInline spacing={8}>
                    <div style={{ width: '50%' }}>{n[0]}</div>
                    <div>{n[1]}</div>
                  </Stack>
                ))}
              />
            </Box>
          </Stack>
        </ModalBody>

        <Stack isInline justifyContent="center">
          <Button
            width="calc(100% / 3 * 1)"
            onClick={onClose}
            backgroundColor={themeUtils.colors.green}
            rightIcon={CheckSquareIcon}
          >
            {t('lateCancelModal.buttonText', { ns: ['orderOverviewScreen'] })}
          </Button>
        </Stack>
      </Modal>
    </>
  )
}
