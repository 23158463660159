import React from 'react'
import { IconProps } from '../../types'
import * as path from "path";
import { rgb } from "polished";

const CancelRemovalRequestIcon: React.FC<IconProps> = ({
  title = 'Edit',
}) => {
  return (
    <svg
      fill={rgb(255, 215, 107)}
      height='32'
      width='32'
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g>
        <path
          d="M0 0h24v24H0z"
          fill="none"/>
        <path
          d="M6.535 3H21a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H6.535a1 1 0 0 1-.832-.445l-5.333-8a1 1 0 0 1 0-1.11l5.333-8A1 1 0 0 1 6.535 3zm.535 2l-4.666 7 4.666 7H20V5H7.07zM13 10.586l2.828-2.829 1.415 1.415L14.414 12l2.829 2.828-1.415 1.415L13 13.414l-2.828 2.829-1.415-1.415L11.586 12 8.757 9.172l1.415-1.415L13 10.586z"/>
      </g>
    </svg>
  )
}

export { CancelRemovalRequestIcon }
