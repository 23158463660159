import React from 'react'
import { Box } from '@chakra-ui/core'
import { VialSearch_vialSearch_vials } from '../../../../schema/queries/__generated__/VialSearch'
import { StepButton } from './StepButton'
import { themeUtils } from '../../../../themeUtils'
import { useVialSearch } from '../../../../hooks'

interface VialStepperProps {
  vial: VialSearch_vialSearch_vials
  selectedQuantity: number
  index: number
}

export const VialStepper: React.FC<VialStepperProps> = ({
  vial,
  selectedQuantity,
  index,
}) => {
  const { addVial, removeVial } = useVialSearch()
  const vialsAvailable = vial.available
  const vialDosage = vial.dosage
  const vialFromSurplus = vial.surplus
  const subtractDisabled = selectedQuantity === 0
  const addDisabled = vialFromSurplus
    ? selectedQuantity === vialsAvailable
    : false

  const addDose = () => {
    addVial(vialDosage)
  }

  const removeDose = () => {
    removeVial(vialDosage)
  }

  return (
    <Box data-cy={`vial-btn-container-${index+1}`}>
      <StepButton
        isDisabled={subtractDisabled}
        stepType="subtract"
        aria-label={`Remove ${vialDosage} GBq dose from order`}
        onClick={removeDose}
        value={vialDosage}
        backgroundColor={themeUtils.colors.white}
        marginRight="0.5rem"
        data-cy={`remove-btn-${index+1}`}
      />
      <StepButton
        isDisabled={addDisabled}
        stepType="add"
        aria-label={`Add ${vialDosage} GBq dose to order`}
        onClick={addDose}
        value={vialDosage}
        data-cy={`add-btn-${index+1}`}
      />
    </Box>
  )
}
