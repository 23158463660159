import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { PencilIcon } from '../../Icons'
import { MEDIUM, mqNames, themeUtils } from '../../../themeUtils'
import '../../../SharedStyles.css'
import { TeamMemberForm } from './TeamMemberForm'
import { Flex, useDisclosure } from '@chakra-ui/core'
import { GetTeamMembers_teamMembers } from '../../../schema/queries/__generated__/GetTeamMembers'
import { TeamMemberProfessionEnum } from '../../../__generated__/globalTypes'
import { humanize, titleize } from 'underscore.string'
import { RequestRemovalButton } from './RequestRemovalButton'
import { useUser } from '../../../context/user-context'
import { getProfessionOptions } from '../BasicDetails'
import { Button } from '../../Shared'
import { lighten } from 'polished'
import { isUSCustomer } from '../../lib'
import { isROWCustomer } from '../../lib'
import { useTranslation } from 'react-i18next'

interface Props {
  member: GetTeamMembers_teamMembers
}

const TableCell = styled.td`
  max-width: 90px;
  padding: 2.5rem 0.5rem;

  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;

  ${mqNames(MEDIUM)} {
    max-width: 100%;
  }

  &:first-of-type {
    text-transform: uppercase;
  }

  @media print {
    font-size: 1rem;
  }
`

const ResponsiveTableCell = styled(TableCell)`
  @media screen {
    display: none;

    ${mqNames(MEDIUM)} {
      display: table-cell;
    }
  }
`

const TableRow = styled.tr`
  border-top: 1px solid ${themeUtils.colors.borderGrey};
  &:hover {
    background-color: ${themeUtils.colors.lightGrey};
  }
`
const { midnightBlue } = themeUtils.colors

export const GetProfessionValue = (
  profession: TeamMemberProfessionEnum,
  otherProfession: string | null
): string => {
  const professionLabel =
    getProfessionOptions().find(p => p.value === profession)?.label || ''
  return profession === TeamMemberProfessionEnum.other && otherProfession
    ? `${professionLabel} - ${titleize(humanize(otherProfession))}`
    : professionLabel
}

export const TeamMemberRow: React.FC<Props> = ({ member }) => {
  const { t } = useTranslation(['teamMemberSection'])
  const { isOpen, onOpen, onClose } = useDisclosure()

  const user = useUser()
  const {
    firstName,
    lastName,
    email,
    profession,
    otherProfession,
    npi,
    isPortalUser,
  } = member

  const professionValue = useMemo(() => {
    return GetProfessionValue(profession, otherProfession)
  }, [profession, otherProfession])

  return (
    <>
      <TableRow>
        {user?.id.toString() != member.portalUserId?.toString() ? (
          <TableCell>
            {`${firstName} ${lastName}`}{' '}
            {!isROWCustomer(user) && (
              <Button
                color={midnightBlue}
                _hover={{
                  color: lighten(0.2, midnightBlue),
                }}
                _focus={undefined}
                backgroundColor={'transparent'}
                height={'unset'}
                variant="link"
                paddingLeft={0}
                paddingRight={0}
                padding={0}
                onClick={onOpen}
              >
                <PencilIcon
                  title={t('table.editIconText', { ns: ['teamMemberSection'] })}
                />
              </Button>
            )}
          </TableCell>
        ) : (
          <TableCell>
            <Flex>{`${firstName} ${lastName}`} </Flex>
          </TableCell>
        )}
        {!isROWCustomer(user) && (
          <ResponsiveTableCell>{email}</ResponsiveTableCell>
        )}
        <TableCell>{professionValue}</TableCell>
        {isUSCustomer(user) && <ResponsiveTableCell>{npi}</ResponsiveTableCell>}
        {isPortalUser ? (
          <TableCell style={{ color: '#45DA7A' }}>
            {t('table.yesPoratUser', { ns: ['teamMemberSection'] })}
          </TableCell>
        ) : (
          <TableCell>
            {t('table.noPortalUser', { ns: ['teamMemberSection'] })}
          </TableCell>
        )}
        <TableCell style={{ display: 'flex', justifyContent: 'end' }}>
          <RequestRemovalButton member={member} />
        </TableCell>
      </TableRow>
      {isOpen && (
        <TeamMemberForm
          isOpen={isOpen}
          onClose={onClose}
          member={member}
          headingTitle={t('add.headingTitle', { ns: ['teamMemberSection'] })}
        />
      )}
    </>
  )
}
