import React from 'react'
import { themeUtils } from '../../themeUtils'
import { Box, BoxProps, FlexProps } from '@chakra-ui/core'
import { ThemeVersion } from '../../types'

interface ContainerProps extends FlexProps {
  heading?: string
  highlight?: boolean
  wrapperProps?: BoxProps
  headingProps?: BoxProps
  version?: ThemeVersion
}

const { casper, red } = themeUtils.colors
const highlightColor = red

const Container: React.FC<ContainerProps> = ({
  heading,
  children,
  className,
  highlight = false,
  wrapperProps,
  headingProps,
  version,
  ...rest
}) => {
  const highlightStyles = {
    borderColor: highlight ? highlightColor : '',
    borderWidth: highlight ? '2px' : '',
    borderRadius: version === ThemeVersion.V2 ? '1rem' : '0 0 1rem 1rem',
  }

  return (
    <Box className={className} {...wrapperProps}>
      {heading && (
        <Box
          backgroundColor={highlight ? highlightColor : casper}
          color={themeUtils.colors.white}
          padding={version === ThemeVersion.V2 ? "1.5rem" : "1rem 1.5rem"}
          borderRadius="1rem 1rem 0 0"
          fontSize="1.8rem"
          {...headingProps}
        >
          {heading}
        </Box>
      )}
      <Box
        backgroundColor={themeUtils.colors.white}
        padding={version === ThemeVersion.V2 ? "1.5rem" : "2.5rem"}
        boxShadow={'0 10px 15px rgba(141, 162, 179, 0.1)'}
        {...highlightStyles}
        {...rest}
      >
        {children}
      </Box>
    </Box>
  )
}

export { Container }
