import React from 'react'
import styled from '@emotion/styled'
import { Box, Divider } from '@chakra-ui/core'
import { MEDIUM, mqNames, themeUtils } from '../../../themeUtils'
import { useOrderForm } from '../../../hooks'
import { Button } from '../../Shared'
import { OrderFormTypeEnum } from '../../../__generated__/globalTypes'
import { useTranslation } from 'react-i18next'

const { midnightBlue } = themeUtils.colors

interface Props {
}

export const NoAvailableVials: React.FC<Props> = ({
}) => {
  const { t } = useTranslation(['orderCreate'])
  const { setFormType } = useOrderForm()

  const onClick = () => {
    setFormType(OrderFormTypeEnum.VIAL_SELECTOR)
  }

  return (
    <Container>
      <HeadingLabel>{t('activityFocused.noAvailable.header')}</HeadingLabel>
      <SubLabel>{t('activityFocused.noAvailable.description')}</SubLabel>
      <Button backgroundColor={midnightBlue} isFullWidth onClick={onClick}>
        {t('activityFocused.noAvailable.buttonLabel')}
      </Button>
    </Container>
  )
}

const Container = styled(Box)`
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: 0fr 1fr 0fr;
`

const Border = styled(Divider)`
  width: 100%;
  height: 1px;
  border: 1;
  background: rgba(0, 57, 104, 0.4);
  margin: 0;

  ${mqNames(MEDIUM)} {
    width: 1px;
    height: auto;
  }
`

const HeadingLabel = styled.label`
  margin-top: 30px;
  color: ${midnightBlue};
  text-align: center;
  font-size: 20px;
  font-weight: 500;
`

const SubLabel = styled.label`
  color: ${midnightBlue};
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 28px;
`
