import { Flex, FlexProps } from '@chakra-ui/core'
import React from 'react'
import { themeUtils } from '../../../themeUtils'
import { ParagraphText } from '../../Shared'

interface Props extends FlexProps {
  heading: string
}
export const BillingItem: React.FC<Props> = ({
  heading,
  children,
  ...props
}) => {
  return (
    <Flex
      borderBottom={`1px solid ${themeUtils.colors.borderGrey}`}
      justifyContent="space-between"
      marginBottom="1rem"
      {...props}
    >
      <ParagraphText variant="title">{heading}</ParagraphText>
      {children}
    </Flex>
  )
}
