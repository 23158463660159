import gql from 'graphql-tag'

export const CHECK_DELIVERY_CHARGES_QUERY = gql`
  query CheckDeliveryCharges( $orderDate: Date!, $treatmentTime: String!, $treatmentDate: Date!, $addressId: ID!, $isAccessoryOnlyOrder: Boolean!) {
    checkDeliveryCharges (
      orderDate: $orderDate      
      treatmentTime: $treatmentTime      
      treatmentDate: $treatmentDate      
      addressId: $addressId   
      isAccessoryOnlyOrder: $isAccessoryOnlyOrder   
    ) {
      applicableForCharges
      key
      price
      label
      text
      freeReason
      allowToCreateOrder        
    }
  }
`
