import i18n from "../i18n";
import { Notification } from "./Notification";

export const statusSubmittedText = (customerPortalOrder: boolean): string => {
  return customerPortalOrder
    ? i18n.t('order.submitted', { ns: ['status'] })
    : i18n.t('order.created', { ns: ['status'] })
}

export const copyToClipboard = async (
  textToCopy: string,
  successMsg: string
) => {
  try {
    await navigator.clipboard.writeText(textToCopy)
    Notification.success(successMsg)
  } catch (err) {
    Notification.error(i18n.t('copyToClipboard.error', { ns: ['common'] }))
  }
}
