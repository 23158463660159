import React from 'react'
import styled from '@emotion/styled'
import { themeUtils } from '../../../themeUtils'
import { useTranslation } from 'react-i18next'

interface Props {
  totalActivityAtTreatment: number
}

const { midnightBlue } = themeUtils.colors

const TotalActivityLabel = styled.p`
  color: ${midnightBlue};
  font-size: 20px;
  font-weight: 400;
  line-height: 28px; /* 140% */
`

const TotalActivityValue = styled.label`
  color: ${midnightBlue};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`

export const TotalEstimatedActivity: React.FC<Props> = ({
  totalActivityAtTreatment,
}) => {
  const { t } = useTranslation(['treatment'])
  const formattedTotalEstimatedActivity =
    totalActivityAtTreatment > 0 ? totalActivityAtTreatment.toFixed(2) : '-'

  return (
    <TotalActivityLabel>
      {t('activityAtTreatment.totalEstimated', { ns: ['treatment'] })}{' '}
      <TotalActivityValue>{`${formattedTotalEstimatedActivity} ${t('unit', {
        ns: ['treatment'],
      })}`}</TotalActivityValue>
    </TotalActivityLabel>
  )
}
