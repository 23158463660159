import * as React from 'react'
import { Link } from '@reach/router'

export interface ActiveLinkProps {
  activeClassName?: string
  defaultClassNames?: string
  to: string
  exact?: boolean
  except?: string[]
  onClick?: never
}

export const ActiveLink: React.FC<ActiveLinkProps> = ({
  activeClassName = 'active',
  defaultClassNames,
  exact = false,
  except = [],
  onClick,
  ...props
}) => {
  return (
    <Link
      getProps={({ isCurrent, isPartiallyCurrent, location }) => ({
        className:
          (exact ? isCurrent : isPartiallyCurrent) &&
          !except.includes(location.pathname)
            ? `${defaultClassNames} ${activeClassName}`
            : defaultClassNames,
      })}
      onClick={onClick}
      {...props}
    />
  )
}
