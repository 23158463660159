import React, { useEffect, useState } from 'react'
import { Link } from '@reach/router'
import styled from '@emotion/styled'
import { themeUtils } from '../../../themeUtils'
import { NavBar, ProfileMenu } from '.'
import { TherasphereLogo } from '../../Icons/TherasphereLogo'
import { EnvHeader } from './EnvHeader'
import {UserDetailsBanner} from "../../Dashboard";
const HeaderElement = styled.header`
  background-color: ${themeUtils.colors.darkBlue};
  color: #ffffff;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 8rem;
  padding: 0 3rem;
  justify-content: space-between;
  max-width: ${themeUtils.widths.max};
  margin: 0 auto;
`

const LogoLink = styled(Link)`
  max-width: 168px;
`

const Header: React.FC = () => {
  return (
    <HeaderElement>
      <HeaderContainer>
        <LogoLink to="/">
          <TherasphereLogo />
        </LogoLink>
        <NavBar />
        <ProfileMenu />
      </HeaderContainer>
      <EnvHeader />
      <UserDetailsBanner />
    </HeaderElement>
  )
}

export { Header }
