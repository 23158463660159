import React from 'react'
import styled from '@emotion/styled'
import { Flex, FlexProps } from '@chakra-ui/core'
import { themeUtils } from '../../../themeUtils'
import { useOrderForm } from '../../../hooks'
import { OrderFormStepType } from '../../../context/OrderFormContext'
import { useTranslation } from 'react-i18next'

const { darkBlue, white, shuttleGrey } = themeUtils.colors

interface Props {
  displayLabel?: boolean
}

interface NumberContainerProps {
  active?: boolean
}

const Container = styled(Flex)<FlexProps>`
  display: inline-flex;
  align-items: center;
  gap: 10px;
`

const Label = styled.label`
  color: ${darkBlue};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`

const NumberContainer = styled.span<NumberContainerProps>`
  display: flex;
  width: 32px;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 100px;
  opacity: ${props => (props.active ? 'unset' : '0.2')};
  background: ${shuttleGrey};
`

const Number = styled.span`
  color: ${white};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
`

export const Steps: React.FC<Props> = ({ displayLabel = true }) => {
  const { t } = useTranslation(['orderCreate'])
  const { step } = useOrderForm()
  return (
    <Container data-cy="steps-container">
      {displayLabel && <Label> {t('activityFocused.step')}</Label>}
      <NumberContainer
        data-cy="step-1"
        active={step === OrderFormStepType.STEP_1}
      >
        <Number>1</Number>
      </NumberContainer>
      <NumberContainer
        data-cy="step-2"
        active={step === OrderFormStepType.STEP_2}
      >
        <Number>2</Number>
      </NumberContainer>
      <NumberContainer
        data-cy="step-3"
        active={step === OrderFormStepType.STEP_3}
      >
        <Number>3</Number>
      </NumberContainer>
    </Container>
  )
}
