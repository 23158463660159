import React from 'react'
import { Flex, FlexProps } from '@chakra-ui/core'
import { themeUtils } from '../../../themeUtils'

interface CustomFlexProps extends FlexProps {
  calibrationWeek?: string | null
}

export const ProductContainer: React.FC<CustomFlexProps> = ({
  children,
  maxWidth = { base: '100%', md: '34rem', lg: '57rem' },
  calibrationWeek,
  ...restProps
}) => {
  let color = themeUtils.colors.shuttleGrey
  if (calibrationWeek) {
    color =
      calibrationWeek === '1'
        ? themeUtils.colors.midnightBlue
        : themeUtils.colors.moonstone
  }
  return (
    <Flex
      backgroundColor={color}
      color={themeUtils.colors.white}
      maxWidth={maxWidth}
      height="6rem"
      borderRadius="1rem"
      position="relative"
      alignItems="center"
      paddingX="2rem"
      paddingY="1.5rem"
      fontSize="1.4rem"
      {...restProps}
    >
      {children}
    </Flex>
  )
}
