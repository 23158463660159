import React from 'react'

import { useAuth } from './auth-context'
// eslint-disable-next-line @typescript-eslint/camelcase
import { GetMyPortalUser_me } from './__generated__/GetMyPortalUser'

// TODO [#551]: Once you have a known type/interface for the user object, please update
// this file and replace all 'object' occurances, with the User type.
// eslint-disable-next-line @typescript-eslint/camelcase
const UserContext = React.createContext<null | GetMyPortalUser_me>(null)

const UserProvider: React.FC = props => {
  const authContext = useAuth()
  return (
    <UserContext.Provider value={authContext && authContext.user} {...props} />
  )
}

// eslint-disable-next-line @typescript-eslint/camelcase
const useUser = (): null | GetMyPortalUser_me => React.useContext(UserContext)

export { UserProvider, useUser }
