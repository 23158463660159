import React, { ReactElement } from 'react'
import { Flex, FlexProps, BoxProps } from '@chakra-ui/core'
import { themeUtils } from '../../themeUtils'

const getInitials = (name: string): string => {
  let [firstName, lastName] = name.split(' ')

  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`
  } else {
    return firstName.charAt(0)
  }
}
interface AvatarProps {
  name: string
  width: BoxProps['width']
  height: BoxProps['height']
  fontSize: BoxProps['fontSize']
}

type Props = FlexProps & AvatarProps

const InitialAvatar: React.FC<Props> = ({
  name,
  width,
  height,
  fontSize,
  color = themeUtils.colors.midnightBlue,
  ...rest
}): ReactElement => {
  return (
    <Flex
      textAlign="center"
      textTransform="uppercase"
      fontWeight="medium"
      aria-label={name}
      borderRadius="full"
      alignItems="center"
      justifyContent="center"
      fontSize={fontSize}
      width={width}
      height={height}
      color={color}
      {...rest}
    >
      {name ? getInitials(name) : null}
    </Flex>
  )
}

export { InitialAvatar }
