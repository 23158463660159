import React from 'react'
import { ModalOverlay as BaseModalOverlay, BoxProps } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { animations } from 'react-animation'
import 'react-animation/dist/keyframes.css'

const StyledModalOverlay = styled(BaseModalOverlay)`
  animation: ${animations.fadeIn};
`

export const ModalOverlay: React.FC<BoxProps> = ({
  backgroundColor = 'rgba(0, 57, 104, 0.8)',
  ...props
}) => {
  return <StyledModalOverlay backgroundColor={backgroundColor} {...props} />
}
