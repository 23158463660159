import React from 'react'
import { Link as ReachLink } from '@reach/router'
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/core'

export interface LinkProps extends ChakraLinkProps {
  to: string
}

const Link: React.FC<LinkProps> = ({
  to,
  isExternal = false,
  ...restProps
}) => {
  return (
    // TODO [#632]: Don't opt out from type checking once `ChakraLink` gets fixed
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    <ChakraLink
      fontSize="1.4rem"
      {...(isExternal ? { href: to } : { as: ReachLink, to: to })}
      {...restProps}
    />
  )
}

export { Link }
