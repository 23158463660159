import gql from 'graphql-tag'

export const RESEND_CONFIRMATION_MUTATION = gql`
  mutation ResendPortalAccountConfirmation($email: String!) {
    resendPortalAccountConfirmation(email: $email) # NB: Returns Boolean - ALWAYS NULL
  }
`

export const CONFIRM_ACCOUNT_MUTATION = gql`
  mutation ConfirmPortalAccount($input: NewPasswordInput!) {
    confirmPortalAccount(input: $input) {
      __typename
      ... on ConfirmationSuccess {
        success
      }
      ... on Error {
        errors {
          message
          path
          type
        }
      }
    }
  }
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPortalPassword($input: PasswordResetInput!) {
    resetPortalPassword(input: $input)
  }
`

export const NEW_PASSWORD_MUTATION = gql`
  mutation NewPortalPassword($input: NewPasswordInput!) {
    newPortalPassword(input: $input)
  }
`

export const UNLOCK_ACCOUNT_MUTATION = gql`
  mutation UnlockPortalAccount($token: String!) {
    unlockPortalAccount(token: $token)
  }
`

export const RESEND_UNLOCK_INSTRUCTIONS_MUTATION = gql`
  mutation ResendPortalUnlockInstructions($email: String!) {
    resendPortalUnlockInstructions(email: $email)
  }
`
