import React, { ChangeEvent } from 'react'
import { OrderPortalStatusEnum } from '../../__generated__/globalTypes'
import { Select } from '../Forms'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { ORDER_FILTERS_QUERY } from '../../schema/queries'
import { GetOrderFilters } from '../../schema/queries/__generated__/GetOrderFilters'
import { SET_ORDER_FILTERS_MUTATION } from '../../schema/mutations'
import {
  SetOrderFilters,
  SetOrderFiltersVariables,
} from '../../schema/mutations/__generated__/SetOrderFilters'
import { Flex } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { Option } from '../Shared'
import i18n from '../../i18n'

const getStatusOptions = (): Option[] => ([
  {
    value: OrderPortalStatusEnum.CANCELLED,
    label: i18n.t('order.cancelled', { ns: ['status'] }),
  },
  {
    value: OrderPortalStatusEnum.CANCEL_REQUESTED,
    label: i18n.t('order.cancelRequested', { ns: ['status'] }),
  },
  {
    value: OrderPortalStatusEnum.CONFIRMED,
    label: i18n.t('order.confirmed', { ns: ['status'] }),
  },
  {
    value: OrderPortalStatusEnum.CREATED,
    label: i18n.t('order.created', { ns: ['status'] }),
  },
  {
    value: OrderPortalStatusEnum.SHIPPED,
    label: i18n.t('order.shipped', { ns: ['status'] }),
  },
  {
    value: OrderPortalStatusEnum.SUBMITTED,
    label: i18n.t('order.submitted', { ns: ['status'] }),
  },
])

const humanise = (string: string): string => {
  const humanised = string
    .toLowerCase()
    .split('_')
    .join(' ')

  return (
    humanised.substr(0, 1).toUpperCase() + humanised.substr(1).toLowerCase()
  )
}

export const OrderStatusSelect: React.FC = () => {
  const { t } = useTranslation(['orderScreen'])
  const { data } = useQuery<GetOrderFilters>(ORDER_FILTERS_QUERY)

  const status = data?.orderFilters.status || ''

  const [setFiltersMutation] = useMutation<
    SetOrderFilters,
    SetOrderFiltersVariables
  >(SET_ORDER_FILTERS_MUTATION)

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!data?.orderFilters) return

    const newStatus = (event.target.value as OrderPortalStatusEnum) || null

    setFiltersMutation({
      variables: {
        input: {
          ...data.orderFilters,
          status: newStatus,
        },
      },
    })
  }

  return (
    <Flex gridArea="status-select">
      <Select
        value={status}
        isEmpty={status === ''}
        onChange={onChange}
        marginBottom={0}
      >
        <option value="">
          {t('statusDropdown.all', { ns: ['orderScreen'] })}
        </option>
        {getStatusOptions().map(s => {
          return (
            <option key={s.value} value={s.value}>
              {s.label}
            </option>
          )
        })}
      </Select>
    </Flex>
  )
}
