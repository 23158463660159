import React from 'react'
import { IconProps } from '../../types'

const LoginCheckIcon: React.FC<IconProps> = ({
  fill = "none",
  width = 22,
  height = 16,
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 16">
      <path fill={fill} stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" strokeWidth="3" d="M2.429 8.26L7.975 14 19.57 2" />
    </svg>
  )
}

export { LoginCheckIcon }
