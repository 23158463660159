import gql from 'graphql-tag'

export const POSTAL_ADDRESS_FRAGMENT = gql`
  fragment PostalAddressFragment on Address {
    city
    country
    line1
    line2
    line3
    state
    zip
  }
`

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    id
    nordionShipToId
    shippingNote
    mnxEligibleForRush
    timezone
    deliveryCharges {
      id
      key
      price
      label
    }
    timezone
    ...PostalAddressFragment
  }
  ${POSTAL_ADDRESS_FRAGMENT}
`
