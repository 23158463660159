import gql from 'graphql-tag'

export const GET_REASONS_QUERY = gql`
  query GetReasons($type: ReasonTypeEnum!, $page: Int, $limit: Int) {
    reasons(type: $type, page: $page, limit: $limit) {
      totalPages
      items {
        id
        code
        customerFacingDescription
        showInPortal
      }
    }
  }
`
