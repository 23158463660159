import React from 'react'
import { LoadingIndicator, Alert } from '../../Shared'
import { ApolloError } from 'apollo-client'
import { TeamMemberRow } from './TeamMemberRow'
import { GetTeamMembers_teamMembers } from '../../../schema/queries/__generated__/GetTeamMembers'
import { useUser } from '../../../context/user-context'
import { RegionCodeEnum } from '../../../__generated__/globalTypes'
import { isROWCustomer, isUSCustomer } from '../../lib'
import { useTranslation } from 'react-i18next'

const BlankRow: React.FC<{ columnCount: number }> = ({ children, columnCount }) => (
  <tr>
    <td colSpan={columnCount} style={{ textAlign: 'center' }}>
      {children}
    </td>
  </tr>
)

export interface team_member_items {
  // __typename: "Team_Member";
  firstName: string;
  lastName: string;
  email: string | null;
  profession: string;
  otherProfession: string | null;
  npi: string | null
  customerId: number
  isPortalUser: boolean
}

interface Props {
  teamMembers: GetTeamMembers_teamMembers[] | null | undefined
  loading: boolean
  error?: ApolloError
}

export const TableContent: React.FC<Props> = ({ teamMembers, loading, error }) => {
  const { t } = useTranslation(['teamMemberSection'])
  const user = useUser()
  const columnCount = isUSCustomer(user) ? 6 : isROWCustomer(user) ? 3 : 5
  if (error) {
    return (
      <BlankRow columnCount={columnCount}>
        <Alert status="error">{error.message}</Alert>
      </BlankRow>
    )
  }

  if (loading) {
    return (
      <BlankRow columnCount={columnCount}>
        <LoadingIndicator />
      </BlankRow>
    )
  }

  if (!teamMembers || teamMembers.length === 0)
    return (
      <BlankRow columnCount={columnCount}>
        {t('table.noMemberMsg', { ns: ['teamMemberSection'] })}
      </BlankRow>
    )

  return (
    <>
      {teamMembers.map((member, i) => {
        return (
          <TeamMemberRow key={`${member.id}-${i}`} member={member} />
        )
      })}
    </>
  )
}
