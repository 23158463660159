import React from 'react'
import { IconProps } from '../../types'
import styled from '@emotion/styled'

type Props = IconProps & React.SVGAttributes<SVGElement>

const Svg = styled.svg`
  flex-shrink: 0;
  backface-visibility: hidden;
  &:not(:root) {
    overflow: hidden;
  }
`

export const CheckSquareIcon: React.FC<Props> = ({
  fill = '#fff',
  width = 20,
  height = 20,
  ...rest
}) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      {...rest}
    >
      <g>
        <g>
          <path
            d="M14.021,8.43183l-3.888,3.89445c-0.587,0.58697 -1.537,0.58697 -2.124,0l-1.767,-1.76993c-0.39,-0.39065 -0.39,-1.0247 0,-1.41634c0.391,-0.39065 1.024,-0.39065 1.414,0l0.706,0.70717c0.391,0.39165 1.026,0.39165 1.418,0l2.827,-2.83169c0.39,-0.39165 1.024,-0.39165 1.414,0c0.391,0.39065 0.391,1.0257 0,1.41634zM20,18c0,1.105 -0.895,2 -2,2h-16c-1.105,0 -2,-0.895 -2,-2v-16c0,-1.105 0.895,-2 2,-2h16c1.105,0 2,0.895 2,2zM18,3c0,-0.552 -0.448,-1 -1,-1h-14c-0.552,0 -1,0.448 -1,1v14c0,0.552 0.448,1 1,1h14c0.552,0 1,-0.448 1,-1z"
            fill={fill}
          ></path>
        </g>
      </g>
    </Svg>
  )
}
