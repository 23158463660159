import React from 'react'
import {
  FinalisingOrder,
  OrderFormClearErrorType,
  OrderFormErrorType,
  TreatmentExtraDetailsWidget,
} from './'
import { OrderFormData } from '../OrderCreate/OrderFormDataTypes'
import { NestDataObject } from 'react-hook-form'
import { useOrderForm } from '../../../hooks'
import { OrderFormStepType } from '../../../context/OrderFormContext'
import styled from '@emotion/styled'
import { MEDIUM, mqNames } from '../../../themeUtils'
import { CheckDeliveryCharges } from '../../../schema/queries/__generated__/CheckDeliveryCharges'
import { GetCustomer } from '../../../schema/queries/__generated__/GetCustomer'
import { Option } from '../../Shared'

interface Props {
  formData: OrderFormData
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
  onChangeCheckBoxValue: (val: boolean) => void
  createAccessoryOnlyOrder: boolean
  changeTreatmentDate: (date: Date | null) => void
  minOrderTreatmentDate(isMnxEligible: boolean): Date
  isMnxEligibleOrder: () => boolean
  isMnxTreatmentDate: () => boolean
  errors: NestDataObject<OrderFormData>
  setError: OrderFormErrorType
  clearError: OrderFormClearErrorType
  screenOpenedAt: Date
  deliveryChargeData: CheckDeliveryCharges | undefined
  customerData: GetCustomer | undefined
}

const Container = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  ${mqNames(MEDIUM)} {
    grid-template-columns: 1.2fr 1fr;
    grid-template-rows: auto;
  }
`

export const Step3: React.FC<Props> = ({ ...props }) => {
  const { step } = useOrderForm()
  if (step !== OrderFormStepType.STEP_3) return null
  return (
    <Container>
      <TreatmentExtraDetailsWidget {...props} />
      <FinalisingOrder {...props} />
    </Container>
  )
}
