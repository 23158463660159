import gql from 'graphql-tag'

export const OPTIMUM_SHIP_DATE_QUERY = gql`
  query OptimumShipDate(
    $treatmentDate: String!
    $treatmentTime: String!
    $selectedCalibrationDate: String!
    $mnxRushOrder: Boolean
  ) {
    optimumShipDate(
      treatmentDate: $treatmentDate
      treatmentTime: $treatmentTime
      selectedCalibrationDate: $selectedCalibrationDate
      mnxRushOrder: $mnxRushOrder
    ) {
      shipDate
      fulfillable
      amTreatmentUnavailable
    }
  }
`
