import React, { ReactNode } from 'react'
import { darken } from 'polished'
import { themeUtils } from '../../../../themeUtils'
import { PlusIconThin, MinusIcon } from '../../../Icons'
import { IconProps } from '../../../../types'
import { Button, CustomButtonProps } from '../../../Shared/Button'

type _CustomButtonProps = Omit<CustomButtonProps, 'children'>

interface StepButtonProps extends _CustomButtonProps {
  stepType: 'add' | 'subtract'
  value: number | string
}

const ActiveMinusIcon: React.FC<IconProps> = () => (
  <MinusIcon fill={themeUtils.colors.fringyFlower} />
)

export const StepButton: React.FC<StepButtonProps> = ({
  stepType = 'add',
  backgroundColor = themeUtils.colors.shuttleGrey,
  ...props
}) => {
  const subtractIcon = (): ReactNode =>
    props.isDisabled ? <MinusIcon /> : <ActiveMinusIcon />

  return (
    <Button
      _disabled={{
        background: 'rgba(92, 108, 122, 0.2)',
      }}
      backgroundColor={backgroundColor}
      boxShadow="0px 4px 6px rgba(92, 108, 122, 0.304606)"
      _hover={{
        backgroundColor:
          stepType === 'add' ? darken(0.1, `${backgroundColor}`) : '#FFFAFA',
      }}
      transition="background-color 250ms linear"
      height="2.6rem"
      width="2.6rem"
      borderRadius="9999px"
      paddingX={0}
      paddingY={0}
      {...props}
    >
      {stepType === 'add' ? <PlusIconThin /> : subtractIcon()}
    </Button>
  )
}
