import React from 'react'

interface Props {
  text?: string
}

export const LoadingIndicator: React.FC<Props> = ({ text = 'Loading...' }) => (
  <>
    Loading...
  </>
)
