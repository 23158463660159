import React, { useEffect, useState } from 'react'
import { useDisclosure } from '@chakra-ui/core'
import { useMutation } from '@apollo/react-hooks'
import { themeUtils } from '../../../themeUtils'
import { Notification, handleErrors } from '../../../utils'
import { Button } from '../../Shared'
import { RequestRemovalModal } from './RequestRemovalModal'
import { GetTeamMembers_teamMembers } from '../../../schema/queries/__generated__/GetTeamMembers'
import { UPDATE_PORTAL_TEAM_MEMBER_MUTATION } from '../../../schema/mutations/TeamMemberMutations'
import {
  UpdatePortalTeamMember,
  UpdatePortalTeamMemberVariables,
} from '../../../schema/mutations/__generated__/UpdatePortalTeamMember'
import { today } from '../../lib'
import { RequestRemovalIcon } from '../../Icons/RequestRemovalIcon'
import { CancelRemovalRequestIcon } from '../../Icons/CancelRemovalRequestIcon'
import { CrossSquareIcon } from '../../Icons'
import { CancelRemovalRequestWhiteIcon } from '../../Icons/CancelRemovalRequesWhiteIcon'
import { rgb } from 'polished'
import { useTranslation } from 'react-i18next'

interface Props {
  member: GetTeamMembers_teamMembers
}

export const RequestRemovalButton: React.FC<Props> = props => {
  const { t } = useTranslation(['teamMemberSection'])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure()

  const [dimension, updateDimension] = useState({ width: window.innerWidth })
  const [isSmallScreen, setIsSmallScreen] = useState(dimension.width < 720)

  const RequestButtonText = props.member.removalRequestedAt
    ? t('table.requestRemoval.pendingRemovalBtn', { ns: ['teamMemberSection'] })
    : t('table.requestRemoval.removalRequestBtn', { ns: ['teamMemberSection'] })
  const CancelRequestButtonText = t('table.cancelRemoval.btnLabel', {
    ns: ['teamMemberSection'],
  })

  const [requestRemoval, { loading }] = useMutation<
    UpdatePortalTeamMember,
    UpdatePortalTeamMemberVariables
  >(UPDATE_PORTAL_TEAM_MEMBER_MUTATION, {
    variables: {
      input: {
        id: props.member.id,
        removalRequestedAt: today(),
      },
    },
  })

  const [cancelRequest, {}] = useMutation<
    UpdatePortalTeamMember,
    UpdatePortalTeamMemberVariables
  >(UPDATE_PORTAL_TEAM_MEMBER_MUTATION, {
    variables: {
      input: {
        id: props.member.id,
        removalRequestedAt: null,
      },
    },
  })

  const onRequestRemoval = async (): Promise<void> => {
    const { data, errors } = await requestRemoval({
      refetchQueries: ['GetTeamMembers'],
    })
    const successMessage = t('table.requestRemoval.successMsg', {
      ns: ['teamMemberSection'],
    })
    if (errors) {
      onClose()
      errors.forEach(error => Notification.error(error.message))
    } else {
      if (data && data.updatePortalTeamMember.__typename === 'Error') {
        handleErrors(data.updatePortalTeamMember.errors)
      } else {
        onClose()
        Notification.success(successMessage)
      }
    }
  }

  const onCancelRemovalRequest = async (): Promise<void> => {
    const { data, errors } = await cancelRequest({
      refetchQueries: ['GetTeamMembers'],
    })
    const successMessage = t('table.cancelRemoval.successMsg', {
      ns: ['teamMemberSection'],
    })
    if (errors) {
      onCancelClose()
      errors.forEach(error => Notification.error(error.message))
    } else {
      if (data && data.updatePortalTeamMember.__typename === 'Error') {
        handleErrors(data.updatePortalTeamMember.errors)
      } else {
        onCancelClose()
        Notification.success(successMessage)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      updateDimension({
        ...dimension,
        width: window.innerWidth,
      })
      setIsSmallScreen(window.innerWidth < 720)
    })
  }, [window.innerWidth, window])

  return (
    <>
      {props.member.removalRequestedAt && isSmallScreen && (
        <div style={{ display: 'flex', marginTop: '20%' }}>
          <span style={{ cursor: 'pointer' }} onClick={onCancelOpen}>
            <CancelRemovalRequestIcon title={CancelRequestButtonText} />
          </span>
        </div>
      )}
      {!props.member.removalRequestedAt && isSmallScreen && (
        <div style={{ cursor: 'pointer', marginTop: '20%' }} onClick={onOpen}>
          <RequestRemovalIcon title={RequestButtonText} />
        </div>
      )}
      {props.member.removalRequestedAt && !isSmallScreen && (
        <Button
          backgroundColor={rgb(255, 215, 107)}
          leftIcon={CancelRemovalRequestWhiteIcon}
          marginBottom={isSmallScreen ? '1rem' : '0'}
          onClick={onCancelOpen}
          display={'flex'}
          justifyContent={'initial'}
        >
          {CancelRequestButtonText}
        </Button>
      )}
      {!props.member.removalRequestedAt && !isSmallScreen && (
        <Button
          backgroundColor={themeUtils.colors.red}
          leftIcon={CrossSquareIcon}
          // isFullWidth={isSmallScreen}
          marginBottom={isSmallScreen ? '1rem' : '0'}
          onClick={onOpen}
          display={'flex'}
          justifyContent={'initial'}
        >
          {RequestButtonText}
        </Button>
      )}

      <RequestRemovalModal
        headingText={t('table.requestRemoval.headingTitle', {
          ns: ['teamMemberSection'],
        })}
        buttonText={t('table.requestRemoval.buttonText', {
          ns: ['teamMemberSection'],
        })}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onRequestRemoval}
        loading={loading}
        description={t('table.requestRemoval.description', {
          ns: ['teamMemberSection'],
        })}
      />
      <RequestRemovalModal
        headingText={t('table.cancelRemoval.headingTitle', {
          ns: ['teamMemberSection'],
        })}
        buttonText={t('table.cancelRemoval.buttonText', {
          ns: ['teamMemberSection'],
        })}
        isOpen={isCancelOpen}
        onClose={onCancelClose}
        onSubmit={onCancelRemovalRequest}
        loading={loading}
      />
    </>
  )
}
