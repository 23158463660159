import React from 'react'
import { OrderFormData } from '../OrderCreate/OrderFormDataTypes'
import { Box, Flex, Text } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { themeUtils } from '../../../themeUtils'
import { AccessoryFields } from '../OrderCreate/AccessoryFields'
import { LinkButton } from '../../Shared'
import { AccessoryIcon, EmailIcon } from '../../Icons'
import { useUser } from '../../../context/user-context'
import { ThemeVersion } from '../../../types'
import { useTranslation } from 'react-i18next'

const { midnightBlue, red } = themeUtils.colors

const Container = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
`

const Header = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.label`
  color: ${midnightBlue};
  font-size: 16px;
  font-weight: 400;
  line-height: 12px; /* 75% */
`

interface Props {
  formData: OrderFormData
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
  isMnxTreatmentDate: () => boolean
}

export const AddTubingSets: React.FC<Props> = ({ formData, setFormData, isMnxTreatmentDate}) => {
  const { t } = useTranslation(['orderCreate'])
  const user = useUser()
  const customerSalesRep = user?.customer.regionalCustomerCareRep
  const { defaultAdminSet, adminSets } = formData
  return (
    <Container>
      <Header>
        <Title>
          {t('orderCreateWidget.tubingSets.subTitle', { ns: ['orderCreate'] })}
        </Title>
        <AccessoryIcon fill={midnightBlue} width="33px" height="37px" />
      </Header>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        marginBottom={defaultAdminSet ? 0 : '3rem'}
      >
        {defaultAdminSet && !isMnxTreatmentDate() ? (
          <AccessoryFields
            defaultAdminSet={defaultAdminSet}
            adminSets={adminSets}
            setAccessoryData={setFormData}
            initializeWithAccessory={true}
            version={ThemeVersion.V2}
          />
        ) : (
          <>
            {isMnxTreatmentDate() ? (
              <div>
                <Text color={red}>
                  <b>
                    {t('orderCreateWidget.accessorySets', {
                      ns: ['orderCreate'],
                    })}
                  </b>
                </Text>
              </div>
            ) : (
              <div>
                <Text color={red}>
                  <b>
                    {t('orderCreateWidget.sorryNo', {
                      ns: ['orderCreate'],
                    })}
                  </b>
                </Text>
                <Text marginY="1rem">
                  <b>{customerSalesRep?.name}</b>
                </Text>
                <LinkButton
                  wordBreak="break-all"
                  padding="1.5rem"
                  href={`mailto:${customerSalesRep?.email}`}
                >
                  <EmailIcon /> {customerSalesRep?.email}
                </LinkButton>
              </div>
            )}
          </>
        )}
      </Box>
    </Container>
  )
}
