import React from 'react'
import { AlertIcon, AlertProps } from '@chakra-ui/core'
import { Alert as BaseAlert } from '../../components/Shared'

export const Alert: React.FC<AlertProps> = ({ status, children }) => (
  <BaseAlert status={status} maxWidth="37.5rem">
    <AlertIcon />
    <p>{children}</p>
  </BaseAlert>
)
