import gql from 'graphql-tag'
import { FEATURE_FLAG_STATUS_FRAGMENT } from '../fragments'

export const FEATURE_FLAGS_QUERY = gql`
  query FeatureFlags {
    featureFlags {
      ...FeatureFlagStatus
    }
  }
  ${FEATURE_FLAG_STATUS_FRAGMENT}
`

export const FEATURE_FLAG_QUERY = gql`
  query FeatureFlag($title: String!) {
    featureFlag(title: $title) {
      id
      enabled
    }
  }
`
