import React from "react";
import { ConfirmUserDetails } from "../MyAccount/BasicDetails";
import { Alert } from "../Shared";
import { Text, useDisclosure } from "@chakra-ui/core";
import { themeUtils } from "../../themeUtils";
import { useUser } from "../../context/user-context";
import { useFeatureFlag } from "../../context/FeatureFlagsContext";
import styled from "@emotion/styled";
import { useLocation } from "@reach/router";
import { useTranslation } from 'react-i18next'
import { isDistributor } from "../lib";

const AlertContainer = styled(Alert)`
  display: flex;
  justify-content: center;
  min-height: 4rem;
  border-radius: unset;
`

export const UserDetailsBanner: React.FC = () => {
  const { t } = useTranslation(['dashboardScreen'])
  const treatingPhysicianFeatureFlag = useFeatureFlag('use_treating_physician')
  const user = useUser()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const location = useLocation();
  const currentRoute = location.pathname;

  if (
    !user ||
    !treatingPhysicianFeatureFlag ||
    currentRoute !== '/' ||
    isDistributor(user)
  )
    return null

  const onProfileSetUpLink = (): void => {
    onOpen()
  }

  return (
    <>
      {!user.hasUpdatedDetails && (
        <AlertContainer status="success">
          <Text color={themeUtils.colors.midnightBlue}>
            {t('alertStrip.improveExperience1', { ns: ['dashboardScreen'] })}{' '}
            <span
              onClick={onProfileSetUpLink}
              style={{
                fontWeight: 'bold',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              {t('alertStrip.improveExperience2', { ns: ['dashboardScreen'] })}
            </span>{' '}
          </Text>
        </AlertContainer>
      )}

      {isOpen && (
        <ConfirmUserDetails
          isHardTriggerModalOpen={isOpen}
          onHardTriggerModalClose={onClose}
        />
      )}
    </>
  )
}