import React from 'react'
import { IconProps } from '../../types'
import styled from '@emotion/styled'
import { themeUtils } from '../../themeUtils'

type Props = IconProps & React.SVGAttributes<SVGElement>

const Svg = styled.svg`
  flex-shrink: 0;
  backface-visibility: hidden;
  &:not(:root) {
    overflow: hidden;
  }
`

export const BoxIcon: React.FC<Props> = ({
  fill = themeUtils.colors.slateGrey,
  width = 51,
  height = 49,
  ...rest
}) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 51 49"
      {...rest}
    >
      <g>
        <g>
          <path
            d="M32.12476,34.66073l-4.96805,-6.68456l16.4532,-9.22579l4.91883,5.79237zM43.00655,36.95514c0,0.43713 -0.23344,0.84494 -0.60881,1.06434l-15.64791,9.11306v-17.0767l4.55094,6.12048c0.15258,0.20544 0.38681,0.31554 0.62562,0.31554c0.13883,0 0.28,-0.03775 0.40664,-0.11545l10.67352,-6.57928zM9.43952,37.99646c-0.34562,-0.20249 -0.56078,-0.57828 -0.56078,-0.98097v-6.67801l10.80555,6.05889c0.11976,0.06702 0.24945,0.09935 0.37836,0.09935c0.24039,0 0.47609,-0.11166 0.62796,-0.318l4.49672,-6.10454v17.09916zM8.41734,18.77183l16.38132,9.18535l-4.95422,6.72515l-16.41154,-9.20245zM19.9353,1.77415l4.80508,4.85295l-16.46243,10.16023l-5.65148,-4.89225zM32.05913,1.76571l16.44367,9.21941l-4.87476,5.74096l-16.40758,-10.11964zM26.74983,8.16451l15.48719,9.55223l-15.48719,8.6841zM9.75202,17.71151l15.43531,-9.51498v18.16515zM50.31171,24.2201l-5.49563,-6.47118l5.49563,-6.47119c0.15335,-0.17946 0.21742,-0.41893 0.17625,-0.65224c-0.04125,-0.23332 -0.18469,-0.43502 -0.38985,-0.54974l-17.79328,-9.97637c-0.30289,-0.17015 -0.68131,-0.1178 -0.92929,0.1294l-5.40446,5.40726l-5.35531,-5.40498c-0.25023,-0.25255 -0.63781,-0.30412 -0.94375,-0.12474l-18.78203,10.97429c-0.21891,0.12781 -0.36313,0.35498 -0.38678,0.60982c-0.02291,0.25484 0.07779,0.5044 0.27006,0.67069l6.36432,5.50552l-5.49229,7.39073c-0.13578,0.18167 -0.18617,0.41419 -0.13883,0.63757c0.04657,0.22252 0.18688,0.41349 0.38453,0.52435l5.47196,3.0682c-0.02344,0.07506 -0.04672,0.15076 -0.04672,0.23363v7.29437c0,0.96323 0.51344,1.86188 1.34125,2.34625l15.89812,9.26325c0.42807,0.24869 0.90719,0.37343 1.38703,0.37343c0.00469,0 0.00922,-0.00086 0.01391,-0.00086c0.0043,0 0.00781,0.00244 0.01203,0.00244c0.00828,0 0.01516,-0.00449 0.02336,-0.00481c0.46336,-0.00828 0.92516,-0.13002 1.33852,-0.3702l15.84931,-9.23557c0.85678,-0.50054 1.38928,-1.43222 1.38928,-2.43015v-8.12576l5.55727,-3.42759c0.19531,-0.12088 0.32882,-0.32187 0.3639,-0.54974c0.03586,-0.22795 -0.02898,-0.46127 -0.17851,-0.63608z"
            fill={fill}
          ></path>
        </g>
      </g>
    </Svg>
  )
}
