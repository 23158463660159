import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  Box,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  useDisclosure,
} from '@chakra-ui/core'
import {
  Button,
  Heading,
  ModalContent,
  ModalOverlay,
  SingleSelect,
  Scrollable,
} from '../../Shared'
import { useUser } from '../../../context/user-context'
import { InviteTeamMemberPopover, TeamMemberPopover } from '../TeamMember'
import {
  LARGE,
  MEDIUM,
  X_LARGE,
  breakpoints,
  mqNames,
  themeUtils,
} from '../../../themeUtils'
import { GetTeamMembers } from '../../../schema/queries/__generated__/GetTeamMembers'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { TeamMemberProfessionEnum } from '../../../__generated__/globalTypes'
import { useLazyQuery, useMutation } from 'react-apollo'
import { Input, LoadingIndicator } from '../../Shared'
import { FormLabel } from '../../Forms'
import { GET_TEAM_MEMBERS } from '../../../schema/queries/TeamMembersQueries'
import { MemberCard } from '../TeamMember/MemberCard'
import { ConfirmPopupValues, LocalStorageKeys } from '../../../types'
import styled from '@emotion/styled'
import { useMedia } from 'use-media'
import { navigate } from '@reach/router'
import {
  UpdatePortalUser,
  UpdatePortalUserVariables,
} from '../../../schema/mutations/__generated__/UpdatePortalUser'
import { UPDATE_PORTAL_USER_MUTATION } from '../../../schema/mutations/PortalUserMutations'
import { Notification } from '../../../utils'
import { PortalUserForm, PortalUserSourceEnum } from './PortalUserForm'
import { isROWCustomer } from '../../lib'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'

const ModalContentContainer = styled(ModalContent)`
  max-width: 30.5rem;

  ${mqNames(LARGE)} {
    max-width: 38.5rem;
  }

  ${mqNames(X_LARGE)} {
    max-width: 48.5rem;
  }
`

interface Props {
  onSuccess?: () => void
  onMainClose?: () => void
}

interface FormData {
  profession: TeamMemberProfessionEnum
  otherProfession: string
  npi: string
}

const validationSchema = () => yup.object().shape({
  profession: yup
    .string()
    .required(
      i18n.t('basicDetails.profession.requiredError', { ns: ['myAccount'] })
    ),
  otherProfession: yup.string(),
  npi: yup.string(),
})

const { green, blue } = themeUtils.colors

export const getProfessionOptions = () => ([
  {
    value: TeamMemberProfessionEnum.treating_physician,
    label: i18n.t('professionOptions.treating_physician', {
      ns: ['myAccount'],
    }),
  },
  {
    value: TeamMemberProfessionEnum.physicist,
    label: i18n.t('professionOptions.physicist', { ns: ['myAccount'] }),
  },
  {
    value: TeamMemberProfessionEnum.radiation_safety_officer,
    label: i18n.t('professionOptions.radiation_safety_officer', {
      ns: ['myAccount'],
    }),
  },
  {
    value: TeamMemberProfessionEnum.radiopharmacist,
    label: i18n.t('professionOptions.radiopharmacist', { ns: ['myAccount'] }),
  },
  {
    value: TeamMemberProfessionEnum.nuclear_medicine_technologist,
    label: i18n.t('professionOptions.nuclear_medicine_technologist', {
      ns: ['myAccount'],
    }),
  },
  {
    value: TeamMemberProfessionEnum.finance_supply_chain,
    label: i18n.t('professionOptions.finance_supply_chain', {
      ns: ['myAccount'],
    }),
  },
  {
    value: TeamMemberProfessionEnum.administrative_staff,
    label: i18n.t('professionOptions.administrative_staff', {
      ns: ['myAccount'],
    }),
  },
  {
    value: TeamMemberProfessionEnum.other,
    label: i18n.t('professionOptions.other', { ns: ['myAccount'] }),
  },
])

export const UpdateDetailsModal: React.FC<Props> = ({
  onSuccess,
  onMainClose,
}) => {
  const { t } = useTranslation(['myAccount', 'teamMemberSection'])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isPortalUserFormOpen,
    onOpen: onPortalUserFormOpen,
    onClose: onPortalUserFormClose,
  } = useDisclosure()
  const user = useUser()

  useEffect(() => {
    onOpen()
  }, [])

  const [
    updatePortalUser,
    { loading: loadingUpdate, error: errorUpdate },
  ] = useMutation<UpdatePortalUser, UpdatePortalUserVariables>(
    UPDATE_PORTAL_USER_MUTATION
  )

  useEffect(() => {
    if (errorUpdate) Notification.error(errorUpdate.message)
  }, [errorUpdate])

  const [isPopoverOpen, setPopoverOpen] = useState<boolean>(false)
  const [isInvitePopoverOpen, setInvitePopoverOpen] = useState<boolean>(false)
  const [isProfessionConfirmed, setProfessionConfirm] = useState<boolean>(false)

  const [getTeamMembers, { data, loading, error }] = useLazyQuery<
    GetTeamMembers
  >(GET_TEAM_MEMBERS)

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    clearError,
    setError,
    reset,
  } = useForm<FormData>({
    validationSchema: validationSchema(),
    defaultValues: {
      profession: (user && user.profession) || undefined,
      otherProfession:
        (user && user.profession === TeamMemberProfessionEnum.other
          ? user.otherProfession
          : '') || '',
      npi:
        (user && user.profession === TeamMemberProfessionEnum.treating_physician
          ? user.npi
          : '') || '',
    },
  })

  useEffect(() => {
    register({ name: 'profession' })
  }, [register])

  const profession = watch('profession') as TeamMemberProfessionEnum

  useEffect(() => {
    if (
      !data &&
      profession &&
      profession !== TeamMemberProfessionEnum.treating_physician
    ) {
      getTeamMembers()
    }
  }, [profession])

  const onProfessionChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value as TeamMemberProfessionEnum
    setValue('profession', value)
    clearError('profession')

    // clear value of npi if value is not Treating Physician
    if (value !== TeamMemberProfessionEnum.treating_physician) {
      setValue('npi', '')
      clearError('npi')
    }

    // clear value of other Profession if value is not others
    if (value !== TeamMemberProfessionEnum.other) {
      setValue('otherProfession', '')
      clearError('otherProfession')
      if (value === TeamMemberProfessionEnum.treating_physician) {
        onPopoverClose()
      }
    }
  }

  const onSubmit = async (results: FormData): Promise<void> => {
    if (user) {
      if (results.profession === TeamMemberProfessionEnum.treating_physician) {
        onPortalUserFormOpen()
        return
      } else if (!isProfessionConfirmed) {
        setProfessionConfirm(true)
        return
      }

      const { data, errors } = await updatePortalUser({
        variables: {
          input: {
            id: user.id,
            teamMemberAttributes: {
              id: user.teamMember.id,
              profession: results.profession,
              otherProfession: results.otherProfession ?? null,
              npi: results.npi ?? null,
            },
            hasUpdatedDetails: true,
          },
        },
      })
      if (errors) {
        errors.forEach(error => Notification.error(error.message))
      } else {
        if (data && data.updatePortalUser) {
          if (data.updatePortalUser.__typename === 'Error') {
            data.updatePortalUser.errors.forEach(error => {
              if (error.path) {
                setError(error.path[0], error.type, error.message)
              }
            })
            Notification.error(t('toast.failure', { ns: ['myAccount'] }), {
              timeout: 3000,
            })
          } else {
            Notification.success(t('toast.success', { ns: ['myAccount'] }))
            onDataUpdateSuccess()
          }
        }
      }
    }
  }

  const onDataUpdateSuccess = (): void => {
    if (onSuccess) onSuccess()
    reset()
    onClose()
    localStorage.removeItem(LocalStorageKeys.CONFIRM_USER_DETAILS_POPUP)
  }

  const onModalClose = (): void => {
    reset()
    onClose()
    onPortalUserFormClose()
    localStorage.setItem(
      LocalStorageKeys.CONFIRM_USER_DETAILS_POPUP,
      ConfirmPopupValues.DISABLED
    )
    if (onMainClose) onMainClose()
  }

  const onPopoverClose = (): void => {
    setPopoverOpen(false)
  }

  const onInvitePopoverClose = (): void => {
    setInvitePopoverOpen(false)
  }

  const addTeamMemberAction = (): void => {
    setPopoverOpen(!isPopoverOpen)
  }

  const inviteTeamMemberAction = (): void => {
    setInvitePopoverOpen(!isInvitePopoverOpen)
  }

  const isMedium = useMedia({ maxWidth: breakpoints[MEDIUM] })

  if (isPortalUserFormOpen) {
    return (
      <PortalUserForm
        isOpen={isPortalUserFormOpen}
        onClose={onModalClose}
        source={PortalUserSourceEnum.CONFIRMING_DETAILS_POPUP}
        onSuccess={onDataUpdateSuccess}
      />
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContentContainer
        marginRight={
          (isPopoverOpen || isInvitePopoverOpen) && !isMedium
            ? '30%'
            : undefined
        }
      >
        <ModalHeader padding="0">
          <Heading color={blue} as="h2" marginBottom="0">
            {t('updateDetailsModal.heading', { ns: ['myAccount'] })}
          </Heading>
        </ModalHeader>
        <ModalCloseButton _focus={undefined} />

        <ModalBody borderTop="1px solid #EFEFEF" padding="2rem 0 0 0">
          <Box>
            <SingleSelect
              label={t('updateDetailsModal.profession.label', {
                ns: ['myAccount'],
              })}
              placeholder={t('updateDetailsModal.profession.placeholder', {
                ns: ['myAccount'],
              })}
              name="Profession"
              value={profession}
              onChange={onProfessionChange}
              options={getProfessionOptions()}
              error={errors.profession && errors.profession.message}
              data-cy="account-profession-select"
              isRequired
            />
            {profession === TeamMemberProfessionEnum.other && (
              <Input
                label={t('updateDetailsModal.otherProfession.label', {
                  ns: ['myAccount'],
                })}
                type="text"
                id="otherProfession"
                name="otherProfession"
                innerRef={register}
                invalid={!!errors.otherProfession}
                error={errors.otherProfession && errors.otherProfession.message}
                data-cy="account-otherProfession-input"
              />
            )}
            {isProfessionConfirmed &&
              profession &&
              profession !== TeamMemberProfessionEnum.treating_physician && (
                <>
                  <FormLabel>
                    {t('updateDetailsModal.helpText', { ns: ['myAccount'] })}
                  </FormLabel>
                  <Divider border={1} />
                  {/* SECONDARY POPUP */}
                  <TeamMemberPopover
                    isPopoverOpen={isPopoverOpen}
                    onPopoverClose={onPopoverClose}
                  />
                  <InviteTeamMemberPopover
                    isPopoverOpen={isInvitePopoverOpen}
                    onPopoverClose={onInvitePopoverClose}
                  />
                  {/* TEAM MEMBER LIST */}
                  {loading && <LoadingIndicator />}
                  <Scrollable maxHeight={'14rem'}>
                    {data?.teamMembers &&
                      data?.teamMembers.map((member, i) => {
                        return (
                          <MemberCard
                            key={`${member.id}-${i}`}
                            member={member}
                          />
                        )
                      })}
                  </Scrollable>
                  {isROWCustomer(user) ? (
                    <Button
                      backgroundColor={blue}
                      isFullWidth
                      onClick={inviteTeamMemberAction}
                    >
                      {t('invite.btnLabel', { ns: ['teamMemberSection'] })}
                    </Button>
                  ) : (
                    <Button
                      backgroundColor={blue}
                      isFullWidth
                      onClick={addTeamMemberAction}
                    >
                      {t('add.btnLabel', { ns: ['teamMemberSection'] })}
                    </Button>
                  )}
                </>
              )}
            <Divider
              marginTop={'1.5rem'}
              marginBottom={'1.5rem'}
              border={'1'}
            />
            <div style={{ padding: '0 1rem' }}>
              <Button
                backgroundColor={green}
                isFullWidth
                isDisabled={isPopoverOpen || isInvitePopoverOpen}
                isLoading={loadingUpdate}
                onClick={handleSubmit(onSubmit)}
              >
                {t('updateDetailsModal.btnLabel', { ns: ['myAccount'] })}
              </Button>
            </div>
          </Box>
        </ModalBody>
      </ModalContentContainer>
    </Modal>
  )
}
