import React from 'react'
import { themeUtils } from '../../themeUtils'
import { Box, BoxProps } from '@chakra-ui/core'

const LoginContainer: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box
      backgroundColor={{ lg: themeUtils.colors.white }}
      padding={{ lg: '2rem' }}
      borderRadius="1rem"
      maxWidth="37.5rem"
      boxShadow={{ base: 'none', lg: '0 10px 15px rgba(141, 162, 179, 0.1)' }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export { LoginContainer }
