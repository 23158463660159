import React from 'react'
import {
  DesiredActivityAtTreatment,
  OrderFormData,
} from '../../Orders/OrderCreate/OrderFormDataTypes'
import { NestDataObject } from 'react-hook-form'
import { OrderFormClearErrorType, OrderFormErrorType } from '../../Orders'
import styled from '@emotion/styled'
import { MEDIUM, breakpoints, mqNames, themeUtils } from '../../../themeUtils'
import { Box, Divider, Flex } from '@chakra-ui/core'
import { FormLabel } from '../../Forms'
import { InputV2 } from '../Inputs'
import { useMedia } from 'use-media'
import { useTranslation } from 'react-i18next'

export enum DesiredActivityFieldSourceEnum {
  DASHBOARD_SEARCH = "DASHBOARD_SEARCH",
  ORDER_FORM = "ORDER_FORM",
} 

const { midnightBlue } = themeUtils.colors

const VialDetailsConatiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const TotalValue = styled.label`
  color: ${midnightBlue};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
`

type Props = {
  noOfVials: number
  desiredActivityAtTreatmentSet: DesiredActivityAtTreatment[] | undefined
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
  errors: NestDataObject<OrderFormData>
  isReadOnly?: boolean
  source?: DesiredActivityFieldSourceEnum
}

interface DesiredActivityEvent {
  currentTarget: {
    value: string
  }
}

export const DesiredActivityFields: React.FC<Props> = ({
  noOfVials,
  desiredActivityAtTreatmentSet,
  setFormData,
  errors,
  isReadOnly,
  source,
}) => {
  const { t } = useTranslation(['orderCreate'])
  const isMedium = useMedia({ maxWidth: breakpoints[MEDIUM] })

  const totalActivity = desiredActivityAtTreatmentSet
    ? desiredActivityAtTreatmentSet.reduce((sum, obj) => {
        if (obj.activity && !isNaN(obj.activity)) {
          return sum + obj.activity
        }
        return sum
      }, 0)
    : 0

  const formattedTotalActivity =
    totalActivity > 0 ? totalActivity.toFixed(2) : '-'
  return (
    <>
      {noOfVials > 0 && (
        <VialDetailsConatiner>
          <Divider
            height={'1px'}
            border={1}
            background={'rgba(0, 57, 104, 0.25)'}
            margin={'20px 0 0 0'}
            display={
              source === DesiredActivityFieldSourceEnum.DASHBOARD_SEARCH &&
              !isMedium
                ? 'none'
                : undefined
            }
          />
          {desiredActivityAtTreatmentSet?.map((vial, index) => {
            return (
              <VialDetails
                key={index}
                index={index}
                vial={vial}
                errors={errors}
                isReadOnly={isReadOnly}
                desiredActivityAtTreatmentSet={desiredActivityAtTreatmentSet}
                setFormData={setFormData}
              />
            )
          })}
          <Divider
            height={'1px'}
            border={1}
            background={'rgba(0, 57, 104, 0.25)'}
            margin={'0'}
          />
          <FormLabel>{t('activityFocused.desiredActivity.totalLabel')}</FormLabel>
          <TotalValue>{`${formattedTotalActivity} GBq`}</TotalValue>
        </VialDetailsConatiner>
      )}
    </>
  )
}

const VialComponentContainer = styled(Flex)`
  gap: 10px;
  flex-direction: column;
  ${mqNames(MEDIUM)} {
    flex-direction: row;
  }
`

const Box1 = styled(Box)`
  display: flex;
  flex-direction: column;
`

const SubBox1 = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  div[role='group'] {
    width: 100%;
  }

  ${mqNames(MEDIUM)} {
    div[role='group'] {
      width: unset;
    }
  }
`

const VialText = styled.label`
  color: ${midnightBlue};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  width: 38px;

  ${mqNames(MEDIUM)} {
    width: 34px;
  }
`

const VialInputStyle = styled(InputV2)`
  text-align: center;
  ${mqNames(MEDIUM)} {
    width: 90px;
  }
`

const NodeInputStyle = styled(InputV2)`
  text-align: center;
  ${mqNames(MEDIUM)} {
    text-align: start;
  }
`

interface DesiredActivityEvent {
  currentTarget: {
    value: string
  }
}

interface VialDetailsProps {
  index: number
  vial: DesiredActivityAtTreatment
  errors: NestDataObject<OrderFormData>
  isReadOnly?: boolean
  desiredActivityAtTreatmentSet: DesiredActivityAtTreatment[] | undefined
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
}

export const VialDetails: React.FC<VialDetailsProps> = ({
  index,
  vial,
  errors,
  isReadOnly,
  desiredActivityAtTreatmentSet,
  setFormData,
}) => {
  const { t } = useTranslation(['dashboardScreen'])
  const changeDesiredActivity = (event: {
    currentTarget: { value: string }
  }): void => {
    const { value } = event.currentTarget
    const twoDecimalsRegex = /^-?\d*([\.,]\d{0,2})?$/

    if ((value === '' || value.match(twoDecimalsRegex)) && index !== -1) {
      let desiredActivitySet: DesiredActivityAtTreatment[] =
        desiredActivityAtTreatmentSet ?? []
      // Create a copy of the object to avoid mutating the original array
      const updatedActivity = { ...desiredActivitySet[index] }

      // Parse the input value as a float
      let actualActivity = value.replace(/,/g, '.')

      const numericValue = parseFloat(actualActivity)

      if (isNaN(numericValue)) {
        updatedActivity.activity = undefined
        updatedActivity.activityValue = undefined
      } else if (numericValue <= 20) {
        updatedActivity.activity = numericValue
        updatedActivity.activityValue = value
      }

      desiredActivitySet[index] = updatedActivity

      setFormData((prevFormData: OrderFormData) => {
        return {
          ...prevFormData,
          desiredActivityAtTreatmentSet: desiredActivitySet,
        }
      })
    }
  }

  return (
    <VialComponentContainer>
      <Box1>
        <FormLabel fontSize={'1.2rem'} paddingRight={0}>
          {t('desiredActivityField.label', { ns: ['dashboardScreen'] })}
        </FormLabel>
        <SubBox1>
          <VialText>{t('desiredActivityField.vial', { ns: ['dashboardScreen'] })} {index + 1}</VialText>
          <VialInputStyle
            id={`desiredActivityAtTreatmentVial${index + 1}`}
            data-cy={`desired-activity-at-treatment-vial-${index + 1}`}
            type="text"
            value={vial.activityValue ? vial.activityValue : ''}
            onChange={changeDesiredActivity}
            error={
              errors.desiredActivityAtTreatmentSet &&
              //@ts-ignore
              errors.desiredActivityAtTreatmentSet[index]?.activity?.message
            }
            isDisabled={isReadOnly}
          />
          <VialText>{t('desiredActivityField.gbq', { ns: ['dashboardScreen'] })}</VialText>
        </SubBox1>
      </Box1>
    </VialComponentContainer>
  )
}
