import { addMinutes, format, parse } from 'date-fns'
import { getTimeFormat } from '../i18n'

export interface TimeOptionType {
  label: string
  value: string
}

export const generateTimes = (
  desiredStartTime = '12:00',
  interval = 30,
  duration = 24
): Array<TimeOptionType> => {
  const timeLabels = []
  const labelTimeFormat = getTimeFormat()

  const startTime = parse(desiredStartTime, 'HH:mm', new Date())
  let time = startTime

  for (let i = 0; i <= duration * 60; i += interval) {
    time = addMinutes(time, i === 0 ? 0 : interval)
    timeLabels.push({
      label: format(time, labelTimeFormat),
      value: format(time, 'HH:mm'),
    })
  }

  return timeLabels
}
