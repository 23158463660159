import React from 'react'
import { Button, ButtonProps } from '@chakra-ui/core'
import { themeUtils } from '../../../themeUtils'

interface ToggleButtonProps {
  isActive: boolean
  inactiveShadow?: boolean
  padRight: boolean
  name?: string
  onClick?: () => void
}

type Props = ToggleButtonProps & ButtonProps

export const ToggleButton: React.FC<Props> = ({
  isActive = false,
  inactiveShadow = false,
  padRight = true,
  boxShadow = '0 10px 15px rgba(0, 57, 104, 0.1)',
  onClick,
  ...props
}) => {
  const colors = themeUtils.colors
  const activeBackgroundColor = colors.midnightBlue
  const backgroundColor = colors.white
  const boxShadowValue =
    inactiveShadow || (!inactiveShadow && isActive) ? boxShadow : 'none'

  return (
    <Button
      variant={isActive ? 'solid' : 'ghost'}
      isActive={isActive}
      onClick={onClick}
      backgroundColor={isActive ? activeBackgroundColor : backgroundColor}
      _active={{
        backgroundColor: isActive ? activeBackgroundColor : backgroundColor,
      }}
      _hover={{
        backgroundColor: isActive ? activeBackgroundColor : colors.geyserGrey,
      }}
      boxShadow={boxShadowValue}
      borderRadius="0.5rem"
      color={isActive ? colors.white : colors.midnightBlue}
      padding="0.7rem 2rem"
      fontSize="1.4rem"
      fontWeight="medium"
      flex="1"
      height="auto"
      marginRight={padRight ? '0.5rem' : 0}
      {...props}
    />
  )
}
