import React, { useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Toggle, ToggleButton } from '../Shared'
import { ORDER_FILTERS_QUERY } from '../../schema/queries'
import { GetOrderFilters } from '../../schema/queries/__generated__/GetOrderFilters'
import { SET_ORDER_FILTERS_MUTATION } from '../../schema/mutations/FilterMutations'
import { SetOrderFilters, SetOrderFiltersVariables } from '../../schema/mutations/__generated__/SetOrderFilters'
import { OrderFiltersInput, OrderStateEnum } from '../../__generated__/globalTypes'
import { globalHistory } from '@reach/router'
import { client } from '../../schema/apollo-client'
import i18n from '../../i18n'

const getStates = () => [
  {
    key: OrderStateEnum.OPEN,
    value: i18n.t('statusToggle.pre', { ns: ['orderScreen'] }),
  },
  {
    key: OrderStateEnum.CLOSED,
    value: i18n.t('statusToggle.post', { ns: ['orderScreen'] }),
  },
] 

export const StatusToggle: React.FC = () => {
  const { data } = useQuery<GetOrderFilters>(ORDER_FILTERS_QUERY)

  const [setFiltersMutation] = useMutation<
    SetOrderFilters,
    SetOrderFiltersVariables
  >(SET_ORDER_FILTERS_MUTATION)

  const setFilters = (newFilters: OrderFiltersInput): void => {
    setFiltersMutation({
      variables: {
        input: newFilters,
      },
    })
  }

  useEffect(() => {
    globalHistory.listen(({ action }) => {

      const updatedDataFromCache = client.readQuery({
        query: ORDER_FILTERS_QUERY,
      })

      if (action === 'PUSH') {
        if (data) {
          const previousFilters = updatedDataFromCache.orderFilters

          setFilters({
            ...previousFilters,
            state: OrderStateEnum.OPEN,
            page: 1,
          })
        }
      }
    })
  }, [])

  if (!data) return null

  return (
    <Toggle margin={0} gridArea="toggle">
      {getStates().map((state, index) => {
        const isActive = state.key === data.orderFilters.state
        const onClick = (): void =>
          setFilters({
            ...data.orderFilters,
            state: state.key,
            page: 1
          })
        return (
          <ToggleButton
            key={state.key}
            isActive={isActive}
            padRight={index === 0}
            onClick={onClick}
            aria-label={`Click to view ${state.value} orders`}
          >
            {state.value}
          </ToggleButton>
        )
      })}
    </Toggle>
  )
}
