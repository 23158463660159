import React from 'react'
import { Box, BoxProps } from '@chakra-ui/core'
import styled from '@emotion/styled'

const VisuallyHiddenStyled = styled(Box)`
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
`
interface Props extends BoxProps {
  type?: string
  name?: string
}

export const VisuallyHidden: React.FC<Props> = props => {
  return <VisuallyHiddenStyled {...props} />
}
