import loadDevTools from './dev-tools/load'
import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import { App } from '../src/app'
import { Maintenance } from '../src/components/maintenance'
import { AppProviders } from '../src/context/index'
import './i18n'

import { client } from './schema/apollo-client'

if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
  ReactDOM.render(
    <Maintenance />,
    document.getElementById('root')
  )
}
else
{
  // load and install the dev tools (if they need to be)
  // and when that's done, let's render the app
  // NOTE: if we don't need to install the devtools, then the callback
  // is called synchronously so there's no penalty for including this
  // in production.

  loadDevTools(() => {
    ReactDOM.render(
      <ApolloProvider client={client}>
        <AppProviders>
          <App />
        </AppProviders>
      </ApolloProvider>,
      document.getElementById('root')
    )
  })

}