import React from 'react'
import { Text, Grid, GridProps } from '@chakra-ui/core'
import { VialSearch_vialSearch_vials } from '../../../schema/queries/__generated__/VialSearch'
import { BoxIcon } from '../../Icons'
import { CenteredWrapper, Spinner } from '../../Shared'
import { Vial } from './Vial'
import { useTranslation } from 'react-i18next'

interface VialSearchResultsProps extends GridProps {
  loading: boolean
  vials: VialSearch_vialSearch_vials[] | undefined
  paddingTop: string
}

export const VialSearchResults: React.FC<VialSearchResultsProps> = ({
  maxHeight = { base: '100%', md: '43rem' },
  loading = false,
  vials,
  paddingTop = '0rem',
}) => {
  const { t } = useTranslation(['dashboardScreen'])
  if (!vials) {
    return (
      <CenteredWrapper
        height="100%"
        padding={{ base: '2rem 0 0 0', md: '6.2rem 0 0 0' }}
        data-cy="vial-search-result-container"
      >
        <BoxIcon />
        {loading ? (
          <Spinner
            marginTop="1.5rem"
            label="Searching for available vials..."
          />
        ) : (
          <Text marginTop="1.5rem" textAlign="center">
            {t('vialSearchResults.placeholder', { ns: ['dashboardScreen'] })}
          </Text>
        )}
      </CenteredWrapper>
    )
  }

  const anyQuantityInVials = (vials: VialSearch_vialSearch_vials[]) => {
    const vials_quantity_sum = vials.reduce(function(acc: any, curr: any) {
      if (curr.available !== null) {
        return (acc = acc + curr.available)
      } else {
        return 0
      }
    }, 0)

    return vials_quantity_sum > 0
  }

  if (vials.length) {
    return (
      <div>
        <Grid
          width="100%"
          maxHeight={maxHeight}
          overflow="auto"
          gridGap="1rem"
          gridTemplateColumns={{ base: '1fr', sm: '1fr 1fr' }}
          paddingTop={{ base: '2rem', md: paddingTop }}
          data-cy="vial-search-result-container"
        >
          {vials.map((vial, index) => (
            <Vial key={vial.dosage} vial={vial} index={index} />
          ))}
        </Grid>
      </div>
    )
  }

  return (
    <CenteredWrapper
      height="100%"
      padding={{ base: '2rem 0 0 0', md: '6.2rem 0 0 0' }}
      data-cy="vial-search-result-container"
    >
      <BoxIcon />
      <Text marginTop="1.5rem">
        {t('vialSearchResults.noneFound', { ns: ['dashboardScreen'] })}
      </Text>
    </CenteredWrapper>
  )
}

export const VialSearchUnfulfillable: any = () => {
  const { t } = useTranslation(['dashboardScreen'])
  return (
    <CenteredWrapper
      height="100%"
      padding={{ base: '2rem 0 0 0', md: '6.2rem 0 0 0' }}
      data-cy="vial-search-result-container"
    >
      <BoxIcon />
      <Text marginTop="1.5rem">
        {t('vialSearchResults.unfulfillable', { ns: ['dashboardScreen'] })}
      </Text>
    </CenteredWrapper>
  )
}

export const AmTreatmentUnfulfillable: any = () => {
  const { t } = useTranslation(['dashboardScreen'])
  return (
    <CenteredWrapper
      height="100%"
      padding={{ base: '2rem 0 0 0', md: '6.2rem 0 0 0' }}
    >
      <BoxIcon />
      <Text marginTop="1.5rem">
        {t('vialSearchResults.amTreatmentUnavailable', {
          ns: ['dashboardScreen'],
        })}
      </Text>
    </CenteredWrapper>
  )
}
