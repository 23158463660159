import React from 'react'
import { IconProps } from '../../types'

interface CustomProps {
  rotate?: number
}
type CogProps = IconProps & CustomProps

const CogIcon: React.FC<CogProps> = ({
  fill = '#fff',
  width = 20,
  height = 21,
  rotate = 0,
  title,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      id="Outlined"
      viewBox="0 0 32 32"
      role="img"
      aria-labelledby="title"
      transform={`rotate(${rotate})`}
    >
      <title id="title">{title}</title>
      <g>
        <path
          fill={fill}
          d="M16,12a4,4,0,1,0,4,4A4,4,0,0,0,16,12Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,16,18Z"
        />
        <path
          fill={fill}
          d="M27.57,18.3l-1.38-.79a9.56,9.56,0,0,0,0-3l1.38-.79a2.86,2.86,0,0,0,1.05-3.91l-.93-1.61a2.88,2.88,0,0,0-3.91-1l-1.38.8a10.07,10.07,0,0,0-2.61-1.52V4.83A2.86,2.86,0,0,0,16.93,2H15.07a2.86,2.86,0,0,0-2.86,2.86V6.41A10.07,10.07,0,0,0,9.6,7.93l-1.38-.8a2.86,2.86,0,0,0-3.91,1L3.38,9.79a2.88,2.88,0,0,0,1,3.91l1.38.79a9.56,9.56,0,0,0,0,3l-1.38.79a2.86,2.86,0,0,0-1,3.91l.93,1.61a2.88,2.88,0,0,0,3.91,1.05l1.38-.8a10.07,10.07,0,0,0,2.61,1.52v1.58A2.86,2.86,0,0,0,15.07,30h1.86a2.86,2.86,0,0,0,2.86-2.86V25.59a10.07,10.07,0,0,0,2.61-1.52l1.38.8a2.86,2.86,0,0,0,3.91-1.05l.93-1.61A2.88,2.88,0,0,0,27.57,18.3Zm-.69,2.91L26,22.82a.86.86,0,0,1-1.17.32l-2.63-1.52-.54.49a8.3,8.3,0,0,1-3.12,1.82l-.7.22v3a.86.86,0,0,1-.86.86H15.07a.86.86,0,0,1-.86-.86v-3l-.7-.22a8.3,8.3,0,0,1-3.12-1.82l-.54-.49L7.22,23.14a.87.87,0,0,1-.65.08.84.84,0,0,1-.52-.4l-.93-1.61A.86.86,0,0,1,5.43,20l2.62-1.51-.16-.72a8.56,8.56,0,0,1-.2-1.8,8,8,0,0,1,.21-1.8l.15-.72L5.43,12a.86.86,0,0,1-.31-1.18l.93-1.61a.86.86,0,0,1,1.17-.32l2.63,1.52.54-.49a8.3,8.3,0,0,1,3.12-1.82l.7-.22v-3A.86.86,0,0,1,15.07,4h1.86a.86.86,0,0,1,.86.86v3l.7.22a8.3,8.3,0,0,1,3.12,1.82l.54.49,2.63-1.52a.87.87,0,0,1,.65-.08.84.84,0,0,1,.52.4l.93,1.61A.86.86,0,0,1,26.57,12L24,13.48l.16.72a8.56,8.56,0,0,1,.2,1.8,8,8,0,0,1-.21,1.8l-.15.72L26.57,20A.86.86,0,0,1,26.88,21.21Z"
        />
      </g>
    </svg>
  )
}

export { CogIcon }
