import React, { useEffect, useState, ChangeEvent } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useMedia } from 'use-media'
import { InputGroup, InputRightElement, CloseButton } from '@chakra-ui/core'
import { themeUtils } from '../../themeUtils'
import { useDebouncedCallback } from 'use-debounce'
import { ORDER_FILTERS_QUERY } from '../../schema/queries'
import {
  GetOrderFilters,
  GetOrderFilters_orderFilters,
} from '../../schema/queries/__generated__/GetOrderFilters'
import { SET_ORDER_FILTERS_MUTATION } from '../../schema/mutations/FilterMutations'
import {
  SetOrderFilters,
  SetOrderFiltersVariables,
} from '../../schema/mutations/__generated__/SetOrderFilters'
import { Input } from '../Forms'
import { breakpoints, SMALL } from '../../themeUtils'
import { useTranslation } from 'react-i18next'

const flexStyles = {
  base: 'initial',
  md: 1,
}

export const OrderSearch: React.FC = () => {
  const { t } = useTranslation(['orderScreen'])
  const { data } = useQuery<GetOrderFilters>(ORDER_FILTERS_QUERY)

  const [search, setSearch] = useState('')

  useEffect(() => {
    if (data && data.orderFilters) {
      setSearch(data.orderFilters.search)
    }
  }, [data])

  const [setFiltersMutation] = useMutation<
    SetOrderFilters,
    SetOrderFiltersVariables
  >(SET_ORDER_FILTERS_MUTATION)

  const setFilters = (newFilters: GetOrderFilters_orderFilters): void => {
    setFiltersMutation({
      variables: {
        input: newFilters,
      },
    })
  }

  const [debouncedSetFilters] = useDebouncedCallback(
    (newFilters: GetOrderFilters_orderFilters) => setFilters(newFilters),
    500
  )

  useEffect(() => {
    if (data) debouncedSetFilters({ ...data.orderFilters, search })
  }, [search, data, debouncedSetFilters])

  const isSmallScreen = useMedia({ minWidth: breakpoints[SMALL] })

  const placeholderText = isSmallScreen
    ? t('orderSearch.placeholder', { ns: ['orderScreen'] })
    : t('orderSearch.placeholderSmallScreen', { ns: ['orderScreen'] })
  const onChange = (event: ChangeEvent<HTMLInputElement>): void =>
    setSearch(event.target.value)

  const clearSearch = (): void => setSearch('')

  return (
    <InputGroup width="100%" flex={flexStyles} gridArea="search">
      <Input
        type="search"
        placeholder={placeholderText}
        onChange={onChange}
        color={themeUtils.colors.midnightBlue}
        backgroundColor={themeUtils.colors.borderGrey}
        _placeholder={{
          color: themeUtils.colors.midnightBlue,
        }}
        value={search}
        margin={0}
        flex={flexStyles}
        width="100%"
      />
      {/*<InputRightElement height="6rem" width="4.5rem">*/}
      {/*  {search.length > 0 && <CloseButton onClick={clearSearch} />}*/}
      {/*</InputRightElement>*/}
    </InputGroup>
  )
}
