import React from 'react'
import { OrderFormData } from '../OrderCreate/OrderFormDataTypes'
import { NestDataObject } from 'react-hook-form'
import {
  OrderFormClearErrorType,
  OrderFormErrorType,
  ShippingAddress,
} from '..'
import { themeUtils } from '../../../themeUtils'
import styled from '@emotion/styled'
import { Box, Flex } from '@chakra-ui/core'
import { FormHeader } from '../../Shared'
import { useTranslation } from 'react-i18next'

const { white } = themeUtils.colors

const Container = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: fit-content;
`

interface Props {
  formData: OrderFormData
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
  onChangeCheckBoxValue: (val: boolean) => void
  createAccessoryOnlyOrder: boolean
  changeTreatmentDate: (date: Date | null) => void
  minOrderTreatmentDate(isMnxEligible: boolean): Date
  isMnxEligibleOrder: () => boolean
  isMnxTreatmentDate: () => boolean
  errors: NestDataObject<OrderFormData>
  setError: OrderFormErrorType
  clearError: OrderFormClearErrorType
}

export const DeliveryDetails: React.FC<Props> = ({ formData, setFormData }) => {
  const { t } = useTranslation(['orderCreate'])
  return (
    <Container>
      <FormHeader>{t('deliveryDetails.header')}</FormHeader>
      <Box width={'100%'}>
        <ShippingAddress formData={formData} setFormData={setFormData} />
      </Box>
    </Container>
  )
}
