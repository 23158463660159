import React from 'react'
import { IconProps } from '../../types'

export const MinusIcon: React.FC<IconProps> = ({
  fill = '#fff',
  width = 12,
  height = 2,
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 2"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0V2H0V0H12Z"
        fill={fill}
      />
    </svg>
  )
}
