import { Box, BoxProps, Flex, FlexProps } from '@chakra-ui/core'
import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import { LARGE, MEDIUM, mqNames, themeUtils } from '../../../themeUtils'
import { useOrderForm, useVialSearch } from '../../../hooks'
import { Button, FormHeader, Option } from '../../Shared'
import { Checkbox, DatePickerWidget } from '../../Forms'
import { NoOfVialsWidget } from '../../Shared/VialSearch/NoOfVialsWidget'
import {
  OrderFormData,
} from '../OrderCreate/OrderFormDataTypes'
import { isDateDisabled, mnxRushWindow } from '../../lib'
import { dateOnly } from '../../../utils'
import { NestDataObject } from 'react-hook-form'
import { OrderFormClearErrorType, OrderFormErrorType } from './OrderNewWidget'
import { OrderFormStepType } from '../../../context/OrderFormContext'
import { PencilIcon } from '../../Icons'
import { lighten } from 'polished'
import {
  PortalVialSearch,
  PortalVialSearchVariables,
} from '../../../schema/queries/__generated__/PortalVialSearch'
import { useLazyQuery } from '@apollo/react-hooks'
import { TreatmentTime } from '..'
import { PORTAL_VIAL_SEARCH } from '../../../schema/queries'
import { OrderData } from '../../../types'
import { navigate, useLocation } from '@reach/router'
import { ORDERS_CREATE_PATH } from '../../../routes'
import { OrderFormTypeEnum } from '../../../__generated__/globalTypes'
import { useTranslation } from 'react-i18next'

const { white, green, midnightBlue } = themeUtils.colors

const Container = styled(Flex)<FlexProps>`
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  height: fit-content;

  border-radius: 10px;
  background: ${white};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
`

interface CustomBoxProps extends BoxProps {
  isReadOnly?: boolean
}

const BoxContainer1 = styled(Box)<CustomBoxProps>`
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  justify-content: ${props => (props.isReadOnly ? 'center' : 'start')};
  border-bottom: ${props =>
    props.isReadOnly ? '1px solid rgba(0, 57, 104, 0.20)' : 'unset'};

  ${mqNames(MEDIUM)} {
    width: ${props => (props.isReadOnly ? 'unset' : '450px')};
    justify-content: space-between;
    border-bottom: unset;
  }
`

const BoxContainer2 = styled(Box)<BoxProps>`
  align-items: center;
  gap: 10px;
  align-self: stretch;

  ${mqNames(LARGE)} {
    width: 450px;
  }
`

const Heading = styled(FormHeader)`
  display: none;
  ${mqNames(MEDIUM)} {
    display: block;
  }
`

const EditButtonStyle = styled(Button)`
  color: ${midnightBlue};
  padding-bottom: 0.5rem;
  border: none;

  ${mqNames(MEDIUM)} {
    padding-bottom: unset;
  }
  &:hover {
    color: ${lighten(0.2, midnightBlue)};
  }
`

interface CustomizedState {
  orderData: OrderData
}

interface Props {
  formData: OrderFormData
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
  onChangeCheckBoxValue: (val: boolean) => void
  createAccessoryOnlyOrder: boolean
  changeTreatmentDate: (date: Date | null) => void
  minOrderTreatmentDate(isMnxEligible: boolean): Date
  isMnxEligibleOrder: () => boolean
  isMnxTreatmentDate: () => boolean
  errors: NestDataObject<OrderFormData>
  setError: OrderFormErrorType
  clearError: OrderFormClearErrorType
  isReadOnly?: boolean
}

export const TreatmentDetailsWidget: React.FC<Props> = ({
  formData,
  setFormData,
  onChangeCheckBoxValue,
  createAccessoryOnlyOrder,
  changeTreatmentDate,
  minOrderTreatmentDate,
  isMnxEligibleOrder,
  isMnxTreatmentDate,
  errors,
  setError,
  clearError,
  isReadOnly,
}) => {
  const { t } = useTranslation(['orderCreate'])
  const location = useLocation()
  const locationState = location.state as CustomizedState
  const { formHeading, setFormStep } = useOrderForm()

  const {
    selectedCalibrationDate,
    reverifySelectedActivityVials,
  } = useVialSearch()

  const {
    treatmentDate,
    treatmentTime,
    showShippingError,
    noOfVials,
    desiredActivityAtTreatmentSet,
    orderDate,
    orderTime,
    vialSearchResult,
  } = formData

  const [portalVialSearchQuery, { data, loading }] = useLazyQuery<
    PortalVialSearch,
    PortalVialSearchVariables
  >(PORTAL_VIAL_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setFormStep(OrderFormStepType.STEP_2)
      window.scrollTo(0, 0)
    },
  })

  useEffect(() => {
    if (!!data?.portalVialSearch.vials.length) {
      setFormData((prevFormData: OrderFormData) => {
        return {
          ...prevFormData,
          vialSearchResult: data?.portalVialSearch.vials[0],
        }
      })
      // Reverify the selected dose from the reseult
      reverifySelectedActivityVials(
        desiredActivityAtTreatmentSet,
        vialSearchResult
      )
    }
  }, [data])

  useEffect(() => {
    if (
      locationState.orderData &&
      locationState.orderData.formType ===
      OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED
    ) {
      handleSubmit()
      //clear route state
      navigate(ORDERS_CREATE_PATH, { state: undefined })
    }
  }, [])

  const changeTreatmentTime = (option: Option | null): void => {
    setFormData((prevFormData: OrderFormData) => {
      return { ...prevFormData, treatmentTime: option?.value }
    })
  }

  const handleSubmit = (): void => {
    //Verify if there are errors
    const hasErrors = Object.values(errors).some(obj => obj)

    if (
      !hasErrors &&
      desiredActivityAtTreatmentSet &&
      treatmentDate &&
      orderDate &&
      orderTime &&
      portalVialSearchQuery
    ) {
      const activityArray: number[] = desiredActivityAtTreatmentSet.map(
        item => item.activity || 0
      )
      portalVialSearchQuery({
        variables: {
          desiredActivitiesAtTreatment: activityArray,
          treatmentDate: dateOnly(treatmentDate).toString(),
          treatmentTime: treatmentTime,
          orderDate: dateOnly(orderDate).toString(),
          orderTime: orderTime,
          mnxRushOrder: isMnxTreatmentDate(),
        },
      })
    }
  }

  const handleEditClick = (): void => {
    setFormStep(OrderFormStepType.STEP_1)
  }

  return (
    <Container>
      <BoxContainer1 isReadOnly={isReadOnly}>
        <Heading>{formHeading}</Heading>
        {!isReadOnly && (
          <Checkbox
            id="accessory-only-input-checkbox"
            label={t('activityFocused.accessoryOnlyLabel')}
            defaultChecked={createAccessoryOnlyOrder}
            onChange={e => onChangeCheckBoxValue(e.target.checked)}
          />
        )}
        {!!isReadOnly && (
          <EditButtonStyle
            _focus={undefined}
            variant="link"
            onClick={handleEditClick}
            id="edit-treatment-details-btn"
            data-cy="edit-treatment-details-btn"
          >
            <PencilIcon title={t('activityFocused.editIconTitle')} />
          </EditButtonStyle>
        )}
      </BoxContainer1>
      <BoxContainer2>
        <DatePickerWidget
          name="treatmentDate"
          label={t('activityFocused.treatmentDate.label')}
          id="treatment-date-input"
          placeholder={t('activityFocused.treatmentDate.placeholder')}
          date={!!treatmentDate ? treatmentDate : undefined}
          onDateChange={changeTreatmentDate}
          minimumDate={minOrderTreatmentDate(isMnxEligibleOrder())}
          modifiers={{
            disabled: (date: Date) => {
              return isDateDisabled(date, isMnxEligibleOrder())
            },
            highlight: (date: Date) => {
              // do not highlight selected date
              if (treatmentDate && dateOnly(date) === dateOnly(treatmentDate)) {
                return false
              }
              return mnxRushWindow(isMnxEligibleOrder())
                .map(d => dateOnly(d))
                .includes(dateOnly(date))
            },
          }}
          isDisabled={isReadOnly}
        />
      </BoxContainer2>
      <BoxContainer2>
        <TreatmentTime
          treatmentDate={treatmentDate}
          treatmentTime={treatmentTime}
          changeTreatmentTime={changeTreatmentTime}
          isMnxEligibleOrder={isMnxEligibleOrder}
          showShippingError={showShippingError}
          isReadOnly={isReadOnly}
        />
      </BoxContainer2>
      <BoxContainer2>
        <NoOfVialsWidget
          noOfVials={noOfVials ?? 0}
          desiredActivityAtTreatmentSet={desiredActivityAtTreatmentSet}
          setFormData={setFormData}
          isDisabled={!treatmentDate || !treatmentTime}
          isReadOnly={isReadOnly}
          errors={errors}
          setError={setError}
          clearError={clearError}
        />
      </BoxContainer2>
      {!isReadOnly && (
        <BoxContainer2>
          <Button
            id="show-treatment-vials-submit-btn"
            data-cy="show-treatment-vials-submit-btn"
            backgroundColor={green}
            isFullWidth
            onClick={handleSubmit}
            isLoading={loading}
            isDisabled={
              !desiredActivityAtTreatmentSet?.every(s => s.activity) ||
              noOfVials === 0
            }
          >
            {t('activityFocused.showOptionsBtn')}
          </Button>
        </BoxContainer2>
      )}
    </Container>
  )
}
