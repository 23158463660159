// /** @jsx jsx */
import React, { useState, ReactNode, Fragment } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { RouteComponentProps, Link } from '@reach/router'
import { useMutation } from '@apollo/react-hooks'
import { parse } from 'query-string'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { css, jsx } from '@emotion/core'
import {
  NewPortalPasswordVariables,
  NewPortalPassword,
} from '../../schema/mutations/__generated__/NewPortalPassword'
import { NEW_PASSWORD_MUTATION } from '../../schema/mutations'
import { LoginContainer, Heading } from '../../components/Shared'
import { Alert } from '../../screens/Account'
import { FormControl, FormHelperText, Flex } from '@chakra-ui/core'
import { ErrorMessage, Input, SubmitButton } from '../../components/Forms'
import { DoneIcon } from '../../components/Icons'
import { LOGIN_PATH } from '../../routes'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../i18n'

const newPasswordSchema = () => yup.object().shape({
  password: yup
    .string()
    .required(i18n.t('passwordInput.required', { ns: ['newPasswordScreen'] }))
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)./, {
      message:
        i18n.t('passwordInput.matchesComplexity', { ns: ['newPasswordScreen'] }),
      excludeEmptyString: true,
    }),
  passwordConfirmation: yup
    .string()
    .required(i18n.t('passwordConfirmationInput.required', { ns: ['newPasswordScreen'] }))
    .oneOf([yup.ref('password'), null], i18n.t('passwordConfirmationInput.matching', { ns: ['newPasswordScreen'] }))
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)./, {
      message:
        i18n.t('passwordConfirmationInput.matchesComplexity', { ns: ['newPasswordScreen'] }),
      excludeEmptyString: true,
    }),
})

type FormData = {
  password: string
  passwordConfirmation: string
  submissionError: string
}
const successMsg = (
  <Fragment>
    <Trans
      i18nKey="successFragment.successText"
      ns="newPasswordScreen"
      components={{ a: <Link to={LOGIN_PATH} /> }}
    />
  </Fragment>
)

export const NewPasswordScreen: React.FC<RouteComponentProps> = props => {
  const { t } = useTranslation(['newPasswordScreen'])
  const [success, setSuccess] = useState(false)
  const {
    register,
    handleSubmit,
    setError,
    clearError,
    errors,
    reset,
    formState: { isSubmitting, isValid },
  } = useForm<FormData>({
    mode: 'onChange',
    validationSchema: newPasswordSchema(),
  })
  const [newPortalPassword] = useMutation<
    NewPortalPassword,
    NewPortalPasswordVariables
  >(NEW_PASSWORD_MUTATION)

  let token = ''

  if (props.location && props.location.search) {
    token = parse(props.location.search).reset_password_token as string
  }

  const setSubmissionError = (errorMsg: string): void =>
    setError('submissionError', 'submission', errorMsg)

  const showErrorMessage = (): ReactNode => {
    if (errors.submissionError) {
      return errors.submissionError.message
    }

    return (
      <Fragment>
        {errors.password && errors.password.message} {errors.password && <br />}
        {errors.passwordConfirmation && errors.passwordConfirmation.message}
      </Fragment>
    )
  }

  const onSubmit = handleSubmit(
    async ({ password, passwordConfirmation }): Promise<void> => {
      try {
        clearError('submissionError')
        const { data } = await newPortalPassword({
          variables: { input: { token, password, passwordConfirmation } },
        })
        if (!data) return
        reset()
        setSuccess(true)
      } catch (error) {
        setSubmissionError(t('submission.error', { ns: ['newPasswordScreen'] }))
      }
    }
  )
  const hasError =
    !!errors.password ||
    !!errors.passwordConfirmation ||
    !!errors.submissionError

  return (
    <Flex direction="column">
      <Heading>{t('header.main', { ns: ['newPasswordScreen'] })}</Heading>
      {true && <Alert status="success">{successMsg}</Alert>}
      <LoginContainer mt={{ base: '2rem', lg: '3rem' }}>
        <FormControl
          isInvalid={hasError}
          display="flex"
          justifyContent={{ base: 'flex-start', lg: 'center' }}
        >
          <ErrorMessage mt={{ lg: '-3.5rem' }}>
            {errors && showErrorMessage()}
          </ErrorMessage>
        </FormControl>
        <form
          id="ConfirmAccount"
          autoComplete="off"
          acceptCharset="UTF-8"
          noValidate
          onSubmit={onSubmit}
        >
          <FormControl>
            <Input
              autoFocus
              variant="outline"
              type="password"
              placeholder={t('passwordInput.placeholder', { ns: ['newPasswordScreen'] })}
              id="password"
              data-cy="password"
              name="password"
              isDisabled={success}
              ref={register({
                required: true,
              })}
            />
            <FormHelperText
              fontSize="1.1rem"
              px="1rem"
              mt="-0.5rem"
              id="password-helper-text"
            >
              {t('passwordInput.helperText', { ns: ['newPasswordScreen'] })}
            </FormHelperText>
          </FormControl>
          <FormControl mt="1rem">
            <Input
              variant="outline"
              type="password"
              placeholder={t('passwordConfirmationInput.placeholder', { ns: ['newPasswordScreen'] })}
              id="passwordConfirmation"
              data-cy="passwordConfirmation"
              name="passwordConfirmation"
              isDisabled={success}
              ref={register({
                required: true,
              })}
            />
          </FormControl>
          <Link
            css={css`
              font-size: 1.4rem;
              padding-right: 2rem;
              float: right;
            `}
            to={LOGIN_PATH}
          >
            {t('loginLink.linkText', { ns: ['newPasswordScreen'] })}
          </Link>
          <SubmitButton
            isLoading={isSubmitting}
            isDisabled={success || !isValid}
            isFullWidth
            loadingText={t('submission.loadingText', { ns: ['newPasswordScreen'] })}
            rightIcon={DoneIcon}
            justifyContent={isSubmitting ? 'center' : 'space-between'}
          >
            {t('submission.buttonText', { ns: ['newPasswordScreen'] })}
          </SubmitButton>
        </form>
      </LoginContainer>
    </Flex>
  )
}
