import { Flex, useDisclosure } from '@chakra-ui/core'
import React from 'react'
import { Button } from '../../Shared'
import { themeUtils } from '../../../themeUtils'
import { PlusSquareIcon } from '../../Icons'
import { TeamMemberForm } from './TeamMemberForm'
import { TeamMembersTable } from './TeamMembersTable'
import { InviteTeamMemberButton } from './InviteTeamMember'
import { isROWCustomer } from '../../lib'
import { useUser } from '../../../context/user-context'
import { useTranslation } from 'react-i18next'

export const TeamMemberSection: React.FC = () => {
  const { t } = useTranslation(['teamMemberSection'])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { green } = themeUtils.colors
  const user = useUser()

  return (
    <>
      <Flex justifyContent="end" paddingY="2rem">
        <Flex alignItems="center">
          {isROWCustomer(user) ? (
            <InviteTeamMemberButton />
          ) : (
            <Button
              backgroundColor={green}
              boxShadow={{
                base: 'none',
                sm: '0 10px 15px rgba(69, 218, 122, 0.2);',
              }}
              leftIcon={PlusSquareIcon}
              iconSpacing={'1rem'}
              onClick={onOpen}
            >
              {t('add.btnLabel', { ns: ['teamMemberSection'] })}
            </Button>
          )}
        </Flex>
      </Flex>
      <TeamMemberForm isOpen={isOpen} onClose={onClose} />

      <TeamMembersTable />
    </>
  )
}
