import React from 'react'
import { Flex, FlexProps } from '@chakra-ui/core'
import { themeUtils } from '../../../themeUtils'

export const DeliveryAccessoryContainer: React.FC<FlexProps> = ({
  children,
  maxWidth = { base: '100%', md: '34rem', lg: '57rem' },
  ...restProps
}) => (
  <Flex
    backgroundColor={themeUtils.colors.shuttleGrey}
    maxWidth={maxWidth}
    height="4rem"
    marginBottom="1rem"
    borderRadius="1rem"
    position="relative"
    alignItems="center"
    paddingX="2rem"
    paddingY="1.5rem"
    fontSize="1.4rem"
    border-radius="8px"
    color="rgba(0,57,104, 1)"
    {...restProps}
  >
    {children}
  </Flex>
)


