import React, { createContext, useState, Dispatch, SetStateAction } from 'react'
import { VialSearchResultOptionType } from '../components/Orders/OrderCreate/OrderFormDataTypes'

export type VialType = Map<string, number>
export type VialMapType = Map<number, VialType>
export type ActivityVialType = Map<number, VialSearchResultOptionType>
export type ActivityVialMapType = Map<string, ActivityVialType>

interface VialsSearchContextState {
  selectedCalibrationDate: string
  selectedVials: VialMapType
  selectedActivityWiseVials: ActivityVialMapType
}

export interface VialsSearchContextType {
  state: VialsSearchContextState
  setState: Dispatch<SetStateAction<VialsSearchContextState>>
}

export const VialSearchContext = createContext<VialsSearchContextType>({
  state: {
    selectedVials: new Map<number, VialType>(),
    selectedActivityWiseVials: new Map<string, ActivityVialType>(),
    selectedCalibrationDate: '',
  },
  setState: () => ({}),
})

export const VialSearchProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<VialsSearchContextState>({
    selectedVials: new Map<number, VialType>(),
    selectedActivityWiseVials: new Map<string, ActivityVialType>(),
    selectedCalibrationDate: '',
  })

  return (
    <VialSearchContext.Provider value={{ state, setState }}>
      {children}
    </VialSearchContext.Provider>
  )
}
