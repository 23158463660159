import React from 'react'
import { Badge } from '@chakra-ui/core'
import { STATUSES } from '../../constants'
import { themeUtils } from '../../themeUtils'
import { statusSubmittedText } from '../../utils/commonUtils'
import i18n from '../../i18n'

const { black, green, red, turquoise, yellow } = themeUtils.colors
// Statuses
// Submitted = Draft, New, Released
// Confirmed = Accepted, Build Pending, Built
// Shipped = Shipped
// Cancel Requested = Cancellation Request
// Cancelled = Cancelled

const badgeColour = (status: string, reservedAt: Date): string => {
  switch (status) {
    case STATUSES.DRAFT: return reservedAt ? green : yellow
    case STATUSES.NEW: return reservedAt ? green : yellow
    case STATUSES.RELEASED: return reservedAt ? green : yellow
    case STATUSES.RESERVED: return green
    case STATUSES.ACCEPTED: return green
    case STATUSES.REJECTED: return black
    case STATUSES.BUILD_PENDING: return green
    case STATUSES.BUILT: return green
    case STATUSES.CANCELLING: return red
    case STATUSES.CANCELLED: return red
    case STATUSES.SHIPPED: return turquoise
    case STATUSES.VOID: return red
    default: return black
  }
}

const statusText = (status: string, customerPortalOrder: boolean, reservedAt: Date): string => {
  switch (status) {

    case STATUSES.DRAFT: return reservedAt ? i18n.t('order.confirmed', { ns: ['status'] }) : statusSubmittedText(customerPortalOrder)
    case STATUSES.NEW: return reservedAt ? i18n.t('order.confirmed', { ns: ['status'] }) : statusSubmittedText(customerPortalOrder)
    case STATUSES.RELEASED: return reservedAt ? i18n.t('order.confirmed', { ns: ['status'] }) : statusSubmittedText(customerPortalOrder)
    case STATUSES.RESERVED: return i18n.t('order.confirmed', { ns: ['status'] })
    case STATUSES.ACCEPTED: return i18n.t('order.confirmed', { ns: ['status'] })
    case STATUSES.BUILD_PENDING: return i18n.t('order.confirmed', { ns: ['status'] })
    case STATUSES.BUILT: return i18n.t('order.confirmed', { ns: ['status'] })
    case STATUSES.SHIPPED: return i18n.t('order.shipped', { ns: ['status'] })
    case STATUSES.CANCELLING: return i18n.t('order.cancelling', { ns: ['status'] })
    case STATUSES.CANCELLED: return i18n.t('order.cancelled', { ns: ['status'] })
    case STATUSES.VOID: return i18n.t('order.void', { ns: ['status'] })
    case STATUSES.REJECTED: return i18n.t('order.rejected', { ns: ['status'] })
    default: return status
  }
}

interface Props {
  status: string
  customerPortalOrder: boolean
  reservedAt: Date
}

export const StatusTag: React.FC<Props> = React.memo(({ status, customerPortalOrder, reservedAt}) => (
  <Badge
    fontSize="1.4rem"
    fontWeight="normal"
    width="9rem"
    textTransform="inherit"
    textAlign="center"
    color={themeUtils.colors.white}
    backgroundColor={badgeColour(status, reservedAt)}
    borderRadius="0.5rem"
  >
    { statusText(status, customerPortalOrder, reservedAt) }
  </Badge>
))

StatusTag.displayName = 'StatusTag'
