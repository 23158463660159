import React from 'react'
import { IconProps } from '../../types'
import path from "path";
import { rgb } from "polished";

const RequestRemovalIcon: React.FC<IconProps> = ({
  title = 'Edit',
}) => {
  return (
    <svg
      data-name={title}
      fill={rgb(245, 135, 116)}
      height="32"
      id={title}
      viewBox="0 0 200 200"
      width="32"
      xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z"/>
    </svg>
  )
}

export { RequestRemovalIcon }
