import { theme, ITheme } from '@chakra-ui/core'
import { SMALL, MEDIUM, LARGE, X_LARGE, themeUtils } from './themeUtils'
import { Breakpoints } from './types'

const bp = themeUtils.breakpoints
const breakpoints: Breakpoints = [bp[SMALL], bp[MEDIUM], bp[LARGE], bp[X_LARGE]]
// aliases
// @ts-ignore
breakpoints.sm = breakpoints[0]
// @ts-ignore
breakpoints.md = breakpoints[1]
// @ts-ignore
breakpoints.lg = breakpoints[2]
// @ts-ignore
breakpoints.xl = breakpoints[3]

const CustomTheme: ITheme = {
  ...theme,
  breakpoints,
  fonts: {
    ...theme.fonts,
    heading: themeUtils.fonts.headings,
    body: themeUtils.fonts.body,
  },
}

export default CustomTheme
