import React, { Dispatch, SetStateAction, useEffect } from 'react'
import {
  FormControl,
  FlexProps,
  Box,
  CloseButton,
  Text,
  Flex,
} from '@chakra-ui/core'
import { darken } from 'polished'
import { AnimateGroup } from 'react-animation'
import styled from '@emotion/styled'
import {
  ScreenReaderOnlyText,
  Button,
  CustomSelect,
  Option,
} from '../../Shared'
import { GetMyPortalUser_me_shippingAddresses_defaultAdminSet } from '../../../context/__generated__/GetMyPortalUser'
import { AdminSetType, OrderFormData } from './OrderFormDataTypes'
import { Select } from '../../Forms'
import { themeUtils } from '../../../themeUtils'
import { ThemeVersion } from '../../../types'
import { useOrderForm } from '../../../hooks'
import { OrderFormTypeEnum } from '../../../__generated__/globalTypes'
import { useTranslation } from 'react-i18next'

const ContainerV2 = styled.div`
  width: 100%;
`

const QuantityContainer = styled(Flex)`
  gap: 10px;
  margin-bottom: 1rem;
  .accessory-quantity-select {
    width: 100%;
  }
`

interface Props extends FlexProps {
  adminSets: Array<AdminSetType>
  defaultAdminSet:
    | GetMyPortalUser_me_shippingAddresses_defaultAdminSet
    | undefined
  setAccessoryData: Dispatch<SetStateAction<OrderFormData>>
  initializeWithAccessory: boolean | undefined
  version?: ThemeVersion
}
const QUANTITY_OPTIONS = [5, 10, 15, 20]

const { slateGrey } = themeUtils.colors
const StyledAnimateGroup = styled(AnimateGroup)`
  width: 100%;
`

export const AccessoryFields: React.FC<Props> = ({
  adminSets,
  defaultAdminSet,
  setAccessoryData,
  initializeWithAccessory,
  version,
}) => {
  const { t } = useTranslation(['orderCreate'])
  const { formType } = useOrderForm()

  useEffect(() => {
    if (initializeWithAccessory === true && adminSets.length === 0) {
      addAccessory()
    }
  })

  const newAccessory = {
    productId: defaultAdminSet?.id,
    lineItemNumber: Number(Date.now()),
    quantity: 0,
    confirmed: false,
    hasError: false,
  }

  const addAccessory = (): void => {
    // @ts-ignore
    setAccessoryData((prevData: OrderFormData) => {
      const adminSets = [...prevData.adminSets, newAccessory]
      return { ...prevData, adminSets }
    })
  }

  const removeAccessory = (indexToRemove: number): void => {
    const updatedAdminSets = adminSets.reduce((array, accessory, idx) => {
      if (idx !== indexToRemove) array.push(accessory)
      return array
    }, Array<AdminSetType>())

    setAccessoryData((prevData: OrderFormData) => {
      return { ...prevData, adminSets: updatedAdminSets }
    })
  }

  const updateAccessory = (
    event: {
      currentTarget: { value: string; name: string }
    },
    idx: number
  ) => {
    const { value, name } = event.currentTarget
    const newAdminSets = [...adminSets]
    // @ts-ignore
    newAdminSets[idx][name] = value
    newAdminSets[idx]['hasError'] = false
    if (initializeWithAccessory) {
      newAdminSets[idx]['confirmed'] = !!value
    }

    setAccessoryData((prevData: OrderFormData) => {
      return { ...prevData, adminSets: newAdminSets }
    })
  }

  const onChange = (option: Option, idx: number): void => {
    const { value } = option
    const name = 'quantity'
    const newAdminSets = [...adminSets]
    // @ts-ignore
    newAdminSets[idx][name] = value
    newAdminSets[idx]['hasError'] = false
    if (initializeWithAccessory) {
      newAdminSets[idx]['confirmed'] = !!value
    }

    setAccessoryData((prevData: OrderFormData) => {
      return { ...prevData, adminSets: newAdminSets }
    })
  }

  const renderAdminSets = () => (
    <StyledAnimateGroup
      animationIn="fadeIn"
      animationOut="fadeOut"
      duration={500}
    >
      {adminSets.length ? (
        adminSets.map((adminSet, idx) => {
          return (
            <Box
              key={adminSet.lineItemNumber}
              display="flex"
              justifyContent="space-between"
              marginBottom="1rem"
            >
              <FormControl flex="1" marginRight="1rem">
                <ScreenReaderOnlyText id="quantity">
                  {t('orderCreateWidget.tubingSets.placeholder', {
                    ns: ['orderCreate'],
                  })}
                </ScreenReaderOnlyText>
                <Select
                  aria-labelledby="quantity"
                  name="quantity"
                  data-cy="quantity-select"
                  isEmpty={!adminSets}
                  isDisabled={!adminSets}
                  isInvalid={adminSet.hasError}
                  value={adminSet.quantity}
                  onChange={(event: {
                    currentTarget: { value: string; name: string }
                  }) => updateAccessory(event, idx)}
                  marginBottom="0"
                >
                  <option value="0" disabled>
                    {t('orderCreateWidget.tubingSets.placeholder', {
                      ns: ['orderCreate'],
                    })}
                  </option>
                  {QUANTITY_OPTIONS.map(quantity => (
                    <option key={quantity} value={quantity}>
                      {quantity}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <CloseButton
                alignSelf="center"
                onClick={() => removeAccessory(idx)}
                data-cy="close-btn"
              />
            </Box>
          )
        })
      ) : (
        <Text marginBottom="2rem">
          {t('orderCreateWidget.tubingSets.hint', {
            ns: ['orderCreate'],
          })}
        </Text>
      )}
    </StyledAnimateGroup>
  )

  const renderAdminSetsV2 = () => {
    const Options: Option[] = []
    QUANTITY_OPTIONS.map(quantity => {
      return Options.push({
        value: quantity,
        label: quantity.toString(),
      })
    })

    return (
      <ContainerV2>
        {adminSets.length ? (
          adminSets.map((adminSet, idx) => {
            return (
              <QuantityContainer>
                <CustomSelect
                  placeholder={t('orderCreateWidget.tubingSets.placeholder', {
                    ns: ['orderCreate'],
                  })}
                  value={Options.find(o => o.value === adminSet.quantity)}
                  onChange={option => onChange(option, idx)}
                  options={Options}
                  version={ThemeVersion.V2}
                  classNamePrefix="quantity-select"
                  disabled={!adminSets}
                  groupClassName="accessory-quantity-select"
                />
                <CloseButton
                  alignSelf="center"
                  onClick={() => removeAccessory(idx)}
                  data-cy="close-btn"
                />
              </QuantityContainer>
            )
          })
        ) : (
          <Text marginBottom="2rem" textAlign={'center'}>
            {t('orderCreateWidget.tubingSets.hint', {
              ns: ['orderCreate'],
            })}
          </Text>
        )}
      </ContainerV2>
    )
  }

  if (version === ThemeVersion.V2) {
    const displayAddBtn = !initializeWithAccessory || adminSets.some(a => a.quantity > 0)
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        textAlign={'start'}
        alignItems={
          formType === OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED
            ? undefined
            : 'center'
        }
        marginBottom={defaultAdminSet ? 0 : '3rem'}
      >
        {renderAdminSetsV2()}
        {displayAddBtn && (
          <Button
            onClick={addAccessory}
            variant="link"
            marginLeft="-1rem"
            textDecoration="underline"
            _hover={{
              color: darken(0.1, slateGrey),
            }}
            _focus={undefined}
            height="3rem"
            width="14rem"
            paddingX="1rem"
            justifyContent="flex-start"
            data-cy="add-tubing-set-link-btn"
          >
            {t('orderCreateWidget.tubingSets.addBtn', {
              ns: ['orderCreate'],
            })}
          </Button>
        )}
      </Box>
    )
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={defaultAdminSet ? 0 : '3rem'}
      >
        {renderAdminSets()}
        <Button
          onClick={addAccessory}
          variant="link"
          marginLeft="-1rem"
          textDecoration="underline"
          _hover={{
            color: darken(0.1, slateGrey),
          }}
          height="6rem"
          width="14rem"
          paddingX="1rem"
          justifyContent="flex-start"
        >
          {t('orderCreateWidget.tubingSets.addBtn', {
            ns: ['orderCreate'],
          })}
        </Button>
      </Box>
    </>
  )
}
