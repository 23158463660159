import React from 'react'
import { Button, ButtonProps } from '@chakra-ui/core'
import { themeUtils } from '../../themeUtils'
import { darken } from 'polished'

const SubmitButton: React.FC<ButtonProps> = ({
  variantColor = 'blue',
  ...rest
}) => (
  <Button
    loadingText="Loading"
    backgroundColor={themeUtils.colors.midnightBlue}
    _hover={{
      backgroundColor: darken(0.2, themeUtils.colors.midnightBlue),
    }}
    boxShadow={{
      base: '0 8px 10px rgba(69, 218, 122, 0.2)',
      lg: 'none',
    }}
    variantColor={variantColor}
    borderRadius="1rem"
    color={themeUtils.colors.white}
    px="2rem"
    py="2rem"
    height="6rem"
    fontSize="1.4rem"
    fontWeight="bold"
    mt="2rem"
    type="submit"
    data-cy="submit"
    whiteSpace="unset"
    {...rest}
  />
)

export { SubmitButton }
