import React, { useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Flex } from '@chakra-ui/core'
import { CustomerName, Heading, SubText } from '../../components/Shared'
import { AccountBasicDetailsWidget } from '../../components/MyAccount/BasicDetails/AccountBasicDetailsWidget'
import { TeamMemberSection } from '../../components/MyAccount/TeamMember'
import { useTranslation } from 'react-i18next'

export const MyAccountIndexScreen: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation(['myAccount'])
  // Scroll to the top when the component mounts.
  // To solve the page jump issue on mount.
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Flex flexDirection="column">
      <div>
        <Heading as="h1" marginBottom="0">
          {t('header.main', { ns: ['myAccount'] })}
        </Heading>
        <SubText>
          <CustomerName />
        </SubText>
      </div>
      <AccountBasicDetailsWidget />
      <TeamMemberSection />
    </Flex>
  )
}
